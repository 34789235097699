import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';
import { viewBoxes } from './CustomSvgIcons.types';

const SvgPath = ({ shapeId }: SvgPathProps): ReactJSXElement => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 11.8801C24 13.2859 23.6724 14.525 23.0171 15.5972C22.755 16.0142 22.4512 16.4073 22.1057 16.7766C21.8198 17.0745 21.522 17.3366 21.2122 17.5629C20.1162 18.3611 18.8354 18.7602 17.3701 18.7602H4.84289L4.66419 18.242L3.9315 16.169H11.9017V13.1847H2.87715L1.96575 10.5935H11.9017V7.6091H0.911399L0 5H17.3701C18.8354 5 20.1162 5.40507 21.2122 6.2152C21.522 6.44156 21.8198 6.70364 22.1057 7.00148C22.4512 7.35889 22.7491 7.74608 22.9992 8.16306C23.6664 9.2472 24 10.4862 24 11.8801ZM21.4088 11.8801C21.4088 11.4751 21.3671 11.0938 21.2837 10.7364C21.1288 9.97396 20.7952 9.32464 20.2829 8.78852C20.0447 8.52642 19.7766 8.312 19.4788 8.14521C18.8831 7.78781 18.1802 7.6091 17.3701 7.6091H14.618V16.169H17.3701C18.1802 16.169 18.8831 15.9903 19.4788 15.6329C19.7766 15.4542 20.0447 15.2398 20.2829 14.9896C20.7952 14.4416 21.1288 13.7923 21.2837 13.0417C21.3671 12.6843 21.4088 12.2971 21.4088 11.8801Z"
      id={shapeId}
    />
  );
};

export const HadoukenIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_hadoukenCurrentColorGradient`;
  const newColorId = `${prefix}_hadoukenNewColorGradient`;
  const shapeId = `${prefix}_hadoukenShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.redColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.redColors?.PLUS_3,
      stop: theme.palette.redColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox={viewBoxes[iconType]}>
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

import { useMediaQuery } from '@mui/material';
import { theme } from '../landingPage';

export const useMobileDetect = () =>
  useMediaQuery(theme.breakpoints.down('sm'));

export const useDesktopDetect = () => useMediaQuery(theme.breakpoints.up('md'));

export const useXlDesktopDetect = () =>
  useMediaQuery(theme.breakpoints.up('lg'));

export const useTabletDetect = () =>
  useMediaQuery(theme.breakpoints.down('md'));

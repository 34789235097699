/* eslint-disable @typescript-eslint/naming-convention */
enum GreyColorsDark {
  MINUS_4 = '#1B242C',
  MINUS_3 = '#272E35',
  MINUS_2 = '#3A424A',
  MINUS_1 = '#4A545E',
  _0 = '#555F6D',
  PLUS_1 = '#7E8B99',
  PLUS_2 = '#9EA8B3',
  PLUS_3 = '#CFD6DD',
  PLUS_4 = '#DEE3E7',
  PLUS_5 = '#E9ECEF',
  PLUS_6 = '#F0F3F5',
  PLUS_7 = '#F5F7F9',
  PLUS_8 = '#FBFCFD',
}
enum InvertedColorsDark {
  MINUS_4 = 'rgb(255,255,255,1)',
  MINUS_3 = 'rgb(255,255,255,0.85)',
  MINUS_2 = 'rgb(255,255,255,0.75)',
  MINUS_1 = 'rgb(255,255,255,0.7)',
  _0 = 'rgb(255,255,255,0.65)',
  PLUS_1 = 'rgb(255,255,255,0.5)',
  PLUS_2 = 'rgb(255,255,255,0.4)',
  PLUS_3 = 'rgb(255,255,255,0.35)',
  PLUS_4 = 'rgb(255,255,255,0.25)',
  PLUS_5 = 'rgb(255,255,255,0.2)',
  PLUS_6 = 'rgb(255,255,255,0.15)',
  PLUS_7 = 'rgb(255,255,255,0.1)',
  PLUS_8 = 'rgb(255,255,255,0.05)',
}

enum RedColorsDark {
  _0 = '#C53434',
  PLUS_1 = '#F26464',
  PLUS_2 = '#F49090',
  PLUS_3 = '#FCCFCF',
}
enum RaspberryColorsDark {
  _0 = '#C03060',
  PLUS_1 = '#E4628D',
  PLUS_2 = '#EC8DAD',
  PLUS_3 = '#F8CEDC',
}
enum MagentaColorsDark {
  _0 = '#B12F86',
  PLUS_1 = '#DE5EB3',
  PLUS_2 = '#EC89CB',
  PLUS_3 = '#F7CAE8',
}
enum PurpleColorsDark {
  _0 = '#9939AC',
  PLUS_1 = '#C466DB',
  PLUS_2 = '#D98DEC',
  PLUS_3 = '#F4CAF7',
}
enum GrapeColorsDark {
  _0 = '#8040BF',
  PLUS_1 = '#AD71EA',
  PLUS_2 = '#C494F4',
  PLUS_3 = '#E5D0FB',
}
enum VioletColorsDark {
  _0 = '#634ECA',
  PLUS_1 = '#917EF1',
  PLUS_2 = '#ABA2FB',
  PLUS_3 = '#DCD6FF',
}
enum BlueColorsDark {
  _0 = '#3062D4',
  PLUS_1 = '#6792F4',
  PLUS_2 = '#8DB0FB',
  PLUS_3 = '#CDDDFF',
}
enum CyanColorsDark {
  _0 = '#0870BA',
  PLUS_1 = '#5699DC',
  PLUS_2 = '#79BCF6',
  PLUS_3 = '#C2E3FF',
}
enum TealColorsDark {
  _0 = '#077597',
  PLUS_1 = '#4F9EBA',
  PLUS_2 = '#7AC0DC',
  PLUS_3 = '#B8E8F5',
}
enum AquamarineColorsDark {
  _0 = '#097C69',
  PLUS_1 = '#3DA497',
  PLUS_2 = '#6EC9BF',
  PLUS_3 = '#BEE9E4',
}
enum GreenColorsDark {
  _0 = '#1D7C4D',
  PLUS_1 = '#4AA578',
  PLUS_2 = '#75CC9E',
  PLUS_3 = '#C2EBD5',
}
enum EmeraldColorsDark {
  _0 = '#347434',
  PLUS_1 = '#55A555',
  PLUS_2 = '#87C987',
  PLUS_3 = '#C6ECC6',
}
enum OrangeColorsDark {
  _0 = '#F59638',
  PLUS_1 = '#F6A351',
  PLUS_2 = '#FEB872',
  PLUS_3 = '#FFD4A8',
}
// TODO: add yellow colors when needed - and adjust color shades
// enum YellowColorsDark {
//   _0 = '#fff200',
//   PLUS_1 = '#ffdf5d',
//   PLUS_2 = '#ffe889',
//   PLUS_3 = '#fffbb8',
// }
enum NeutralColorsDark {
  _0 = '#4A545E',
  PLUS_1 = '#555F6D',
  PLUS_2 = '#7E8B99',
  PLUS_3 = '#9EA8B3',
}
enum GreyContentDark {
  PRIMARY = GreyColorsDark.MINUS_3,
  SECONDARY = GreyColorsDark._0,
  TETRIARY = GreyColorsDark.PLUS_1,
  DISABLED = GreyColorsDark.PLUS_2,
}
enum GreyBorderDark {
  STRONG = GreyColorsDark._0,
  MEDIUM = GreyColorsDark.PLUS_3,
  LIGHT = GreyColorsDark.PLUS_5,
}
enum GreyActionDark {
  ENABLED = GreyColorsDark.MINUS_1,
  HOVERED = GreyColorsDark.MINUS_2,
  PRESSED = GreyColorsDark.MINUS_3,
  SELECTED = GreyColorsDark.MINUS_2,
}

enum GreySubtleActionDark {
  ENABLED = GreyColorsDark.PLUS_6,
  HOVERED = GreyColorsDark.PLUS_5,
  PRESSED = GreyColorsDark.PLUS_4,
  SELECTED = GreyColorsDark.PLUS_5,
}
enum GreyOutlineActionDark {
  ENABLED = GreyColorsDark.PLUS_3,
  HOVERED = GreyColorsDark.PLUS_2,
  PRESSED = GreyColorsDark.PLUS_1,
  SELECTED = GreyColorsDark.PLUS_2,
}
enum GreyBackgroundDark {
  INVERTED = GreyColorsDark.MINUS_4,
  STRONG = GreyColorsDark.MINUS_3,
  MUTED = GreyColorsDark.PLUS_4,
  ON_SUBTLE = GreyColorsDark.PLUS_5,
  SUBTLE = GreyColorsDark.PLUS_7,
  SURFACE = GreyColorsDark.PLUS_8,
}

enum InvertedContentDark {
  PRIMARY = InvertedColorsDark.MINUS_4,
  SECONDARY = InvertedColorsDark._0,
  TETRIARY = InvertedColorsDark.PLUS_1,
  DISABLED = InvertedColorsDark.PLUS_2,
}
enum InvertedBorderDark {
  STRONG = InvertedColorsDark._0,
  MEDIUM = InvertedColorsDark.PLUS_3,
  LIGHT = InvertedColorsDark.PLUS_5,
}
enum InvertedActionDark {
  ENABLED = InvertedColorsDark.MINUS_1,
  HOVERED = InvertedColorsDark.MINUS_2,
  PRESSED = InvertedColorsDark.MINUS_3,
  SELECTED = InvertedColorsDark.PLUS_4,
}

enum InvertedSubtleActionDark {
  ENABLED = InvertedColorsDark.PLUS_6,
  HOVERED = InvertedColorsDark.PLUS_5,
  PRESSED = InvertedColorsDark.PLUS_4,
  SELECTED = InvertedColorsDark.PLUS_5,
}

enum InvertedOutlineActionDark {
  ENABLED = InvertedColorsDark.PLUS_4,
  HOVERED = InvertedColorsDark.PLUS_3,
  PRESSED = InvertedColorsDark.PLUS_2,
  SELECTED = InvertedColorsDark.PLUS_3,
}

enum InvertedBackgroundDark {
  INVERTED = InvertedColorsDark.MINUS_4,
  STRONG = InvertedColorsDark._0,
  MUTED = InvertedColorsDark.PLUS_4,
  ON_SUBTLE = InvertedColorsDark.PLUS_5,
  SUBTLE = InvertedColorsDark.PLUS_7,
  SURFACE = InvertedColorsDark.PLUS_8,
}

export const colorsDark = {
  greyColors: GreyColorsDark,
  blueColors: BlueColorsDark,
  raspberryColors: RaspberryColorsDark,
  magentaColors: MagentaColorsDark,
  purpleColors: PurpleColorsDark,
  redColors: RedColorsDark,
  grapeColors: GrapeColorsDark,
  violetColors: VioletColorsDark,
  cyanColors: CyanColorsDark,
  tealColors: TealColorsDark,
  aquamarineColors: AquamarineColorsDark,
  greenColors: GreenColorsDark,
  emeraldColors: EmeraldColorsDark,
  orangeColors: OrangeColorsDark,
  // yellowColors: YellowColorsDark,
  neutralColors: NeutralColorsDark,
  invertedColors: InvertedColorsDark,
  greyContent: GreyContentDark,
  greyBorder: GreyBorderDark,
  greyAction: GreyActionDark,
  greySubtleAction: GreySubtleActionDark,
  greyOutlineAction: GreyOutlineActionDark,
  greyBackgroundColor: GreyBackgroundDark,
  invertedContent: InvertedContentDark,
  invertedBorder: InvertedBorderDark,
  invertedAction: InvertedActionDark,
  invertedSubtleAction: InvertedSubtleActionDark,
  invertedOutlineAction: InvertedOutlineActionDark,
  invertedBackgroundColor: InvertedBackgroundDark,
};

enum GreyColorsLight {
  PLUS_8 = '#1B242C',
  PLUS_7 = '#272E35',
  PLUS_6 = '#3A424A',
  PLUS_5 = '#4A545E',
  PLUS_4 = '#555F6D',
  PLUS_3 = '#7E8B99',
  PLUS_2 = '#9EA8B3',
  PLUS_1 = '#CFD6DD',
  _0 = '#DEE3E7',
  MINUS_1 = '#E9ECEF',
  MINUS_2 = '#F0F3F5',
  MINUS_3 = '#F5F7F9',
  MINUS_4 = '#FBFCFD',
}
enum InvertedColorsLight {
  MINUS_4 = 'rgb(27, 36, 44,1)',
  MINUS_3 = 'rgb(27, 36, 44,0.85)',
  MINUS_2 = 'rgb(27, 36, 44,0.75)',
  MINUS_1 = 'rgb(27, 36, 44,0.7)',
  _0 = 'rgb(27, 36, 44,0.65)',
  PLUS_1 = 'rgb(27, 36, 44,0.5)',
  PLUS_2 = 'rgb(27, 36, 44,0.4)',
  PLUS_3 = 'rgb(27, 36, 44,0.35)',
  PLUS_4 = 'rgb(27, 36, 44,0.25)',
  PLUS_5 = 'rgb(27, 36, 44,0.2)',
  PLUS_6 = 'rgb(27, 36, 44,0.15)',
  PLUS_7 = 'rgb(27, 36, 44,0.1)',
  PLUS_8 = 'rgb(27, 36, 44,0.05)',
}
enum RedColorsLight {
  _0 = '#C53434',
  PLUS_1 = '#F26464',
  PLUS_2 = '#F49090',
  PLUS_3 = '#FCCFCF',
}
enum RaspberryColorsLight {
  _0 = '#C03060',
  PLUS_1 = '#E4628D',
  PLUS_2 = '#EC8DAD',
  PLUS_3 = '#F8CEDC',
}
enum MagentaColorsLight {
  _0 = '#B12F86',
  PLUS_1 = '#DE5EB3',
  PLUS_2 = '#EC89CB',
  PLUS_3 = '#F7CAE8',
}
enum PurpleColorsLight {
  _0 = '#9939AC',
  PLUS_1 = '#C466DB',
  PLUS_2 = '#D98DEC',
  PLUS_3 = '#F4CAF7',
}
enum GrapeColorsLight {
  _0 = '#8040BF',
  PLUS_1 = '#AD71EA',
  PLUS_2 = '#C494F4',
  PLUS_3 = '#E5D0FB',
}
enum VioletColorsLight {
  _0 = '#634ECA',
  PLUS_1 = '#917EF1',
  PLUS_2 = '#ABA2FB',
  PLUS_3 = '#DCD6FF',
}
enum BlueColorsLight {
  _0 = '#3062D4',
  PLUS_1 = '#6792F4',
  PLUS_2 = '#8DB0FB',
  PLUS_3 = '#CDDDFF',
}
enum CyanColorsLight {
  _0 = '#0870BA',
  PLUS_1 = '#5699DC',
  PLUS_2 = '#79BCF6',
  PLUS_3 = '#C2E3FF',
}
enum TealColorsLight {
  _0 = '#077597',
  PLUS_1 = '#4F9EBA',
  PLUS_2 = '#7AC0DC',
  PLUS_3 = '#B8E8F5',
}
enum AquamarineColorsLight {
  _0 = '#097C69',
  PLUS_1 = '#3DA497',
  PLUS_2 = '#6EC9BF',
  PLUS_3 = '#BEE9E4',
}
enum GreenColorsLight {
  _0 = '#1D7C4D',
  PLUS_1 = '#4AA578',
  PLUS_2 = '#75CC9E',
  PLUS_3 = '#C2EBD5',
}
enum EmeraldColorsLight {
  _0 = '#347434',
  PLUS_1 = '#55A555',
  PLUS_2 = '#87C987',
  PLUS_3 = '#C6ECC6',
}
enum OrangeColorsLight {
  _0 = '#F59638',
  PLUS_1 = '#F6A351',
  PLUS_2 = '#FEB872',
  PLUS_3 = '#FFD4A8',
}
// enum YellowColorsLight {
//   _0 = '#fff200',
//   PLUS_1 = '#ffea5d',
//   PLUS_2 = '#ead97f',
//   PLUS_3 = '#fffbb8',
// }
enum NeutralColorsLight {
  _0 = '#4A545E',
  PLUS_1 = '#555F6D',
  PLUS_2 = '#7E8B99',
  PLUS_3 = '#9EA8B3',
}
enum GreyContentLight {
  PRIMARY = GreyColorsLight.MINUS_3,
  SECONDARY = GreyColorsLight._0,
  TETRIARY = GreyColorsLight.PLUS_1,
  DISABLED = GreyColorsLight.PLUS_2,
}
enum GreyBorderLight {
  STRONG = GreyColorsLight._0,
  MEDIUM = GreyColorsLight.PLUS_3,
  LIGHT = GreyColorsLight.PLUS_5,
}
enum GreyActionLight {
  ENABLED = GreyColorsLight.MINUS_1,
  HOVERED = GreyColorsLight.MINUS_2,
  PRESSED = GreyColorsLight.MINUS_3,
  SELECTED = GreyColorsLight.MINUS_2,
}

enum GreySubtleActionLight {
  ENABLED = GreyColorsLight.PLUS_6,
  HOVERED = GreyColorsLight.PLUS_5,
  PRESSED = GreyColorsLight.PLUS_4,
  SELECTED = GreyColorsLight.PLUS_5,
}
enum GreyOutlineActionLight {
  ENABLED = GreyColorsLight.PLUS_3,
  HOVERED = GreyColorsLight.PLUS_2,
  PRESSED = GreyColorsLight.PLUS_1,
  SELECTED = GreyColorsLight.PLUS_2,
}
enum GreyBackgroundLight {
  INVERTED = GreyColorsLight.MINUS_4,
  STRONG = GreyColorsLight.MINUS_3,
  MUTED = GreyColorsLight.PLUS_4,
  ON_SUBTLE = GreyColorsLight.PLUS_5,
  SUBTLE = GreyColorsLight.PLUS_7,
  SURFACE = GreyColorsLight.PLUS_8,
}

enum InvertedContentLight {
  PRIMARY = InvertedColorsLight.MINUS_4,
  SECONDARY = InvertedColorsLight._0,
  TETRIARY = InvertedColorsLight.PLUS_1,
  DISABLED = InvertedColorsLight.PLUS_2,
}
enum InvertedBorderLight {
  STRONG = InvertedColorsLight._0,
  MEDIUM = InvertedColorsLight.PLUS_3,
  LIGHT = InvertedColorsLight.PLUS_7,
}
enum InvertedActionLight {
  ENABLED = InvertedColorsLight.MINUS_1,
  HOVERED = InvertedColorsLight.MINUS_2,
  PRESSED = InvertedColorsLight.MINUS_3,
  SELECTED = InvertedColorsLight.PLUS_4,
}

enum InvertedSubtleActionLight {
  ENABLED = InvertedColorsLight.PLUS_6,
  HOVERED = InvertedColorsLight.PLUS_5,
  PRESSED = InvertedColorsLight.PLUS_4,
  SELECTED = InvertedColorsLight.PLUS_5,
}

enum InvertedOutlineActionLight {
  ENABLED = InvertedColorsLight.PLUS_4,
  HOVERED = InvertedColorsLight.PLUS_3,
  PRESSED = InvertedColorsLight.PLUS_2,
  SELECTED = InvertedColorsLight.PLUS_3,
}

enum InvertedBackgroundLight {
  INVERTED = InvertedColorsLight.MINUS_4,
  STRONG = InvertedColorsLight._0,
  MUTED = InvertedColorsLight.PLUS_4,
  ON_SUBTLE = InvertedColorsLight.PLUS_5,
  SUBTLE = InvertedColorsLight.PLUS_7,
  SURFACE = InvertedColorsLight.PLUS_8,
}

export const colorsLight = {
  greyColors: GreyColorsLight,
  blueColors: BlueColorsLight,
  raspberryColors: RaspberryColorsLight,
  magentaColors: MagentaColorsLight,
  purpleColors: PurpleColorsLight,
  redColors: RedColorsLight,
  grapeColors: GrapeColorsLight,
  violetColors: VioletColorsLight,
  cyanColors: CyanColorsLight,
  tealColors: TealColorsLight,
  aquamarineColors: AquamarineColorsLight,
  greenColors: GreenColorsLight,
  emeraldColors: EmeraldColorsLight,
  orangeColors: OrangeColorsLight,
  // yellowColors: YellowColorsLight,
  neutralColors: NeutralColorsLight,
  invertedColors: InvertedColorsLight,
  greyContent: GreyContentLight,
  greyBorder: GreyBorderLight,
  greyAction: GreyActionLight,
  greySubtleAction: GreySubtleActionLight,
  greyOutlineAction: GreyOutlineActionLight,
  greyBackgroundColor: GreyBackgroundLight,
  invertedContent: InvertedContentLight,
  invertedBorder: InvertedBorderLight,
  invertedAction: InvertedActionLight,
  invertedSubtleAction: InvertedSubtleActionLight,
  invertedOutlineAction: InvertedOutlineActionLight,
  invertedBackgroundColor: InvertedBackgroundLight,
};

import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';

const SvgPath = ({ shapeId, solidColor }: SvgPathProps): ReactJSXElement => {
  return (
    <path
      d="M17.736 17.152V14.528C17.736 14.1653 17.896 13.888 18.216 13.696L21.128 11.872C21.6187 11.5733 22.0347 11.424 22.376 11.424H24.936C25.384 11.424 25.608 11.648 25.608 12.096V31.328C25.608 31.584 25.5547 31.7653 25.448 31.872C25.3627 31.9573 25.2027 32 24.968 32H22.216C21.768 32 21.544 31.776 21.544 31.328V15.744L18.408 17.536C17.96 17.728 17.736 17.6 17.736 17.152Z"
      fill={solidColor ? 'currentColor' : ''}
      id={shapeId}
      strokeWidth="1.2"
      stroke={solidColor ? 'currentColor' : ''}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
};

export const OneIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor = 'orange',
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_oneCurrentColorGradient`;
  const newColorId = `${prefix}_oneNewColorGradient`;
  const shapeId = `${prefix}_oneShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const colors = theme.palette?.[`${gradientColor}Colors`];

  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: colors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: colors?.PLUS_3,
      stop: colors?.PLUS_1,
    },
  };

  const svgColors = isLight ? colorsLight : colorsDark;

  return (
    <SvgIcon {...props} viewBox="0 0 45 45">
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="90%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="90%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>

          <g
            style={{
              stroke: `url("#${currColorId}")`,
              fill: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              fill: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

import {
  CustomActionButton,
  CustomAnchor,
  CustomBlogPost,
  CustomCaseStudyTestimonial,
  CustomCodeSnippet,
  CustomComponentGroupEmbedded,
  CustomCurrentOpening,
  CustomDownloadButton,
  CustomHubspotForm,
  CustomIconCardsCollection,
  CustomImage,
  CustomImageSlider,
  CustomImagesGrid,
  CustomInstagramPost,
  CustomLinkImage,
  CustomMaybe,
  CustomPersonCard,
  CustomScalars,
  CustomTextBlockGrid,
  CustomTilesCollection,
} from '../../customStrapiTypes';
export type RichTextProps = {
  content: CustomScalars['String']['output'];
  embeddedItems?: CustomMaybe<CustomComponentGroupEmbedded> | undefined;
};

export const contentTypesConfiguration = {
  codeSnippet: {
    field: 'title',
  },
  caseStudyTestimonial: {
    field: 'title',
  },
  imagesGrid: {
    field: 'title',
  },
  textBlockGrid: {
    field: 'title',
  },
  downloadButton: {
    field: 'title',
  },
  hubspotForm: {
    field: 'title',
  },
  actionButton: {
    field: 'label',
  },
  imageSlider: {
    field: 'title',
  },
  instagramPosts: {
    field: 'title',
  },
  image: {
    field: 'title',
  },
  iconCardsCollection: {
    field: 'name',
  },
  anchor: {
    field: 'textId',
  },
  personCard: {
    field: 'email',
  },
  currentOpenings: {
    field: 'title',
  },
  linkImage: {
    field: 'title',
  },
  tilesCollection: {
    field: 'title',
  },
  blogPost: {
    field: 'title',
  },
};
export type ComponentGroupEmbeddedKeys =
  | 'action_buttons'
  | 'anchors'
  | 'blog_posts'
  | 'case_study_testimonials'
  | 'code_snippets'
  | 'current_openings'
  | 'download_buttons'
  | 'hubspot_forms'
  | 'icon_cards_collections'
  | 'image_sliders'
  | 'images'
  | 'images_grids'
  | 'instagram_posts'
  | 'link_images'
  | 'person_cards'
  | 'text_block_grids'
  | 'tiles_collections';

export type ContentTypesConfigurationKeys =
  | 'blogPost'
  | 'tilesCollection'
  | 'linkImage'
  | 'currentOpenings'
  | 'personCard'
  | 'anchor'
  | 'iconCardsCollection'
  | 'image'
  | 'instagramPosts'
  | 'imageSlider'
  | 'actionButton'
  | 'hubspotForm'
  | 'downloadButton'
  | 'textBlockGrid'
  | 'imagesGrid'
  | 'caseStudyTestimonial'
  | 'codeSnippet';

interface IAtrribs {
  href?: string;
  alt?: string;
  src?: string;
  style?: string;
}
export interface IReplace {
  name: string;
  children: any;
  attribs: IAtrribs;
}
export type EmbeddedEntries =
  | CustomBlogPost
  | CustomTilesCollection
  | CustomLinkImage
  | CustomCurrentOpening
  | CustomPersonCard
  | CustomAnchor
  | CustomIconCardsCollection
  | CustomImage
  | CustomInstagramPost
  | CustomImageSlider
  | CustomActionButton
  | CustomHubspotForm
  | CustomDownloadButton
  | CustomTextBlockGrid
  | CustomImagesGrid
  | CustomCaseStudyTestimonial
  | CustomCodeSnippet;

export interface EmbeddedAssetBlockProps {
  data: {
    target: {
      metadata: {
        tags: string[];
      };
      sys: {
        space: {
          sys: {
            type: string;
            linkType: string;
            id: string;
          };
        };
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        environment: {
          sys: {
            id: string;
            type: string;
            linkType: string;
          };
        };
        revision: number;
        locale: string;
      };
      fields: {
        title: string;
        description: string;
        file: {
          url: string;
          details: {
            size: number;
            image: {
              width: number;
              height: number;
            };
          };
          fileName: string;
          contentType: string;
        };
      };
    };
  };
  content: unknown[];
  nodeType: string;
}

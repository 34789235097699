import { Stack, SvgIcon, Typography, styled } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4, 0),
  gap: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(2.5, 0),
  },
  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    borderBottom: `1px solid ${theme.palette.invertedBorder?.LIGHT}`,
    borderTop: `1px solid ${theme.palette.invertedBorder?.LIGHT}`,
    ...theme.mixins.mobilePadding(),
    paddingTop: `${theme.spacing(2)} !important`,
    paddingBottom: `${theme.spacing(2)} !important`,
  },
  overflowX: 'hidden',
}));
export const UpperStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  flexWrap: 'wrap',
  flexDirection: 'row',
  height: 'min-content',
  minHeight: theme.spacing(23),
  '>div': {
    width: '23%',
    [theme.breakpoints.down('xl')]: {
      width: '30%',
    },
  },
  [theme.breakpoints.down('xl')]: {
    minHeight: theme.spacing(36),
  },
  [theme.breakpoints.down('md')]: {
    minHeight: theme.spacing(41),
    gap: theme.spacing(2),
    width: '100%',
    flexDirection: 'column',
    '>div': {
      width: '100%',
    },
  },
}));
export const LowerStack = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(-2.5),
  width: '100%',
  flexWrap: 'wrap',
  flexDirection: 'row',
  height: 'min-content',
  '>div': {
    width: '33%',
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
  },
  [theme.breakpoints.down('md')]: {
    background: 'rgba(255, 255, 255, 0.05)',
    marginLeft: 'calc((100vw - 100%) * -0.5)',
    ...theme.mixins.mobilePadding(),
    width: '100vw',
    marginTop: theme.spacing(0),
    flexDirection: 'column',
    '>div': {
      // marginLeft: '-2%',
      width: '100%',
    },
    marginBottom: `-${theme.spacing(2)}`,
  },
}));
export const DividerSectionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  letterSpacing: '3px !important',
  flex: 1,

  [theme.breakpoints.up('md')]: {
    textTransform: 'uppercase',
  },

  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(2),
    letterSpacing: '-0.1px !important',
  },
}));

export const DividerSection = styled(Stack)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.invertedBorder?.LIGHT}`,
  width: '100%',
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    borderBottom: 0,
    paddingBottom: 0,
  },
}));
export const StyledMobileVisitBlogIcon = styled(NavigateNextIcon)(
  ({ theme }) => ({
    color: theme.palette.invertedContent?.PRIMARY,
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  })
);
export const StyledDesktopVisitBlogIcon = styled(SvgIcon)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  display: 'block',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
export const DividerWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  '&.HoverPossibility': {
    cursor: 'pointer',
  },
  whiteSpace: 'nowrap',
  width: 'min-content',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
export const EbookStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));
export const ImageStack = styled(Stack)(({ theme }) => ({
  aspectRatio: '1/1.385',
  width: theme.utils.fluidSize({ minSize: 170, maxSize: 236 }),
  maxWidth: '236px',
  minWidth: '170px',
  [theme.breakpoints.down('md')]: {
    minWidth: 'auto',
    width: '142px',
  },
}));
export const StyledImage = styled('img')(({ theme }) => ({}));
export const ContentStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  flex: 1,
  transform: 'translateX(-7%)',
  [theme.breakpoints.down('md')]: {
    transform: 'translateX(-3%)',
  },
}));
export const StyledEbookName = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
}));
export const DownloadStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  cursor: 'pointer',
  width: 'min-content',
  gap: theme.spacing(0.5),
  svg: {
    path: {
      stroke: theme.palette.invertedContent?.PRIMARY,
    },
  },
}));
export const StyledDownloadText = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  whiteSpace: 'nowrap',
}));

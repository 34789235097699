import React from 'react';
import { StyledIconLink } from './styles';
interface StartupIconProps {
  url: string;
  imageSrc: string;
  imageAlt: string;
}
export const StartupIcon: React.FC<StartupIconProps> = ({
  url,
  imageSrc,
  imageAlt,
}) => {
  const websiteUrl = process.env.NEXT_PUBLIC_PATH as string | '';

  return (
    <StyledIconLink
      href={url}
      target={`${url.startsWith(websiteUrl) ? '_self' : '_blank'}`}
      rel={`${url.startsWith(websiteUrl) ? '' : 'noopener noreferrer'}`}>
      <img src={imageSrc} alt={imageAlt} />
    </StyledIconLink>
  );
};

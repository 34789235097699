import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { colors } from '../../../theme/default/color';
import type { RichTextProps } from '../RichText.types';
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { IReplace } from '../RichText.types';
const PREFIX = 'RichTextLandingPages';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    color: theme.palette.primary.main,
    '& h2': {
      textAlign: 'center',
      margin: `3rem 0`,
      textTransform: 'uppercase',
    },
    '& h3': {
      margin: `3rem 0`,
    },
    '& h4': {
      margin: `3rem 0`,
    },
    '& p b': {
      marginBottom: theme.spacing(3),
      display: 'inline-block',
      fontWeight: 600,
      color: colors.black,
    },
    '& ol': {
      fontSize: '1.125rem',
      color: colors.black,
      fontWeight: 600,
    },
    '& ul': {
      fontSize: '16px',
      textAlign: 'left',

      '& li': {
        listStyle: 'none',
        position: 'relative',

        '&:before': {
          content: '""',
          display: 'inline-block',
          width: '16px',
          top: '6px',
          left: '-26px',
          position: 'absolute',
          height: '16px',
          borderRadius: '50%',
          background: 'transparent',
          border: `4px solid ${theme.palette.secondary.main}`,
        },
        '& fragment>ul>li': {
          '&:before': {
            content: '"unset !important"',
          },
        },
        '& p': {
          display: 'inline',
        },
      },
    },
  },
}));
const options = {
  replace: ({ name, children }: IReplace) => {
    if (name === 'h1') {
      return (
        <Typography variant="h1">{domToReact(children, options)}</Typography>
      );
    }
    if (name === 'h2') {
      return (
        <Typography variant="h2">{domToReact(children, options)}</Typography>
      );
    }
    if (name === 'h3') {
      return (
        <Typography variant="h3">{domToReact(children, options)}</Typography>
      );
    }
    if (name === 'h4') {
      return (
        <Typography variant="h4">{domToReact(children, options)}</Typography>
      );
    }

    if (name === 'p') {
      return (
        <Typography component="p" variant="body1">
          {domToReact(children, options)}
        </Typography>
      );
    }
  },
} as HTMLReactParserOptions;

export const RichTextLandingPages: React.FC<RichTextProps> = ({ content }) => {
  return <Root className={classes.root}>{parse(content, options)}</Root>;
};

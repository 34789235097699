import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { isTouchScreen } from '../../../utils/touchScreenDetect';
import { submenusWhichAction } from '../utils/HeaderSubmenusUtils';
import {
  StyledBorderStack,
  StyledCollapse,
  StyledContentStack,
} from './styles';
import type { HeaderSubmenuProps } from './HeaderSubmenu.types';
import { ThemeContext } from '../../../../context/themeContext/themeContext';
import { ThemeContextType } from '../../../../context/themeContext/theme.Context.types';
export const HeaderSubmenu: React.FC<HeaderSubmenuProps> = ({
  onSubmenuEnter,
  setShouldCloseSubmenu,
  children,
  isOpen,
  state,
  setUnmounted = (val: boolean) => {
    return val;
  },
  onUnwantedTouch,
  onHoverStateChange,
  submenu,
}) => {
  const { changeScrollTarget } = useContext(ThemeContext) as ThemeContextType;
  const SubmenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      state.currentSubmenu !== state.prevSubmenu &&
      state.currentSubmenu !== null &&
      !isTouchScreen()
    ) {
      onHoverStateChange(submenusWhichAction[submenu], false);
      return;
    }
    if (state.isSubmenuNameHover || !state.shouldCloseSubmenu) return;
    onHoverStateChange(submenusWhichAction[submenu], false);
  }, [state.isSubmenuNameHover, state.shouldCloseSubmenu]);

  const handleCollapseEntering = useCallback(() => {
    setUnmounted(false);
  }, []);

  const handleCollapseExited = useCallback(() => {
    setUnmounted(true);
  }, []);

  const handleMouseEnter = useCallback(() => {
    if (isTouchScreen()) {
      onUnwantedTouch(true);
      onHoverStateChange(submenusWhichAction[submenu], true);
    } else {
      onSubmenuEnter({
        whichSubmenu: submenusWhichAction[submenu],
        newValue: true,
        shouldCloseSub: false,
      });
    }
  }, [onSubmenuEnter, onUnwantedTouch, submenu]);

  const handleMouseLeave = useCallback(() => {
    if (isTouchScreen()) {
      onUnwantedTouch(true);
      onHoverStateChange(submenusWhichAction[submenu], false);
    } else {
      setShouldCloseSubmenu(true);
    }
  }, [onUnwantedTouch, setShouldCloseSubmenu]);

  useEffect(() => {
    const checkIsSubmenuLarger = () => {
      if (!SubmenuRef.current) return;
      if (
        SubmenuRef.current.getBoundingClientRect().height >=
          window.innerHeight - 65 &&
        isOpen
      ) {
        changeScrollTarget(SubmenuRef.current);
      } else {
        changeScrollTarget(null);
      }
    };
    checkIsSubmenuLarger();
    window.addEventListener('resize', checkIsSubmenuLarger);
    return () => window.removeEventListener('resize', checkIsSubmenuLarger);
  }, [isOpen, SubmenuRef.current]);
  return (
    <StyledCollapse
      onEntering={handleCollapseEntering}
      onExited={handleCollapseExited}
      in={isOpen}
      timeout={350}
      unmountOnExit>
      <StyledBorderStack
        ref={SubmenuRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <StyledContentStack>{children}</StyledContentStack>
      </StyledBorderStack>
    </StyledCollapse>
  );
};

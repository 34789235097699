import { Stack, styled, Collapse } from '@mui/material';

export const StyledContentStack = styled(Stack)(({ theme }) => ({
  maxWidth: `${theme.breakpoints.values.xl}px`,
  width: '90%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
export const StyledBorderStack = styled(Stack)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.invertedBorder?.LIGHT}`,
  maxHeight: `calc(100dvh - 65px)`,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));
export const StyledCollapse = styled(Collapse)(() => ({
  '&.MuiCollapse-wrapper': {
    overflow: 'auto',
    '& .MuiCollapse-wrapperInner': {
      '& .MuiBox-root': {
        height: 'auto',
        padding: '0px',
      },
    },
  },
  width: 'calc((100vw - 100%) + 100%)',
  marginLeft: 'calc((100vw - 100%) / -2)',
}));

import { Typography, useTheme } from '@mui/material';
import { Link, Section } from '@rumblefish/ui/AlgeaTheme';
import React from 'react';
import macImg from '../../assets/png/AlgeaTheme/turtable_case_study.png';
import type { LatestCaseStudyProps } from './LatestCaseStudy.types';
import {
  StyledButton,
  StyledHeadline,
  StyledImage,
  StyledStack,
  StyledText,
  StyledTextWrapper,
} from './styles';

export const LatestCaseStudy = (props: LatestCaseStudyProps) => {
  const theme = useTheme();

  return (
    <>
      <Section
        width="small"
        backgroundColor={theme.palette.colorBrand?.primary}>
        <StyledStack>
          <Typography variant="uppercase">Latest case study</Typography>
          <StyledTextWrapper>
            <StyledHeadline variant="heading3" color={theme.palette.colorWhite}>
              {props.title ?? 'A hybrid of a social network and a music app'}
            </StyledHeadline>
            <StyledText variant="body2Big">
              {props.description ??
                `The original idea behind the all-new Turntable™ is to bring people
              together with music. TT.fm is a social network platform that
              enables its users to listen to and share music with friends in
              real-time through a streaming service. The platform offers a
              virtual space for music lovers all over the world to share and
              discover new tunes. Besides listening and streaming
              functionalities, TT.fm also enables user interactions, creating
              virtual events, and a chat feature.`}
            </StyledText>
          </StyledTextWrapper>
          <Link
            sx={{ color: theme.palette.colorWhite }}
            to={props.buttonLink ?? '/case-studies/tt-fm/'}>
            <StyledButton
              variant="contained"
              size="large"
              sx={{ alignSelf: 'flex-start' }}>
              View case study
            </StyledButton>
          </Link>
        </StyledStack>
      </Section>
      <Section
        width={props.imageWidth ?? 'full'}
        backgroundColor={`linear-gradient(180deg, ${theme.palette.colorBrand?.primary} 50%, ${theme.palette.colorWhite} 50%)`}
        style={{ display: 'flex', justifyContent: 'center' }}>
        <StyledImage src={props.image ?? macImg} />
      </Section>
    </>
  );
};

import { styled } from '@mui/material/styles';
import React from 'react';
import { colors } from '../../theme/default/color';
import type { DividerProps } from './Divider.types';
import { Box } from '@mui/material';

const PREFIX = 'Divider';

const classes = {
  divider: `${PREFIX}-divider`,
};

const Root = styled(Box)<DividerProps>(({ type }) => ({
  [`&.${classes.divider}`]: {
    height: '1px',
    background: colors.dividerGray,
    opacity: type === 'lighten' ? 0.15 : 1,
  },
}));

export const Divider: React.FC<DividerProps> = ({
  type = 'normal',
  ...props
}) => {
  return <Root {...props} type={type} className={classes.divider} />;
};

import React from 'react';
import { data } from '../data';
import { MobileCaseBox } from '../MobileCaseBox';
import type { MobileCaseStudiesProps } from './MobileCaseStudies.types';
import { StyledStack } from './styles';

export const MobileCaseStudies = ({ ...props }: MobileCaseStudiesProps) => (
  <StyledStack {...props} spacing={8}>
    {data.map((item, index) => (
      <MobileCaseBox key={index} {...item} />
    ))}
  </StyledStack>
);

import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';
import { viewBoxes } from './CustomSvgIcons.types';

const SvgPath = ({
  iconType,
  shapeId,
  solidColor,
}: SvgPathProps): ReactJSXElement => {
  if (iconType === 'bold') {
    return (
      <path
        d="M9.5 3V4.8C9.48801 4.9456 9.53369 5.09008 9.62722 5.20232C9.72075 5.31455 9.85462 5.38554 10 5.4H14C14.1454 5.38554 14.2793 5.31455 14.3728 5.20232C14.4663 5.09008 14.512 4.9456 14.5 4.8V3M10 18H14M16 21H8C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0391 6 19.5304 6 19V5C6 4.46957 6.21071 3.96086 6.58579 3.58579C6.96086 3.21071 7.46957 3 8 3H16C16.5304 3 17.0391 3.21071 17.4142 3.58579C17.7893 3.96086 18 4.46957 18 5V19C18 19.5304 17.7893 20.0391 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21Z"
        fill="none"
        id={shapeId}
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.2"
      />
    );
  } else if (iconType === 'normal') {
    return (
      <path
        d="M15.8333 5V8C15.8133 8.24267 15.8895 8.48347 16.0454 8.67053C16.2012 8.85758 16.4244 8.97591 16.6667 9H23.3333C23.5756 8.97591 23.7988 8.85758 23.9546 8.67053C24.1105 8.48347 24.1867 8.24267 24.1667 8V5M16.6667 30H23.3333M26.6667 35H13.3333C12.4493 35 11.6014 34.6488 10.9763 34.0237C10.3512 33.3986 10 32.5507 10 31.6667V8.33333C10 7.44928 10.3512 6.60143 10.9763 5.97631C11.6014 5.35119 12.4493 5 13.3333 5H26.6667C27.5507 5 28.3986 5.35119 29.0237 5.97631C29.6488 6.60143 30 7.44928 30 8.33333V31.6667C30 32.5507 29.6488 33.3986 29.0237 34.0237C28.3986 34.6488 27.5507 35 26.6667 35Z"
        strokeWidth="1.1"
        fill="none"
        id={shapeId}
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  } else {
    return (
      <path
        d="M31.6667 10V16C31.6267 16.4853 31.779 16.9669 32.0907 17.3411C32.4025 17.7152 32.8487 17.9518 33.3333 18H46.6667C47.1513 17.9518 47.5975 17.7152 47.9093 17.3411C48.221 16.9669 48.3733 16.4853 48.3333 16V10M33.3333 60H46.6667M53.3333 70H26.6667C24.8986 70 23.2029 69.2976 21.9526 68.0474C20.7024 66.7971 20 65.1014 20 63.3333V16.6667C20 14.8986 20.7024 13.2029 21.9526 11.9526C23.2029 10.7024 24.8986 10 26.6667 10H53.3333C55.1014 10 56.7971 10.7024 58.0474 11.9526C59.2976 13.2029 60 14.8986 60 16.6667V63.3333C60 65.1014 59.2976 66.7971 58.0474 68.0474C56.7971 69.2976 55.1014 70 53.3333 70Z"
        strokeWidth="1.3"
        fill="none"
        id={shapeId}
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  }
};

export const MobileIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_mobileCurrentColorGradient`;
  const newColorId = `${prefix}_mobileNewColorGradient`;
  const shapeId = `${prefix}_mobileShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.magentaColors?.PLUS_2,
    },
  };
  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.magentaColors?.PLUS_3,
      stop: theme.palette.magentaColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox={viewBoxes[iconType]}>
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

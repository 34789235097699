import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import karolinaImage from '../../assets/png/karolina.png';
import tailImage from '../../assets/png/tail-6_1x.png';
import { colors } from '../../theme/default/color';
import { ContactForm } from '../ContactForm';
import { GradientText } from '../GradientText';
import { Person } from '../Person';
import type { ApplyNowProps } from './ApplyNow.type';

const PREFIX = 'ApplyNow';

const classes = {
  applyNowContainer: `${PREFIX}-applyNowContainer`,
  content: `${PREFIX}-content`,
  textContainer: `${PREFIX}-textContainer`,
  formContainer: `${PREFIX}-formContainer`,
  tailImageContainer: `${PREFIX}-tailImageContainer`,
};

const StyledApplyNow = styled('section')(
  ({
    theme: {
      spacing,
      breakpoints,
      template: { normalSectionWidth },
    },
  }: {
    theme: Theme;
  }) => ({
    display: 'grid',
    marginBottom: '3rem',
    backgroundColor: colors.white,
    gridTemplateColumns: `1fr minmax(0, ${normalSectionWidth}) 1fr`,
    overflow: 'hidden',

    [`& .${classes.textContainer}`]: {
      maxWidth: '22rem',
      margin: '0 auto',
      display: 'grid',
      gap: spacing(2),
      scrollMarginTop: '5rem',

      '& > h3': {
        fontSize: '2rem',
        margin: '0.4rem 0 3rem',

        [breakpoints.down('sm')]: {
          margin: '0.4rem 1rem 3rem',
        },
      },
    },

    [`& .${classes.tailImageContainer}`]: {
      display: 'grid',
      justifyContent: 'center',
      alignContent: 'center',
      marginTop: '-20rem',

      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export const ApplyNow: React.FC<ApplyNowProps> = ({ scrollRef }) => {
  return (
    <StyledApplyNow>
      <Grid
        gridColumn="2 / span 1"
        display="grid"
        gridTemplateColumns={{ md: '1fr 1fr' }}>
        <div>
          <div className={classes.tailImageContainer}>
            <img alt="" src={tailImage} width={560} height={560} />
          </div>

          <div ref={scrollRef} className={classes.textContainer}>
            <Typography variant="h2">
              <GradientText type="big">Apply now!</GradientText>
            </Typography>
            <Typography variant="h3">
              Don’t miss out on this opportunity, as places are limited. Apply
              Now!
            </Typography>
            <Person
              name="Karolina Kubas-Stachowska"
              position="Head of People"
              type="smaller"
              image={karolinaImage}
            />
          </div>
        </div>

        <Box m={6}>
          <ContactForm phone cv subject="RF website - Blockchain Hub" />
        </Box>
      </Grid>
    </StyledApplyNow>
  );
};

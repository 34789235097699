import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AppBar from '@mui/material/AppBar';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { Button } from '../../components/Button';
import { NavLink } from '../../components/NavLink';
import { colors } from '../../theme/default/color';
import { CareersPopperMenu, ResourcesPopperMenu } from '../DropdownMenus';
import { RumblefishLogo } from '../RumblefishLogo';
import type { WebHeaderProps } from './WebHeader.types';

const PREFIX = 'WebHeader';

const classes = {
  root: `${PREFIX}-root`,
  webHeader: `${PREFIX}-webHeader`,
  webHeaderOnTop: `${PREFIX}-webHeaderOnTop`,
  webHeaderToolbar: `${PREFIX}-webHeaderToolbar`,
  webHeaderLogoContainer: `${PREFIX}-webHeaderLogoContainer`,
  webHeaderButton: `${PREFIX}-webHeaderButton`,
  webHeaderButtonOnTop: `${PREFIX}-webHeaderButtonOnTop`,
  webHeaderNavlinkText: `${PREFIX}-webHeaderNavlinkText`,
  webHeaderNavlinkIcon: `${PREFIX}-webHeaderNavlinkIcon`,
  webHeaderNavButton: `${PREFIX}-webHeaderNavButton`,
  webHeaderNavlinkIconRotate: `${PREFIX}-webHeaderNavlinkIconRotate`,
  active: `${PREFIX}-active`,
  paperContainer: `${PREFIX}-paperContainer`,
};

const WebHeaderWrapper = styled('div')(
  ({ theme: { palette, template } }: { theme: Theme }) => ({
    color: palette.primary.main,

    [`& .${classes.webHeader}`]: {
      background: colors.white,
      height: template.webHeaderHeight,
      transition: '0.5s',
      padding: '0 calc( 100% / 12 )',
      '& > *': {
        height: '100%',
      },
    },

    [`& .${classes.webHeaderOnTop}`]: {
      height: template.webHeaderHeightOnTop,
    },

    [`& .${classes.webHeaderToolbar}`]: {
      padding: '0',
      display: 'flex',
      justifyContent: 'space-between',
    },

    [`& .${classes.webHeaderLogoContainer}`]: {
      height: '100%',
    },

    [`& .${classes.webHeaderButton}`]: {
      height: '48px',
      width: '166px',
      borderRadius: '24px',
      padding: '0',
    },

    [`& .${classes.webHeaderButtonOnTop}`]: {
      height: '56px',
      width: '184px',
      borderRadius: '28px',
    },

    [`& .${classes.webHeaderNavButton}`]: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: 'none',
    },

    [`& .${classes.webHeaderNavlinkText}`]: {
      '&:hover h5, &:active h5, &:hover svg, &:active svg': {
        color: colors.blue,
      },
    },

    [`& .${classes.webHeaderNavlinkIcon}`]: {
      color: colors.black,
      transition: '0.3s',
      transform: 'rotate(0deg)',
    },

    [`& .${classes.webHeaderNavlinkIconRotate}`]: {
      transform: 'rotate(180deg)',
    },

    [`& .${classes.paperContainer}`]: {
      borderRadius: '8px',
    },
  })
);

export const WebHeader: React.FC<WebHeaderProps> = ({ isOnTop }) => {
  const openResourcesMenuRef = useRef<HTMLDivElement>(null);
  const [openResourcesMenu, setOpenResourcesMenu] = useState(false);

  const openCareersMenuRef = useRef<HTMLDivElement>(null);
  const [openCareersMenu, setOpenCareersMenu] = useState(false);

  return (
    <WebHeaderWrapper>
      <AppBar
        position="static"
        className={clsx(classes.webHeader, {
          [classes.webHeaderOnTop]: isOnTop,
        })}
        elevation={2}>
        <Toolbar className={classes.webHeaderToolbar}>
          <NavLink to="/" label="Home Page">
            <RumblefishLogo isOnTop={isOnTop} />
          </NavLink>
          <NavLink
            exact
            to="/services/"
            className={classes.webHeaderNavlinkText}
            activeClassName={classes.active}>
            <Typography variant="h5">Services</Typography>
          </NavLink>
          <NavLink
            exact
            to="/case-studies/"
            className={classes.webHeaderNavlinkText}
            activeClassName={classes.active}>
            <Typography variant="h5">Case Studies</Typography>
          </NavLink>
          <NavLink
            exact
            to="/team/"
            className={classes.webHeaderNavlinkText}
            activeClassName={classes.active}>
            <Typography variant="h5">About us</Typography>
          </NavLink>
          <div
            className={classes.webHeaderNavlinkText}
            ref={openResourcesMenuRef}>
            <button
              className={classes.webHeaderNavButton}
              tabIndex={0}
              onClick={() => setOpenResourcesMenu(true)}>
              <Typography variant="h5">Resources</Typography>
              <ExpandMoreIcon
                fontSize="small"
                className={clsx(classes.webHeaderNavlinkIcon, {
                  [classes.webHeaderNavlinkIconRotate]: openResourcesMenu,
                })}
              />
            </button>
          </div>
          <ResourcesPopperMenu
            open={openResourcesMenu}
            openMenuRef={openResourcesMenuRef}
            setOpen={setOpenResourcesMenu}
          />
          <div
            className={classes.webHeaderNavlinkText}
            ref={openCareersMenuRef}>
            <button
              className={classes.webHeaderNavButton}
              tabIndex={0}
              onClick={() => setOpenCareersMenu(true)}>
              <Typography variant="h5">Careers</Typography>
              <ExpandMoreIcon
                fontSize="small"
                className={clsx(classes.webHeaderNavlinkIcon, {
                  [classes.webHeaderNavlinkIconRotate]: openCareersMenu,
                })}
              />
            </button>
          </div>
          <CareersPopperMenu
            open={openCareersMenu}
            openMenuRef={openCareersMenuRef}
            setOpen={setOpenCareersMenu}
          />
          <NavLink
            exact
            to="/contact/"
            className={classes.webHeaderNavlinkText}
            activeClassName={classes.active}>
            <Button
              className={clsx(classes.webHeaderButton, {
                [classes.webHeaderButtonOnTop]: isOnTop,
              })}
              variant="contained"
              color="secondary">
              Estimate project
            </Button>
          </NavLink>
        </Toolbar>
      </AppBar>
    </WebHeaderWrapper>
  );
};

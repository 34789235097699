import { Stack, styled, Typography } from '@mui/material';
import { CallToAction } from '../CallToAction';

export const StyledStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(8, 0, 14, 0),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(8, 0, 10, 0),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(8, 0, 6, 0),
  },
}));
export const StyledTextWrapper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
  },
}));
export const StyledText = styled(Typography)(({ theme }) => ({
  maxWidth: '533px',
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(3),
    maxWidth: 'unset',
  },
}));
export const StyledHeading = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'widerHeading',
})<{ widerHeading?: boolean }>(({ theme, widerHeading }) => ({
  maxWidth: widerHeading ? '1100px' : '1015px',
  marginTop: theme.spacing(3),
}));
export const StyledCallToAction = styled(CallToAction)(({ theme }) => ({
  marginTop: '64px',
  [theme.breakpoints.down('md')]: {
    marginTop: '36px',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '36px',
  },
}));

import React, { useState } from 'react';
import pointer from '../../assets/svg/icons/pointerMap.svg';
import { config } from './config';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%',
};
const center = {
  lat: 50.069334177672154,
  lng: 19.96789997823044,
};

export const Map = React.memo(() => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY,
  });

  return isLoaded ? (
    <GoogleMap
      options={{
        styles: [
          {
            elementType: 'labels',
            featureType: 'poi.business',
            stylers: [{ visibility: 'off' }],
          },
        ],
      }}
      clickableIcons={false}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={17}>
      <MarkerF key={Math.random()} position={center} icon={{ url: pointer }} />
    </GoogleMap>
  ) : (
    <div style={containerStyle}></div>
  );
});

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { colors } from '../../../../theme/default/color';
import { getCookie } from '../../../../utils/getCookie';
import AlertSnackbar from '../../../AlertSnackbar';
import { NextComponentsContext } from '../../../../../context/nextComponentsContext/nextComponentsContext';

import type {
  ErrorPopupProps,
  HubspotFormBody,
  HubspotFormProps,
  HubspotFormValues,
} from './HubspotForm.types';

const PREFIX = 'HubspotForm';

const classes = {
  root: `${PREFIX}-root`,
  submitContainer: `${PREFIX}-submitContainer`,
  fieldRow: `${PREFIX}-fieldRow`,
  roomForMessage: `${PREFIX}-roomForMessage`,
  errorPopupMessage: `${PREFIX}-errorPopupMessage`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(5),
  marginBottom: theme.spacing(10),

  [`& .${classes.submitContainer}`]: {
    paddingTop: theme.spacing(4),
  },

  [`& .${classes.fieldRow}`]: {
    position: 'relative',
    paddingTop: theme.spacing(4),
  },

  [`& .${classes.roomForMessage}`]: {
    position: 'relative',
    paddingBottom: '20px',
  },

  [`& .${classes.errorPopupMessage}`]: {
    position: 'absolute',
    boxSizing: 'border-box',
    padding: '4px 16px',
    background: colors.errorRed,
    bottom: '0',
    right: '0',
    zIndex: 100,
    borderRadius: '4px 0 4px 4px',
    color: colors.white,
    fontSize: '14px',
    '&:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '0 12px 12px 12px',
      borderColor: `transparent transparent ${colors.errorRed} transparent`,
      right: '0',
      top: '0',
      marginTop: '-12px',
    },
  },
}));

const emailCommunicationText =
  'I wish to receive Rumble Fish email communication.';
const policyText = 'I accept Rumble Fish Privacy Policy.';

const ErrorPopup: React.FC<ErrorPopupProps> = ({ errorMessage }) => {
  return <Box className={classes.errorPopupMessage}>{errorMessage}</Box>;
};

export const HubspotForm: React.FC<HubspotFormProps> = ({ model }) => {
  const useRouter = useContext(NextComponentsContext)?.hooks?.useRouter;
  const router = useRouter ? useRouter() : undefined;
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<HubspotFormValues>();

  const [openSuccessMessage, setOpenSuccessMessage] = React.useState(false);
  const [openErrorMessage, setOpenErrorMessage] = React.useState(false);
  const onSubmit = React.useCallback(
    async (values: HubspotFormValues) => {
      if (!router) return;

      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${model.attributes?.portalId}/${model.attributes?.formGuid}`;
      const body: HubspotFormBody = {
        submittedAt: new Date().getTime().toString(),
        fields: [
          {
            name: 'email',
            value: values.email,
          },
          {
            name: 'firstname',
            value: values.firstname,
          },
          {
            name: 'lastname',
            value: values.lastname,
          },
        ],
        context: {
          pageUri: document.location.toString(),
        },
        legalConsentOptions: {
          consent: {
            consentToProcess: values.policy,
            text: policyText,
            communications: [
              {
                value: values.emailCommunications,
                subscriptionTypeId: 999,
                text: emailCommunicationText,
              },
            ],
          },
        },
      };
      const hutk = getCookie('hubspotutk');
      if (hutk) body.context.hutk = hutk;

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(body, null, 2),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(() => {
          setOpenSuccessMessage(true);
          router.push(
            model.attributes?.successUrl ? model.attributes.successUrl : ''
          );
        })
        .then(() =>
          reset({
            firstname: '',
            lastname: '',
            email: '',
            emailCommunications: false,
            policy: false,
          })
        )
        .catch(() => setOpenErrorMessage(true));
    },
    [model, reset, router]
  );

  return (
    <StyledCard className={classes.root}>
      <Typography variant="h2">{model.attributes?.title}</Typography>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <Grid item xs={12} className={classes.fieldRow}>
          <Typography variant="caption">First name&nbsp;</Typography>
          <Typography variant="caption" color="secondary">
            *
          </Typography>
          <Controller
            control={control}
            name="firstname"
            rules={{
              required: 'Empty Field!',
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                name="firstname"
                InputProps={{ 'aria-label': 'First name' }}
                fullWidth
                variant="outlined"
                defaultValue=""
                error={!!errors.firstname}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          {errors.firstname && errors.firstname.message ? (
            <ErrorPopup errorMessage={errors.firstname.message} />
          ) : null}
        </Grid>
        <Grid item xs={12} className={classes.fieldRow}>
          <Typography variant="caption">Last name&nbsp;</Typography>
          <Typography variant="caption" color="secondary">
            *
          </Typography>
          <Controller
            control={control}
            name="lastname"
            rules={{
              required: 'Empty Field!',
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                name="lastname"
                InputProps={{ 'aria-label': 'First name' }}
                fullWidth
                variant="outlined"
                defaultValue=""
                error={!!errors.lastname}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          {errors.lastname && errors.lastname.message ? (
            <ErrorPopup errorMessage={errors.lastname.message} />
          ) : null}
        </Grid>
        <Grid item xs={12} className={classes.fieldRow}>
          <Typography variant="caption">Business email&nbsp;</Typography>
          <Typography variant="caption" color="secondary">
            *
          </Typography>
          <Controller
            control={control}
            name="email"
            rules={{
              required: 'Empty Field!',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Please enter a valid email address',
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                name="email"
                InputProps={{ 'aria-label': 'First name' }}
                fullWidth
                variant="outlined"
                defaultValue=""
                error={!!errors.email}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          {errors.email && errors.email.message ? (
            <ErrorPopup errorMessage={errors.email.message} />
          ) : null}
        </Grid>
        <Grid item xs={12} className={classes.roomForMessage}>
          <FormControlLabel
            name="emailCommunications"
            defaultValue="false"
            control={
              <Controller
                control={control}
                name="emailCommunications"
                rules={{
                  validate: (value) =>
                    value === true || 'If you want to continue check this box',
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Checkbox
                    value={value}
                    onChange={(e) => onChange(e.target.checked)}
                    onBlur={onBlur}
                  />
                )}
              />
            }
            label={<p>I wish to receive Rumble Fish email communication.</p>}
          />
          {errors.emailCommunications && errors.emailCommunications.message ? (
            <ErrorPopup errorMessage={errors.emailCommunications.message} />
          ) : null}
        </Grid>
        <Grid item xs={12} className={classes.roomForMessage}>
          <FormControlLabel
            name="policy"
            defaultValue="false"
            control={
              <Controller
                control={control}
                name="policy"
                rules={{
                  validate: (value) =>
                    value === true ||
                    'If you want to continue accept our privacy policy',
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Checkbox
                    value={value}
                    onChange={(e) => onChange(e.target.checked)}
                    onBlur={onBlur}
                  />
                )}
              />
            }
            label={
              <p>
                I accept Rumble Fish{' '}
                <a href="/privacy-policy/" target="_blank">
                  Privacy Policy
                </a>
              </p>
            }
          />
          {errors.policy && errors.policy.message ? (
            <ErrorPopup errorMessage={errors.policy.message} />
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          className={classes.submitContainer}>
          <Grid item xs={false}>
            <Button color="primary" variant="contained" type="submit">
              {model.attributes?.submitText}
            </Button>
          </Grid>
        </Grid>
      </form>
      <AlertSnackbar
        openSuccessMessage={openSuccessMessage}
        setOpenSuccessMessage={setOpenSuccessMessage}
        openErrorMessage={openErrorMessage}
        setOpenErrorMessage={setOpenErrorMessage}
        succesMessage="Your message has been sent successfully. We will get back to you as soon as possible."
        errorMessage="There was an error trying to send your message. Please try again later or send us an email."
      />
    </StyledCard>
  );
};

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { useState } from 'react';
import logo from '../../assets/png/logo-rf-500.png';
import { Button } from '../../components/Button';
import { NavLink } from '../../components/NavLink';
import { colors } from '../../theme/default/color';
import type { MobileHeaderProps } from './MobileHeader.types';
import { NextLink } from '../../components/NextLink';

const PREFIX = 'MobileHeader';

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  mobileHeader: `${PREFIX}-mobileHeader`,
  mobileHeaderLogo: `${PREFIX}-mobileHeaderLogo`,
  mobileHeaderList: `${PREFIX}-mobileHeaderList`,
  mobileHeaderNavlink: `${PREFIX}-mobileHeaderNavlink`,
  mobileHeaderNavLinkText: `${PREFIX}-mobileHeaderNavLinkText`,
  mobileHeaderMenuShadow: `${PREFIX}-mobileHeaderMenuShadow`,
  mobileHeaderMenuShadowOpened: `${PREFIX}-mobileHeaderMenuShadowOpened`,
  mobileHeaderMenuItem: `${PREFIX}-mobileHeaderMenuItem`,
  mobileHeaderMenuItemSub: `${PREFIX}-mobileHeaderMenuItemSub`,
  mobileHeaderNavlinkIcon: `${PREFIX}-mobileHeaderNavlinkIcon`,
  mobileHeaderNavlinkIconRotate: `${PREFIX}-mobileHeaderNavlinkIconRotate`,
  active: `${PREFIX}-active`,
};

const MobileHeaderWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
  [`& .${classes.root}`]: {
    color: theme.palette.primary.main,
    paddingTop: '80px',
    position: 'relative',
  },

  [`&.${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.mobileHeader}`]: {
    height: '80px',
    transition: '0.5s',
    zIndex: theme.zIndex.drawer + 1,
    '& > *': {
      height: '100%',
    },
  },

  [`& .${classes.mobileHeaderLogo}`]: {
    boxSizing: 'border-box',
    padding: '15px 0',
  },

  [`& .${classes.mobileHeaderList}`]: {
    marginTop: '16px',
  },

  [`& .${classes.mobileHeaderNavlink}`]: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover > li > h5, &:active > li > h5, &:hover > li > svg, &:active > li > svg':
      {
        color: colors.blue,
      },
  },

  [`& .${classes.mobileHeaderNavLinkText}`]: {
    height: '48px',
    fontWeight: 'normal',
    lineHeight: '48px',
  },

  [`& .${classes.mobileHeaderMenuShadow}`]: {
    position: 'absolute',
    top: '0',
    height: '0',
    width: '100vw',
    background: 'rgba(0,0,0,0.5)',
  },

  [`& .${classes.mobileHeaderMenuShadowOpened}`]: {
    height: '100vh',
  },

  [`& .${classes.mobileHeaderMenuItem}`]: {
    paddingLeft: '28px',
  },

  [`& .${classes.mobileHeaderMenuItemSub}`]: {
    paddingLeft: '56px',
    background: colors.greyBackground,

    '&:after': {
      position: 'absolute',
      content: "''",
      display: 'inline-block',
      left: '36px',
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      background: colors.blue,
    },
  },

  [`& .${classes.mobileHeaderNavlinkIcon}`]: {
    color: colors.black,
    transition: '0.3s',
    transform: 'rotate(0deg)',
  },

  [`& .${classes.mobileHeaderNavlinkIconRotate}`]: {
    transform: 'rotate(180deg)',
  },
}));

const CareersSubMenu: React.FC = () => {
  return (
    <>
      <Divider />
      <List>
        <NavLink
          exact
          to="/careers/"
          className={classes.mobileHeaderNavlink}
          activeClassName={classes.active}>
          <ListItem
            className={clsx(
              classes.mobileHeaderMenuItem,
              classes.mobileHeaderMenuItemSub
            )}>
            <Typography
              className={classes.mobileHeaderNavLinkText}
              variant="h5">
              Job offers
            </Typography>
          </ListItem>
        </NavLink>
        {/* <NavLink
          exact
          to="/blockchainhub/"
          className={classes.mobileHeaderNavlink}
          activeClassName={classes.active}>
          <ListItem
            className={clsx(
              classes.mobileHeaderMenuItem,
              classes.mobileHeaderMenuItemSub
            )}>
            <Typography
              className={classes.mobileHeaderNavLinkText}
              variant="h5">
              Blockchain Training Hub
            </Typography>
          </ListItem>
        </NavLink> */}
      </List>
    </>
  );
};
const ResourcesSubMenu: React.FC = () => {
  return (
    <>
      <Divider />
      <List>
        <NavLink
          exact
          to="/blog/"
          className={classes.mobileHeaderNavlink}
          activeClassName={classes.active}>
          <ListItem className={classes.mobileHeaderMenuItem}>
            <Typography
              className={classes.mobileHeaderNavLinkText}
              variant="h5">
              Blog
            </Typography>
          </ListItem>
        </NavLink>
        <NavLink
          exact
          to="/blog?category=code-stories"
          className={classes.mobileHeaderNavlink}
          activeClassName={classes.active}>
          <ListItem
            className={clsx(
              classes.mobileHeaderMenuItem,
              classes.mobileHeaderMenuItemSub
            )}>
            <Typography
              className={classes.mobileHeaderNavLinkText}
              variant="h5">
              Code Stories
            </Typography>
          </ListItem>
        </NavLink>
        <NavLink
          exact
          to="/blog?category=business-stories"
          className={classes.mobileHeaderNavlink}
          activeClassName={classes.active}>
          <ListItem
            className={clsx(
              classes.mobileHeaderMenuItem,
              classes.mobileHeaderMenuItemSub
            )}>
            <Typography
              className={classes.mobileHeaderNavLinkText}
              variant="h5">
              Business Stories
            </Typography>
          </ListItem>
        </NavLink>
        <Box>
          <ListItem className={classes.mobileHeaderMenuItem}>
            <Typography
              className={classes.mobileHeaderNavLinkText}
              variant="h5">
              E-book
            </Typography>
          </ListItem>
        </Box>
        <NavLink
          exact
          to="/resources/workshop/"
          className={classes.mobileHeaderNavlink}
          activeClassName={classes.active}>
          <ListItem
            className={clsx(
              classes.mobileHeaderMenuItem,
              classes.mobileHeaderMenuItemSub
            )}>
            <Typography
              className={classes.mobileHeaderNavLinkText}
              variant="h5">
              Workshop
            </Typography>
          </ListItem>
        </NavLink>
        <NavLink
          exact
          to="/resources/blockchain/"
          className={classes.mobileHeaderNavlink}
          activeClassName={classes.active}>
          <ListItem
            className={clsx(
              classes.mobileHeaderMenuItem,
              classes.mobileHeaderMenuItemSub
            )}>
            <Typography
              className={classes.mobileHeaderNavLinkText}
              variant="h5">
              Blockchain
            </Typography>
          </ListItem>
        </NavLink>
      </List>
    </>
  );
};

const MobileDrawer: React.FC<MobileHeaderProps> = ({ open, setOpen }) => {
  const [openResourcesSubMenu, setOpenResourcesSubMenu] = useState(false);
  const [openCarrersSubMenu, setOpenCarrersSubMenu] = useState(false);

  return (
    <MobileHeaderWrapper>
      <Drawer
        variant="persistent"
        anchor="top"
        open={open}
        onClose={() => setOpen(!open)}>
        <MobileHeaderWrapper className={classes.toolbar} />
        <List className={classes.mobileHeaderList}>
          <NavLink
            exact
            to="/services/"
            className={classes.mobileHeaderNavlink}
            activeClassName={classes.active}>
            <ListItem className={classes.mobileHeaderMenuItem}>
              <Typography
                className={classes.mobileHeaderNavLinkText}
                variant="h5">
                Services
              </Typography>
            </ListItem>
          </NavLink>
          <Divider />
          <NavLink
            exact
            to="/case-studies/"
            className={classes.mobileHeaderNavlink}
            activeClassName={classes.active}>
            <ListItem className={classes.mobileHeaderMenuItem}>
              <Typography
                className={classes.mobileHeaderNavLinkText}
                variant="h5">
                Case Studies
              </Typography>
            </ListItem>
          </NavLink>
          <Divider />
          <NavLink
            exact
            to="/team/"
            className={classes.mobileHeaderNavlink}
            activeClassName={classes.active}>
            <ListItem className={classes.mobileHeaderMenuItem}>
              <Typography
                className={classes.mobileHeaderNavLinkText}
                variant="h5">
                About us
              </Typography>
            </ListItem>
          </NavLink>
          <Divider />
          <Box
            className={classes.mobileHeaderNavlink}
            onClick={() => setOpenResourcesSubMenu(!openResourcesSubMenu)}>
            <ListItem className={classes.mobileHeaderMenuItem}>
              <Typography
                className={classes.mobileHeaderNavLinkText}
                variant="h5">
                Resources
              </Typography>
              <ExpandMoreIcon
                fontSize="small"
                className={clsx(classes.mobileHeaderNavlinkIcon, {
                  [classes.mobileHeaderNavlinkIconRotate]: openResourcesSubMenu,
                })}
              />
            </ListItem>
          </Box>

          {openResourcesSubMenu && <ResourcesSubMenu />}

          <Divider />
          <Link
            href="https://kariera.rumblefish.dev/"
            aria-label="Rumble Fish - Hire Top AWS Developers"
            className={classes.mobileHeaderNavlink}
            target="_blank"
            rel="noreferrer"
          />

          <Box
            className={classes.mobileHeaderNavlink}
            onClick={() => setOpenCarrersSubMenu(!openCarrersSubMenu)}>
            <ListItem className={classes.mobileHeaderMenuItem}>
              <Typography
                className={classes.mobileHeaderNavLinkText}
                variant="h5">
                Careers
              </Typography>
              <ExpandMoreIcon
                fontSize="small"
                className={clsx(classes.mobileHeaderNavlinkIcon, {
                  [classes.mobileHeaderNavlinkIconRotate]: openCarrersSubMenu,
                })}
              />
            </ListItem>
          </Box>

          {openCarrersSubMenu && <CareersSubMenu />}
          <Divider />
          <NavLink exact to="/contact/" className={classes.mobileHeaderNavlink}>
            <ListItem>
              <Button variant="outlined" color="secondary">
                Estimate project
              </Button>
            </ListItem>
          </NavLink>
        </List>
      </Drawer>
    </MobileHeaderWrapper>
  );
};

export const MobileHeader: React.FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <MobileHeaderWrapper>
      <div className={classes.root}>
        <AppBar
          sx={{ backgroundColor: colors.white }}
          position="absolute"
          className={classes.mobileHeader}
          elevation={2}>
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <NextLink href="/">
              <a>
                <img
                  className={classes.mobileHeaderLogo}
                  src={logo}
                  width="180"
                  height="80"
                  alt="rumble fish logo"
                />
              </a>
            </NextLink>
            <IconButton
              edge="end"
              aria-label="mobile version app bar button"
              onClick={() => setOpen(!open)}
              size="large">
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <MobileDrawer open={open} setOpen={setOpen} />
        <button
          aria-label="tap to cloce menu"
          onClick={() => setOpen(!open)}
          className={clsx(classes.mobileHeaderMenuShadow, {
            [classes.mobileHeaderMenuShadowOpened]: open,
          })}
        />
      </div>
    </MobileHeaderWrapper>
  );
};

import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';
import { viewBoxes } from './CustomSvgIcons.types';

const SvgPath = ({
  iconType,
  shapeId,
  solidColor,
}: SvgPathProps): ReactJSXElement => {
  if (iconType === 'bold') {
    return (
      <path
        d="M23 19V17.904C23 16.523 21.881 15.404 20.5 15.404H19.699M1 19V17.904C1 16.523 2.119 15.404 3.5 15.404H4.301M17.339 19V17.399C17.339 15.466 15.772 13.899 13.839 13.899H10.16C8.227 13.899 6.66 15.466 6.66 17.399V19M20.7925 9.52352C21.5825 10.3136 21.5825 11.5944 20.7925 12.3845C20.0025 13.1745 18.7216 13.1745 17.9315 12.3845C17.1415 11.5944 17.1415 10.3135 17.9315 9.52352C18.7216 8.73349 20.0025 8.73349 20.7925 9.52352ZM14.2026 5.91236C15.4191 7.12884 15.4191 9.10115 14.2026 10.3176C12.9861 11.5341 11.0138 11.5341 9.79732 10.3176C8.58084 9.10116 8.58084 7.12885 9.79732 5.91236C11.0138 4.69588 12.9861 4.69588 14.2026 5.91236ZM6.06848 9.52352C6.85851 10.3136 6.85851 11.5944 6.06848 12.3845C5.27845 13.1745 3.99756 13.1745 3.20753 12.3845C2.4175 11.5944 2.4175 10.3135 3.20753 9.52352C3.99756 8.73349 5.27845 8.73349 6.06848 9.52352Z"
        id={shapeId}
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  } else if (iconType === 'normal') {
    return (
      <path
        d="M38.3334 31.6667V29.84C38.3334 27.5384 36.4684 25.6734 34.1667 25.6734H32.8317M1.66669 31.6667V29.84C1.66669 27.5384 3.53169 25.6734 5.83335 25.6734H7.16835M28.8984 31.6667V28.9984C28.8984 25.7767 26.2867 23.165 23.065 23.165H16.9334C13.7117 23.165 11.1 25.7767 11.1 28.9984V31.6667M34.6542 15.8726C35.9709 17.1893 35.9709 19.3241 34.6542 20.6408C33.3375 21.9575 31.2026 21.9575 29.8859 20.6408C28.5692 19.3241 28.5692 17.1893 29.8859 15.8726C31.2027 14.5559 33.3375 14.5559 34.6542 15.8726ZM23.671 9.85397C25.6985 11.8814 25.6985 15.1686 23.671 17.1961C21.6436 19.2236 18.3564 19.2236 16.3289 17.1961C14.3014 15.1686 14.3014 11.8815 16.3289 9.85397C18.3564 7.82651 21.6435 7.82651 23.671 9.85397ZM10.1142 15.8726C11.4309 17.1893 11.4309 19.3241 10.1142 20.6408C8.79743 21.9575 6.66262 21.9575 5.3459 20.6408C4.02919 19.3241 4.02919 17.1893 5.3459 15.8726C6.66262 14.5559 8.79744 14.5559 10.1142 15.8726Z"
        id={shapeId}
        fill="none"
        strokeWidth="1.1"
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  } else {
    return (
      <path
        d="M76.6667 63.3334V59.68C76.6667 55.0767 72.9367 51.3467 68.3334 51.3467H65.6634M3.33337 63.3334V59.68C3.33337 55.0767 7.06337 51.3467 11.6667 51.3467H14.3367M57.7967 63.3334V57.9967C57.7967 51.5534 52.5734 46.33 46.13 46.33H33.8667C27.4234 46.33 22.2 51.5534 22.2 57.9967V63.3334M69.3084 31.7451C71.9418 34.3785 71.9418 38.6482 69.3084 41.2816C66.6749 43.915 62.4053 43.915 59.7719 41.2816C57.1384 38.6482 57.1384 34.3785 59.7719 31.7451C62.4053 29.1117 66.6749 29.1117 69.3084 31.7451ZM47.342 19.7079C51.397 23.7628 51.397 30.3372 47.342 34.3922C43.2871 38.4471 36.7127 38.4471 32.6578 34.3922C28.6028 30.3372 28.6028 23.7629 32.6578 19.7079C36.7127 15.653 43.2871 15.653 47.342 19.7079ZM20.2283 31.7451C22.8617 34.3785 22.8617 38.6482 20.2283 41.2816C17.5949 43.915 13.3252 43.915 10.6918 41.2816C8.05837 38.6482 8.05837 34.3785 10.6918 31.7451C13.3252 29.1117 17.5949 29.1117 20.2283 31.7451Z"
        id={shapeId}
        fill="none"
        strokeWidth="1.4"
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  }
};

export const TeamIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_teamCurrentColorGradient`;
  const newColorId = `${prefix}_teamNewColorGradient`;
  const shapeId = `${prefix}_teamShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.tealColors?.PLUS_2,
    },
  };
  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.tealColors?.PLUS_3,
      stop: theme.palette.tealColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox={viewBoxes[iconType]}>
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>

          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

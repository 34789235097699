import { Hidden } from '@mui/material';
import React, { useState } from 'react';
import { Link } from '../Link';
import type { MenuBoxIconProps } from './MenuBoxIcon.types';
import {
  StyledHeader,
  StyledStack,
  StyledText,
  StyledTextWrapper,
  StyledImage,
  StyledImageWrapper,
} from './styles';

export const MenuBoxIcon: React.FC<MenuBoxIconProps> = ({
  iconIdle,
  iconHover,
  headline,
  text,
  link,
  ...props
}) => {
  const [hover, setHover] = useState(false);

  return (
    <Link to={link}>
      <StyledStack
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        {...props}>
        <StyledImageWrapper
          sx={{
            position: 'relative',
            display: {
              xs: 'none',
              md: 'block',
            },
          }}>
          <StyledImage
            src={iconHover}
            alt=""
            sx={hover ? { opacity: 1 } : {}}
          />
          <StyledImage
            src={iconIdle}
            alt=""
            sx={!hover ? { opacity: 1 } : {}}
          />
        </StyledImageWrapper>

        <StyledTextWrapper>
          <StyledHeader variant="buttonSmall">{headline}</StyledHeader>

          <StyledText
            sx={{ display: { xs: 'none', md: 'block' } }}
            variant="caption">
            {text}
          </StyledText>
        </StyledTextWrapper>
      </StyledStack>
    </Link>
  );
};

import { Box, Typography, styled } from '@mui/material';

export const StyledHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.colorBrand?.primary,
  maxWidth: '524px',
}));
export const StyledHeaderDash = styled(Box)(({ theme }) => ({
  position: 'absolute',
  background: theme.palette.colorBrand?.primary,
  height: '4px',
  width: '100px',
  left: '-150px',
  top: '30px',
  borderRadius: '4px',
}));
export const StyledHeaderBox = styled(Box)(() => ({
  position: 'relative',
}));

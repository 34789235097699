import { useTheme, Collapse } from '@mui/material';
import React from 'react';
import { isDarkOrNavy } from '../../../utils/darkOrNavy';
import { StyledBox } from './styles';
import type { SubmenuProps } from './Submenu.types';
export const Submenu: React.FC<SubmenuProps> = ({
  children,
  isOpen,
  backDropFilter = false,
  setUnmounted = (val: boolean) => {
    return val;
  },
}) => {
  const theme = useTheme();
  const darkMode: boolean = theme.palette.type === 'dark';
  const navyMode: boolean = theme.palette.type === 'navy';
  const useIsDarkOrNavyMode = isDarkOrNavy(theme);

  return (
    <Collapse
      onEnter={() => setUnmounted(false)}
      onExited={() => setUnmounted(true)}
      in={isOpen}
      timeout={350}
      unmountOnExit
      sx={() => ({
        '& .MuiCollapse-wrapper': {
          overflow: 'auto',
          '& .MuiCollapse-wrapperInner': {
            '& .MuiBox-root': {
              height: 'auto',
              padding: '0px',
            },
          },
        },

        ['img']: {
          filter:
            useIsDarkOrNavyMode &&
            'brightness(0%) saturate(100%) invert(100%) sepia(2%) saturate(887%) hue-rotate(84deg) brightness(110%) contrast(100%)',
        },

        boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
        width: '100%',
        backdropFilter: backDropFilter ? 'blur(42px)' : '',
        background: darkMode ? '#0E1516' : navyMode ? 'transparent' : 'white',
        ['span']: {
          color: useIsDarkOrNavyMode && 'white',
        },
      })}>
      <StyledBox>{children}</StyledBox>
    </Collapse>
  );
};

import { Hidden } from '@mui/material';
import { Section } from '@rumblefish/ui/AlgeaTheme';
import React from 'react';
import { DesktopCaseStudies } from './DesktopCaseStudies';
import { MobileCaseStudies } from './MobileCaseStudies';

import type { RestCaseStudiesProps } from './RestCaseStudies.types';
import { StyledStack } from './styles';

export const RestCaseStudies = ({ ...props }: RestCaseStudiesProps) => {
  return (
    <Section width="small">
      <StyledStack {...props}>
        <MobileCaseStudies />
        <DesktopCaseStudies />
      </StyledStack>
    </Section>
  );
};

import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';
import type { GradientTextProps } from './GradientText.types';

const PREFIX = 'GradientText';

const classes = {
  gradientText: `${PREFIX}-gradientText`,
  gradientTextBig: `${PREFIX}-gradientTextBig`,
  gradientTextHuge: `${PREFIX}-gradientTextHuge`,
};

const Root = styled('span')(({ theme: { breakpoints } }: { theme: Theme }) => ({
  [`&.${classes.gradientText}`]: {
    backgroundImage:
      'linear-gradient(120.61deg, #2E56F3 0%, #A839AC 48.44%, #FB267C 100%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'block',
    zIndex: 1,

    '&::before': {
      content: '" "',
      display: 'inline',
    },
    '&::after': {
      content: '" "',
      display: 'inline',
    },
  },

  [`&.${classes.gradientTextBig}`]: {
    fontSize: '6rem',
    fontWeight: 900,
    fontFamily: 'Axia Black',
    lineHeight: '0.9em',

    margin: '0',
    [breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  },

  [`&.${classes.gradientTextHuge}`]: {
    fontSize: '12vw',
    lineHeight: '9.4vw',
    fontWeight: 900,
    fontFamily: 'Axia Black',

    margin: '0',
    [breakpoints.down('sm')]: {
      fontSize: '24vw',
      lineHeight: '19vw',
      paddingBottom: '48px',
    },
    [breakpoints.only('lg')]: {
      fontSize: '11vw',
      lineHeight: '8.8vw',
    },
    [breakpoints.only('xl')]: {
      fontSize: '196px',
      lineHeight: '148px',
    },
  },
}));

export const GradientText: React.FC<GradientTextProps> = ({
  children,
  type,
}) => {
  return (
    <Root
      className={clsx(classes.gradientText, {
        [classes.gradientTextBig]: type === 'big',
        [classes.gradientTextHuge]: type === 'huge',
      })}>
      {children}
    </Root>
  );
};

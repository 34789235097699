import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';

const SvgPath = ({ shapeId, solidColor }: SvgPathProps): ReactJSXElement => {
  return (
    <path
      d="M22.008 20.032H23.64C24.344 20.032 24.8453 19.8933 25.144 19.616C25.464 19.3173 25.624 18.8373 25.624 18.176V16.672C25.624 16.0107 25.464 15.5413 25.144 15.264C24.8453 14.9653 24.344 14.816 23.64 14.816H22.104C21.4213 14.816 20.9413 14.9653 20.664 15.264C20.3867 15.5413 20.248 16.0107 20.248 16.672V17.472C20.248 17.92 20.024 18.144 19.576 18.144H16.856C16.408 18.144 16.184 17.92 16.184 17.472V16.608C16.184 14.944 16.6427 13.664 17.56 12.768C18.4773 11.872 19.7787 11.424 21.464 11.424H24.312C25.9973 11.424 27.2987 11.872 28.216 12.768C29.1333 13.664 29.592 14.944 29.592 16.608V18.208C29.592 20 28.7493 21.1627 27.064 21.696C28.7493 22.0587 29.592 23.2213 29.592 25.184V26.816C29.592 28.48 29.1333 29.76 28.216 30.656C27.2987 31.552 25.9973 32 24.312 32H21.464C19.7787 32 18.4773 31.552 17.56 30.656C16.6427 29.76 16.184 28.48 16.184 26.816V25.92C16.184 25.4507 16.408 25.216 16.856 25.216H19.576C20.024 25.216 20.248 25.4507 20.248 25.92V26.752C20.248 27.4347 20.3867 27.9147 20.664 28.192C20.9413 28.4693 21.4213 28.608 22.104 28.608H23.64C24.344 28.608 24.8453 28.4693 25.144 28.192C25.464 27.8933 25.624 27.4133 25.624 26.752V25.184C25.624 24.5227 25.464 24.0533 25.144 23.776C24.8453 23.4773 24.344 23.328 23.64 23.328H22.008C21.56 23.328 21.336 23.104 21.336 22.656V20.736C21.336 20.2667 21.56 20.032 22.008 20.032Z"
      fill={solidColor ? 'currentColor' : ''}
      id={shapeId}
      strokeWidth="1.2"
      stroke={solidColor ? 'currentColor' : ''}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
};

export const ThreeIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor = 'magenta',
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_threeCurrentColorGradient`;
  const newColorId = `${prefix}_threeNewColorGradient`;
  const shapeId = `${prefix}_threeShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const colors = theme.palette?.[`${gradientColor}Colors`];

  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: colors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: colors?.PLUS_3,
      stop: colors?.PLUS_2,
    },
  };

  const svgColors = isLight ? colorsLight : colorsDark;

  return (
    <SvgIcon {...props} viewBox="0 0 45 45">
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="90%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="90%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>

          <g
            style={{
              stroke: `url("#${currColorId}")`,
              fill: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              fill: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

import { useTheme, Stack, Typography, Box } from '@mui/material';
import React from 'react';
import Ebook1ImgSmall from '../../../assets/png/AlgeaTheme/ebook1-small.png';
import Ebook1Img from '../../../assets/png/AlgeaTheme/ebook1.png';
import Ebook2ImgSmall from '../../../assets/png/AlgeaTheme/ebook2-small.png';
import Ebook2Img from '../../../assets/png/AlgeaTheme/ebook2.png';
import Ebook3ImgSmall from '../../../assets/png/AlgeaTheme/ebook3-small.png';
import Ebook3Img from '../../../assets/png/AlgeaTheme/ebook3.png';
import { Section, MenuItem } from '../index';
import { MenuBoxCover } from '../MenuBoxCover';
import type { ResourcesSubmenuProps } from './ResourcesSubmenu.types';
import {
  StyledDivider,
  StyledStack,
  StyledEbookSectionWrapper,
  StyledMenuItemIconLink,
  StyledBlogSectionWrapper,
  StyledSectionWrapper,
  StyledMenuBoxImage,
  StyledLinkWrapper,
  MdUpHiddenStack,
  MdDownHiddenStack,
} from './styles';
import { MenuBoxImageProps } from '../MenuBoxImage/MenuBoxImage.types';
const Link: React.FC<{
  to: string;
  noIcon?: boolean;
  children?: React.ReactNode;
}> = ({ to, children, noIcon }) => {
  const theme = useTheme();

  if (theme.utils.isMobile()) {
    return (
      <StyledLinkWrapper>
        <MenuItem sx={{ fontSize: '1rem' }} noIcon={noIcon} to={to}>
          {children}
        </MenuItem>
      </StyledLinkWrapper>
    );
  } else {
    return (
      <StyledMenuItemIconLink noIcon={noIcon} to={to}>
        {children}
      </StyledMenuItemIconLink>
    );
  }
};

const EbooksSection = () => {
  const theme = useTheme();

  return (
    <>
      <Stack
        sx={{
          margin: theme.spacing(1, 0, 2, 0),
          [theme.breakpoints.down('md')]: { margin: theme.spacing(0) },
        }}>
        <Typography
          variant="buttonSmall"
          sx={{
            color: theme.palette.colorBrand?.primary,
            [theme.breakpoints.down('md')]: { fontSize: '1rem' },
          }}>
          Check all ebooks
        </Typography>
      </Stack>
      <StyledDivider sx={{ display: { md: 'none' } }} />
      <StyledEbookSectionWrapper sx={{ img: { filter: 'none !important' } }}>
        <MenuBoxCover
          to="/resources/fintech-mvp-guide"
          cover={theme.utils.isMobile() ? Ebook3ImgSmall : Ebook3Img}
          text={'The Ultimate Guide to \nBuilding Your Fintech MVP'}
        />
        <MenuBoxCover
          to="/resources/workshop"
          cover={theme.utils.isMobile() ? Ebook2ImgSmall : Ebook2Img}
          text={'Discovery \nWorkshop'}
        />
        <MenuBoxCover
          to="/resources/blockchain"
          cover={theme.utils.isMobile() ? Ebook1ImgSmall : Ebook1Img}
          text={'Blockchain \nTrends in 2022'}
        />
      </StyledEbookSectionWrapper>
    </>
  );
};

export const ResourcesSubmenu = ({
  blogs,
  ...props
}: ResourcesSubmenuProps) => {
  const firstSixBlogs = blogs?.slice(0, 6) || [];

  const adjustedBlogsData: MenuBoxImageProps[] = firstSixBlogs
    ?.map((blog) => {
      const attribs = blog.attributes;
      if (
        !attribs?.title ||
        !attribs?.category?.[0]?.name ||
        !attribs?.image?.data?.attributes?.url ||
        !attribs?.slug
      )
        return undefined;

      return {
        text: attribs?.title,
        headline: attribs?.category?.[0]?.name,
        img: attribs?.image?.data?.attributes?.url,
        link: `/blog/post/${attribs?.slug}`,
      };
    })
    .filter((data): data is MenuBoxImageProps => !!data);

  const theme = useTheme();
  return (
    <Section
      backgroundColor="unset"
      width={theme.utils.isMobile() ? 'full' : 'small'}>
      <StyledStack
        gap={2}
        direction="column"
        alignItems="flex-start"
        justifyContent="space-between"
        {...props}>
        <StyledSectionWrapper sx={{ width: 'auto' }}>
          <Stack
            sx={{
              margin: theme.spacing(0, 0, 2, 0),
              [theme.breakpoints.down('md')]: {
                margin: theme.spacing(0),
              },
            }}>
            <Link to="/blog">Visit our blog</Link>
          </Stack>
          <StyledDivider sx={{ display: { md: 'none' } }} />
          <StyledBlogSectionWrapper>
            {adjustedBlogsData?.map((item, index) => (
              <StyledMenuBoxImage
                key={index}
                {...item}
                sx={{ img: { filter: 'none !important' } }}
              />
            ))}
          </StyledBlogSectionWrapper>
        </StyledSectionWrapper>

        <StyledDivider sx={{ display: { xs: 'none', md: 'block' } }} />

        <StyledSectionWrapper sx={{ width: '100%' }}>
          <EbooksSection />
        </StyledSectionWrapper>
      </StyledStack>
    </Section>
  );
};

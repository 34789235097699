import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React from 'react';
import { colors } from '../../theme/default/color';

const PREFIX = 'FormErrorPopup';

const classes = {
  errorPopupMessage: `${PREFIX}-errorPopupMessage`,
};

const StyledBox = styled(Box)(() => ({
  [`&.${classes.errorPopupMessage}`]: {
    position: 'absolute',
    boxSizing: 'border-box',
    padding: '4px 16px',
    background: colors.errorRed,
    bottom: '0',
    right: '0',
    zIndex: 100,
    borderRadius: '4px 0 4px 4px',
    color: colors.white,
    fontSize: '14px',
    '&:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '0 12px 12px 12px',
      borderColor: `transparent transparent ${colors.errorRed} transparent`,
      right: '0',
      top: '0',
      marginTop: '-12px',
    },
  },
}));

export const FormErrorPopup: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <StyledBox className={classes.errorPopupMessage}>{children}</StyledBox>
  );
};

import React from 'react';
import { images } from '../data';
import type { FloatingImageProps } from './FloatingImage.types';
import { StyledImage, StyledWrapper } from './styles';

export const FloatingImage = ({ activeCompany }: FloatingImageProps) => {
  const activeStyle = {
    opacity: 1,
  };
  const inactiveStyle = {
    opacity: 0,
  };
  return (
    <StyledWrapper display={{ xs: 'none', sm: 'block' }}>
      {images.map((image, index) => {
        const isActive = index === activeCompany;

        return (
          <StyledImage
            key={index}
            src={image}
            sx={isActive ? { ...activeStyle } : { ...inactiveStyle }}
          />
        );
      })}
    </StyledWrapper>
  );
};

import { Box, Stack, styled } from '@mui/material';

export const StyledStack = styled(Stack)(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  cursor: 'pointer',
}));
export const StyledLine = styled(`div`)(({ theme }) => ({
  height: '1px',
  background: theme.palette.colorLink,
  margin: theme.spacing(0, 2),
  alignSelf: 'center',
}));

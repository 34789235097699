import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { CustomSvgIconsProps, SvgPathProps } from '../../CustomSvgIcons.types';
import { useTheme } from '@mui/material';
import { SvgPath } from '../../SvgPath/SvgPath';

export const MobileAppIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_mobileAppIconCurrentColorGradient`;
  const newColorId = `${prefix}_mobileAppIconNewColorGradient`;
  const shapeId = `${prefix}_mobileAppIconShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';

  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.purpleColors?.PLUS_3,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;

  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      {solidColor ? (
        <SvgPath
          d="M14.1667 20H19.1667M15.0001 35H10.0001C9.11603 35 8.26818 34.6488 7.64306 34.0237C7.01794 33.3986 6.66675 32.5507 6.66675 31.6667V8.33333C6.66675 7.44928 7.01794 6.60143 7.64306 5.97631C8.26818 5.35119 9.11603 5 10.0001 5H23.3334C24.2175 5 25.0653 5.35119 25.6904 5.97631C26.3156 6.60143 26.6667 7.44928 26.6667 8.33333V11.6667M20.8334 5V8.33333C20.8334 8.55435 20.7456 8.76631 20.5893 8.92259C20.4331 9.07887 20.2211 9.16667 20.0001 9.16667H13.3334C13.1124 9.16667 12.9004 9.07887 12.7442 8.92259C12.5879 8.76631 12.5001 8.55435 12.5001 8.33333V5M16.6667 17.5V22.5M25.0001 28.3333L24.0251 27.3567C23.8062 27.1378 23.5464 26.9641 23.2604 26.8456C22.9745 26.7271 22.668 26.6662 22.3584 26.6662C22.0489 26.6662 21.7424 26.7271 21.4564 26.8456C21.1704 26.9641 20.9106 27.1378 20.6917 27.3567C20.3061 27.7419 20.0659 28.249 20.0121 28.7914C19.9583 29.3339 20.0943 29.8782 20.3967 30.3317L23.6301 35.1817C23.9344 35.6384 24.3467 36.0129 24.8305 36.2719C25.3143 36.531 25.8546 36.6666 26.4034 36.6667H32.7301C33.5424 36.6664 34.3267 36.3696 34.9355 35.8319C35.5444 35.2942 35.936 34.5527 36.0367 33.7467L36.6401 28.9183C36.7471 28.0626 36.5183 27.1987 36.0018 26.5082C35.4852 25.8176 34.7212 25.3541 33.8701 25.215L30.0001 24.585V20.8333C30.0001 20.1703 29.7367 19.5344 29.2678 19.0656C28.799 18.5967 28.1631 18.3333 27.5001 18.3333C26.837 18.3333 26.2012 18.5967 25.7323 19.0656C25.2635 19.5344 25.0001 20.1703 25.0001 20.8333V28.3333Z"
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              d="M14.1667 20H19.1667M15.0001 35H10.0001C9.11603 35 8.26818 34.6488 7.64306 34.0237C7.01794 33.3986 6.66675 32.5507 6.66675 31.6667V8.33333C6.66675 7.44928 7.01794 6.60143 7.64306 5.97631C8.26818 5.35119 9.11603 5 10.0001 5H23.3334C24.2175 5 25.0653 5.35119 25.6904 5.97631C26.3156 6.60143 26.6667 7.44928 26.6667 8.33333V11.6667M20.8334 5V8.33333C20.8334 8.55435 20.7456 8.76631 20.5893 8.92259C20.4331 9.07887 20.2211 9.16667 20.0001 9.16667H13.3334C13.1124 9.16667 12.9004 9.07887 12.7442 8.92259C12.5879 8.76631 12.5001 8.55435 12.5001 8.33333V5M16.6667 17.5V22.5M25.0001 28.3333L24.0251 27.3567C23.8062 27.1378 23.5464 26.9641 23.2604 26.8456C22.9745 26.7271 22.668 26.6662 22.3584 26.6662C22.0489 26.6662 21.7424 26.7271 21.4564 26.8456C21.1704 26.9641 20.9106 27.1378 20.6917 27.3567C20.3061 27.7419 20.0659 28.249 20.0121 28.7914C19.9583 29.3339 20.0943 29.8782 20.3967 30.3317L23.6301 35.1817C23.9344 35.6384 24.3467 36.0129 24.8305 36.2719C25.3143 36.531 25.8546 36.6666 26.4034 36.6667H32.7301C33.5424 36.6664 34.3267 36.3696 34.9355 35.8319C35.5444 35.2942 35.936 34.5527 36.0367 33.7467L36.6401 28.9183C36.7471 28.0626 36.5183 27.1987 36.0018 26.5082C35.4852 25.8176 34.7212 25.3541 33.8701 25.215L30.0001 24.585V20.8333C30.0001 20.1703 29.7367 19.5344 29.2678 19.0656C28.799 18.5967 28.1631 18.3333 27.5001 18.3333C26.837 18.3333 26.2012 18.5967 25.7323 19.0656C25.2635 19.5344 25.0001 20.1703 25.0001 20.8333V28.3333Z"
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

import React from 'react';
import type { StrapiImageProps } from './StrapiImage.types';
import { useUniversalComponents } from '../../utils/useUniversalComponents';

function strapiImageLoader({
  src,
  width,
  quality,
}: {
  src: string;
  width: string;
  quality: string;
}): string {
  return `${src}?w=${width}&q=${quality || 75}&fit=fill&f=face`;
}

export const StrapiImage: React.FC<StrapiImageProps> = ({
  image,
  width,
  height,
}) => {
  const { Image } = useUniversalComponents();
  return (
    <Image
      loader={strapiImageLoader}
      src={image?.attributes?.url || ''}
      alt={image?.attributes?.alternativeText || 'strapiImage'}
      width={width}
      height={height}
    />
  );
};

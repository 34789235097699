export const rumbleFishBullets = [
  {
    heading: 'passion for coding ',
    copy: 'When building teams we look for developers whose eyes light up when they talk about software development. We all share a passion for technology and strive for the quality of our work to reflect that.',
  },

  {
    heading: 'unique skillset ',
    copy: 'Our developers have extraordinary qualifications that make them valuable to any team or project they join. Whether your project requires Blockchain, AWS cloud, or FinTech expertise - we’ve got a perfect team for you.',
  },
  {
    heading: 'innovative thinking',
    copy: 'Our team goes the extra mile to make your product stand out. We don’t just settle for what might seem obvious - we always look for improvements and innovative solutions.',
  },

  {
    heading: 'agile approach',
    copy: 'Agility helps us deliver value to your project faster and leaves space for continuous improvements.',
  },
  {
    heading: 'open communication',
    copy: 'We believe that openness and honesty go a long way. That’s why we choose a transparent approach to our processes - with Ruble Fish you’ll never be left in the dark, we keep our customers involved and informed about the status of the project throughout the journey.',
  },
];

import { SubmenuBlockProps } from '../SubmenuBlock/SubmenuBlock.types';
import {
  AIIcon,
  AwsIcon,
  BlockchainIcon,
  DefiIcon,
  DesignIcon,
  FintechIcon,
  MobileIcon,
  NftIcon,
  StartupIcon,
  TeamIcon,
  WebIcon,
} from '../Icons';
export const servicesSubmenuData: {
  blocks: SubmenuBlockProps[];
} = {
  blocks: [
    {
      name: 'AWS Cloud Solutions',
      caption: 'Creative AWS cloud solutions to elevate your business',
      icon: AwsIcon,
      linkTo: '/services/aws-cloud-solutions/',
    },
    {
      name: 'Web development',
      caption: 'Sleek front-end and powerful back-end to make your website pop',
      icon: WebIcon,
      linkTo: '/services/web-development/',
    },
    {
      name: 'Mobile development',
      caption: 'Custom end-to-end IOS and Android app development',
      icon: MobileIcon,
      linkTo: '/services/mobile-development/',
    },
    {
      name: 'AI Chat Assistant Development',
      caption: 'Develop your custom AI chatbot with our team of AI experts',
      icon: AIIcon,
      linkTo: '/services/ai-chat-assistant/',
    },
    {
      name: 'DeFi Development',
      caption: 'Bringing you custom DeFi product development from A to Z ',
      icon: DefiIcon,
      linkTo: '/services/defi-development/',
    },
    {
      name: 'NFT Development',
      caption:
        'Utilize our Blockchain expertise to develop your own NFT platform',
      icon: NftIcon,
      linkTo: '/services/nft-development/',
    },
    {
      name: 'Fintech Software Development',
      caption: 'Boost your financial product with state-of-the-art technology',
      icon: FintechIcon,
      linkTo: '/services/fintech-software-development/',
    },
    {
      name: 'Blockchain Software Development',
      caption:
        'Grow your business with our blockchain software development services',
      icon: BlockchainIcon,
      linkTo: '/services/blockchain-software-development/',
    },
    {
      name: 'Dedicated Development Teams',
      caption: 'Experienced teams tailored to your needs and requirements',
      icon: TeamIcon,
      linkTo: '/services/dedicated-development-teams/',
    },
    {
      name: 'UI/UX',
      caption:
        'Make your product as user friendly as possible with our UI/UX services',
      icon: DesignIcon,
      linkTo: '/services/product-design',
    },
    {
      name: 'IT Services for Startups',
      caption: 'End-to-end development services to boost your startup',
      icon: StartupIcon,
      linkTo: '/startup',
    },
  ],
};

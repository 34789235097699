import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { getCmsOptimizedUrl } from '../../../utils/getCmsOptimizedUrl';
import { truncateText } from '../../../utils/stringUtilis';
import { Link } from '../Link';
import type { BlogBoxProps } from './BlogBox.types';
import { StyledWrapper, StyledImage, StyledArrow, StyledStack } from './styles';
import parse from 'html-react-parser';

export const BlogBox = ({
  image,
  link,
  author,
  date,
  title,
  text,
  ...props
}: BlogBoxProps) => {
  const theme = useTheme();

  const truncatedText = truncateText(text, 100);

  const adjustedImage = image
    ? getCmsOptimizedUrl({
        url: image,
        width: 352,
      })
    : '';

  return (
    <StyledStack {...props} spacing={2}>
      <Link to={link}>
        <StyledImage src={adjustedImage} sx={{ cursor: 'pointer' }} alt="" />
      </Link>
      <Stack justifyContent="space-around" height="100%" spacing={2}>
        <Stack spacing={2}>
          <StyledWrapper>
            <Typography
              variant="overline"
              color={theme.palette.colorBrand?.secondary}>
              {author}
            </Typography>
            <Typography
              variant="overline"
              color={theme.palette.colorGrey?.shade50}>
              {date}
            </Typography>
          </StyledWrapper>
          <Link to={link}>
            <Typography variant="heading6" sx={{ cursor: 'pointer' }}>
              {title ? truncateText(title, 48) : ''}
            </Typography>
          </Link>
        </Stack>
        <Stack spacing={2}>
          <Typography
            variant="bodySmall"
            color={theme.palette.colorGrey?.primary}>
            {parse(truncatedText)}
          </Typography>
          <Link to={link}>
            <Stack direction="row" sx={{ cursor: 'pointer' }}>
              <Typography variant="buttonSmall" color={theme.palette.colorLink}>
                Learn more
              </Typography>
              <StyledArrow>→</StyledArrow>
            </Stack>
          </Link>
        </Stack>
      </Stack>
    </StyledStack>
  );
};

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import React from 'react';
import { colors } from '../../theme/default/color';
import { useDesktopDetect } from '../../theme/utils/useBreakpoints';
import { CourseCard } from '../CourseCard/CourseCard';
import { Section } from '../Section';
import { SectionHeader } from '../SectionHeader';
import { courseTimelineContent } from './CourseTimeline.content';

const ProgramDescription = styled('p')({
  fontWeight: 900,
  fontSize: '1.375rem',
  color: colors.black,
});

const CourseCardGrid = styled('div')(({ theme: { spacing, breakpoints } }) => ({
  marginTop: spacing(4),

  display: 'grid',
  gridTemplateColumns: 'repeat(11,1fr)',
  gridTemplateRows: '9rem 3.4rem 9rem',

  [breakpoints.down('sm')]: {
    gridTemplateRows: 'repeat(5, 1fr)',
    gap: spacing(3),
  },
}));

const TimelineDashedLine = styled('div')(({ theme: { breakpoints } }) => ({
  borderTop: `2px dashed ${colors.dividerGray}`,
  width: '90%',
  margin: '0 auto',
  gridColumn: '2 / 11',
  gridRow: '2 / 3',
  alignSelf: 'center',

  [breakpoints.down('sm')]: {
    borderTop: 'none',
    borderRight: `1px dashed ${colors.dividerGray}`,
    borderLeft: `1px dashed ${colors.dividerGray}`,
    gridColumn: '1 / 1',
    gridRow: '1 / 6',
    height: '80%',
    width: '0',
  },
}));

export const CourseTimeline: React.FC = () => {
  const isOnDesktop = useDesktopDetect();

  return (
    <Section theme="gray">
      <Grid container>
        <Grid item xs={12} md={6}>
          <SectionHeader
            title="Our original program in a nutshell"
            annotation="timeline"
          />
          <ProgramDescription>
            The program was developed with real cases in mind. This way, our
            graduates are ready to participate in commercial projects as soon as
            the training ends.
          </ProgramDescription>
        </Grid>
      </Grid>
      <CourseCardGrid>
        {courseTimelineContent.map((card, index) => (
          <Box
            key={card.description}
            sx={{
              gridColumn: { md: `${index * 2 + 1} / span 3`, xs: '2 / -1' },
              gridRow: {
                md: `${index % 2 === 0 ? '1 / span 1' : '3 / span 1'}`,
                xs: 'auto',
              },
            }}>
            <CourseCard
              arrowPosition={
                isOnDesktop ? `${index % 2 === 0 ? 'bottom' : 'top'}` : 'left'
              }
              {...card}
            />
          </Box>
        ))}
        <TimelineDashedLine />
      </CourseCardGrid>
    </Section>
  );
};

import { Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import React from 'react';
import { colors } from '../../theme/default/color';
import { GradientText } from '../GradientText';
import type { SectionHeaderProps } from './SectionHeader.types';

const PREFIX = 'SectionHeader';

const classes = {
  root: `${PREFIX}-root`,
  sectionHeaderAnnotation: `${PREFIX}-sectionHeaderAnnotation`,
  sectionHeaderTitle: `${PREFIX}-sectionHeaderTitle`,
  sectionHeaderDescription: `${PREFIX}-sectionHeaderDescription`,
};

const Root = styled('div')(({ theme: { spacing } }: { theme: Theme }) => ({
  [`&.${classes.root}`]: { display: 'grid' },

  [`& .${classes.sectionHeaderAnnotation}`]: {
    zIndex: 1,
    position: 'relative',
    marginLeft: spacing(3),

    '&:before': {
      content: "''",
      position: 'absolute',
      left: spacing(-3),
      top: spacing(1),
      width: '14px',
      height: '2px',
      background: colors.greyBoldText,
      marginRight: spacing(1),
    },
  },

  [`& .${classes.sectionHeaderTitle}`]: { marginTop: spacing(1) },

  [`& .${classes.sectionHeaderDescription}`]: {
    marginTop: spacing(2),
    color: colors.greyBoldText,
  },
}));

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  annotation,
  description,
}) => {
  return (
    <Root className={classes.root}>
      {annotation && (
        <Typography
          variant="caption"
          className={classes.sectionHeaderAnnotation}>
          {annotation}
        </Typography>
      )}
      <GradientText>
        <Typography
          variant="h1"
          component="h2"
          className={classes.sectionHeaderTitle}>
          {title}
        </Typography>
      </GradientText>
      {description && (
        <Typography
          variant="body1"
          className={classes.sectionHeaderDescription}>
          {description}
        </Typography>
      )}
    </Root>
  );
};

import { Box, Link, Stack, useTheme } from '@mui/material';
import { Button, Checkbox, InputField } from '@rumblefish/ui/AlgeaTheme';
import React from 'react';
import type { ISendData } from '../../../hooks/useForm/types';
import { useForm } from '../../../hooks/useForm/useForm';
import { AnimatedLoadingCircleIcon } from './AnimatedLoadingCircleIcon';
import { ebookFormStackClasses } from './EbookForm.types';
import type { EbookFormProps } from './EbookForm.types';
import { ebookFormFields } from './FieldsData';
import { StyledRecaptcha, StyledStack, StyledLink } from './styles';

const LinkLabel = () => (
  <span>
    I accept Rumble Fish &nbsp;
    {useTheme().palette.type === 'navy' ? (
      <StyledLink
        target="_blank"
        href="/privacy-policy"
        sx={{
          position: 'relative',
          textDecoration: 'none',
          '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: -2,
            width: '100%',
            height: '1px',
            background:
              'linear-gradient(152.46deg, #FFFFFF -22.85%, rgba(255, 255, 255, 0) 100%), #F47AFF',
          },
        }}>
        Privacy Policy
      </StyledLink>
    ) : (
      <Link
        href="/privacy-policy"
        underline="none"
        color="#2F57F4"
        target="_blank">
        Privacy Policy
      </Link>
    )}
  </span>
);

export const EbookForm = ({
  downloadSource,
  callback,
  btntext = 'Download free e-book now',
  sx = {},
  ...props
}: EbookFormProps) => {
  const { data, methods, loading } = useForm(
    ebookFormFields,
    `${process.env.BACKEND_API}/v1/contact`,
    true,
    callback
  );

  const firstName = data.firstName.text;
  const lastName = data.lastName.text;
  const fullName = firstName.concat(' ', lastName);
  const isNavyMode: boolean = useTheme().palette.type === 'navy';
  const dataToSend: ISendData = {
    fullName,
    email: data.email.text,
    subject: downloadSource,
    message: `${
      data.communication.checked
        ? 'I would like to receive communication from the team.'
        : ''
    }`,
  };
  const palleteType = useTheme().palette.type;
  return (
    <StyledStack
      sx={sx}
      direction="column"
      justifyContent="space-between"
      {...props}
      className={ebookFormStackClasses[palleteType]}>
      <Box>
        <Stack
          spacing={isNavyMode ? 2 : 7}
          marginTop={isNavyMode ? '0px' : '24px'}>
          <InputField
            size="small"
            label="First Name"
            onChangeEvent={(e) => methods.setInputField('firstName', e)}
            value={data.firstName.text}
            disabled={loading}
            errorMessage={data.firstName.errorMessage}
            inputId={ebookFormFields.firstName.id}
          />
          <InputField
            size="small"
            label="Last Name"
            onChangeEvent={(e) => methods.setInputField('lastName', e)}
            value={data.lastName.text}
            disabled={loading}
            errorMessage={data.lastName.errorMessage}
            inputId={ebookFormFields.lastName.id}
          />
          <InputField
            size="small"
            label="Bussiness Email"
            onChangeEvent={(e) => methods.setInputField('email', e)}
            value={data.email.text}
            disabled={loading}
            errorMessage={data.email.errorMessage}
            inputId={ebookFormFields.email.id}
          />
        </Stack>
        <Stack>
          <Checkbox
            label="I wish to receive Rumble Fish email communication."
            onChangeEvent={() => methods.setCheckBoxField('communication')}
            value={data.communication.checked}
            disabled={loading}
            error={data.communication.isError}
            checkboxId={ebookFormFields.communication.id}
          />
          <Checkbox
            label={<LinkLabel />}
            onChangeEvent={() => methods.setCheckBoxField('policy')}
            value={data.policy.checked}
            disabled={loading}
            error={data.policy.isError}
            checkboxId={ebookFormFields.policy.id}
          />
        </Stack>
      </Box>
      <Stack
        sx={{
          display: 'flex',
          alignItems: isNavyMode ? 'center' : 'flex-start',
        }}>
        <StyledRecaptcha
          sitekey={process.env.CAPTCHA_KEY as string}
          onChange={(value) => methods.setCaptcha(value)}
        />
      </Stack>

      <Button
        sx={
          isNavyMode
            ? {
                height: '60px',
                borderRadius: '16px',
                fontSize: '20px',
                fontWeight: 500,
                lineHeight: '100%',
              }
            : {}
        }
        onClick={() => methods.submitForm(dataToSend)}
        variant="contained"
        size="large">
        {loading ? <AnimatedLoadingCircleIcon /> : btntext}
      </Button>
    </StyledStack>
  );
};

import { Box, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';
import type { ParagraphTextProps } from './ParagraphText.types';

const PREFIX = 'ParagraphText';

const classes = {
  root: `${PREFIX}-root`,
  withIcon: `${PREFIX}-withIcon`,
  iconContainer: `${PREFIX}-iconContainer`,
  paragraphTextTitle: `${PREFIX}-paragraphTextTitle`,
};

const Root = styled('div')(
  ({ theme: { breakpoints, spacing } }: { theme: Theme }) => ({
    [`&.${classes.root}`]: {
      display: 'grid',
      gap: spacing(3),
      [breakpoints.down('md')]: {
        gap: '0px',
      },
    },

    [`&.${classes.withIcon}`]: {
      gridTemplateColumns: '1fr 4fr',
      justifyItems: 'center',

      [breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        justifyItems: 'start',
      },
    },

    [`& .${classes.iconContainer}`]: {
      display: 'relative',
    },

    [`& .${classes.paragraphTextTitle}`]: {
      marginBottom: spacing(2),
    },
  })
);

export const ParagraphText: React.FC<ParagraphTextProps> = ({
  title,
  description,
  icon,
  className,
  smallerBody = false,
}) => {
  const isWithIcon = !!icon;

  return (
    <Root
      className={clsx(classes.root, className, {
        [classes.withIcon]: isWithIcon,
      })}>
      {isWithIcon && (
        <div className={classes.iconContainer}>
          <img src={icon.src} alt={icon.alt} width={116} height={116} />
        </div>
      )}

      <Box>
        <Typography variant="h3" className={classes.paragraphTextTitle}>
          {title}
        </Typography>
        <Typography variant={smallerBody ? 'body2' : 'body1'}>
          {description}
        </Typography>
      </Box>
    </Root>
  );
};

import type { SvgIconProps } from '@mui/material';
import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';
import * as React from 'react';
const SvgIcon = styled(MuiSvgIcon, {
  name: 'AnimatedLoadingCircleIcon',
  shouldForwardProp: (prop) => prop !== 'fill',
})<SvgIconProps>(() => ({
  fill: 'none',
  stroke: 'currentColor',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeWidth: '1.5px',
}));

SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
};

export const AnimatedLoadingCircleIcon: React.FunctionComponent<
  SvgIconProps
> = (props) => {
  return (
    <SvgIcon
      titleAccess="AnimatedLoadingCircleIcon"
      {...props}
      sx={{
        animation: 'spin 4s linear infinite ',
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(360deg)',
          },
          '100%': {
            transform: 'rotate(deg0)',
          },
        },
      }}>
      <path d="M21 12H18" />
      <path d="M3 12H6" />
      <path d="M12 3V6" />
      <path d="M12 21V18" />
      <path d="M18.364 18.364L16.243 16.243" />
      <path d="M5.63599 5.63599L7.75699 7.75699" />
      <path d="M16.243 7.75699L18.364 5.63599" />
      <path d="M7.75699 16.243L5.63599 18.364" />
    </SvgIcon>
  );
};

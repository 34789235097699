export const truncateText = (text: string, n: number) => {
  if (text.length <= n) {
    return text;
  }
  const truncated = text.substring(0, n);
  if (text.charAt(n) === ' ') {
    return truncated + '...';
  }
  return `${truncated.substring(0, truncated.lastIndexOf(' '))}...`;
};
export const removeDash = (text: string) => {
  return text.replace('-', ' ');
};

import cyc from '../../assets/jpg/AlgaeTheme/ConnectYourCare.jpg';
import makerDAO from '../../assets/jpg/AlgaeTheme/makerDAO.jpg';
import veryfiID from '../../assets/jpg/AlgaeTheme/VeryfiID.jpg';
import zbay from '../../assets/jpg/AlgaeTheme/Zbay.jpg';
export const images = [makerDAO, cyc, zbay, veryfiID];
export const data = [
  {
    company: 'makerDAO',
    title: 'The company behind DAI',
    link: '/case-studies/makerdao/',
    image: makerDAO,
  },
  {
    company: 'ConnectYourCare',
    title: 'Online platform for benefit brokers',
    link: '/case-studies/connectyourcare/',
    image: cyc,
  },
  {
    company: 'Zbay',
    title: 'A private inbox, wallet, and marketplace all in one',
    link: '/case-studies/zbay-a-private-inbox-wallet-and-marketplace-all-in-one/',
    image: zbay,
  },
  {
    company: 'VeryfiID',
    title: 'Discovery Workshop for an identity verification',
    link: '/case-studies/verifiid-mvp/',
    image: veryfiID,
  },
];

import HubIcon from '../../../assets/svg/AlgeaTheme/careers-hub.svg';
import JoinIcon from '../../../assets/svg/AlgeaTheme/careers-join.svg';

export const data = [
  {
    headline: 'Join Our Team',
    text: 'We’re always looking for a new talents, we are waiting for you. Join to Rumblefish today!',
    icon: JoinIcon,
    to: '/careers',
  },
  {
    headline: 'Blockchain Training Hub',
    text: 'A course designed by Marek Kowalski, CTO at Rumble Fish. Learn Blockchain and get paid for it!',
    icon: HubIcon,
    to: '/blockchainhub',
  },
];

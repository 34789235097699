import { Stack, styled, Typography } from '@mui/material';

export const StyledStack = styled(Stack)(({ theme }) => ({
  maxWidth: '352px',
  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
    width: '100%',
    justifyContent: 'space-between',
    flexFlow: 'row nowrap',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '352px',
    flexFlow: 'column nowrap',
  },
}));
export const StyledWrapper = styled(Stack)(() => ({
  width: '100%',
  justifyContent: 'space-between',
  flexFlow: 'row nowrap',
}));
export const StyledImage = styled(`img`)(({ theme }) => ({
  width: '100%',
  height: '195px',
  [theme.breakpoints.down('md')]: {
    width: 'auto',
    maxWidth: '352px',
    height: 'auto',
    marginRight: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '352px',
    width: '100%',
    marginRight: 'unset',
  },
}));
export const StyledArrow = styled(Typography)(({ theme }) => ({
  marginLeft: '4px',
  fontSize: '12px',
  color: theme.palette.colorLink,
}));

import { Hidden, Stack, Typography, useTheme } from '@mui/material';
import { Section } from '@rumblefish/ui/AlgeaTheme';
import type { LegacyRef } from 'react';
import React from 'react';
import { useScrollbarPosition } from '@rumblefish/ui/hooks/useScrollbarPosition';
import { Line } from './Line';
import type { OfferBoxProps, OfferDataProp, OfferProps } from './Offer.types';
import {
  StyledOfferBoxIcon,
  StyledOffersWrapper,
  StyledOneElement,
  StyledRightWrapper,
  StyledStack,
  StyledTextWrapper,
} from './styles';

export const OfferBox = ({
  description,
  icon,
  title,
  isActive,
}: OfferBoxProps) => {
  const theme = useTheme();

  const colorWhite = theme.palette.colorWhite;
  return (
    <Stack
      sx={{ opacity: isActive ? 1 : 0.15, transition: 'ease-in-out 0.2s' }}>
      <StyledOfferBoxIcon src={icon} />

      <Typography color={colorWhite} marginBottom="16px" variant="heading4">
        {title}
      </Typography>
      <Typography color={colorWhite} variant="body2Small">
        {description}
      </Typography>
    </Stack>
  );
};

export const Offer = React.forwardRef(function Ref(
  { ...props }: OfferProps & OfferDataProp,
  ref: LegacyRef<HTMLDivElement>
) {
  const theme = useTheme();

  const { containerRef, scrollPercentagePosition, activeThreshold } =
    useScrollbarPosition();

  const colorWhite = theme.palette.colorWhite;

  const percentageThreshold = [0, 22, 48, 75];

  const activeCompany = activeThreshold([0, 22, 48, 75]);

  const oneElementMode =
    props.oneElementTitle &&
    props.oneElementSubtitle &&
    props.oneElementDescription;

  return (
    <Section width="small" backgroundColor={theme.palette.colorBrand?.primary}>
      {!oneElementMode && (
        <>
          <div ref={ref} style={{ position: 'relative', top: '-80px' }} />
          <StyledStack {...props}>
            <StyledTextWrapper
              spacing={4}
              descMarginBottom={props?.descMarginBottom}>
              <Typography color={colorWhite} variant="heading3">
                {props.title}
              </Typography>
              <Typography color={colorWhite} variant="subtitle">
                {props.description}
              </Typography>
            </StyledTextWrapper>
            <StyledRightWrapper ref={containerRef}>
              <Line
                scrollPercentagePosition={scrollPercentagePosition}
                activeCompany={activeCompany}
                percentageThreshold={percentageThreshold}
              />
              <StyledOffersWrapper spacing={18}>
                {props.data?.map((item, index) => {
                  return (
                    <OfferBox
                      key={index}
                      {...item}
                      isActive={index <= activeCompany}
                    />
                  );
                })}
              </StyledOffersWrapper>
            </StyledRightWrapper>
          </StyledStack>
        </>
      )}
      {oneElementMode && (
        <>
          <div ref={ref} style={{ position: 'relative', top: '-80px' }} />
          <StyledOneElement spacing={4}>
            <Typography variant="heading3" color="white">
              {props.oneElementTitle}
            </Typography>
            <Typography variant="subtitle" color="white">
              {props.oneElementSubtitle}
            </Typography>
            <Typography variant="body2Small" color="white">
              {props.oneElementDescription}
            </Typography>
          </StyledOneElement>
        </>
      )}
    </Section>
  );
});

import React, { createContext } from 'react';
import {
  ContextType,
  NextComponentsContextProviderProps,
} from './nextComponents.Context.types';
export const NextComponentsContext = createContext<ContextType | null>(null);

export const NextComponentsContextProvider = ({
  children,
  components,
  hooks,
}: NextComponentsContextProviderProps) => {
  return (
    <NextComponentsContext.Provider value={{ components, hooks }}>
      {children}
    </NextComponentsContext.Provider>
  );
};

import type { Theme } from '@mui/material';
import type { GridSize, GridSpacing } from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Background } from '../Background';
import { Block } from '../Block';
import type { PageProps } from './Page.types';

const PREFIX = 'Page';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  [`& .${classes.root}`]: {
    color: theme.palette.primary.main,
    overflow: 'hidden',
    paddingTop: theme.spacing(20),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(10),
    },
  },
}));

export const Page: React.FC<PageProps> = ({ page }) => {
  return (
    <Root>
      {page?.attributes?.backgroundImage && (
        <Background image={page?.attributes?.backgroundImage?.data} />
      )}
      <div className={classes.root}>
        <Grid container justifyContent="center">
          {page?.attributes?.outerColumnGapMD &&
            page?.attributes?.outerColumnGapSM &&
            page?.attributes?.outerColumnGapXS && (
              <>
                <Grid
                  item
                  xs={page?.attributes?.outerColumnGapXS as GridSize}
                  sm={page?.attributes?.outerColumnGapSM as GridSize}
                  md={page?.attributes?.outerColumnGapMD as GridSize}
                />
                <Grid
                  item
                  xs={(12 - 2 * page?.attributes?.outerColumnGapXS) as GridSize}
                  sm={(12 - 2 * page?.attributes?.outerColumnGapSM) as GridSize}
                  md={
                    (12 - 2 * page?.attributes?.outerColumnGapMD) as GridSize
                  }>
                  <Grid
                    container
                    spacing={page?.attributes.spacing as GridSpacing}
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}>
                    {page?.attributes.blocks?.data.map((block) => (
                      <React.Fragment key={block.id}>
                        <Block block={block} />
                      </React.Fragment>
                    ))}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={page?.attributes.outerColumnGapXS as GridSize}
                  sm={page?.attributes.outerColumnGapSM as GridSize}
                  md={page?.attributes.outerColumnGapMD as GridSize}
                />
              </>
            )}
        </Grid>
      </div>
    </Root>
  );
};

export default Page;

import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';

const SvgPath = ({ shapeId }: SvgPathProps): ReactJSXElement => {
  return (
    <g id={shapeId}>
      <path
        d="M 94.433594 116.191406 C 93.554688 116.191406 92.675781 115.753906 92.347656 114.875 L 84.988281 96.460938 L 77.742188 114.875 C 76.644531 117.507812 72.582031 115.972656 73.679688 113.230469 L 83.011719 89.773438 C 83.78125 87.910156 86.308594 87.910156 87.074219 89.773438 L 96.410156 113.230469 C 96.957031 114.65625 95.96875 116.191406 94.433594 116.191406 Z M 94.433594 116.191406 "
        fillRule="evenodd"
      />
      <path
        d="M 91.25 108.40625 L 78.839844 108.40625 C 75.875 108.40625 75.875 104.023438 78.839844 104.023438 L 91.25 104.023438 C 94.214844 104.023438 94.214844 108.40625 91.25 108.40625 Z M 91.25 108.40625 "
        fillRule="evenodd"
      />
      <path
        d="M 107.71875 116.191406 C 106.511719 116.191406 105.523438 115.3125 105.523438 114 L 105.523438 90.429688 C 105.523438 87.582031 109.917969 87.582031 109.917969 90.429688 L 109.917969 114 C 109.917969 115.3125 108.929688 116.191406 107.71875 116.191406 Z M 107.71875 116.191406 "
        fillRule="evenodd"
      />
      <path
        d="M 114.527344 92.625 L 100.800781 92.625 C 97.945312 92.625 97.945312 88.238281 100.800781 88.238281 L 114.527344 88.238281 C 117.492188 88.238281 117.492188 92.625 114.527344 92.625 Z M 114.527344 92.625 "
        fillRule="evenodd"
      />
      <path
        d="M 114.527344 116.191406 L 100.800781 116.191406 C 97.945312 116.191406 97.945312 111.804688 100.800781 111.804688 L 114.527344 111.804688 C 117.492188 111.804688 117.492188 116.191406 114.527344 116.191406 Z M 114.527344 116.191406 "
        fillRule="evenodd"
      />
      <path
        d="M 95.09375 136.359375 C 76.3125 136.359375 60.941406 121.125 60.941406 102.269531 C 60.941406 83.417969 76.3125 68.070312 95.09375 68.070312 C 113.980469 68.070312 129.351562 83.417969 129.351562 102.269531 C 129.351562 121.125 113.980469 136.359375 95.09375 136.359375 Z M 95.09375 72.453125 C 78.730469 72.453125 65.332031 85.828125 65.332031 102.269531 C 65.332031 118.710938 78.730469 131.976562 95.09375 131.976562 C 111.5625 131.976562 124.960938 118.710938 124.960938 102.269531 C 124.960938 85.828125 111.5625 72.453125 95.09375 72.453125 Z M 95.09375 72.453125 "
        fillRule="evenodd"
      />
      <path
        d="M 95.09375 187 C 70.382812 187 50.945312 148.964844 50.945312 100.296875 C 50.945312 62.589844 63.027344 29.484375 81.144531 17.757812 C 83.5625 16.222656 85.976562 19.949219 83.453125 21.484375 C 66.871094 32.226562 55.339844 64.5625 55.339844 100.296875 C 55.339844 145.675781 73.128906 182.617188 95.09375 182.617188 C 117.054688 182.617188 134.953125 145.675781 134.953125 100.296875 C 134.953125 64.5625 123.3125 32.226562 106.730469 21.484375 C 104.316406 19.949219 106.730469 16.222656 109.148438 17.757812 C 127.15625 29.484375 139.347656 62.589844 139.347656 100.296875 C 139.347656 148.964844 119.910156 187 95.09375 187 Z M 95.09375 187 "
        fillRule="evenodd"
      />
      <path
        d="M 53.144531 158.171875 C 46.335938 158.171875 47.652344 157.296875 47.652344 153.679688 C 47.652344 150.828125 52.046875 150.609375 52.046875 153.789062 C 70.492188 154.117188 93.335938 147.210938 115.078125 134.605469 C 154.5 111.914062 177.5625 78.046875 166.691406 59.082031 C 155.707031 40.007812 114.636719 43.078125 75.214844 65.878906 C 34.585938 89.226562 8.777344 127.042969 26.460938 145.238281 C 28.546875 147.320312 25.359375 150.390625 23.382812 148.308594 C 3.179688 127.480469 29.203125 87.253906 73.019531 62.042969 C 115.296875 37.707031 158.015625 35.40625 170.421875 56.890625 C 182.832031 78.265625 159.441406 114.109375 117.273438 138.441406 C 95.3125 151.046875 72.140625 158.171875 53.144531 158.171875 Z M 53.144531 158.171875 "
        fillRule="evenodd"
      />
      <path
        d="M 137.148438 158.171875 C 118.589844 158.171875 95.3125 151.265625 73.019531 138.441406 C 40.40625 119.589844 17.675781 92.515625 16.574219 71.140625 C 16.464844 68.289062 20.859375 67.960938 20.96875 70.921875 C 21.957031 90.652344 44.25 116.847656 75.214844 134.605469 C 106.183594 152.472656 140.113281 158.609375 157.683594 149.621094 C 160.210938 148.308594 162.1875 152.253906 159.660156 153.570312 C 153.511719 156.636719 145.824219 158.171875 137.148438 158.171875 Z M 171.410156 131.644531 C 170.3125 131.644531 169.324219 130.769531 169.214844 129.5625 C 168.335938 109.832031 145.933594 83.636719 115.078125 65.878906 C 84.109375 48.011719 50.179688 41.871094 32.609375 50.859375 C 30.082031 52.175781 27.996094 48.230469 30.632812 46.914062 C 49.738281 37.160156 84.550781 43.1875 117.273438 62.042969 C 149.886719 80.894531 172.621094 107.96875 173.609375 129.34375 C 173.71875 130.550781 172.730469 131.644531 171.410156 131.644531 Z M 171.410156 131.644531 "
        fillRule="evenodd"
      />
      <path
        d="M 95.09375 31.238281 C 86.527344 31.238281 79.5 24.226562 79.5 15.675781 C 79.5 7.015625 86.527344 0 95.09375 0 C 103.765625 0 110.796875 7.015625 110.796875 15.675781 C 110.796875 24.226562 103.765625 31.238281 95.09375 31.238281 Z M 95.09375 4.382812 C 88.941406 4.382812 83.890625 9.425781 83.890625 15.675781 C 83.890625 21.8125 88.941406 26.855469 95.09375 26.855469 C 101.351562 26.855469 106.402344 21.8125 106.402344 15.675781 C 106.402344 9.425781 101.351562 4.382812 95.09375 4.382812 Z M 95.09375 4.382812 "
        fillRule="evenodd"
      />

      <path
        d="M 168.554688 158.171875 C 159.882812 158.171875 152.964844 151.15625 152.964844 142.5 C 152.964844 133.949219 159.882812 126.933594 168.554688 126.933594 C 177.121094 126.933594 184.148438 133.949219 184.148438 142.5 C 184.148438 151.15625 177.121094 158.171875 168.554688 158.171875 Z M 168.554688 131.316406 C 162.296875 131.316406 157.355469 136.359375 157.355469 142.5 C 157.355469 148.746094 162.296875 153.789062 168.554688 153.789062 C 174.707031 153.789062 179.757812 148.746094 179.757812 142.5 C 179.757812 136.359375 174.707031 131.316406 168.554688 131.316406 Z M 168.554688 131.316406 "
        fillRule="evenodd"
      />

      <path
        d="M 36.453125 169.242188 C 27.777344 169.242188 20.859375 162.230469 20.859375 153.679688 C 20.859375 145.019531 27.777344 138.003906 36.453125 138.003906 C 45.015625 138.003906 52.046875 145.019531 52.046875 153.679688 C 52.046875 162.230469 45.015625 169.242188 36.453125 169.242188 Z M 36.453125 142.386719 C 30.300781 142.386719 25.25 147.429688 25.25 153.679688 C 25.25 159.816406 30.300781 164.859375 36.453125 164.859375 C 42.601562 164.859375 47.652344 159.816406 47.652344 153.679688 C 47.652344 147.429688 42.601562 142.386719 36.453125 142.386719 Z M 36.453125 142.386719 "
        fillRule="evenodd"
      />
      <path
        d="M 21.738281 73.550781 C 13.0625 73.550781 6.035156 66.535156 6.035156 57.984375 C 6.035156 49.328125 13.0625 42.3125 21.738281 42.3125 C 30.300781 42.3125 37.332031 49.328125 37.332031 57.984375 C 37.332031 66.535156 30.300781 73.550781 21.738281 73.550781 Z M 21.738281 46.695312 C 15.476562 46.695312 10.425781 51.738281 10.425781 57.984375 C 10.425781 64.125 15.476562 69.167969 21.738281 69.167969 C 27.886719 69.167969 32.9375 64.125 32.9375 57.984375 C 32.9375 51.738281 27.886719 46.695312 21.738281 46.695312 Z M 21.738281 46.695312 "
        fillRule="evenodd"
      />
    </g>
  );
};

export const AIWithWebIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_aiWithWebCurrentColorGradient`;
  const newColorId = `${prefix}_aiWithWebNewColorGradient`;
  const shapeId = `${prefix}_aiWithWebShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.grapeColors?.PLUS_2,
    },
  };
  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.grapeColors?.PLUS_3,
      stop: theme.palette.grapeColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox="0 0 187.5 187.499992">
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              fill: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              fill: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />{' '}
        </>
      )}
    </SvgIcon>
  );
};

import React from 'react';
import { Oval } from 'react-loader-spinner';
import { LoaderProps } from './Loader.types';
import { StyledText, StyledWrapper } from './styles';
import { useTheme } from '@mui/material';
export const Loader = ({ children }: LoaderProps) => {
  const theme = useTheme();
  const loaderColor = theme.palette.invertedContent?.SECONDARY;
  return (
    <StyledWrapper>
      <Oval
        height="40"
        width="40"
        color={loaderColor}
        secondaryColor={loaderColor}
        ariaLabel="loading"
        strokeWidth={1.5}
      />
      <StyledText variant="regular_200">{children}</StyledText>
    </StyledWrapper>
  );
};

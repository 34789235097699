import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React from 'react';
import { colors } from '../../theme/default/color';
import type { CourseCardProps } from './CourseCard.types';

const PREFIX = 'CourseCard';

const classes = {
  courseCardContainer: `${PREFIX}-courseCardContainer`,
  arrowBottom: `${PREFIX}-arrowBottom`,
  arrowTop: `${PREFIX}-arrowTop`,
  arrowLeft: `${PREFIX}-arrowLeft`,
};

const Root = styled('div')(({ theme: { breakpoints } }) => ({
  [`&.${classes.courseCardContainer}`]: {
    backgroundColor: colors.navyBlue,
    borderRadius: '0.5rem',
    position: 'relative',
    margin: '0 1rem',

    [breakpoints.down('md')]: {
      margin: '0',
    },

    //arrow
    '&:after': {
      content: "''",
      height: triangleHeight,
      width: triangleWidth,
      background: 'inherit',
      position: 'absolute',
    },

    //circle
    '&:before': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      height: circleRadius,
      width: circleRadius,
      borderRadius: '50%',
      background: colors.white,
      border: `4px solid ${colors.pink}`,
    },
  },

  [`&.${classes.arrowBottom}`]: {
    '&:after': {
      top: '100%',
      left: '50%',
      //TODO remove this -10% Y translate
      transform: 'translate(-50%, -10%)',
      background: 'inherit',
      clipPath: `polygon(
          0 0,
          100% 0,
          50% 100%)`,
    },
    '&:before': {
      top: `calc(100% + ${circleDistance})`,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },

  [`&.${classes.arrowTop}`]: {
    '&:after': {
      bottom: '100%',
      left: '50%',
      transform: 'translate(-50%, 10%)',
      clipPath: `polygon(
          0 100%,
          50% 0%,
          100% 100%)`,
    },

    '&:before': {
      bottom: `calc(100% + ${circleDistance})`,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },

  [`&.${classes.arrowLeft}`]: {
    '&:after': {
      height: triangleWidth,
      width: triangleHeight,
      top: '50%',
      left: '0%',
      transform: 'translate(-100%, -50%)',
      clipPath: `polygon(
          0 50%,
          104% 0,
          104% 100%)`,
    },

    '&:before': {
      top: `calc(50% - calc(${circleRadius} / 2))`,
      right: `calc(100% + calc(${circleDistance} / 2))`,
      transform: 'translateX(-50%)',
    },
  },
}));

const triangleWidth = '1.5rem';
const triangleHeight = '0.75rem';
const circleDistance = '1.2rem';
const circleRadius = '1rem';

export const CourseCard: React.FC<CourseCardProps> = ({
  heading,
  subheading,
  description,
  arrowPosition,
}) => {
  return (
    <Root
      className={clsx(classes.courseCardContainer, {
        [classes.arrowBottom]: arrowPosition === 'bottom',
        [classes.arrowTop]: arrowPosition === 'top',
        [classes.arrowLeft]: arrowPosition === 'left',
      })}>
      <Grid
        container
        gap={1}
        direction="column"
        wrap="nowrap"
        sx={{ padding: 2, height: '9rem' }}>
        <Typography
          variant="h3"
          component="p"
          sx={{
            textTransform: subheading ? 'uppercase' : 'none',
            color: colors.white,
            fontFeatureSettings: "'pnum' on, 'lnum' on",
          }}>
          {heading}
        </Typography>
        {subheading && (
          <Typography
            variant="h4"
            component="h3"
            sx={{ color: colors.pink, fontSize: '1rem' }}>
            {subheading}
          </Typography>
        )}
        <Typography
          variant="body2"
          component="h4"
          sx={{
            fontWeight: 900,
            fontFamily: 'Axia Black',
            color: colors.white,
          }}>
          {description}
        </Typography>
      </Grid>
    </Root>
  );
};

import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';

const SvgPath = ({ shapeId, solidColor }: SvgPathProps): ReactJSXElement => {
  return (
    <path
      d="M16.504 31.328V28.288C16.504 27.072 17.0267 26.0373 18.072 25.184L23.96 20.192C24.92 19.4027 25.4 18.5493 25.4 17.632V16.672C25.4 16.0107 25.24 15.5413 24.92 15.264C24.6213 14.9653 24.12 14.816 23.416 14.816H22.296C21.6133 14.816 21.1333 14.9653 20.856 15.264C20.5787 15.5413 20.44 16.0107 20.44 16.672V17.696C20.44 18.1653 20.216 18.4 19.768 18.4H17.048C16.6 18.4 16.376 18.1653 16.376 17.696V16.608C16.376 14.944 16.8347 13.664 17.752 12.768C18.6693 11.872 19.9707 11.424 21.656 11.424H24.088C25.7733 11.424 27.0747 11.872 27.992 12.768C28.9093 13.664 29.368 14.944 29.368 16.608V17.888C29.368 19.552 28.728 20.928 27.448 22.016L21.432 27.136C21.176 27.3707 21.048 27.6267 21.048 27.904V28.416H28.696C29.144 28.416 29.368 28.64 29.368 29.088V31.328C29.368 31.776 29.144 32 28.696 32H17.176C16.728 32 16.504 31.776 16.504 31.328Z"
      fill={solidColor ? 'currentColor' : ''}
      id={shapeId}
      strokeWidth="1.2"
      stroke={solidColor ? 'currentColor' : ''}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
};

export const TwoIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor = 'red',
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_twoCurrentColorGradient`;
  const newColorId = `${prefix}_twoNewColorGradient`;
  const shapeId = `${prefix}_twoShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const colors = theme.palette?.[`${gradientColor}Colors`];

  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: colors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: colors?.PLUS_3,
      stop: colors?.PLUS_2,
    },
  };

  const svgColors = isLight ? colorsLight : colorsDark;

  return (
    <SvgIcon {...props} viewBox="0 0 45 45">
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="90%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="90%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>

          <g
            style={{
              stroke: `url("#${currColorId}")`,
              fill: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              fill: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

import { useTheme, Typography } from '@mui/material';

import React, { useEffect, useRef, useState } from 'react';

import { ArrowButton } from '../ArrowButton';
import type { CallToActionProps } from './CallToAction.types';
import { StyledStack, StyledLine } from './styles';

export const CallToAction = ({
  text,
  arrowDirection = 'down',
  ...props
}: CallToActionProps) => {
  const theme = useTheme();
  const [isHover, setIsHover] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const lineRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    const line = lineRef.current;
    const text = textRef.current;

    if (container && line && text) {
      const containerWidth = container.offsetWidth;
      const textWidth = text.offsetWidth;

      lineRef.current.style.width = `${containerWidth - textWidth - 36 - 33}px`;
    }
  }, []);

  return (
    <StyledStack
      {...props}
      ref={containerRef}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}>
      <Typography
        variant="uppercase"
        color={theme.palette.colorLink}
        ref={textRef}>
        {text}
      </Typography>
      <StyledLine ref={lineRef} />
      <ArrowButton direction={arrowDirection} isHover={isHover} />
    </StyledStack>
  );
};

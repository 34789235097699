import { Stack, styled, Typography, Link } from '@mui/material';
import type { LinkProps } from '@mui/material';
export const StyledStack = styled(Stack)(({ theme }) => ({
  // background: 'blue',
  zIndex: 10,
  height: '509px',
  width: '40%',
  maxWidth: 'none',
  padding: theme.spacing(4),
  borderRadius: '24px',
  [theme.breakpoints.down('lg')]: {
    width: '46%',
  },
  [theme.breakpoints.down('md')]: {
    width: '70%',
  },
  [theme.breakpoints.down(700)]: {
    width: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: theme.spacing(2),
  },
}));
export const StyledHeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colorBrand?.primary,
}));

export const StyledIcon = styled(`img`)(() => ({
  height: `40px`,
  width: `40px`,
}));

export const StyledLink = styled(Link)(() => ({
  lineHeight: '150%',
  fontWeight: 650,
  fontStyle: 'normal',
  fontFamily: 'Inter',
  fontSize: '14px',
  background:
    'linear-gradient(152.46deg, #FFFFFF -22.85%, rgba(255, 255, 255, 0) 100%), #F47AFF',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  fontVariationSettings: 'slnt 0',
})) as React.FC<LinkProps>;

import { styled } from '@mui/material';
import { colors } from '../../theme/default/color';

export const List = styled('li')({
  listStyle: 'none',
  position: 'relative',
  color: colors.greyBoldText,

  '&:before': {
    content: '""',
    display: 'inline-block',
    width: '16px',
    top: '6px',
    left: '-26px',
    position: 'absolute',
    height: '16px',
    borderRadius: '50%',
    background: colors.white,
    border: `4px solid ${colors.lightblue}`,
  },
  '& fragment>ul>li': {
    '&:before': {
      content: '"unset !important"',
    },
  },
  '& p': {
    display: 'inline',
  },
});

export const StyledIframe = styled('iframe')(({ theme }) => ({
  width: '100%',
  height: '400px',
  [theme.breakpoints.down('sm')]: {
    height: '200px',
  },
}));

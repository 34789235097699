import React from 'react';
import type { LinkProps } from './Link.types';
import { StyledLink, StyledSpan } from './styles';
import { useUniversalComponents } from '../../../utils/useUniversalComponents';
export const Link = ({ children, to, ...props }: LinkProps) => {
  const fixScroll = () => {
    document.body.style.overflow = 'auto';
  };

  const { Link, isUsingNext } = useUniversalComponents();
  return (
    <Link href={to} legacyBehavior passHref style={{ textDecoration: 'none' }}>
      {isUsingNext ? (
        <StyledLink onClick={fixScroll} {...props}>
          {children}
        </StyledLink>
      ) : (
        <StyledSpan onClick={fixScroll} {...props}>
          {children}
        </StyledSpan>
      )}
    </Link>
  );
};

import { Typography, useTheme } from '@mui/material';
import React from 'react';
import ConnectYourCare from '../../../assets/svg/AlgeaTheme/clients/footerExtension/connect_your_care.svg';
import MakerDao from '../../../assets/svg/AlgeaTheme/clients/footerExtension/dao_maker.svg';
import Solar from '../../../assets/svg/AlgeaTheme/clients/footerExtension/solar_generation.svg';
import SpaceInch from '../../../assets/svg/AlgeaTheme/clients/footerExtension/space_inch.svg';
import VerifyId from '../../../assets/svg/AlgeaTheme/clients/footerExtension/verify_id.svg';
import ZBay from '../../../assets/svg/AlgeaTheme/clients/footerExtension/zbay.svg';
import { Section } from '../Section';
import type { FooterExtensionProps } from './FooterExtension.types';
import {
  ClientImage,
  ClientsExtendedTextWrapper,
  ClientsWrapper,
  StyledExtendWrapper,
} from './styles';

export const FooterExtension = ({ ...props }: FooterExtensionProps) => {
  const theme = useTheme();

  return (
    <Section width="small">
      <StyledExtendWrapper {...props}>
        <ClientsExtendedTextWrapper>
          <Typography
            variant="uppercase"
            sx={{
              color: theme.palette.colorBrand?.secondary,
              marginBottom: '16px',
            }}>
            Our clients
          </Typography>
          <Typography
            variant="heading4"
            sx={{ color: theme.palette.colorBlack }}>
            We're trusted by global innovators and leaders. Join them!
          </Typography>
        </ClientsExtendedTextWrapper>
        <ClientsWrapper>
          <ClientImage src={SpaceInch} />
          <ClientImage src={ZBay} />
          <ClientImage src={VerifyId} />
          <ClientImage src={ConnectYourCare} />
          <ClientImage src={Solar} />
          <ClientImage src={MakerDao} />
        </ClientsWrapper>
      </StyledExtendWrapper>
    </Section>
  );
};

import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';

import { SubmenuBlock } from '../SubmenuBlock';
import type { ServicesSubmenuType } from './ServicesSubmenu.types';
import { servicesSubmenuData } from './servicesSubmenuData';
import { Wrapper, StyledStack } from './styles';

export const ServicesSubmenu = ({
  ...props
}: ServicesSubmenuType): ReactJSXElement => {
  return (
    <StyledStack>
      <Wrapper>
        {servicesSubmenuData.blocks.map((block, index) => (
          <SubmenuBlock
            type="icon"
            key={index}
            name={block.name}
            caption={block.caption}
            icon={block.icon}
            iconPrefix={'services'}
            linkTo={block.linkTo}
          />
        ))}
      </Wrapper>
    </StyledStack>
  );
};

import { styled, Typography } from '@mui/material';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.colorBrand?.primary,
  '&:hover': theme.mixins.hoverTextLightBlue,
  fontSize: '120%',
  [theme.breakpoints.up('md')]: {
    margin: '-44px 0px -44px 0px',
    padding: '44px 0px 44px 0px',
  },
}));

import { Typography, Stack, useTheme } from '@mui/material';
import { UserBanner, Button } from '@rumblefish/ui/AlgeaTheme';
import React from 'react';
import Image from '../../../assets/png/workshop/sylwia.png';
import Icon from '../../../assets/svg/workshop/confetti.svg';
import type { DownloadViewProps } from './downloaView.types';
import {
  StyledStack,
  StyledIcon,
  StyledHeaderText,
  StyledLink,
} from './styles';
const LinkLabel = () => (
  <span>
    <br />
    <StyledLink
      href="sylwia.bien.chudarek@rumblefish.pl"
      sx={{
        position: 'relative',
        textDecoration: 'none',
        '&::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          bottom: -2,
          width: '100%',
          height: '1px',
          background:
            'linear-gradient(152.46deg, #FFFFFF -22.85%, rgba(255, 255, 255, 0) 100%), #F47AFF',
        },
      }}>
      sylwia.bien.chudarek@rumblefish.pl
    </StyledLink>
  </span>
);
const Header = () => {
  return (
    <Stack direction="row" spacing={2}>
      <StyledHeaderText variant="h3">Hurray!</StyledHeaderText>
      <StyledIcon src={Icon} />
    </Stack>
  );
};

export const DownloadView = ({ sx = {} }: DownloadViewProps) => {
  const theme = useTheme();
  const websiteUrl = process.env.NEXT_PUBLIC_PATH as string;
  return (
    <StyledStack sx={sx} direction="column" justifyContent="space-between">
      <Stack direction="column" justifyContent="flex-start" spacing={3}>
        <Header />
        <Typography
          variant="body"
          component="div"
          sx={{ color: 'rgba(255, 255, 255, 0.75)' }}>
          Now download and enjoy our free ebook. If you have any topics to
          discuss, feel free to write me on my email:
          <Typography variant="body">
            <LinkLabel />
          </Typography>
        </Typography>

        <Typography
          variant="body"
          sx={{
            color: 'rgba(255, 255, 255, 0.75)',
            paddingTop: '10px',
            [theme.breakpoints.down('lg')]: {
              paddingTop: '30px',
            },
          }}>
          Best wishes,
        </Typography>
        <UserBanner
          image={Image}
          name="Sylwia Bień-Chudarek"
          positionName="Head of Growth"
        />
      </Stack>
      <Button
        onClick={() => window.open(`${websiteUrl}/pdf/your-fintech-mvp.pdf`)}
        sx={{
          borderRadius: '16px',
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: '100%',
        }}
        variant="contained"
        size="large">
        Download the Guide
      </Button>
    </StyledStack>
  );
};

import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type {
  SvgPathProps,
  CustomSvgIconsProps,
} from '../../CustomSvgIcons.types';

const SvgPath = ({ shapeId }: SvgPathProps): ReactJSXElement => {
  return (
    <g id={shapeId}>
      <path d="M22.685 47L45.4176 55.2394C45.0881 56.8263 43.2432 63 35.7315 63C28.7734 63 24.1346 51.3333 22.685 47Z" />
      <path d="M75.685 47L52.9525 55.2394C53.2819 56.8263 55.1269 63 62.6385 63C69.5966 63 74.2354 51.3333 75.685 47Z" />
      <path d="M54 98.4243C52.3648 98.6451 50.6957 98.7592 49 98.7592C28.5654 98.7592 12 82.1938 12 61.7592C12 41.3247 28.5654 24.7592 49 24.7592C69.4345 24.7592 86 41.3247 86 61.7592C86 65.6018 85.4142 69.3075 84.3272 72.792C84.5328 72.8816 85.59 73.3551 86.0382 73.8064C88.3192 76.1033 90.3049 78.4462 91.9681 80.8339C94.5597 75.0046 96 68.5501 96 61.7592C96 35.8018 74.9573 14.7592 49 14.7592C23.0426 14.7592 1.99996 35.8018 1.99996 61.7592C1.99996 87.7166 23.0426 108.759 49 108.759C51.3709 108.759 53.7008 108.584 55.9775 108.245C54.6791 105.304 54.0062 101.981 54 98.4243Z" />
      <path d="M58.0133 97.6536C58.0044 97.8937 58 98.1342 58 98.375C58 101.8 58.7141 104.865 60.0298 107.458C72.8104 104.384 83.5575 96.0882 89.8875 84.9536C89.3673 84.1048 88.7978 83.256 88.1789 82.4071C87.0802 80.9001 85.8258 79.393 84.4159 77.8859C84.0227 77.4656 83.6174 77.0453 83.2 76.625C83.7439 78.268 84.0412 79.911 84.1417 81.5165C84.1883 82.2603 84.1926 82.9961 84.1596 83.7201C84.0137 86.9222 83.1378 89.8939 81.9592 92.3125C80.1723 95.9794 77.6896 98.375 76 98.375C76.8061 95.7774 77.5545 93.2961 78.1986 90.8895C79.0295 87.7845 79.6868 84.8038 80.0703 81.8579C75.0508 89.6017 67.2149 95.3504 58.0133 97.6536Z" />
      <path d="M0.936237 5.50515C-1.15654 16.5662 7.6652 32.2516 12.3377 38.7116C24.7297 32.0306 45.466 21.6204 28.5223 21.2655C12.71 20.9342 0.936237 5.50515 0.936237 5.50515Z" />
      <path d="M94.7486 5.50515C96.8414 16.5662 88.0197 32.2516 83.3472 38.7116C70.9552 32.0306 50.2188 21.6204 67.1626 21.2655C82.9748 20.9342 94.7486 5.50515 94.7486 5.50515Z" />
      <path d="M37.0657 9.35294C26.2277 11.5882 22.506 18.9118 22 22.2941L35.197 24.5L68.9525 22.2941L73 13C70.5474 15.6961 64.9119 18.7647 62.3893 15.8235C59.8666 12.8824 62.5766 9.21814 65.4184 6.375L43.3722 14.0588L55 0C52.5 1 47.9036 7.11765 37.0657 9.35294Z" />
      <path
        className="SvgFilledPath"
        fill="#F32727"
        d="M58 98.375C58 109.25 65.2 116.5 76 116.5C86.8 116.5 94 109.25 94 98.375C94 91.125 90.4 83.875 83.2 76.625C86.8 87.5 79.6 98.375 76 98.375C80.5 83.875 83.2 73 76 58.5C76 69.375 58 83.1118 58 98.375Z"
      />
    </g>
  );
};

export const DevilWalletIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_devilWalletCurrentColorGradient`;
  const newColorId = `${prefix}_devilWalletNewColorGradient`;
  const shapeId = `${prefix}_devilWalletShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.redColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.redColors?.PLUS_3,
      stop: theme.palette.redColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox={'0 0 96 117'}>
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

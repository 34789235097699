import awsIconHover from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/aws-hover.svg';
import awsIcon from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/aws.svg';

import customIconHover from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/custom-hover.svg';
import customIcon from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/custom.svg';
import defiIconHover from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/defi-hover.svg';
import defiIcon from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/defi.svg';
import fintechIconHover from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/fintech-hover.svg';
import fintechIcon from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/fintech.svg';
import mobileIconHover from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/mobile-hover.svg';
import mobileIcon from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/mobile.svg';

import nftIconHover from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/nft-hover.svg';
import nftIcon from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/nft.svg';

import teamIconHover from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/team-hover.svg';
import teamIcon from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/team.svg';

import uiIconHover from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/ui-hover.svg';
import uiIcon from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/ui.svg';
import unicornIcon from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/unicorn.svg';
import webIconHover from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/web-hover.svg';
import webIcon from '../../../assets/svg/AlgeaTheme/ServicesSubmenu/web.svg';

export const data = [
  {
    title: 'AWS Cloud Solutions',
    text: 'Creative AWS cloud solutions to elevate your business',
    icon: awsIcon,
    iconHover: awsIconHover,
    link: '/services/aws-cloud-solutions/',
  },
  {
    title: 'Web development',
    text: 'Sleek front-end and powerful back-end to make your website pop',
    icon: webIcon,
    iconHover: webIconHover,
    link: '/services/web-development/',
  },
  {
    title: 'Mobile development',
    text: 'Custom end-to-end IOS and Android app development',
    icon: mobileIcon,
    iconHover: mobileIconHover,
    link: '/services/mobile-development/',
  },
  {
    title: 'DeFi Development',
    text: 'Bringing you custom DeFi product development from A to Z ',
    icon: defiIcon,
    iconHover: defiIconHover,
    link: '/services/defi-development/',
  },
  {
    title: 'NFT Development',
    text: 'Utilize our Blockchain expertise to develop your own NFT platform',
    icon: nftIcon,
    iconHover: nftIconHover,
    link: '/services/nft-development/',
  },
  {
    title: 'Fintech Software Development',
    text: 'Boost your financial product with state-of-the-art technology',
    icon: fintechIcon,
    iconHover: fintechIconHover,
    link: '/services/fintech-software-development/',
  },
  {
    title: 'Blockchain Software Development',
    text: 'Grow your business with our blockchain software development services',
    icon: customIcon,
    iconHover: customIconHover,
    link: '/services/blockchain-software-development/',
  },
  {
    title: 'Dedicated Development Teams',
    text: 'Experienced teams tailored to your needs and requirements',
    icon: teamIcon,
    iconHover: teamIconHover,
    link: '/services/dedicated-development-teams/',
  },
  {
    title: 'UI/UX',
    text: 'Make your product as user friendly as possible with our UI/UX services',
    icon: uiIcon,
    iconHover: uiIconHover,
    link: '/services/product-design',
  },
  {
    title: 'IT Services for Startups',
    text: 'End-to-end development services to boost your startup',
    icon: unicornIcon,
    iconHover: unicornIcon,
    link: '/startup',
  },
];

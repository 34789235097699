import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { Navigator } from '@rumblefish/ui';
import { Section } from '@rumblefish/ui/AlgeaTheme';
import React, { useCallback } from 'react';
import type { HeroProps } from './Hero.types';
import {
  StyledCallToAction,
  StyledHeading,
  StyledStack,
  StyledText,
  StyledTextWrapper,
} from './styles';

export const Hero = (props: HeroProps) => {
  const theme = useTheme();
  const tabletAndPhoneOn = useMediaQuery(theme.breakpoints.down('md'));

  const scrollToContact = useCallback(() => {
    props.offerRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [props.offerRef]);

  return (
    <Section width="small">
      <StyledStack>
        <Navigator
          name={props.navigatorName}
          path={props.navigatorPath}
          subname={props.navigatorSubname}
          newDesign
        />
        <StyledHeading variant="heading2" widerHeading={props.widerHeading}>
          {props.heading}
        </StyledHeading>
        <StyledTextWrapper>
          <Stack>
            <StyledText variant="body2Big">{props.descriptionLeft}</StyledText>
            {!tabletAndPhoneOn && (
              <StyledCallToAction
                text="let's dive in"
                onClick={() => scrollToContact()}
              />
            )}
          </Stack>
          <StyledText variant="body2Big">{props.descriptionRight}</StyledText>
          {tabletAndPhoneOn && (
            <StyledCallToAction
              text="let's dive in"
              onClick={() => scrollToContact()}
            />
          )}
        </StyledTextWrapper>
      </StyledStack>
    </Section>
  );
};

import type { TypographyOptions } from '@mui/material/styles/createTypography';
import { createBreakpoints } from '@mui/system';
import type { CSSProperties } from 'react';
import { breakpoints as breakpointsValues } from './breakpoints';
import { palette } from './palette';

const breakpoints = createBreakpoints(breakpointsValues);
export interface ExtendedTypographyPropsVariantOverrides1 {
  buttonSmall: true;
  buttonRegular: true;
  buttonSmallLink: true;
  form: true;
  formLink: true;
  body: true;
  bodyBold: true;
  bodyItalic: true;
  bodyItalicBold: true;
  bodyLink: true;
  bodyLinkBold: true;
  bodySmall: true;
  bodySmallBold: true;
  bodySmallItalic: true;
  bodySmallItalicBold: true;
  bodySmallLink: true;
  bodySmallLinkBold: true;
  uppercase: true;
}
export interface ExtendedTypographyOptions extends TypographyOptions {
  buttonSmall: CSSProperties;
  buttonRegular: CSSProperties;
  buttonSmallLink: CSSProperties;
  form: CSSProperties;
  formLink: CSSProperties;
  body: CSSProperties;
  bodyBold: CSSProperties;
  bodyItalic: CSSProperties;
  bodyItalicBold: CSSProperties;
  bodyLink: CSSProperties;
  bodyLinkBold: CSSProperties;
  bodySmall: CSSProperties;
  bodySmallBold: CSSProperties;
  bodySmallItalic: CSSProperties;
  bodySmallItalicBold: CSSProperties;
  bodySmallLink: CSSProperties;
  bodySmallLinkBold: CSSProperties;
  uppercase: CSSProperties;
}

const rajdhaniStyle = {
  fontFamily: 'Rajdhani',
  fontWeight: 600,
  fontStyle: 'normal',
  lineHeight: '120%',
  color: palette.colorBlack,
};
const interStyle = {
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '150%',
  color: palette.colorBlack,
};
const bodyStyle = {
  fontFamily: 'Inter',
  lineHeight: '150%',
  color: palette.colorGrey?.primary,
  fontSize: '1.125rem',
  [breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
};
const bodySmallStyle = {
  fontFamily: 'Inter',
  lineHeight: '150%',
  color: palette.colorGrey?.primary,
  fontSize: '0.875rem',
  [breakpoints.down('sm')]: {
    fontSize: '0.812rem',
  },
};

export const typography = {
  fontFamily: ['Rajdhani,Inter'].join(','),
  buttonRegular: {
    ...rajdhaniStyle,
    fontSize: '1rem',
    letterSpacing: '0,05em',
  },
  buttonSmall: {
    ...rajdhaniStyle,
    fontSize: '0.875rem',
    letterSpacing: '0,05em',
  },
  buttonSmallLink: {
    ...rajdhaniStyle,
    fontSize: '0.875rem',
    letterSpacing: '0,05em',
    textDecoration: 'underline',
  },
  form: {
    ...interStyle,
  },
  formLink: {
    ...interStyle,
    textDecoration: 'underline',
  },
  body: {
    ...bodyStyle,
    fontStyle: 'normal',
    fontWeight: 400,
  },
  bodyBold: {
    ...bodyStyle,
    fontStyle: 'normal',
    fontWeight: 600,
  },
  bodyItalic: {
    ...bodyStyle,
    fontStyle: 'italic',
    fontWeight: 400,
  },
  bodyItalicBold: {
    ...bodyStyle,
    fontStyle: 'italic',
    fontWeight: 600,
  },
  bodyLink: {
    ...bodyStyle,
    fontStyle: 'normal',
    fontWeight: 400,
    textDecoration: 'underline',
    color: palette.colorLink,
  },
  bodyLinkBold: {
    ...bodyStyle,
    fontStyle: 'normal',
    fontWeight: 600,
    textDecoration: 'underline',
  },
  bodySmall: {
    ...bodySmallStyle,
    fontStyle: 'normal',
    fontWeight: 400,
  },
  bodySmallBold: {
    ...bodySmallStyle,
    fontStyle: 'normal',
    fontWeight: 600,
  },
  bodySmallItalic: {
    ...bodySmallStyle,
    fontStyle: 'italic',
    fontWeight: 400,
  },
  bodySmallItalicBold: {
    ...bodySmallStyle,
    fontStyle: 'italic',
    fontWeight: 600,
  },
  bodySmallLink: {
    ...bodySmallStyle,
    fontStyle: 'normal',
    fontWeight: 400,
    textDecoration: 'underline',
    color: palette.colorLink,
  },
  bodySmallLinkBold: {
    ...bodySmallStyle,
    fontStyle: 'normal',
    fontWeight: 600,
    textDecoration: 'underline',
    color: palette.colorLink,
  },
  uppercase: {
    ...rajdhaniStyle,
    textTransform: 'uppercase',
    color: palette.colorBrand?.secondary,
    fontSize: '0.875rem',
    letterSpacing: '0.25em',
  },
} as ExtendedTypographyOptions;

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { colors } from '@rumblefish/ui';
import React from 'react';
import type { ImageParagraphProps } from './ImageParagraph.types';

const ParagraphPhoto = styled('img')({
  width: '100%',
  height: 'auto',
  maxWidth: '550px',
  borderRadius: '8px',
});

type SingleParagraphProps = {
  paragraph: string | React.ComponentType<{}>;
};

const SingleParagraph = ({ paragraph }: SingleParagraphProps) => {
  if (typeof paragraph === 'string') {
    return (
      <Typography
        variant="body2"
        fontSize="1rem"
        color={colors.blackText}
        mb={4}>
        {paragraph}
      </Typography>
    );
  }

  const ParagraphComponent = paragraph;
  return <ParagraphComponent />;
};

export const ImageParagraph = ({
  text,
  img,
  imagePosition = 'right',
}: ImageParagraphProps) => {
  return (
    <Grid
      container
      gap={5}
      wrap="nowrap"
      alignItems={{ xs: 'center' }}
      direction={{
        xs: 'column',
        md: imagePosition === 'left' ? 'row-reverse' : 'row',
      }}>
      <Grid item>
        {text.map((singleParagraph) => (
          <SingleParagraph paragraph={singleParagraph} />
        ))}
      </Grid>
      <ParagraphPhoto {...img} alt="" />
    </Grid>
  );
};

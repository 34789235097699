import type { RefObject } from 'react';

type ScrollBarArgs = {
  scrollPosition: number;
  containerPosition: number;
  containerHeight: number;
};

const activateThreshold = global.innerHeight * 0.5;

export const getPercentageScrollPositionInContainer = ({
  scrollPosition,
  containerPosition,
  containerHeight,
}: ScrollBarArgs) => {
  const scrollPercentagePosition =
    ((scrollPosition - containerPosition + activateThreshold) /
      containerHeight) *
    100;

  if (scrollPercentagePosition <= 0) {
    return 0;
  }

  if (scrollPercentagePosition >= 100) {
    return 100;
  }

  return Math.min(Math.max(scrollPercentagePosition, 0), 100);
};

export const getContainrHeightAndOffset = (
  containerRef: RefObject<HTMLDivElement>
) => ({
  containerPosition: containerRef.current?.offsetTop || 0,
  containerHeight: containerRef.current?.clientHeight || 0,
});

import axios from 'axios';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  extractKeysFromFields,
  generateDefaultState,
  validateFields,
} from './helpers';
import type {
  IFieldsData,
  IReturnedState,
  ISendData,
  TFormsKeys,
  TStateCheckbox,
  TStateInput,
} from './types';

export const useForm = <Type extends TFormsKeys>(
  fields: IFieldsData<Type>,
  submitUrl: string,
  useCaptcha: boolean,
  callback?: () => void,
  onSubmitError?: () => void
) => {
  const defaultState: IReturnedState<Type> = generateDefaultState(
    fields as IFieldsData<TFormsKeys>
  );
  const dispatch = useDispatch();

  const [formData, setData] = useState(defaultState);
  const [loading, setLoading] = useState<boolean>(false);
  const [captcha, setCaptcha] = useState<string | null>(null);

  type TInputFieldEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

  const setInputField = (field: Type['inputs'], e: TInputFieldEvent) => {
    const newInputValue: TStateInput = {
      text: e.target.value,
      errorMessage: formData[field].errorMessage,
    };

    setData({
      ...formData,
      [field]: newInputValue,
    });
  };

  const setCheckBoxField = (field: Type['checkboxes']) => {
    const newCheckboxValue: TStateCheckbox = {
      checked: !formData[field].checked,
      isError: formData[field].isError,
    };

    setData({
      ...formData,
      [field]: newCheckboxValue,
    });
  };

  const isFormInvalid = (): boolean => {
    let isError = false;
    const newFormData: IReturnedState<TFormsKeys> = {
      ...formData,
    } as IReturnedState<TFormsKeys>;

    const keys = extractKeysFromFields(fields as IFieldsData<TFormsKeys>);

    isError = validateFields(
      keys,
      newFormData,
      fields as IFieldsData<TFormsKeys>
    );
    setData(newFormData);

    return isError;
  };

  const submitForm = (data: ISendData) => {
    const isFormValid = !isFormInvalid();
    const isCaptchaValid = !(
      useCaptcha &&
      (captcha === null || captcha === '')
    );

    if (isFormValid && isCaptchaValid) {
      setLoading(true);

      axios
        .post(submitUrl, JSON.stringify(data, null, 2))
        .then(() => {
          setTimeout(() => {
            setLoading(false);
            setData(defaultState);
            if (callback) callback();
          }, 1000);
        })
        .catch((e) => {
          if (onSubmitError) {
            onSubmitError();
          }

          setLoading(false);
          dispatch({ type: 'SET_ERROR_ASYNC', e });
        });
    }
  };

  const methods = {
    setInputField,
    setCheckBoxField,
    submitForm,
    setCaptcha,
  };

  return {
    data: formData,
    methods,
    loading,
  };
};

import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';
import { viewBoxes } from './CustomSvgIcons.types';

const SvgPath = ({
  iconType,
  shapeId,
  solidColor,
}: SvgPathProps): ReactJSXElement => {
  if (iconType === 'bold') {
    return (
      <path
        d="M9.07 17.07L10.83 14.02M6.49991 16.5L4.99991 14M9.07 6.93L10.83 9.98M15.5 7.5L13.5 10M17.85 12H14.34M18.9999 14L17.4999 17M13.6553 10.3447C14.5695 11.2589 14.5695 12.7412 13.6553 13.6554C12.7411 14.5696 11.2588 14.5696 10.3446 13.6554C9.43041 12.7411 9.43041 11.2589 10.3446 10.3447C11.2588 9.43048 12.7411 9.43048 13.6553 10.3447ZM21.4489 10.5101C22.2717 11.3329 22.2717 12.667 21.4489 13.4898C20.6261 14.3127 19.292 14.3127 18.4691 13.4898C17.6463 12.667 17.6463 11.3329 18.4691 10.5101C19.292 9.68726 20.6261 9.68726 21.4489 10.5101ZM5.53087 10.5101C6.3537 11.3329 6.3537 12.667 5.53087 13.4898C4.70803 14.3127 3.37396 14.3127 2.55112 13.4898C1.72828 12.667 1.72828 11.3329 2.55112 10.5101C3.37395 9.68726 4.70803 9.68726 5.53087 10.5101ZM17.4689 3.61713C18.2917 4.43996 18.2917 5.77404 17.4689 6.59688C16.6461 7.41971 15.312 7.41971 14.4891 6.59688C13.6663 5.77404 13.6663 4.43997 14.4891 3.61713C15.312 2.79429 16.6461 2.79429 17.4689 3.61713ZM9.51087 17.4031C10.3337 18.2259 10.3337 19.56 9.51087 20.3828C8.68803 21.2057 7.35396 21.2057 6.53112 20.3828C5.70828 19.56 5.70828 18.2259 6.53112 17.4031C7.35395 16.5803 8.68803 16.5803 9.51087 17.4031ZM9.51087 3.61713C10.3337 4.43996 10.3337 5.77404 9.51087 6.59688C8.68803 7.41971 7.35396 7.41971 6.53112 6.59688C5.70828 5.77404 5.70828 4.43997 6.53112 3.61713C7.35395 2.79429 8.68803 2.79429 9.51087 3.61713ZM17.4689 17.4031C18.2917 18.2259 18.2917 19.56 17.4689 20.3828C16.6461 21.2057 15.312 21.2057 14.4891 20.3828C13.6663 19.56 13.6663 18.2259 14.4891 17.4031C15.312 16.5803 16.6461 16.5803 17.4689 17.4031Z"
        fill="none"
        id={shapeId}
        strokeWidth="1.2"
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  } else if (iconType === 'normal') {
    return (
      <path
        d="M15.1167 28.45L18.05 23.3667M10.8332 27.5L8.33319 23.3333M15.1167 11.55L18.05 16.6333M25.8333 12.5L22.5 16.6667M29.75 20H23.9M31.6665 23.3333L29.1665 28.3333M22.7588 17.2412C24.2825 18.7649 24.2825 21.2353 22.7588 22.7589C21.2351 24.2826 18.7647 24.2826 17.2411 22.7589C15.7174 21.2352 15.7174 18.7648 17.2411 17.2412C18.7648 15.7175 21.2352 15.7175 22.7588 17.2412ZM35.7482 17.5168C37.1196 18.8882 37.1196 21.1117 35.7482 22.4831C34.3768 23.8545 32.1533 23.8545 30.7819 22.4831C29.4105 21.1117 29.4105 18.8882 30.7819 17.5168C32.1533 16.1454 34.3768 16.1454 35.7482 17.5168ZM9.21812 17.5168C10.5895 18.8882 10.5895 21.1117 9.21812 22.4831C7.84673 23.8545 5.62327 23.8545 4.25187 22.4831C2.88048 21.1117 2.88048 18.8882 4.25187 17.5168C5.62326 16.1454 7.84672 16.1454 9.21812 17.5168ZM29.1148 6.02854C30.4862 7.39994 30.4862 9.62339 29.1148 10.9948C27.7434 12.3662 25.52 12.3662 24.1486 10.9948C22.7772 9.6234 22.7772 7.39994 24.1486 6.02854C25.52 4.65715 27.7434 4.65715 29.1148 6.02854ZM15.8515 29.0052C17.2228 30.3765 17.2228 32.6 15.8515 33.9714C14.4801 35.3428 12.2566 35.3428 10.8852 33.9714C9.51381 32.6 9.51381 30.3766 10.8852 29.0052C12.2566 27.6338 14.4801 27.6338 15.8515 29.0052ZM15.8515 6.02854C17.2228 7.39994 17.2228 9.62339 15.8515 10.9948C14.4801 12.3662 12.2566 12.3662 10.8852 10.9948C9.51381 9.6234 9.51381 7.39994 10.8852 6.02854C12.2566 4.65715 14.4801 4.65715 15.8515 6.02854ZM29.1148 29.0052C30.4862 30.3765 30.4862 32.6 29.1148 33.9714C27.7434 35.3428 25.52 35.3428 24.1486 33.9714C22.7772 32.6 22.7772 30.3766 24.1486 29.0052C25.52 27.6338 27.7434 27.6338 29.1148 29.0052Z"
        fill="none"
        id={shapeId}
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  } else {
    return (
      <path
        d="M30.2333 56.9L36.1 46.7333M21.6664 55L16.6664 46.6667M30.2333 23.1L36.1 33.2667M51.6667 25L45 33.3333M59.5 40H47.8M63.333 46.6667L58.333 56.6667M45.5177 34.4823C48.5651 37.5297 48.5651 42.4705 45.5177 45.5179C42.4703 48.5653 37.5295 48.5653 34.4821 45.5179C31.4347 42.4705 31.4347 37.5297 34.4821 34.4823C37.5295 31.4349 42.4703 31.4349 45.5177 34.4823ZM71.4963 35.0337C74.2391 37.7764 74.2391 42.2234 71.4963 44.9662C68.7536 47.7089 64.3066 47.7089 61.5638 44.9662C58.8211 42.2234 58.8211 37.7765 61.5638 35.0337C64.3066 32.2909 68.7535 32.2909 71.4963 35.0337ZM18.4362 35.0337C21.179 37.7764 21.179 42.2234 18.4362 44.9662C15.6935 47.7089 11.2465 47.7089 8.50374 44.9662C5.76096 42.2234 5.76096 37.7765 8.50374 35.0337C11.2465 32.2909 15.6934 32.2909 18.4362 35.0337ZM58.2297 12.0571C60.9725 14.7999 60.9725 19.2468 58.2297 21.9896C55.4869 24.7324 51.04 24.7324 48.2972 21.9896C45.5544 19.2468 45.5544 14.7999 48.2972 12.0571C51.04 9.3143 55.4869 9.3143 58.2297 12.0571ZM31.7029 58.0103C34.4457 60.7531 34.4457 65.2 31.7029 67.9428C28.9601 70.6856 24.5132 70.6856 21.7704 67.9428C19.0276 65.2 19.0276 60.7531 21.7704 58.0103C24.5132 55.2675 28.9601 55.2675 31.7029 58.0103ZM31.7029 12.0571C34.4457 14.7999 34.4457 19.2468 31.7029 21.9896C28.9601 24.7324 24.5132 24.7324 21.7704 21.9896C19.0276 19.2468 19.0276 14.7999 21.7704 12.0571C24.5132 9.3143 28.9601 9.3143 31.7029 12.0571ZM58.2297 58.0103C60.9725 60.7531 60.9725 65.2 58.2297 67.9428C55.4869 70.6856 51.04 70.6856 48.2972 67.9428C45.5544 65.2 45.5544 60.7531 48.2972 58.0103C51.04 55.2675 55.4869 55.2675 58.2297 58.0103Z"
        fill="none"
        id={shapeId}
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  }
};

export const DefiIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_defiCurrentColorGradient`;
  const newColorId = `${prefix}_defiNewColorGradient`;
  const shapeId = `${prefix}_defiShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.grapeColors?.PLUS_2,
    },
  };
  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.grapeColors?.PLUS_3,
      stop: theme.palette.grapeColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox={viewBoxes[iconType]}>
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>

          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { StrapiImage } from '../StrapiImage';
import type { PersonProps } from './Person.types';

const PREFIX = 'Person';

const classes = {
  name: `${PREFIX}-name`,
  personWrapper: `${PREFIX}-personWrapper`,
};

const Root = styled('div')<Partial<PersonProps>>(
  ({ theme: { spacing, breakpoints }, type }) => ({
    [`& .${classes.name}`]: {
      fontSize: type === 'normal' ? '1.8rem' : '1.4rem',
    },

    [`&.${classes.personWrapper}`]: {
      display: 'grid',
      gap: spacing(3),
      gridAutoFlow: 'column',
      alignItems: 'center',
      justifyContent: 'start',

      [breakpoints.only('xs')]: {
        gridAutoFlow: 'row',
        placeContent: 'center',
      },
    },
  })
);

const Image = styled('img')({
  objectFit: 'cover',
  borderRadius: '50%',
});

export const Person: React.FC<PersonProps> = ({
  name,
  position,
  image,
  type = 'normal',
}) => {
  const getImageSize = () => (type === 'normal' ? 174 : 64);
  const imageSize = {
    width: getImageSize(),
    height: getImageSize(),
  };

  return (
    <Root type={type} className={classes.personWrapper}>
      <div>
        {typeof image === 'string' ? (
          <Image alt="person" src={image} {...imageSize} />
        ) : (
          <StrapiImage image={image} {...imageSize} />
        )}
      </div>
      <div>
        <Typography variant="h3" component="h4" className={classes.name}>
          {name}
        </Typography>
        {position && <Typography variant="caption">{position}</Typography>}
      </div>
    </Root>
  );
};

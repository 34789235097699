import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { NavLink } from '../../components/NavLink';
import { colors } from '../../theme/default/color';
import type {
  FooterLinksSectionProps,
  FooterSectionProps,
  IconTextProps,
} from './Footer.types';

const PREFIX = 'FooterSection';

const classes = {
  footerSectionWrapper: `${PREFIX}-footerSectionWrapper`,
  footerSectionContent: `${PREFIX}-footerSectionContent`,
  iconTextWrapper: `${PREFIX}-iconTextWrapper`,
};

const StyledGrid = styled(Grid)(({ theme: { breakpoints } }) => ({
  [`&.${classes.footerSectionWrapper}`]: {
    [breakpoints.down('md')]: {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },

  [`& .${classes.footerSectionContent}`]: {
    fontSize: '1.2rem',
    fontFamily: 'Axia Black',
    fontWeight: 900,
    gap: '1rem',
    color: colors.white,
    '& a': {
      color: colors.white,
      fontSize: '1.2rem',
    },
    '& p, & li, & ul': {
      margin: '0.2rem 0',
      padding: 0,
    },

    [breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },

  [`& .${classes.iconTextWrapper}`]: {
    [breakpoints.down('md')]: {
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
}));

export const FooterSection: React.FC<FooterSectionProps> = ({
  heading,
  children,
  vertical,
}) => {
  return (
    <StyledGrid
      id="FooterSection"
      className={classes.footerSectionWrapper}
      item
      container
      direction="column"
      spacing={2}>
      <Grid item>
        <Typography
          fontSize={['1.3rem', '1.4rem']}
          color={colors.white}
          variant="h3"
          component="p">
          {heading}
        </Typography>
      </Grid>

      <Grid
        id="FooterSectionChildren"
        className={classes.footerSectionContent}
        item
        direction={`${vertical ? 'row' : 'column'}`}
        container>
        {children}
      </Grid>
    </StyledGrid>
  );
};

export const FooterLinksSection: React.FC<FooterLinksSectionProps> = ({
  heading,
  linksList,
}) => {
  return (
    <FooterSection heading={heading}>
      <List>
        {linksList.map((menuItem) => (
          <ListItem key={menuItem.to}>
            <NavLink aria-label={menuItem.label} to={menuItem.to}>
              {menuItem.label}
            </NavLink>
          </ListItem>
        ))}
      </List>
    </FooterSection>
  );
};

export const IconText: React.FC<IconTextProps> = ({ icon, alt, children }) => {
  return (
    <Grid
      id="IconText"
      className={classes.iconTextWrapper}
      container
      spacing={2}>
      <Grid item>
        <img alt={alt} src={icon} width={24} height={24} />
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

import { Stack, Typography, styled } from '@mui/material';

import { Section } from '../Section';

export const FooterSection = styled(Section)(() => ({
  position: 'relative',
}));

export const StyledWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  padding: theme.spacing(15, 0),

  gap: theme.utils.fluidSize({ minSize: 40, maxSize: 160 }),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(12, 0),
  },
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(5),
    flexDirection: 'column-reverse',
    padding: theme.spacing(9, 0),
  },
  [theme.breakpoints.up('xl')]: {
    gap: theme.spacing(20),
  },
}));

export const AboutUsStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(2),
  },
}));

export const SubmenusStack = styled(Stack)(({ theme }) => ({
  flex: 1,
  flexDirection: 'row',
  flexWrap: 'wrap',
  minHeight: theme.spacing(43),
  gap: theme.utils.fluidSize({ minSize: 24, maxSize: 72 }),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(3),
    justifyContent: 'space-between',
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(3),
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.up('xl')]: {
    gap: theme.spacing(9),
  },
}));

export const ClutchBadgesStack = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: `calc(${theme.spacing(2)} + 360px)`,
  height: '120px',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: theme.spacing(1),

  [theme.breakpoints.up('xl')]: {
    width: `calc(${theme.spacing(1)} + 200px)`,
    height: '230px',

    '& > :nth-child(even)': {
      position: 'absolute',
      top: '102px',
      left: `calc(${theme.spacing(0.5)} + 60px)`,
    },
  },
}));

export const StyledImage = styled(`img`)(({ theme }) => ({
  width: '120px',

  [theme.breakpoints.down('sm')]: {
    width: '100px',
  },
}));

export const ColumnStack = styled(Stack)({
  justifyContent: 'space-between',
});

export const SingleSubmenuStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.5),
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  marginBottom: theme.spacing(1),
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.SECONDARY,
}));

export const AboutUsText = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
}));

export const AboutUsInfoWrapper = styled(Stack)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
}));

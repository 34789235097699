import { Box, Stack, styled, Typography } from '@mui/material';
import { Button } from '../Button';

export const StyledStack = styled(Stack)(({ theme }) => ({
  overflow: 'hidden',
  flexFlow: 'column nowrap',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  maxWidth: '282px',
  height: '474px',
  padding: theme.spacing(4),
  borderTop: `1px solid ${theme.palette.colorGrey?.shade20}`,
  cursor: 'default',
  background: theme.palette.colorBackground?.dark,
  ...theme.mixins.defaultTransition,
  [theme.breakpoints.up('md')]: {
    '&:hover': {
      background: theme.palette.colorWhite,
      borderTop: `1px solid ${theme.palette.colorWhite}`,
      '& .MuiTypography-h6': {
        color: theme.palette.colorLink,
      },
      '& .MuiBox-root': {
        background: theme.palette.colorLink,
      },
      '& .MuiButton-root': {
        opacity: 1,
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    borderTop: `1px solid ${theme.palette.colorWhite}`,
    background: theme.palette.colorWhite,
  },
}));
export const StyledTextWrapper = styled(Stack)(({ theme }) => ({
  ...theme.mixins.flexColumnStartStart,
}));
export const StyledIcon = styled(Box)(({ theme }) => ({
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.colorBrand?.primary,
  borderRadius: '32px',
  ...theme.mixins.defaultTransition,
}));
export const StyledHeadline = styled(Typography)(({ theme }) => ({
  color: theme.palette.colorBrand?.primary,
  ...theme.mixins.defaultTransition,
}));
export const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colorGrey?.primary,
}));
export const StyledButton = styled(Button)(({ theme }) => ({
  opacity: 0,
  transform: 'translateX(-20px)',
  ...theme.mixins.defaultTransition,
}));

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import type { HeadlineProps } from './Headline.types';

const StyledHeadline = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'smallerOnXs',
})<HeadlineProps>(({ theme, smallerOnXs }) => ({
  position: 'relative',
  display: 'inline-block',
  width: '100%',

  [theme.breakpoints.only('xs')]: {
    fontSize: smallerOnXs ? '2rem' : '',
  },

  '&::after': {
    content: '""',
    display: 'block',
    width: '100%',
    height: '3px',
    background: '#4E54DA',
    position: 'absolute',
    left: 'calc(-100% - 24px)',
    bottom: '50%',
    [theme.breakpoints.only('xs')]: {
      left: 'calc(-100% - 10px)',
    },
  },
}));

export const Headline: React.FC<HeadlineProps> = ({
  headline,
  smallerOnXs,
}) => {
  return (
    <StyledHeadline smallerOnXs={smallerOnXs} variant="h2">
      {headline}
    </StyledHeadline>
  );
};

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { CustomSvgIconsProps, SvgPathProps } from '../../CustomSvgIcons.types';
import { useTheme } from '@mui/material';
import { SvgPath } from '../../SvgPath/SvgPath';

export const GasIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_gasIconCurrentColorGradient`;
  const newColorId = `${prefix}_gasIconNewColorGradient`;
  const shapeId = `${prefix}_gasIconShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';

  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.purpleColors?.PLUS_3,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;

  return (
    <SvgIcon {...props} viewBox="0 0 61 60">
      {solidColor ? (
        <SvgPath
          d="M19.4167 37.5H29.4167M9.41675 52.5V12.5C9.41675 11.1739 9.94353 9.90215 10.8812 8.96447C11.8189 8.02678 13.0907 7.5 14.4167 7.5H34.4167C35.7428 7.5 37.0146 8.02678 37.9523 8.96447C38.89 9.90215 39.4167 11.1739 39.4167 12.5V52.5M49.4167 12.5L52.9517 16.035C53.8895 16.9725 54.4165 18.244 54.4167 19.57V43.75C54.4167 44.7446 54.0217 45.6984 53.3184 46.4016C52.6151 47.1049 51.6613 47.5 50.6667 47.5C49.6722 47.5 48.7184 47.1049 48.0151 46.4016C47.3118 45.6984 46.9167 44.7446 46.9167 43.75V40C46.9167 39.337 46.6534 38.7011 46.1845 38.2322C45.7157 37.7634 45.0798 37.5 44.4167 37.5H39.4167M54.4167 30H49.7492C49.3948 29.9999 49.0445 29.9244 48.7215 29.7786C48.3984 29.6328 48.1101 29.42 47.8756 29.1543C47.641 28.8885 47.4657 28.576 47.3612 28.2373C47.2566 27.8987 47.2253 27.5417 47.2692 27.19L47.8942 22.19C47.9698 21.5855 48.2635 21.0293 48.7201 20.6261C49.1768 20.2228 49.765 20.0002 50.3742 20H54.4167M6.91675 52.5H41.9167M16.9167 17.5H31.9167V27.5H16.9167V17.5Z"
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              d="M19.4167 37.5H29.4167M9.41675 52.5V12.5C9.41675 11.1739 9.94353 9.90215 10.8812 8.96447C11.8189 8.02678 13.0907 7.5 14.4167 7.5H34.4167C35.7428 7.5 37.0146 8.02678 37.9523 8.96447C38.89 9.90215 39.4167 11.1739 39.4167 12.5V52.5M49.4167 12.5L52.9517 16.035C53.8895 16.9725 54.4165 18.244 54.4167 19.57V43.75C54.4167 44.7446 54.0217 45.6984 53.3184 46.4016C52.6151 47.1049 51.6613 47.5 50.6667 47.5C49.6722 47.5 48.7184 47.1049 48.0151 46.4016C47.3118 45.6984 46.9167 44.7446 46.9167 43.75V40C46.9167 39.337 46.6534 38.7011 46.1845 38.2322C45.7157 37.7634 45.0798 37.5 44.4167 37.5H39.4167M54.4167 30H49.7492C49.3948 29.9999 49.0445 29.9244 48.7215 29.7786C48.3984 29.6328 48.1101 29.42 47.8756 29.1543C47.641 28.8885 47.4657 28.576 47.3612 28.2373C47.2566 27.8987 47.2253 27.5417 47.2692 27.19L47.8942 22.19C47.9698 21.5855 48.2635 21.0293 48.7201 20.6261C49.1768 20.2228 49.765 20.0002 50.3742 20H54.4167M6.91675 52.5H41.9167M16.9167 17.5H31.9167V27.5H16.9167V17.5Z"
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

import type { StackProps } from '@mui/material';
import { useTheme } from '@mui/material';
import React from 'react';
import { Section, MenuItem, MenuItemIconLink } from '../index';
import { data } from './data.mock';
import {
  StyledDivider,
  StyledStack,
  StyledWrapper,
  StyledMenuBoxIcon,
  StyledLinkWrapper,
} from './styles';

const Link: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const theme = useTheme();

  if (theme.utils.isMobile()) {
    return (
      <StyledLinkWrapper>
        <MenuItem sx={{ fontSize: '1rem' }} to="/services">
          {children}
        </MenuItem>
      </StyledLinkWrapper>
    );
  } else {
    return (
      <StyledLinkWrapper>
        <MenuItemIconLink to="/services">{children}</MenuItemIconLink>
      </StyledLinkWrapper>
    );
  }
};

export const ServicesSubmenu = ({ ...props }: StackProps) => {
  const theme = useTheme();
  const isMobile = theme.utils.isMobile();
  return (
    <Section backgroundColor="unset" width={isMobile ? 'full' : 'small'}>
      <StyledStack {...props}>
        <Link>All Services</Link>
        <StyledDivider />
        <StyledWrapper>
          {data.map((item, index) => (
            <StyledMenuBoxIcon
              key={index}
              iconIdle={item.icon}
              iconHover={item.iconHover}
              link={item.link}
              headline={item.title}
              text={item.text}
            />
          ))}
        </StyledWrapper>
      </StyledStack>
    </Section>
  );
};

import { combineReducers } from 'redux';

import errorMsgReducer from './reducers/errorMsg';

const rootReducer = combineReducers({
  errorMsg: errorMsgReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;

import { useTheme } from '@mui/material';
import React from 'react';
import { Section } from '../index';
import type { CareersSubmenuProps } from './CareersSubmenu.types';
import { data } from './data.mock';
import {
  StyledStack,
  StyledDivider,
  StyledMenuBoxBigIcon,
  StyledWrapper,
  StyledText,
} from './styles';

export const CareersSubmenu = ({ ...props }: CareersSubmenuProps) => {
  const theme = useTheme();
  return (
    <Section
      backgroundColor="unset"
      width={theme.utils.isMobile() ? 'full' : 'small'}>
      <StyledStack {...props}>
        <StyledText variant="buttonSmall">Careers</StyledText>
        <StyledDivider />
        <StyledWrapper>
          {data.map((item, index) => (
            <StyledMenuBoxBigIcon key={index} {...item} />
          ))}
        </StyledWrapper>
      </StyledStack>
    </Section>
  );
};

import type { Components } from '@mui/material';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import { breakpoints as breakpointsValues } from '../default/breakpoints';
import { spacing } from '../default/spacing';
import { colors } from './palette';

const breakpoints = createBreakpoints(breakpointsValues);

export const overrides: Components = {
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontSize: '3.3rem',
        fontWeight: 'bold',
        lineHeight: '1.13',
        fontFamily: 'Axia Black',
        marginBottom: spacing * 2,

        [breakpoints.only('xs')]: {
          fontSize: '2.4rem',
          lineHeight: '2.75rem',
        },
      },
      h2: {
        fontSize: '2.4rem',
        lineHeight: '1.13',
        fontFamily: 'Axia Black',
        fontWeight: 'bold',
        color: colors.black,
        [breakpoints.only('xs')]: {
          fontSize: '2.4rem',
          lineHeight: '2.75rem',
        },
        margin: '50px auto',
      },
      h3: {
        fontSize: '1.3rem',
        fontFamily: 'Axia Black',
        color: colors.black,
      },
      h4: {
        fontSize: '1.25rem',
        color: colors.black,
        fontFamily: 'Axia Black',
      },
      h5: {
        fontSize: '1.25rem',
        color: colors.black,
        fontFamily: 'Axia Black',
      },
      h6: {
        fontSize: '1.25rem',
        color: colors.black,
        fontFamily: 'Axia Black',
      },
      body1: {
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        letterSpacing: '0.03em',
        color: colors.darkGray,
      },
      body2: {
        fontSize: '0.94rem',
        lineHeight: '1.25rem',
        letterSpacing: '0.03em',
        color: colors.darkGray,
      },
      subtitle1: {
        fontSize: '0.94rem',
        fontWeight: 600,
        color: colors.black,
      },
      caption: {
        fontSize: '0.75rem',
        textTransform: 'uppercase',
        color: colors.greyBoldText,
        fontWeight: 'bold',
        lineHeight: '1.125rem',
        letterSpacing: '0.2em',
        fontFamily: 'Axia Black',
      },
      overline: {
        fontSize: '36px',
        fontWeight: 'bold',
        fontFamily: 'Axia Black, sans-serif',
        [breakpoints.only('xs')]: {
          fontSize: '28px',
        },
        color: colors.black,
        margin: '50px auto',
        padding: '20px',
        whiteSpace: 'break-spaces',
        position: 'relative !important' as 'relative',
        display: 'table',
        textTransform: 'none',
        lineHeight: '1.2',
        '&:after': {
          content: "''",
          height: '2px',
          width: '60%',
          background: colors.blue,
          position: 'absolute',
          left: 'calc(50% - 30%)',
          bottom: '-5px',
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: colors.pink,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      containedSecondary: {
        boxSizing: 'border-box',
        backgroundColor: colors.navyBlue,
        minWidth: '176px',
        height: '40px',
        textTransform: 'none',
        fontFamily: 'Axia Black',
        lineHeight: '20px',
        fontSize: '15px',
        boxShadow: 'none',
        fontWeight: 'bold',
        padding: '18px 42px',
        borderRadius: '28px',
        transition: '0.8s',
        '&:hover, &:focus': {
          boxSizing: 'border-box',
          background: colors.white + '!important',
          color: colors.buttonBlue,
          boxShadow: `inset 0px 0px 0px 2px ${colors.buttonBlue}`,
          transition: '0.8s',
        },
        [breakpoints.only('xs')]: {
          width: '100%',
        },
      },
      outlinedSecondary: {
        background: colors.white + '!important',
        boxSizing: 'border-box',
        textTransform: 'none',
        fontFamily: 'Axia Black',
        lineHeight: '20px',
        fontSize: '20px',
        boxShadow: `inset 0px 0px 0px 2px ${colors.buttonBlue}`,
        padding: '18px 42px',
        borderRadius: '28px',
        transition: '0.8s',
        border: 'none',
        color: colors.buttonBlue,
        '&:hover, &:focus': {
          boxSizing: 'border-box',
          background: `transparent linear-gradient(97deg, ${colors.buttonBlue} 0%, ${colors.buttonRed} 100%) 0% 0% no-repeat padding-box !important`,
          boxShadow: 'none',
          transition: '0.8s',
          border: 'none',
          color: colors.white,
        },
        [breakpoints.only('xs')]: {
          width: '100%',
        },
      },
      containedPrimary: {
        background: `transparent linear-gradient(120deg, #2E56F3 0%, #A839AC 48.44%, #FB267C 100%) 0% 0% no-repeat padding-box !important`,
        fontSize: '14px',
        fontFamily: 'Axia Black',
        letterSpacing: '0.1em',
        padding: '0 3rem',
        fontWeight: 'bold',
        height: '48px',
        borderRadius: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    },
  },
  MuiPaper: {
    defaultProps: { square: true },
    styleOverrides: {
      elevation1: {
        borderRadius: '16px',
        boxShadow: '0px 24px 48px rgba(0, 0, 0, 0.25)',
      },
      elevation2: {
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
        '&$error fieldset$notchedOutline': {
          borderColor: colors.pink,
        },
        '&$focused fieldset$notchedOutline': {
          borderColor: colors.violet,
        },
      },
      notchedOutline: {
        '&$error fieldset': {
          borderColor: colors.pink,
        },
      },
    },
  },
  MuiMobileStepper: {
    styleOverrides: {
      root: {
        backgroundColor: 'unset',
      },
      dot: {
        backgroundColor: colors.violet,
      },
      dots: { margin: 'auto' },
      dotActive: { backgroundColor: colors.pink },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: colors.paleBlue,
        fontFamily: 'OpenSansBold',
        fontSize: '10px',
        textTransform: 'uppercase',
        color: colors.buttonBlue,
        margin: '16px 16px 0 0',
      },
    },
  },
  // Due to nextjs fonts are located in each packages in /public folder

  MuiCssBaseline: {
    styleOverrides: `
        @font-face {
      font-family: Anodina Extra Bold;
      font-style: normal;
      font-display: swap;
      font-weight: 800;
      src:
        local('Anodina-ExtraBold'),
        url(/fonts/Anodina-ExtraBold.otf)
     }
    @font-face {
      font-family: Inter;
      font-style: normal;
      font-display: swap;
      font-weight: 400;
      src:
        local('Inter-Regular'),
        url(/fonts/Inter-Regular.otf)
     }
    @font-face {
      font-family: Inter Bold;
      font-style: normal;
      font-display: swap;
      font-weight: 700;
      src:
        local('Inter-Bold'),
        url(/fonts/Inter-Bold.otf)
     }
    @font-face {
      font-family: Inter Semi Bold;
      font-style: normal;
      font-display: swap;
      font-weight: 600;
      src:
        local('Inter-SemiBold'),
        url(/fonts/Inter-SemiBold.otf)
     }
    @font-face {
      font-family: Axia Black;
      font-style: normal;
      font-display: swap;
      font-weight: 400;
      src:
        local('AxiaBlack'),
        local('AxiaBlack-Regular'),
        url(/fonts/AxiaBlack-Regular.ttf)
      }

    @font-face {
      font-family: OpenSansExtraBold;
      font-style: normal;
      font-display: swap;
      font-weight: 800;
      src:
        local('OpenSansExtraBold'),
        url(/fonts/OpenSans-ExtraBold.ttf)
     }

    @font-face {
      font-family: OpenSansBold;
      font-style: normal;
      font-display: swap;
      font-weight: 600;
      src:
        local('OpenSansBold'),
        url(/fonts/OpenSans-SemiBold.ttf),
      }

    @font-face {
      font-family: Open Sans;
      font-style: normal;
      font-display: swap;
      font-weight: 400;
      src:
        local('OpenSans'),
        url(/fonts/OpenSans-Regular.ttf)
     }
      body {
        font-family: Open Sans;
        background: #ffffff;
        margin: 0;
        padding: 0;
        font-size: 18px;
      }
      html: {
        scroll-behavior: auto
      }
    `,
  },
};

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { CustomSvgIconsProps, SvgPathProps } from '../../CustomSvgIcons.types';
import { useTheme } from '@mui/material';
import { SvgPath } from '../../SvgPath/SvgPath';

export const ShieldIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_shieldIconCurrentColorGradient`;
  const newColorId = `${prefix}_shieldIconNewColorGradient`;
  const shapeId = `${prefix}_shieldIconShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';

  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.purpleColors?.PLUS_3,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;

  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      {solidColor ? (
        <SvgPath
          d="M22.6667 19.1666L19.3351 22.4999L17.3334 20.4999M33.3334 21.6999C33.3334 27.9933 25.5917 32.6666 21.9001 34.5416C21.313 34.8417 20.6631 34.9984 20.0038 34.999C19.3445 34.9995 18.6944 34.844 18.1067 34.5449C14.4167 32.6716 6.66675 27.9866 6.66675 21.7016V12.6149C6.67285 12.2037 6.82902 11.8088 7.10588 11.5046C7.38274 11.2005 7.76121 11.0079 8.17008 10.9633C11.7069 10.6946 15.0978 9.44057 17.9584 7.34327C18.5485 6.90415 19.2645 6.66699 20.0001 6.66699C20.7357 6.66699 21.4516 6.90415 22.0417 7.34327C24.9023 9.44057 28.2932 10.6946 31.8301 10.9633C32.239 11.0079 32.6174 11.2005 32.8943 11.5046C33.1711 11.8088 33.3273 12.2037 33.3334 12.6149V21.6999Z"
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              d="M22.6667 19.1666L19.3351 22.4999L17.3334 20.4999M33.3334 21.6999C33.3334 27.9933 25.5917 32.6666 21.9001 34.5416C21.313 34.8417 20.6631 34.9984 20.0038 34.999C19.3445 34.9995 18.6944 34.844 18.1067 34.5449C14.4167 32.6716 6.66675 27.9866 6.66675 21.7016V12.6149C6.67285 12.2037 6.82902 11.8088 7.10588 11.5046C7.38274 11.2005 7.76121 11.0079 8.17008 10.9633C11.7069 10.6946 15.0978 9.44057 17.9584 7.34327C18.5485 6.90415 19.2645 6.66699 20.0001 6.66699C20.7357 6.66699 21.4516 6.90415 22.0417 7.34327C24.9023 9.44057 28.2932 10.6946 31.8301 10.9633C32.239 11.0079 32.6174 11.2005 32.8943 11.5046C33.1711 11.8088 33.3273 12.2037 33.3334 12.6149V21.6999Z"
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

import { Stack, styled, Typography } from '@mui/material';

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down('sm')]: {},
}));
export const StyledWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(16, 0, 12, 0),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(12, 0, 8, 0),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(6, 0, 4, 0),
  },
}));
export const StyledLogoWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(4),
  },
}));
export const StyledHeadline = styled(Typography)(({ theme }) => ({
  maxWidth: '584px',
  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}));
export const StyledImage = styled(`img`)(({ theme }) => ({
  width: '140px',
  height: '40px',
  margin: theme.spacing(2, 1.5),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(2, 1),
    width: '105px',
    height: '30px',
  },
}));

export const colors = {
  white: '#ffffff',
  black: '#101131',
  darkGray: '#686868',
  lightGray: '#FAFAFA',
  darkBlue: '#001A70',
  paleBlue: '#E0E6FC',
  pink: '#F9277F',
  violet: '#2F57F4',
  navyBlue: '#01196F',
  blue: '#2B57F5',
  buttonBlue: '#4E54DA',
  buttonRed: '#C5448F',
  backgroundLightBlue: '#F2F3F8',
  testimonialBlue: '#001A70',
  errorRed: '#f44336',
  dividerGray: '#D1D1D5',
  lightBlue: '#2E56F3',
  greyBoldText: '#5E5D6D',
} as const;

export const palette = {
  primary: {
    main: colors.darkBlue,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.pink,
    contrastText: colors.white,
  },
  text: {
    primary: colors.darkBlue, // not sure about this
  },
  type: 'light',
};

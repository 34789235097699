import { styled } from '@mui/material/styles';
import TypoGraphy from '@mui/material/Typography';
import React from 'react';
import { colors } from '../../theme/default/color';
import { getCmsOptimizedUrl } from '../../utils/getCmsOptimizedUrl';
import type { AuthorProps } from './Author.types';

const PREFIX = 'Author';

const classes = {
  root: `${PREFIX}-root`,
  name: `${PREFIX}-name`,
  authorImg: `${PREFIX}-authorImg`,
  author: `${PREFIX}-author`,
  position: `${PREFIX}-position`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    display: 'inline-block',
  },

  [`& .${classes.name}`]: {
    float: 'left',
    paddingTop: '5px',
  },

  [`& .${classes.authorImg}`]: {
    borderRadius: '50%',
    objectFit: 'cover',
    border: 'solid 0px ' + colors.blue,
    objectPosition: 'top',
    marginRight: '30px !important',
    display: 'inline-block',
    float: 'left',
  },

  [`& .${classes.author}`]: {
    padding: '0px !important',
    display: 'block !important',
  },

  [`& .${classes.position}`]: {
    fontSize: '12px !important',
    display: 'block !important',
    padding: '0px !important',
  },
}));

export const Author: React.FC<AuthorProps> = ({ author }) => {
  if (!author) return null;

  return (
    <Root className={classes.root}>
      {author.attributes?.Avatar && (
        <img
          className={classes.authorImg}
          src={
            author.attributes.Avatar.data?.attributes?.url
              ? getCmsOptimizedUrl({
                  url: author.attributes.Avatar.data?.attributes?.url,
                  width: 120,
                })
              : ''
          }
          alt={author.attributes.Name}
          width="60px"
          height="60px"
        />
      )}
      <div className={classes.name}>
        <TypoGraphy className={classes.author} variant="h5" component="h6">
          {author.attributes?.Name}
        </TypoGraphy>
        <TypoGraphy className={classes.position}>
          {author.attributes?.Position}
        </TypoGraphy>
      </div>
      <div style={{ clear: 'both' }} />
    </Root>
  );
};

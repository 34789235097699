import { Stack, styled, Typography } from '@mui/material';
import { Button } from '../../components/AlgaeTheme/Button';

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  padding: theme.spacing(8, 0),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(8, 0),
  },
}));
export const StyledButton = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-start',
  marginTop: theme.spacing(2),

  '&.MuiButton-contained': {
    '&:hover': {
      backgroundColor: '#5678FF',
    },
  },

  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(0),
  },
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(8),
  },
  [theme.breakpoints.down('sm')]: {
    alignSelf: 'unset',
    width: '100%',
  },
}));
export const StyledHeadline = styled(Typography)(({ theme }) => ({
  maxWidth: '524px',
  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}));
export const StyledTextWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginTop: theme.spacing(4),
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));
export const StyledText = styled(Typography)(({ theme }) => ({
  width: '584px',
  color: theme.palette.colorWhite,
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(3),
    width: 'auto',
  },
}));
export const StyledImage = styled(`img`)(({ theme: { breakpoints } }) => ({
  maxWidth: '100%',
  [breakpoints.up('lg')]: {
    maxwidth: '1980px',
  },
}));

import React, { useState } from 'react';
import downArrow from '../../../assets/svg/AlgeaTheme/arrow-down-lightblue.svg';
import rightArrow from '../../../assets/svg/AlgeaTheme/arrow-right-lightblue.svg';
import { generateArrowAnimation } from '../utils/arrowAnimationStyles';
import type { ArrowButtonProps } from './ArrowButton.types';
import { ArrowWrapper, StyledIcon } from './styles';

export const ArrowButton = ({
  direction,
  isHover,
  ...props
}: ArrowButtonProps) => {
  const [hover, setHover] = useState(isHover);

  const {
    arrowHiddenMouseOff,
    arrowHiddenMouseOn,
    arrowVisibleMouseOff,
    arrowVisibleMouseOn,
  } = generateArrowAnimation(direction);

  const handleHover = (value: boolean) => {
    if (isHover === undefined) {
      setHover(value);
    }
  };

  return (
    <ArrowWrapper
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      {...props}>
      <StyledIcon
        src={direction === 'right' ? rightArrow : downArrow}
        sx={hover || isHover ? arrowVisibleMouseOn : arrowVisibleMouseOff}
      />
      <StyledIcon
        src={direction === 'right' ? rightArrow : downArrow}
        sx={hover || isHover ? arrowHiddenMouseOn : arrowHiddenMouseOff}
      />
    </ArrowWrapper>
  );
};

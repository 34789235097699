import Grid from '@mui/material/Grid';
import React from 'react';
import { Tile } from '../Tile';
import type { TilesCollectionProps } from './TilesCollection.types';

export const TilesCollection: React.FC<TilesCollectionProps> = ({ model }) => {
  return (
    <Grid container spacing={5}>
      {model?.attributes?.tiles?.data.map((tile) => (
        <Tile tile={tile} key={tile.id} />
      ))}
    </Grid>
  );
};

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import quote from '../../../../../src/assets/svg/quote2.svg';
import type { CaseStudyTestimonialInlineProps } from './CaseStudyTestimonialInline.types';

const PREFIX = 'CaseStudyTestimonialInline';

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  contentContainer: `${PREFIX}-contentContainer`,
  content: `${PREFIX}-content`,
  authorContainer: `${PREFIX}-authorContainer`,
  author: `${PREFIX}-author`,
  authorName: `${PREFIX}-authorName`,
  authorPosition: `${PREFIX}-authorPosition`,
};
const StyledCaseStudyTestimonialWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [`& .${classes.image}`]: {
    paddingLeft: theme.spacing(3),
    '& img': {
      width: 64,
      height: 64,
      borderRadius: 32,
      objectFit: 'cover',
    },
  },

  [`& .${classes.contentContainer}`]: {
    backgroundImage: `url(${quote})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '40px 40px',
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  [`& .${classes.content}`]: {
    paddingTop: '48px',
    flex: 1,
  },

  [`& .${classes.authorContainer}`]: {
    display: 'flex',
  },

  [`& .${classes.author}`]: {
    alignSelf: 'center',
    fontWeight: 'bold',
    paddingLeft: theme.spacing(1),
  },

  [`& .${classes.authorName}`]: {
    flex: 0,
  },

  [`& .${classes.authorPosition}`]: {
    flex: 0,
  },
}));

export const CaseStudyTestimonialInline: React.FC<
  CaseStudyTestimonialInlineProps
> = ({ model }) => {
  return (
    <StyledCaseStudyTestimonialWrapper>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <Typography variant="body2">{model.attributes?.content}</Typography>
        </div>
      </div>
      <div className={classes.authorContainer}>
        <div className={classes.image}>
          <img
            alt="author of testimonial"
            src={
              model.attributes?.author?.data?.attributes?.Avatar?.data
                ?.attributes?.url
            }
          />
        </div>
        <div className={classes.author}>
          <div className={classes.authorName}>
            {model.attributes?.author?.data?.attributes?.Name}
          </div>
          <div className={classes.authorPosition}>
            {model.attributes?.author?.data?.attributes?.Position}
          </div>
        </div>
      </div>
    </StyledCaseStudyTestimonialWrapper>
  );
};

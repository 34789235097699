import { TeamIcon, StudentIcon } from '../Icons';
import type { SubmenuBlockProps } from '../SubmenuBlock/SubmenuBlock.types';

export const careersSubmenuData: {
  blocks: SubmenuBlockProps[];
} = {
  blocks: [
    {
      name: 'Join Our Team',
      caption: 'We are always looking for new talents. Join Rumble Fish!',
      icon: TeamIcon,
      linkTo: '/careers',
    },
    // {
    //   name: 'Blockchain Training Hub',
    //   caption:
    //     'A course designed by Marek Kowalski, CTO at Rumble Fish. Learn Blockchain and get paid for it!',
    //   icon: StudentIcon,
    //   linkTo: '/blockchainhub',
    // },
  ],
};

import React from 'react';
import { FaqIssue, Section, SectionHeader } from '../index';
import type { FaqProps } from './Faq.types';

export const Faq: React.FC<FaqProps> = ({ data }) => {
  return (
    <Section theme="gray">
      <SectionHeader title="Frequently asked questions " />
      <div>
        {data.map((faqIsssue) => (
          <FaqIssue key={faqIsssue.question} {...faqIsssue} />
        ))}
      </div>
    </Section>
  );
};

import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';
import { viewBoxes } from './CustomSvgIcons.types';

const SvgPath = ({
  iconType,
  shapeId,
  solidColor,
}: SvgPathProps): ReactJSXElement => {
  if (iconType === 'bold') {
    return (
      <path
        d="M14 17L14.5 21M10 17L9.5 21M8.143 21H15.857M12 7V6M12 7H11.404C10.629 7 10 7.629 10 8.404C10 9.048 10.438 9.61 11.062 9.768L12.937 10.238C13.562 10.395 14 10.956 14 11.6C14 12.375 13.372 13.004 12.596 13.004L12 13.003M12 7H12.5C13.033 7 13.474 7.292 13.734 7.714M12 13.003V14M12 13.003L11.5 13.004C10.965 13.004 10.523 12.71 10.264 12.286M18.429 17H5.571C4.151 17 3 15.849 3 14.429V5.571C3 4.151 4.151 3 5.571 3H18.428C19.849 3 21 4.151 21 5.571V14.428C21 15.849 19.849 17 18.429 17Z"
        strokeWidth="1.2"
        fill="none"
        id={shapeId}
        strokeMiterlimit="10"
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  } else if (iconType === 'normal') {
    return (
      <path
        d="M23.3333 28.3333L24.1667 35M16.6667 28.3333L15.8333 35M13.5717 35H26.4283M20 11.6667V10M20 11.6667H19.0067C17.715 11.6667 16.6667 12.715 16.6667 14.0067C16.6667 15.08 17.3967 16.0167 18.4367 16.28L21.5617 17.0633C22.6033 17.325 23.3333 18.26 23.3333 19.3333C23.3333 20.625 22.2867 21.6733 20.9933 21.6733L20 21.6717M20 11.6667H20.8333C21.7217 11.6667 22.4567 12.1533 22.89 12.8567M20 21.6717V23.3333M20 21.6717L19.1667 21.6733C18.275 21.6733 17.5383 21.1833 17.1067 20.4767M30.715 28.3333H9.285C6.91833 28.3333 5 26.415 5 24.0483V9.285C5 6.91833 6.91833 5 9.285 5H30.7133C33.0817 5 35 6.91833 35 9.285V24.0467C35 26.415 33.0817 28.3333 30.715 28.3333Z"
        strokeWidth="1.1"
        fill="none"
        id={shapeId}
        strokeMiterlimit="10"
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  } else {
    return (
      <path
        d="M46.6667 56.6667L48.3333 70M33.3333 56.6667L31.6667 70M27.1433 70H52.8567M40 23.3333V20M40 23.3333H38.0133C35.43 23.3333 33.3333 25.43 33.3333 28.0133C33.3333 30.16 34.7933 32.0333 36.8733 32.56L43.1233 34.1267C45.2067 34.65 46.6667 36.52 46.6667 38.6667C46.6667 41.25 44.5733 43.3467 41.9867 43.3467L40 43.3433M40 23.3333H41.6667C43.4433 23.3333 44.9133 24.3067 45.78 25.7133M40 43.3433V46.6667M40 43.3433L38.3333 43.3467C36.55 43.3467 35.0767 42.3667 34.2133 40.9533M61.43 56.6667H18.57C13.8367 56.6667 10 52.83 10 48.0967V18.57C10 13.8367 13.8367 10 18.57 10H61.4267C66.1633 10 70 13.8367 70 18.57V48.0933C70 52.83 66.1633 56.6667 61.43 56.6667Z"
        strokeWidth="1"
        fill="none"
        id={shapeId}
        strokeMiterlimit="10"
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  }
};

export const FintechIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_fintechCurrentColorGradient`;
  const newColorId = `${prefix}_fintechNewColorGradient`;
  const shapeId = `${prefix}_fintechShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.blueColors?.PLUS_2,
    },
  };
  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.blueColors?.PLUS_3,
      stop: theme.palette.blueColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox={viewBoxes[iconType]}>
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

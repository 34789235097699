import type { Components } from '@mui/material';

import { createBreakpoints } from '@mui/system';
import { breakpoints as breakpointsValues } from './breakpoints';
import { palette } from './palette';

const breakpoints = createBreakpoints(breakpointsValues);

const headingStyle = {
  fontFamily: 'Rajdhani',
  fontWeight: 600,
  fontStyle: 'normal',
  lineHeight: '120%',
  color: palette.colorBlack,
};

export const overrides: Components = {
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontFamily: 'inherit',
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      h1: {
        ...headingStyle,
        fontSize: '4.313rem',
        [breakpoints.down('sm')]: {
          fontSize: '3rem',
        },
      },
      h2: {
        ...headingStyle,
        fontSize: '3.438rem',
        [breakpoints.down('sm')]: {
          fontSize: '2.5rem',
        },
      },
      h3: {
        ...headingStyle,
        fontSize: '2.75rem',
        [breakpoints.down('sm')]: {
          fontSize: '2.063rem',
        },
      },
      h4: {
        ...headingStyle,
        fontSize: '2.188rem',
        [breakpoints.down('sm')]: {
          fontSize: '1.75rem',
        },
      },
      h5: {
        ...headingStyle,
        fontSize: '1.75rem',
        [breakpoints.down('sm')]: {
          fontSize: '1.438rem',
        },
      },
      h6: {
        ...headingStyle,
        fontSize: '1.438rem',
        [breakpoints.down('sm')]: {
          fontSize: '1.125rem',
        },
      },
      caption: {
        color: '#343A3F',
        fontFamily: 'Inter',
        fontWeight: '400',
        fontSize: '0.75rem',
        lineHeight: '150%',
      },
      overline: {
        ...headingStyle,
        textTransform: 'uppercase',
        fontSize: '0.75rem',
        letterSpacing: '0.1em',
      },
    },
  },
};

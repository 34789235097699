import React, { useContext, useState } from 'react';
import type { NavLinkProps } from './NavLink.types';
import { NextComponentsContext } from '../../../context/nextComponentsContext/nextComponentsContext';
import { useUniversalComponents } from '../../utils/useUniversalComponents';

export const NavLink: React.FC<NavLinkProps> = ({
  className,
  activeClassName,
  to,
  label,
  onClick,
  children,
}) => {
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const { Link } = useUniversalComponents();

  return (
    <Link
      href={to}
      passHref
      style={{ textDecoration: 'none' }}
      onFocus={onFocus}
      onBlur={onBlur}
      aria-label={label || 'MuiLinklabel'}
      className={focused ? activeClassName : className}
      onClick={onClick}>
      {children}
    </Link>
  );
};

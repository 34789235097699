import { Stack, styled } from '@mui/material';
export const AnimatedLogoWrapper = styled(Stack)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

export const AnimatedLogoTextImage = styled('img')(() => ({
  userSelect: 'none',
  WebkitTouchCallout: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  WebkitUserSelect: 'none',
  pointerEvents: 'none',
  userDrag: 'none',
  animation: 'spin 32s linear infinite ',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(-360deg)',
    },
    '100%': {
      transform: 'rotate(deg0)',
    },
  },
  position: 'absolute',
  width: '100%',
  height: '100%',
}));
export const AnimatedLogoFishesImage = styled('img')(() => ({
  userSelect: 'none',
  userDrag: 'none',
  pointerEvents: 'none',
  WebkitTouchCallout: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  WebkitUserSelect: 'none',
  animation: 'scale 4s linear infinite ',
  '@keyframes scale': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(0.92)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  position: 'absolute',
  width: '100%',
  height: '100%',
}));

import FacebookIcon from '../../assets/svg/facebook.svg';
import LinkedinIcon from '../../assets/svg/linkedIn.svg';
import TwitterIcon from '../../assets/svg/twitter.svg';
import BtcIcon from '../../assets/svg/icons/btc.svg';
import EthIcon from '../../assets/svg/icons/eth.svg';
import DaiIcon from '../../assets/svg/icons/dai.svg';

export const socialMediaList = [
  {
    label: 'facebook',
    to: 'https://www.facebook.com/rumblefishsoftwaredevelopment',
    imgSrc: FacebookIcon,
  },
  {
    label: 'twitter',
    to: 'https://twitter.com/rumblefishdev',
    imgSrc: TwitterIcon,
  },
  {
    label: 'linkedin',
    to: 'https://www.linkedin.com/company/rumblefishdev',
    imgSrc: LinkedinIcon,
  },
] as const;

export const coinsList = [
  { label: 'bitcoin', imgSrc: BtcIcon },
  { label: 'etherum', imgSrc: EthIcon },
  { label: 'dai', imgSrc: DaiIcon },
] as const;

export const servicesItemsList = {
  linksList: [
    {
      label: 'Custom Project Development',
      to: '/services/custom-project-development/',
    },
    {
      label: 'Workshops',
      to: '/services/workshops/',
    },
    {
      label: 'Audit and Consulting',
      to: '/services/audit-and-consulting/',
    },
    {
      label: 'Dedicated Teams and Specialists',
      to: '/services/dedicated-teams-and-specialists/',
    },
  ],
};

export const menuItemsList = {
  linksList: [
    {
      label: 'Home',
      to: '/',
    },
    {
      label: 'Services',
      to: '/services/',
    },
    {
      label: 'Case Studies',
      to: '/case-studies',
    },
    {
      label: 'Blog',
      to: '/blog',
    },
    {
      label: 'Team',
      to: '/team/',
    },
    {
      label: 'Careers',
      to: '/careers/',
    },
    {
      label: 'Contact',
      to: '/contact/',
    },
  ],
};

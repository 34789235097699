import type { IFieldsData } from '../../../hooks/useForm/types';

export type TContactFormKeys = {
  inputs: 'fullName' | 'email' | 'message';
  checkboxes: 'policy';
};

export const contactFormFields: IFieldsData<TContactFormKeys> = {
  fullName: {
    required: true,
    errorMessage: 'Full name is required',
    mockData: 'John Doe',
    id: 'fullName',
    type: 'input',
  },
  email: {
    regex: /^[\w.%+-]+@[A-Z\d.-]+\.[A-Z]{2,4}$/i,
    errorMessage: 'Invalid email',
    mockData: 'john.doe@gmail.com',
    id: 'email',
    type: 'input',
  },
  message: {
    required: true,
    errorMessage: 'Message is required',
    mockData: 'I would like to receive communication from the team.',
    id: 'message',
    type: 'input',
  },
  policy: {
    required: true,
    id: 'policy',
    type: 'checkbox',
  },
};

import { Checkbox, FormControlLabel, styled } from '@mui/material';

import tickIcon from '../../../assets/svg/tick.svg';

import { isDarkOrNavy } from '../../../utils/darkOrNavy';
import { CheckboxClasses } from './types';
export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  [`&.${CheckboxClasses.error}`]: {
    '& span': {
      color: `${theme.palette.colorBrand?.secondary} !important`,
    },
  },

  marginTop: theme.spacing(2),
  marginLeft: 0,
  alignItems: `baseline`,
  cursor: `default`,
  [`&.${CheckboxClasses.navy}`]: {
    '& span': {
      height: '16px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: `Inter`,
      fontStyle: 'normal',
      fontWeight: 350,
      fontSize: '14px',
      lineHeight: `150%`,
      letterSpacing: `-0.01em`,
      color: theme.palette.text.secondary,
      fontVariationSettings: 'slnt 0',
      alignSelf: `flex-start`,
    },
  },
  '& span': {
    letterSpacing: `0`,
    fontSize: '16px',
    color: theme.palette.colorGrey?.primary,
    fontFamily: `Inter`,
    lineHeight: `150%`,
    alignSelf: `flex-start`,
  },
  '& .Mui-disabled': {
    color: `${theme.palette.colorLines} !important`,
  },
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: theme.spacing(0, 1, 0, 0),
  cursor: `default`,
}));

type StyledCheckboxIconProps = {
  disabled: boolean;
};

export const StyledCheckboxIcon = styled(`span`)<StyledCheckboxIconProps>(
  ({ theme, disabled }) => ({
    borderRadius: `4px`,
    width: `24px`,
    height: `24px`,
    alignSelf: `flex-start`,
    cursor: `default !important`,
    ...theme.mixins.defaultTransition,

    'input ~ &': {
      // Apply default border.
      border: `1px solid ${theme.palette.colorLines}`,
    },
    'input:hover ~ &': {
      // Apply hover and disabled state border.
      borderColor: disabled
        ? theme.palette.colorLines
        : theme.palette.type === 'dark'
        ? theme.palette.colorWhite
        : theme.palette.colorBrand?.primary,
    },

    [`&.${CheckboxClasses.navy}`]: {
      width: `16px`,
      height: `16px`,
      background: theme.palette.colorWhite15,
      border: `1px solid ${theme.palette.colorWhite15}`,
      'input:hover ~ &': {
        border: `1px solid rgba(255, 255, 255, 0.65)`,
      },
    },
  })
);

export const StyledCheckboxIconChecked = styled(StyledCheckboxIcon)(
  ({ theme }) => ({
    width: theme.palette.type === 'navy' ? '16px' : '24px',
    height: theme.palette.type === 'navy' ? '16px' : '24px',
    backgroundImage: `url(${tickIcon})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: theme.palette.type === 'navy' ? `8px 10px` : '14px 10px',
    filter: isDarkOrNavy(theme)
      ? 'brightness(0) saturate(100%) invert(100%) sepia(11%) saturate(7500%) hue-rotate(179deg) brightness(106%) contrast(110%)'
      : 'none',
    backgroundPosition: `center`,
  })
);

import { Box, Stack, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import FacebookIcon from '../../../assets/svg/AlgeaTheme/facebook-white.svg';
import LinkedinIcon from '../../../assets/svg/AlgeaTheme/linkedin-white.svg';
import TwitterIcon from '../../../assets/svg/AlgeaTheme/twitter-white.svg';
import { isDarkOrNavy } from '../../../utils/darkOrNavy';
import { Section, Link } from '../index';
import { NextComponentsContext } from '../../../../context/nextComponentsContext/nextComponentsContext';
import type { FooterProps } from './Footer.types';
import {
  StyledStack,
  StyledHeading,
  StyledItem,
  StyledImg,
  LeftSideWrapper,
  RightSideWrapper,
  LeftContentWrapper,
  ContactWrapper,
  ContactDetailsWrapper,
  StyledClutchBox,
} from './styles';
import { useUniversalComponents } from '../../../utils/useUniversalComponents';

const Menu = () => {
  return (
    <LeftContentWrapper>
      <StyledHeading variant="overline">Menu</StyledHeading>
      <Link to="/">
        <StyledItem variant="caption">Home</StyledItem>
      </Link>
      <Link to="/services">
        <StyledItem variant="caption">Services</StyledItem>
      </Link>
      <Link to="/case-studies">
        <StyledItem variant="caption">Case Studies</StyledItem>
      </Link>
      <Link to="/blog">
        <StyledItem variant="caption">Blog</StyledItem>
      </Link>
      <Link to="/team">
        <StyledItem variant="caption">Team</StyledItem>
      </Link>
      <Link to="/careers">
        <StyledItem variant="caption">Careers</StyledItem>
      </Link>
      <Link to="/contact">
        <StyledItem variant="caption">Contact</StyledItem>
      </Link>
    </LeftContentWrapper>
  );
};

const ClutchReviews: React.FC = () => {
  const { Script } = useUniversalComponents();
  return (
    <StyledClutchBox>
      <Script src="https://widget.clutch.co/static/js/widget.js" />
      <div
        className="clutch-widget"
        data-url="https://widget.clutch.co"
        data-widget-type="2"
        data-height="45"
        data-nofollow="true"
        data-expandifr="true"
        data-theme="white"
        data-scale="100"
        data-clutchcompany-id="577137"
      />
    </StyledClutchBox>
  );
};

const Services: React.FC<{ boxWidth?: string }> = ({ boxWidth }) => {
  return (
    <LeftContentWrapper sx={{ width: boxWidth }}>
      <StyledHeading variant="overline">Services</StyledHeading>
      <Link to="/services/aws-cloud-solutions/">
        <StyledItem variant="caption">AWS Cloud Solutions </StyledItem>
      </Link>
      <Link to="/services/defi-development/">
        <StyledItem variant="caption">DEFI Development</StyledItem>
      </Link>
      <Link to="/services/blockchain-software-development/">
        <StyledItem variant="caption">Blockchain Development</StyledItem>
      </Link>
      <Link to="/startup/">
        <StyledItem variant="caption">IT Services for Startups</StyledItem>
      </Link>
      <Link to="/services/web-development/">
        <StyledItem variant="caption">Web Development</StyledItem>
      </Link>
      <Link to="/services/nft-development/">
        <StyledItem variant="caption">NFT Development</StyledItem>
      </Link>
      <Link to="/services/dedicated-development-teams/">
        <StyledItem variant="caption">Dedicated Development Teams</StyledItem>
      </Link>
      <Link to="/services/mobile-development/">
        <StyledItem variant="caption">Mobile Development</StyledItem>
      </Link>
      <Link to="/services/fintech-software-development/">
        <StyledItem variant="caption">Fintech Software Development</StyledItem>
      </Link>
      <Link to="/services/product-design/">
        <StyledItem variant="caption">UI/UX</StyledItem>
      </Link>
    </LeftContentWrapper>
  );
};

const Contact = () => {
  const theme = useTheme();
  const useIsDarkOrNavyMode = isDarkOrNavy(theme);
  return (
    <ContactWrapper>
      <StyledHeading variant="overline">Rumblefish sp z o.o.</StyledHeading>
      <ContactDetailsWrapper
        sx={{
          ['span']: {
            fontFamily: useIsDarkOrNavyMode ? 'Inter' : 'default',
          },
        }}>
        <Typography
          variant="caption"
          sx={{
            whiteSpace: 'pre-line',
            marginBottom: '24px',
          }}>
          {'Filipa Eisenberga 11/3 \n31-523 Kraków, Poland \nPL6772425725'}
        </Typography>
        <Stack>
          <Typography variant="caption">P: +48 601 265 364</Typography>
          <Typography variant="caption">E: hello@rumblefish.dev</Typography>
          <Box display={{ xs: 'none', sm: 'block' }}>
            <ClutchReviews />
          </Box>
        </Stack>
      </ContactDetailsWrapper>
      <Box display={{ sm: 'none' }}>
        <ClutchReviews />
      </Box>
    </ContactWrapper>
  );
};

const SocialMedia = () => {
  return (
    <>
      <Stack direction="row" alignItems="center">
        <Typography
          sx={{ width: '68px', marginTop: '-2px', opacity: 0.5 }}
          variant="overline">
          Follow us
        </Typography>
        <Link to="https://www.facebook.com/rumblefishsoftwaredevelopment">
          <StyledImg src={FacebookIcon} alt="facebook" />
        </Link>
        <Link to="https://www.linkedin.com/company/rumblefishdev/">
          <StyledImg src={LinkedinIcon} alt="linkedin" />
        </Link>
        <Link to="https://twitter.com/rumblefishdev">
          <StyledImg src={TwitterIcon} alt="twitter" />
        </Link>
      </Stack>
      <StyledItem variant="caption">
        Copyright © {new Date().getFullYear()} Rumblefish
      </StyledItem>
    </>
  );
};

export const Footer = ({ ...props }: FooterProps) => {
  const theme = useTheme();
  const useIsDarkOrNavyMode = isDarkOrNavy(theme);
  return (
    <Section
      width="small"
      positionRelativeOn
      backgroundColor={
        useIsDarkOrNavyMode ? 'transparent' : theme.palette.colorBrand?.primary
      }>
      <StyledStack {...props}>
        <LeftSideWrapper>
          <Contact />

          <LeftSideWrapper display={{ xs: 'none', sm: 'flex' }}>
            <Menu />
            <Services />
          </LeftSideWrapper>

          <Stack
            display={{ sm: 'none' }}
            flexDirection="row"
            justifyContent="space-between"
            width="100%">
            <Menu />
            <Services boxWidth="135px" />
          </Stack>
        </LeftSideWrapper>
        <RightSideWrapper>
          <SocialMedia />
        </RightSideWrapper>
      </StyledStack>
    </Section>
  );
};

import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';

const SvgPath = ({
  iconType,
  shapeId,
  solidColor,
}: SvgPathProps): ReactJSXElement => {
  if (iconType === 'bold') {
    return (
      <path
        d="M33.9091 33.5L30.5 26L27.4318 33.5M33.9091 33.5L35.5 37M33.9091 33.5H27.4318M26 37L27.4318 33.5M38 37V26M56 32C56 45.2548 45.2548 56 32 56C18.7452 56 8 45.2548 8 32C8 18.7452 18.7452 8 32 8C45.2548 8 56 18.7452 56 32Z"
        stroke={solidColor ? 'currentColor' : ''}
        fill="none"
        id={shapeId}
        strokeWidth="2.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  } else if (iconType === 'normal') {
    return (
      <path
        d="M33.9091 33.5L30.5 26L27.4318 33.5M33.9091 33.5L35.5 37M33.9091 33.5H27.4318M26 37L27.4318 33.5M38 37V26M56 32C56 45.2548 45.2548 56 32 56C18.7452 56 8 45.2548 8 32C8 18.7452 18.7452 8 32 8C45.2548 8 56 18.7452 56 32Z"
        stroke={solidColor ? 'currentColor' : ''}
        fill="none"
        id={shapeId}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  } else {
    return (
      <path
        d="M33.9091 33.5L30.5 26L27.4318 33.5M33.9091 33.5L35.5 37M33.9091 33.5H27.4318M26 37L27.4318 33.5M38 37V26M56 32C56 45.2548 45.2548 56 32 56C18.7452 56 8 45.2548 8 32C8 18.7452 18.7452 8 32 8C45.2548 8 56 18.7452 56 32Z"
        stroke={solidColor ? 'currentColor' : ''}
        fill="none"
        id={shapeId}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  }
};

export const AIIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_aiCurrentColorGradient`;
  const newColorId = `${prefix}_aiNewColorGradient`;
  const shapeId = `${prefix}_aiShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';

  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.purpleColors?.PLUS_3,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;

  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

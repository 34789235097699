import { Stack, Typography } from '@mui/material';
import { ArrowButton, Link } from '@rumblefish/ui/AlgeaTheme';
import React from 'react';
import type { MobileCaseBoxProps } from './MobileCaseBox.types';
import { StyledImage, StyledStack, StyledTextWrapper } from './styles';

export const MobileCaseBox = ({
  company,
  image,
  link,
  title,
  ...props
}: MobileCaseBoxProps) => (
  <StyledStack {...props}>
    <StyledImage src={image} />
    <StyledTextWrapper spacing={1}>
      <Typography variant="uppercase" sx={{ textTransform: 'none' }}>
        {company}
      </Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="heading4">{title}</Typography>
        <Link to={link}>
          <ArrowButton
            direction="right"
            sx={{ height: 48, width: 48, marginLeft: '8px' }}
          />
        </Link>
      </Stack>
    </StyledTextWrapper>
  </StyledStack>
);

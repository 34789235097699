import { Stack, styled, Typography } from '@mui/material';
import centerEllipse from '../../assets/png/fintechGuide/centerEllipse.png';
import chequered from '../../assets/png/fintechGuide/chequered.png';
import keepScrolling from '../../assets/png/fintechGuide/keep-scrolling-circle.png';
import leftEllipse from '../../assets/png/fintechGuide/leftEllipse.png';
import rightEllipse from '../../assets/png/fintechGuide/rightEllipse.png';
import waveVector from '../../assets/png/fintechGuide/waveVector.png';

export const Chequered = styled('div')(({ theme }) => ({
  ['&:before']: {
    content: "''",
    height: `113%`,
    width: `100%`,
    left: '0%',
    top: `0%`,
    position: 'absolute',
    backgroundImage: `url(${chequered})`,

    backgroundSize: `100% 65%`,
    backgroundRepeat: `no-repeat`,
    backgroundPositionX: `10%, 100%, 70%`,
    backgroundPositionY: `10%, 2%`,
    [theme.breakpoints.down('xl')]: {
      width: `100%`,
    },
    [theme.breakpoints.down('lg')]: {
      backgroundSize: `100% 55%`,
    },
    [theme.breakpoints.down('md')]: {
      backgroundSize: `auto 70%`,
    },
  },
}));

export const LeftEllipse = styled('div')(() => ({
  ['&:before']: {
    content: "''",
    height: `113%`,
    width: `100%`,
    left: '0%',
    top: `0%`,
    position: 'absolute',
    backgroundImage: `url(${leftEllipse})`,
    backgroundSize: `100% 100%, 100%, 100%`,
    backgroundRepeat: `no-repeat`,
    backgroundPositionX: `10%, 100%, 70%`,
    backgroundPositionY: `10%, 2%`,
  },
}));

export const RightEllipse = styled('div')(({ theme }) => ({
  ['&:before']: {
    content: "''",
    height: `140%`,
    width: `100%`,
    right: '0%',
    top: `0%`,
    position: 'absolute',
    backgroundImage: `url(${rightEllipse})`,
    backgroundSize: `90% 100%`,
    backgroundRepeat: `no-repeat`,
    backgroundPositionX: `300%`,
    backgroundPositionY: `0%`,

    [theme.breakpoints.down('lg')]: {
      height: `100%`,
      backgroundPositionX: `450%`,
    },
  },
}));

export const WaveVector = styled('div')(({ theme }) => ({
  ['&:before']: {
    content: "''",
    height: `113%`,
    width: `100%`,
    left: '0%',
    top: `100%`,
    position: 'absolute',
    backgroundImage: `url(${waveVector})`,

    backgroundSize: `100% 60%`,
    backgroundRepeat: `no-repeat`,
    backgroundPositionX: `10%, 100%, 70%`,
    backgroundPositionY: `10%, 2%`,
    [theme.breakpoints.down('xl')]: {
      width: `100%`,
    },
    [theme.breakpoints.down('lg')]: {
      backgroundSize: `100% 55%`,
    },

    [theme.breakpoints.down('sm')]: {
      backgroundSize: `250%, 100%`,
      backgroundPositionX: `50%, 0%, 0%`,
    },
  },
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(12, 0, 6, 0),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0),
    margin: theme.spacing(8, 0, 4, 0),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0),
    margin: theme.spacing(7, 0, 2, 0),
  },
}));

export const StyledDiversionStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.down('md')]: {
    gap: '1rem',
    flexDirection: 'column',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0),
    gap: '2rem',
  },
}));
export const StyledTextSpace = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  zIndex: 5,
  height: 'auto',
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    gap: theme.spacing(1),
  },
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
  userSelect: 'none',
  fontFamily: 'Rajdhani',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '100%',
  fontSize: '80px',
  letterSpacing: '-0.02em',
  background: 'linear-gradient(90deg, #FFFFFF 0%, #D9D9D9 90.34%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textAlign: 'left',
  zIndex: 10,
  [theme.breakpoints.down('lg')]: {
    fontSize: '60px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '60px',
    textAlign: 'center',
  },

  [theme.breakpoints.down('sm')]: {
    lineHeight: '90%',
    fontSize: '50px',
  },
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  userSelect: 'none',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '350',
  fontSize: '24px',
  lineHeight: '150%',
  letterSpacing: '-0.01em',
  color: 'rgba(255, 255, 255, 0.75)',
  fontVariationSettings: 'slnt 0',
  textAlign: 'left',
  [theme.breakpoints.down('lg')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
    textAlign: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    lineHeight: '100%',
    fontSize: '18px',
  },
}));
export const StyledKeepScrollingWrapper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const StyledKeepScrolling = styled(Stack)(({ theme }) => ({
  height: '143px',
  width: '143px',
  backgroundImage: `url(${keepScrolling})`,
  backgroundRepeat: 'no-repeat',

  [theme.breakpoints.down('md')]: {
    backgroundPositionX: `100%`,
  },
  [theme.breakpoints.down('sm')]: {
    backgroundSize: '90%',
  },
}));

export const StyledBooksImage = styled('img')(({ theme }) => ({
  marginTop: '-10%',
  width: '70%',
  zIndex: 0,
  [theme.breakpoints.down('lg')]: {
    width: '60%',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '0%',
    width: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const CenterEllipse = styled('div')(({ theme }) => ({
  ['&:before']: {
    content: "''",
    height: `133%`,
    width: `100%`,
    left: '0%',
    top: '70%',
    position: 'absolute',
    backgroundImage: `url(${centerEllipse})`,
    filter: 'brightness(1)',
    backgroundSize: `100% 100%, 100%, 100%`,
    backgroundRepeat: `no-repeat`,
    [theme.breakpoints.down('md')]: {
      width: `100%`,
      top: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      top: '75%',
    },
  },
}));

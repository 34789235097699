import Box from '@mui/material/Box';
import React from 'react';
import background from '../../assets/png/dot-bg.png';

export const DotBackground: React.FC = () => {
  return (
    <Box
      sx={{
        background: `url(${background})`,
        position: 'absolute',
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        transform: 'rotate(180deg)',
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: 'bottom',
        zIndex: 0,
      }}
    />
  );
};

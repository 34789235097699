import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';
import { CareersSubmenuType } from './CareersSubmenu.types';
import { Wrapper, StyledStack } from './styles';
import { SubmenuBlock } from '../SubmenuBlock';
import { careersSubmenuData } from './careersSubmenuData';
export const CareersSubmenu = ({
  ...props
}: CareersSubmenuType): ReactJSXElement => {
  return (
    <StyledStack>
      <Wrapper>
        {careersSubmenuData.blocks.map((block, index) => (
          <SubmenuBlock
            iconPrefix="careers"
            type="icon"
            key={index}
            name={block.name}
            caption={block.caption}
            icon={block.icon}
            linkTo={block.linkTo}
          />
        ))}
      </Wrapper>
    </StyledStack>
  );
};

import type { SvgIconProps } from '@mui/material/SvgIcon';

export interface CustomSvgIconsProps extends SvgIconProps {
  shouldbegradient?: boolean;
  transitionduration?: number;
  prefix?: string;
  solidColor?: boolean;
  iconType?: 'bold' | 'thin' | 'normal';
  gradientColor?: string;
}

export interface SvgPathProps extends SvgIconProps {
  iconType: 'bold' | 'thin' | 'normal';
  shapeId: string;
  solidColor: boolean | undefined;
  thinStrokeWidth?: number;
  normalStrokeWidth?: number;
  boldStrokeWidth?: number;
}
export const viewBoxes = {
  thin: '0 0 80 80',
  normal: '0 0 40 40',
  bold: '0 0 24 24',
};

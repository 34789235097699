import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import React from 'react';
import { colors } from '../../theme/default/color';
import type { FormFieldProps } from './FormField.types';

const PREFIX = 'FormField';

const classes = {
  formField: `${PREFIX}-formField`,
  labelRedStar: `${PREFIX}-labelRedStar`,
};

const Root = styled('div')(() => ({
  [`& .${classes.formField}`]: {
    marginBottom: '1rem',
    borderRadius: '8px',
    '& label': {
      fontSize: '14px',
    },
    '& input': {
      boxSizing: 'border-box',
      height: '48px',
    },
    '& .MuiOutlinedInput-root': {
      border: `1px solid ${colors.dividerGray}`,
      borderRadius: '8px',
      background: colors.white,
      '& fieldset': {
        borderColor: colors.lightgray,
      },
    },
  },

  [`& .${classes.labelRedStar}`]: {
    position: 'relative',
    '&:after': {
      top: '-4px',
      right: '-12px',
      position: 'absolute',
      content: "'*'",
      fontSize: '0.7rem',
      color: 'red',
    },
  },
}));

export const FormField: React.FC<FormFieldProps> = React.forwardRef(
  (
    {
      id,
      label,
      type = 'text',
      required = true,
      onBlur,
      onChange,
      value,
      invalid,
      rows = 1,
    },
    ref
  ) => {
    return (
      <Root>
        <Typography variant="caption">
          <label htmlFor={id}>
            <span className={clsx({ [classes.labelRedStar]: required })}>
              {label}
            </span>
            <TextField
              className={classes.formField}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              id={id}
              type={type}
              inputProps={{ 'data-testid': label, 'aria-invalid': invalid }}
              variant="outlined"
              fullWidth
              inputRef={ref}
              rows={rows}
              multiline={rows > 1}
            />
          </label>
        </Typography>
      </Root>
    );
  }
);

import { Stack, Typography } from '@mui/material';
import { palette } from '@rumblefish/ui/src/theme/algaeTheme/palette';
import React from 'react';

import { StyledUserImage, StyledWrapper, MobileSpan } from './styles';
import type { UserBannerProps } from './types';

export const UserBanner = ({
  image,
  name,
  positionName,
  dark,
  ...props
}: UserBannerProps) => {
  return (
    <Stack direction="row" alignItems="center" {...props}>
      <StyledUserImage src={image} alt="user image" />
      <StyledWrapper>
        <Typography
          sx={{
            color: dark ? palette.colorBlack : palette.colorWhite,
          }}
          variant="bodySmallBold"
          component="span">
          {name}
          <MobileSpan>{' - '}</MobileSpan>
        </Typography>
        <MobileSpan>&nbsp;</MobileSpan>
        <Typography
          sx={{
            color: dark ? palette.colorBlack : palette.colorWhite,
          }}
          variant="bodySmall"
          component="span">
          {' ' + positionName}
        </Typography>
      </StyledWrapper>
    </Stack>
  );
};

import { Container, styled } from '@mui/material';
import { sectionClasses } from './Section.types';

export const StyledRoot = styled(Container)(({ theme }) => ({
  [`&.${sectionClasses.full}`]: {
    maxWidth: '100%',
    width: '100%',
  },
  [`&.${sectionClasses.normal}`]: {
    maxWidth: `${theme.breakpoints.values.xl}px`,
    width: '90%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  [`&.${sectionClasses.small}`]: {
    maxWidth: '1230px',
    [theme.breakpoints.up('md')]: {
      width: '95%',
    },
  },

  [`&.${sectionClasses.mobilePadding}`]: {
    ...theme.mixins.mobilePadding(),
  },
}));

import type { Mixins } from '@mui/material/styles/createMixins';
import { createBreakpoints } from '@mui/system';
import { breakpoints as breakpointsValues } from '../default/breakpoints';
import { gradientsDark, gradientsLight, IGradientsMixins } from './gradients';
const breakpoints = createBreakpoints(breakpointsValues);

declare module '@mui/material/styles/createMixins' {
  interface Mixins extends IGradientsMixins {
    hoverTextLightBlue: CSSProperties;
    mobilePadding: (paddingY?: string) => CSSProperties;
    flexColumnStartStart: CSSProperties;
    defaultTransition: CSSProperties;
    companiesCarouselGradient: CSSProperties;
    shadowButtonMd: CSSProperties;
  }
}

const applyMobilePadding = (paddingY = '0px') => {
  return {
    [breakpoints.down('md')]: {
      padding: `${paddingY} 48px`,
    },
    [breakpoints.down('sm')]: {
      padding: `${paddingY} 24px`,
    },
  };
};

const mixinsTemplate: Mixins = {
  defaultTransition: {
    transition: 'all 0.3s ease-in-out',
  },
  //NEED TO CHANGE IT IN FUTURE
  hoverTextLightBlue: {
    transition: 'all 0.3s ease-in-out',
    color: '#2F57F4',
  },
  companiesCarouselGradient: {
    background:
      'linear-gradient(90deg, rgba(217, 217, 217, 0.00) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(217, 217, 217, 0.00) 100%)',
  },
  shadowButtonMd: {
    boxShadow: '0px 8px 24px 0px rgba(48, 98, 212, 0.25)',
  },
  mobilePadding: applyMobilePadding,
  flexColumnStartStart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
} as Mixins;

export const mixinsDark: Mixins = {
  ...mixinsTemplate,
  ...gradientsDark,
} as Mixins;
export const mixinsLight: Mixins = {
  ...mixinsTemplate,
  ...gradientsLight,
} as Mixins;

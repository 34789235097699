import { Stack, Typography, styled } from '@mui/material';

export const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  padding: theme.spacing(1.5),
  borderRadius: '8px',
  cursor: 'pointer',
  ...theme.mixins.defaultTransition,
  '&:hover': {
    background: theme.palette.colorBackground?.dark,
    '& .MuiTypography-root:last-of-type':
      theme.palette.type === 'light' && theme.mixins.hoverTextLightBlue,
  },
}));
export const StyledHeadline = styled(Typography)(({ theme }) => ({
  color: theme.palette.colorBrand?.primary,
  [theme.breakpoints.down('md')]: {
    color: theme.palette.colorGrey?.primary,
  },
}));
export const StyledLink = styled('a')(({ theme }) => ({
  width: '33%',
  textDecoration: 'none',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
export const StyledText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.colorGrey?.primary,
  ...theme.mixins.defaultTransition,
  [theme.breakpoints.down('md')]: {
    color: theme.palette.colorBrand?.primary,
    fontSize: '0.875rem',
  },
}));
export const StyledImage = styled(`img`)(() => ({
  alignSelf: 'flex-start',
  width: '64px',
  height: '64px',
  borderRadius: '6px',
}));
export const StyledTextWrapper = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    ...theme.mixins.flexColumnStartStart,
  },
}));

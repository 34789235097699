import React from 'react';
import { NavLink } from '../NavLink';
import { Button } from '../../components/Button';
import { NavigationButtonProps } from './NavigationButton.types';

export const NavigationButton: React.FC<NavigationButtonProps> = ({
  target,
  buttonText,
  className,
  variant = 'contained',
  color = 'secondary',
}) => {
  return (
    <NavLink exact to={target}>
      <Button className={className} variant={variant} color={color}>
        {buttonText}
      </Button>
    </NavLink>
  );
};

import { InputBase, InputLabel, styled } from '@mui/material';
import { inputFieldClasses } from './types';

export const StyledInput = styled(InputBase)(({ theme, error }) => ({
  [`&.${inputFieldClasses.outlined}`]: {
    '& .MuiInputBase-input': {
      background: theme.palette.colorWhite,
      border: `1px solid  ${
        error ? theme.palette.colorBrand?.secondary : theme.palette.colorLines
      }`,
      '&:hover': {
        border: `1px solid ${
          error
            ? theme.palette.colorBrand?.secondary
            : theme.palette.colorBrand?.primary
        }`,
      },
    },
  },

  [`&.${inputFieldClasses.filled}`]: {
    '& .MuiInputBase-input': {
      borderBottom:
        theme.palette.type === 'dark'
          ? 'none'
          : `1px solid ${theme.palette.colorLines}`,
      borderRadius: theme.palette.type === 'dark' ? 'none' : '0px',
    },
  },

  [`&.${inputFieldClasses.navy}`]: {
    '& .MuiInputBase-input': {
      background: theme.palette.colorWhite15,
      border: `1px solid  ${
        error ? theme.palette.colorBrand?.secondary : theme.palette.colorLines
      }`,
      color: theme.palette.text.primary,
      borderRadius: '16px',
      '&:hover': {
        border: `1px solid ${
          error
            ? theme.palette.colorBrand?.secondary
            : 'rgba(255, 255, 255, 0.65)'
        }`,
      },

      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 650,
      fontSize: '14px',
      lineHeight: '150%',
      letterSpacing: '-0.01em',
      fontVariationSettings: 'slnt 0',

      '&::placeholder': {
        opacity: 0.7,
      },
    },
    '& .Mui-disabled': {
      WebkitTextFillColor: theme.palette.text.primary,
    },
  },

  '& .MuiInputBase-input': {
    borderRadius: theme.palette.type === 'dark' ? '6px' : `4px`,
    position: 'relative',
    fontFamily: `Inter`,
    fontSize: '16px',
    fontWeight: theme.palette.type === 'dark' ? '400' : 'default',
    color:
      theme.palette.type === 'dark'
        ? theme.palette.colorWhite
        : theme.palette.colorBlack,
    background:
      theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'none',
    padding: theme.spacing(2),
    ...theme.mixins.defaultTransition,

    [theme.breakpoints.down(`md`)]: {
      minWidth: `unset`,
    },
    '&:-webkit-autofill ': {
      transition: ` background-color 600000s 0s, color 600000s 0s;`,
    },
    '&:-webkit-autofill:hover ': {
      transition: ` background-color 600000s 0s, color 600000s 0s;`,
    },
  },

  '& .Mui-disabled': {
    border: `1px solid ${theme.palette.colorLines}`,
    '&:hover': {
      border: `1px solid ${theme.palette.colorLines}`,
    },
  },
}));

export const StyledLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: `1rem`,
  top: '-12px',
  left: '-14px',
  textTransform: `uppercase`,
  fontWeight: 600,
  letterSpacing: `0.1em`,
  zIndex: 0,

  [`&.${inputFieldClasses.outlined}`]: {
    color: theme.palette.colorBrand?.primary,
    '&.Mui-focused': {
      color: theme.palette.colorBrand?.primary,
    },
  },
  [`&.${inputFieldClasses.filled}`]: {
    color:
      theme.palette.type === 'dark'
        ? theme.palette.colorWhite
        : theme.palette.colorGrey?.light,
  },

  [`&.${inputFieldClasses.navy}`]: {
    display: 'none',
  },

  [`& span`]: {
    color: theme.palette.colorBrand?.secondary,
  },
}));
export const StyledError = styled(InputLabel)(({ theme }) => ({
  fontSize: `14px`,
  position: `relative`,
  top: `12px`,
  left: `-14px`,
  overflow: 'visible',
  color: theme.palette.colorBrand?.secondary,
  textTransform: `uppercase`,
  fontFamily: `Inter`,
  letterSpacing: `0.1em`,
  '&.Mui-focused': {
    color: theme.palette.colorBrand?.secondary,
  },
}));

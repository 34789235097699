import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { CustomSvgIconsProps, SvgPathProps } from '../../CustomSvgIcons.types';
import { useTheme } from '@mui/material';
import { SvgPath } from '../../SvgPath/SvgPath';

export const MobileDevicesSyncIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_mobileDevicesSyncIconCurrentColorGradient`;
  const newColorId = `${prefix}_mobileDevicesSyncIconNewColorGradient`;
  const shapeId = `${prefix}_mobileDevicesSyncIconShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';

  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.purpleColors?.PLUS_3,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;

  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      {solidColor ? (
        <SvgPath
          d="M45 32.5V12.5C45 11.1739 44.4732 9.90215 43.5355 8.96447C42.5979 8.02678 41.3261 7.5 40 7.5H20C18.6739 7.5 17.4021 8.02678 16.4645 8.96447C15.5268 9.90215 15 11.1739 15 12.5V20M45 32.5L50 27.5M45 32.5L40 27.5M45 40V47.5C45 48.8261 44.4732 50.0979 43.5355 51.0355C42.5979 51.9732 41.3261 52.5 40 52.5H20C18.6739 52.5 17.4021 51.9732 16.4645 51.0355C15.5268 50.0979 15 48.8261 15 47.5V27.5M15 27.5L10 32.5M15 27.5L20 32.5M25 45H35M36.25 7.5V12.5C36.25 12.8315 36.1183 13.1495 35.8839 13.3839C35.6495 13.6183 35.3315 13.75 35 13.75H25C24.6685 13.75 24.3505 13.6183 24.1161 13.3839C23.8817 13.1495 23.75 12.8315 23.75 12.5V7.5"
          thinStrokeWidth={1.2}
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              d="M45 32.5V12.5C45 11.1739 44.4732 9.90215 43.5355 8.96447C42.5979 8.02678 41.3261 7.5 40 7.5H20C18.6739 7.5 17.4021 8.02678 16.4645 8.96447C15.5268 9.90215 15 11.1739 15 12.5V20M45 32.5L50 27.5M45 32.5L40 27.5M45 40V47.5C45 48.8261 44.4732 50.0979 43.5355 51.0355C42.5979 51.9732 41.3261 52.5 40 52.5H20C18.6739 52.5 17.4021 51.9732 16.4645 51.0355C15.5268 50.0979 15 48.8261 15 47.5V27.5M15 27.5L10 32.5M15 27.5L20 32.5M25 45H35M36.25 7.5V12.5C36.25 12.8315 36.1183 13.1495 35.8839 13.3839C35.6495 13.6183 35.3315 13.75 35 13.75H25C24.6685 13.75 24.3505 13.6183 24.1161 13.3839C23.8817 13.1495 23.75 12.8315 23.75 12.5V7.5"
              thinStrokeWidth={1.2}
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

import React from 'react';

export const EmailIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.93334 9.73325C8.65932 9.92087 8.33179 10.0144 8.00001 9.99992C7.66823 10.0144 7.3407 9.92087 7.06668 9.73325L9.8296e-06 5.93325V12.6666C9.8296e-06 13.197 0.210723 13.7057 0.585796 14.0808C0.960869 14.4559 1.46958 14.6666 2.00001 14.6666H14C14.5304 14.6666 15.0392 14.4559 15.4142 14.0808C15.7893 13.7057 16 13.197 16 12.6666V5.93325L8.93334 9.73325Z"
        fill="currentColor"
      />
      <path
        d="M14 1.33325H2.00001C1.46958 1.33325 0.960869 1.54397 0.585796 1.91904C0.210723 2.29411 9.8296e-06 2.80282 9.8296e-06 3.33325V3.99992C-0.00062718 4.12013 0.029707 4.23849 0.0880899 4.34358C0.146473 4.44867 0.230936 4.53695 0.333343 4.59992L7.66668 8.59992C7.76904 8.65381 7.8848 8.67696 8.00001 8.66659C8.11522 8.67696 8.23098 8.65381 8.33334 8.59992L15.6667 4.59992C15.7691 4.53695 15.8535 4.44867 15.9119 4.34358C15.9703 4.23849 16.0006 4.12013 16 3.99992V3.33325C16 2.80282 15.7893 2.29411 15.4142 1.91904C15.0392 1.54397 14.5304 1.33325 14 1.33325Z"
        fill="currentColor"
      />
    </svg>
  );
};

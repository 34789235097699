import React from 'react';
import type { CornerStickyButtonProps } from './CornerStickyButton.types';
import { StyledButton, StyledCircle, StyledMovableText } from './styles';

export const CornerStickyButton = (props: CornerStickyButtonProps) => {
  const pagesWithoutTelegram = ['/startup/'];
  const currentPathname = global.location?.pathname;
  const shouldDisplay = !pagesWithoutTelegram.includes(currentPathname);

  if (shouldDisplay) {
    return (
      <a target="_blank" rel="noreferrer" href={props.link}>
        <StyledButton
          animation={props.animation ?? ''}
          backgroundColor={props.backgroundColor ?? 'black'}>
          <StyledMovableText
            textColor={props.textColor ?? 'white'}
            variant="regular_100">
            {props.text}
          </StyledMovableText>
          <StyledCircle>
            <img alt="icon" src={props.backgroundImage} />
          </StyledCircle>
        </StyledButton>
      </a>
    );
  } else {
    return null;
  }
};

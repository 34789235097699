import { Icon, ListItemText, Typography } from '@mui/material';
import { Button } from '@rumblefish/ui/AlgeaTheme';
import React from 'react';
import arrowRight from '../../../assets/svg/AlgeaTheme/arrowRight.svg';
import { Link } from '../Link';
import type { ListComponentProps } from './ListComponent.types';
import {
  StyledAvatar,
  StyledList,
  StyledListItem,
  StyledStyledListItemAvatar,
  StyledTitleGrid,
  StyledUpTitle,
} from './styles';

const ArrRightIcon = (
  <Icon>
    <img alt="arrow to the right" src={arrowRight} width="20px" height="20px" />
  </Icon>
);

export const ListComponent = (props: ListComponentProps) => {
  return (
    <>
      <StyledUpTitle variant="uppercase">{props.upTitle ?? ''}</StyledUpTitle>
      <StyledTitleGrid>
        <Typography variant="heading3">{props.bigTitle}</Typography>
        <Typography className="description" variant="body2Big">
          {props.description}
        </Typography>
      </StyledTitleGrid>

      <StyledList
        sx={{
          gridTemplateColumns: props.threeColumns ? '1fr 1fr 1fr' : '1fr 1fr',
          display: {
            xs: props.mobileScroll ? 'inline-flex' : 'grid',
            lg: 'grid',
          },
          overflowX: {
            xs: props.mobileScroll ? 'auto' : 'visible',
            lg: 'visible',
          },
          marginTop: { xs: '50px' },
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}>
        {props.listItemsArr?.map((listItem, idx) => (
          <StyledListItem
            key={idx}
            sx={{
              display: 'flex',
              flexDirection: props.direction,
              alignItems: 'flex-start',
              borderTop: props.threeColumns ? '1px solid #ECEEF0' : 'none',
              paddingTop: props.threeColumns ? '40px' : 'none',
              flexShrink: props.mobileScroll ? '0' : '1',
              width: props.threeColumns
                ? { xs: '70%', sm: '40%', lg: '100%' }
                : 'auto',
              '& button, a': {
                marginTop: { lg: '20px' },
                paddingLeft: 0,
              },
            }}>
            <StyledStyledListItemAvatar
              sx={{
                marginBottom: props.direction === 'column' ? '5%' : 0,
              }}>
              <StyledAvatar
                noIconBorder={props.noIconBorder}
                key={idx}
                className="exception">
                <img
                  alt="List item icon"
                  src={props.globalIcon ?? listItem.icon}
                />
              </StyledAvatar>
            </StyledStyledListItemAvatar>
            <ListItemText
              primary={listItem.primaryText}
              secondary={listItem.secondaryText}
              primaryTypographyProps={{
                variant: 'heading6',
                style: { marginBottom: '18px' },
              }}
              secondaryTypographyProps={{ variant: 'body2Small' }}
            />
            {props.direction === 'column' && listItem.buttonLink && (
              <Link to={listItem.buttonLink}>
                <Button variant="text" endIcon={ArrRightIcon}>
                  Find out more
                </Button>
              </Link>
            )}
          </StyledListItem>
        ))}
      </StyledList>
    </>
  );
};

import { Stack, styled } from '@mui/material';

export const StyledExtendWrapper = styled(Stack)(({ theme }) => ({
  height: '100%',
  width: '100%',
  padding: theme.spacing(10, 0),
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  borderBottom: '1px solid #D9D9D926',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(6, 0),
  },
}));

export const ClientsWrapper = styled(Stack)(({ theme }) => ({
  maxWidth: '612px',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(3),
    justifyContent: 'center',
    maxWidth: '100%',
  },
}));
export const ClientsExtendedTextWrapper = styled(Stack)(({ theme }) => ({
  maxWidth: '484px',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '384px',
  },
}));
export const ClientImage = styled(`img`)(({ theme }) => ({
  // filter:
  //   'invert(7%) sepia(5%) saturate(100%) hue-rotate(167deg) brightness(93%) contrast(84%)',
  width: '140px',
  height: '40px',
  margin: theme.spacing(2, 0, 2, 4),
  [theme.breakpoints.down('md')]: {
    width: '98px',
    height: '28px',
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(2, 2),
  },
}));

import React, { useState } from 'react';
import arrowDownHover from '../../../assets/svg/AlgeaTheme/arrowDownHover.svg';
import arrowDownIdle from '../../../assets/svg/AlgeaTheme/arrowDownIdle.svg';
import { Link } from '../Link';
import type { MenuItemIconLinkProps } from './MenuItemIconLink.types';
import {
  StyledIcon,
  StyledIconWrapper,
  StyledStack,
  StyledTypography,
} from './styles';

export const MenuItemIconLink = ({
  to,
  children,
  noIcon,
  ...props
}: MenuItemIconLinkProps) => {
  const [hover, setHover] = useState(false);

  return (
    <Link to={to}>
      <StyledStack
        {...props}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <StyledTypography variant="buttonSmall">{children}</StyledTypography>
        <StyledIconWrapper>
          {!noIcon && (
            <>
              <StyledIcon
                src={arrowDownHover}
                alt=""
                sx={hover ? { opacity: 1 } : {}}
              />
              <StyledIcon
                src={arrowDownIdle}
                alt=""
                sx={!hover ? { opacity: 1 } : {}}
              />
            </>
          )}
        </StyledIconWrapper>
      </StyledStack>
    </Link>
  );
};

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { NextComponentsContext } from '../../../context/nextComponentsContext/nextComponentsContext';
import React, { useContext, useEffect, useState } from 'react';
import { colors } from '../../theme/default/color';
import type { FilterButtonProps } from './FilterButton.types';

const PREFIX = 'FilterButton';

const classes = {
  filterButtonInactive: `${PREFIX}-filterButtonInactive`,
  filterButtonActive: `${PREFIX}-filterButtonActive`,
};

const StyledButtonWrapper = styled('div')(() => ({
  [`& .${classes.filterButtonInactive}`]: {
    '&:hover, &:focus': {
      background: 'transparent' + '!important',
      boxShadow: `inset 0px 0px 0px 2px ${colors.newButtonBlue}`,
      color: colors.newButtonBlue + '!important',
    },
  },

  [`& .${classes.filterButtonActive}`]: {
    background: `transparent linear-gradient(97deg, ${colors.newButtonBlue} 0%, ${colors.newButtonRed} 100%) 0% 0% no-repeat padding-box !important`,
    boxShadow: 'none',
    color: colors.white + '!important',
  },
}));

export const FilterButton: React.FC<FilterButtonProps> = ({
  value,
  variant,
  color,
  className,
  labelAdditional,
  onClick,
}) => {
  const useRouter = useContext(NextComponentsContext)?.hooks?.useRouter;
  const router = useRouter ? useRouter() : undefined;
  const filter = (router?.query?.filter as string) || '';
  const [isSeletcted, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(value.trim().toLowerCase() === filter.trim().toLowerCase());
  }, [router, filter]);

  const onClickHandle = () => onClick(value);

  const currentClassName = isSeletcted
    ? classes.filterButtonActive
    : classes.filterButtonInactive;

  return (
    <StyledButtonWrapper>
      <Button
        variant={variant}
        color={color}
        aria-label={labelAdditional || ''}
        onClick={onClickHandle}
        className={clsx(className, currentClassName)}>
        {value}
      </Button>
    </StyledButtonWrapper>
  );
};

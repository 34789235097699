import type { Theme } from '@mui/material';
import { useTheme, Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ReactNode, useContext } from 'react';
import home from '../../assets/svg/icons/home.svg';
import { colors } from '../../theme/default/color';
import type { NavigatorProps } from './Navigator.types';
import { useUniversalComponents } from '../../utils/useUniversalComponents';

const PREFIX = 'Navigator';

const classes = {
  root: `${PREFIX}-root`,
  homeIcon: `${PREFIX}-homeIcon`,
  backSlash: `${PREFIX}-backSlash`,
  home: `${PREFIX}-home`,
  container: `${PREFIX}-container`,
  NavLink: `${PREFIX}-NavLink`,
  blueColor: `${PREFIX}-blueColor`,
  sub: `${PREFIX}-sub`,
};
const Link = ({
  children,
  href,
  useClass,
}: {
  children: ReactNode;
  href: string;
  useClass?: boolean;
}) => {
  const { Link } = useUniversalComponents();

  return (
    <Link
      style={{ textDecoration: 'none' }}
      passHref
      href={href}
      className={useClass ? classes.NavLink : undefined}>
      {children}
    </Link>
  );
};
const NavigatorContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    top: '80px',
  },

  [`& .${classes.homeIcon}`]: {
    marginRight: '20px',
    display: 'inline',
  },

  [`& .${classes.backSlash}`]: {
    display: 'inline',
    margin: '0 0.3rem',
  },

  [`& .${classes.home}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '1rem',
    fontWeight: 'bold',
    color: colors.black,
    textTransform: 'uppercase',
    fontSize: '13px',
  },

  [`& .${classes.container}`]: {
    backgroundColor: colors.lightgray,
    height: '115px',
    padding: '10px',
    position: 'relative',
    zIndex: 10,
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },

  [`& .${classes.NavLink}`]: {
    textDecoration: 'none',
    color: colors.black,
  },

  [`& .${classes.blueColor}`]: {
    color: colors.blue,
  },

  [`& .${classes.sub}`]: {
    color: colors.blue,
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
    },
  },
}));

const OldNavigator: React.FC<NavigatorProps> = ({
  name,
  subname,
  backSlash,
  path,
  noBackground,
}) => (
  <NavigatorContainer>
    <Grid
      className={classes.container}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={
        noBackground
          ? { backgroundColor: 'transparent' }
          : { backgroundColor: colors.lightgray }
      }>
      <Grid
        item
        xs={12}
        sm={10}
        lg={10}
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="start">
        <Link href="/">
          <img
            className={classes.homeIcon}
            src={home}
            alt="home page icon"
            width="16"
            height="16"
          />
        </Link>
        <Typography component="div" className={classes.home} variant="h6">
          <Link href="/" useClass>
            HOME
          </Link>
          <Typography
            component="div"
            className={classes.backSlash}
            color="secondary">
            /
          </Typography>
          <Link href={`/${path}/`} useClass>
            {name}
          </Link>
          <Typography
            component="div"
            className={classes.backSlash}
            color="secondary">
            {backSlash}
          </Typography>
          <span className={classes.sub}>{subname}</span>
        </Typography>
      </Grid>
    </Grid>
  </NavigatorContainer>
);

const StyledLink = styled('span')(({ theme }) => ({
  fontWeight: '600',
  textDecoration: 'none',
  color: theme.palette.grey[400],
}));

const NewNavigator: React.FC<NavigatorProps> = ({
  name,
  subname,
  backSlash = '/',
  path,
}) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      sx={{
        gap: '16px',
        [theme.breakpoints.down('sm')]: {
          gap: '6px',
        },
        textTransform: 'uppercase',
        fontWeight: '600',
      }}>
      <Link href="/">
        <StyledLink>home</StyledLink>
      </Link>
      <Typography component="div" color="secondary">
        {backSlash}
      </Typography>
      <Link href={`/${path}/`}>
        <StyledLink>{name}</StyledLink>
      </Link>
      {subname && (
        <>
          <Typography component="div" color="secondary">
            {backSlash}
          </Typography>
          <Typography component="div" color={colors.blue} fontWeight="600">
            <span>{subname}</span>
          </Typography>
        </>
      )}
    </Box>
  );
};

export const Navigator: React.FC<NavigatorProps> = ({
  newDesign = false,
  ...navProps
}) => {
  return newDesign ? (
    <NewNavigator {...navProps} />
  ) : (
    <OldNavigator {...navProps} />
  );
};

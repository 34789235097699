import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '@rumblefish/ui';
import React from 'react';
import logo from '../../assets/png/logo-ribbons-60.png';
import type { RumblefishProps } from './RumblefishLogo.types';

const transition = '0.3s';
const logoFontStyle = {
  transition,
  fontSmooth: 'always',
  fontWeight: '900',
  fontFamily: 'Axia Black',
  textTransform: 'uppercase',
};

const StyledRibbon = styled('img')({
  transition: '0.3s',
  height: '100%',
  aspectRatio: '350 / 423',
});

export const RumblefishLogo = ({ isOnTop }: RumblefishProps) => (
  <Grid container wrap="nowrap" gap={1} alignItems="center">
    <Box
      sx={{
        transition,
        height: isOnTop ? '60px' : '50px',
      }}>
      <StyledRibbon src={logo} alt="rumble fish logo" />
    </Box>
    <Grid item container direction="column">
      <Typography
        sx={{
          ...logoFontStyle,
          color: colors.navyBlue,
          fontSize: isOnTop ? '1.5rem' : '1.4rem',
          marginBottom: '4px',
        }}>
        Rumblefish
      </Typography>
      <Typography
        sx={{
          ...logoFontStyle,
          color: colors.navyBlue,
          fontSize: '0.44rem',
          marginLeft: '2px',
          lineHeight: '1em',
        }}>
        Software development
      </Typography>
    </Grid>
  </Grid>
);

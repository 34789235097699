import type { Components } from '@mui/material';

export const overrides: Components = {
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontFamily: 'Satoshi',
      },
    },
  },
  MuiCssBaseline: {},
};

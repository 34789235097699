import animatedLogoTextImage from '../../../assets/svg/RumbleFish23Theme/animatedLogoTextImage.svg';
import animatedLogoFishesImage from '../../../assets/svg/RumbleFish23Theme/animatedLogoFishesImage.svg';
import React from 'react';
import { AnimatedLogoProps } from './AnimatedLogo.types';
import {
  AnimatedLogoFishesImage,
  AnimatedLogoTextImage,
  AnimatedLogoWrapper,
} from './styles';

export const AnimatedLogo = ({ variant, ...props }: AnimatedLogoProps) => {
  return (
    <AnimatedLogoWrapper {...props}>
      <AnimatedLogoTextImage
        draggable={false}
        src={animatedLogoTextImage}
        alt="AnimatedLogoTextImage"
      />
      <AnimatedLogoFishesImage
        alt="AnimatedLogoFishesImage"
        draggable={false}
        src={animatedLogoFishesImage}
      />
    </AnimatedLogoWrapper>
  );
};

import React from 'react';
import { NextLinkProps } from './NextLink.types';
import { useUniversalComponents } from '../../utils/useUniversalComponents';
export const NextLink = ({ href, children, sx = {} }: NextLinkProps) => {
  const { Link } = useUniversalComponents();

  return (
    <Link style={sx} passHref href={href}>
      {children}
    </Link>
  );
};

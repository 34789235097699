import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';

const SvgPath = ({ shapeId, solidColor }: SvgPathProps): ReactJSXElement => {
  return (
    <g id={shapeId}>
      <path
        d="M25.7324 26.8208C26.5533 27.7372 27.746 28.3139 29.0731 28.3139C31.5494 28.3139 33.5569 26.3064 33.5569 23.8302C33.5569 23.5761 33.5358 23.3269 33.4952 23.0844"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.5461 38.0295C36.5461 36.0475 35.7589 34.1467 34.3573 32.7453C32.9559 31.3439 31.0552 30.5566 29.0733 30.5566C26.4661 30.5566 23.6942 32.4631 22.8153 33.5374"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1043 3.65454H20.5075C20.1113 3.65454 19.7311 3.81201 19.4508 4.0923C19.1705 4.37258 19.013 4.75274 19.013 5.14913V16.8666C19.013 17.263 19.1705 17.6432 19.4508 17.9235C19.7311 18.2038 20.1113 18.3611 20.5075 18.3611H23.4967V22.0604C23.4955 22.1313 23.5144 22.2011 23.5513 22.2618C23.5882 22.3222 23.6416 22.3711 23.7052 22.4026C23.7689 22.4338 23.8402 22.4465 23.9107 22.4388C23.9813 22.4311 24.0482 22.4035 24.1036 22.3593L29.1014 18.3611H35.4535C35.8499 18.3611 36.23 18.2038 36.5103 17.9235C36.7907 17.6432 36.9482 17.263 36.9482 16.8666L36.948 5.14913C36.948 4.75274 36.7907 4.37258 36.5103 4.0923C36.23 3.81201 35.8499 3.65454 35.4535 3.65454H33.9207"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5334 2.91666L27.5273 4.15832"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.6388 14.1168L27.6327 15.6114"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3689 7.39106C31.3689 7.98564 31.1327 8.55587 30.7123 8.97631C30.2919 9.39675 29.7216 9.63294 29.127 9.63294H25.3906V5.14917H29.127C29.7216 5.14917 30.2919 5.38537 30.7123 5.80581C31.1327 6.22625 31.3689 6.79647 31.3689 7.39106Z"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3689 11.8749C31.3689 12.4695 31.1327 13.0397 30.7123 13.4601C30.2919 13.8806 29.7216 14.1168 29.127 14.1168H25.3906V9.633H29.127C29.7216 9.633 30.2919 9.8692 30.7123 10.2896C31.1327 10.7101 31.3689 11.2803 31.3689 11.8749Z"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6109 22.5775C17.4652 24.2375 15.5495 25.3254 13.3798 25.3254C9.87166 25.3254 7.02777 22.4814 7.02777 18.9735C7.02777 15.4653 9.87166 12.6214 13.3798 12.6214C13.9493 12.6214 14.5012 12.6963 15.0264 12.8369"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.91785 38.0293C2.91785 35.2546 4.0201 32.5935 5.98213 30.6315C7.94416 28.6694 10.6052 27.5673 13.38 27.5673C16.1547 27.5673 18.8158 28.6694 20.7778 30.6315C22.7399 32.5935 23.8421 35.2546 23.8421 38.0293"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  );
};

export const Web3Icon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_web3CurrentColorGradient`;
  const newColorId = `${prefix}_web3NewColorGradient`;
  const shapeId = `${prefix}_web3Shape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.redColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.redColors?.PLUS_3,
      stop: theme.palette.redColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

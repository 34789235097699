import { styled, Link } from '@mui/material';
import type { LinkProps } from '@mui/material';
export const StyledIconLink = styled(Link)(({ theme }) => ({
  ['img']: { width: '100%' },
  width: '12%',
  [theme.breakpoints.down('md')]: {
    width: '15%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '20%',
  },
})) as React.FC<LinkProps>;

import type {
  IFieldsData,
  TFormsKeys,
  TInput,
  TCheckbox,
  TStateInput,
  TStateCheckbox,
  IReturnedState,
} from './types';

export const extractKeysFromFields = (fields: IFieldsData<TFormsKeys>) => {
  return Object.keys(fields) as Array<
    TFormsKeys['inputs'] | TFormsKeys['checkboxes']
  >;
};

/**
 *
 * Takes form fields and map them into state that will be returned to the Form
 * @param fields Fields data defined in Forms components
 */

export const generateDefaultState = (fields: IFieldsData<TFormsKeys>) => {
  let state: IReturnedState<TFormsKeys> = {} as IReturnedState<TFormsKeys>;

  const keys = extractKeysFromFields(fields);

  keys.forEach((key) => {
    const fieldType = fields[key].type;

    if (fieldType === 'input') {
      state = {
        ...state,
        [key]: {
          text: '',
          errorMessage: '',
        },
      };
    }

    if (fieldType === 'checkbox') {
      state = {
        ...state,
        [key]: {
          checked: false,
          isError: false,
        },
      };
    }
  });

  return state;
};

export const isRequiredConditionNotFulfiled = <T extends keyof TFormsKeys>(
  field: TInput<TFormsKeys[T]> | TCheckbox<TFormsKeys[T]>,
  data: TStateInput | TStateCheckbox
) => {
  if ('text' in data) {
    return field.required && !data.text;
  }
  if ('checked' in data) {
    return field.required && !data.checked;
  }
};

/**
 * @important When its called on checkbox field it's always return false
 */
export const isRegexConditionNotFulfiled = <T extends keyof TFormsKeys>(
  field: TInput<TFormsKeys[T]> | TCheckbox<TFormsKeys[T]>,
  data: TStateInput | TStateCheckbox
) => {
  if ('text' in data && 'regex' in field) {
    return field.regex && !field.regex.test(data.text);
  }
  return false;
};

/**
 *
 * Function iterates over all fields and checks if any of them failed the required condition.
 *  Also sets error field depending on type of that field to it's coresponding error value based on above checks
 * @returns If any of the fields failed the required condition then returns true, otherwise returns false
 */
export const validateFields = (
  keys: Array<TFormsKeys['inputs'] | TFormsKeys['checkboxes']>,
  newFormData: IReturnedState<TFormsKeys>,
  fields: IFieldsData<TFormsKeys>
) => {
  let isError = false;

  keys.forEach((key) => {
    const field = fields[key];
    const newFormDataField = newFormData[key];

    const conditionFailed =
      isRequiredConditionNotFulfiled(field, newFormDataField) ||
      isRegexConditionNotFulfiled(field, newFormDataField);

    if ('errorMessage' in field && 'errorMessage' in newFormDataField) {
      newFormDataField.errorMessage = conditionFailed ? field.errorMessage : '';
    } else if ('isError' in newFormDataField) {
      newFormDataField.isError = conditionFailed as boolean;
    }

    // Ensure that when isError is already true, it won't be set to false
    isError = conditionFailed || isError;
  });

  return isError;
};

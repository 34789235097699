import { breakpoints } from './breakpoints';

export interface IFluidSize {
  minSize: number;
  maxSize: number;
  increase?: boolean;
  maxBp?: number;
  minBp?: number;
}
export const fluidSize = ({
  minSize,
  maxSize,
  increase,
  maxBp,
  minBp,
}: IFluidSize) => {
  const minBreakpoint = !minBp ? breakpoints.values?.sm : minBp;
  const maxBreakpoint = !maxBp ? breakpoints.values?.xl : maxBp;

  if (increase)
    return `calc(${maxSize}px - (${maxSize} - ${minSize}) * ((100vw - ${minBreakpoint}px) / (${maxBreakpoint} - ${minBreakpoint} )))`;
  else {
    return `calc(${minSize}px + (${maxSize} - ${minSize}) * ((100vw - ${minBreakpoint}px) / (${maxBreakpoint} - ${minBreakpoint} )))`;
  }
};
export const isMobile = () => {
  if (!breakpoints.values?.md) return false;
  return global.innerWidth <= breakpoints.values?.md;
};
export const isPhone = () => {
  if (!breakpoints.values?.sm) return false;
  return global.innerWidth <= breakpoints.values?.sm;
};

export const handleFetchErrors = (response: {
  ok: boolean;
  statusText: string;
}) => {
  if (!response.ok) throw Error(response.statusText);
  return response;
};

export const isWebkit = () => {
  if (typeof window !== 'undefined') {
    //@ts-ignore
    return typeof window.webkitConvertPointFromNodeToPage === 'function';
  } else return false;
};

export const getViewportHeight = () => {
  return global.innerHeight;
};

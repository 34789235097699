export const customMuiFields = {
  template: {
    normalSectionWidth: '70rem',
    narrowSectionWidth: '48rem',
    webHeaderHeight: '5rem',
    webHeaderHeightOnTop: '6.875rem',
    transitionOptions: 'all 0.2s',
  },
} as const;

type CustomMuiFields = typeof customMuiFields;

declare module '@mui/material/styles' {
  interface CustomTheme extends CustomMuiFields {}

  interface Theme extends CustomTheme {}
  interface DeprecatedThemeOptions extends CustomTheme {}
}

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/system/Box';
import React from 'react';
import quote from '../../assets/svg/quote3.svg';
import { colors } from '../../theme/default/color';
import { Person } from '../Person/Person';
import type { PersonCardProps } from './PersonCard.types';

export const PersonCard: React.FC<PersonCardProps> = ({
  name,
  image,
  content,
}) => {
  return (
    <Box
      sx={{ p: 3, background: colors.footerGrey }}
      display="flex"
      height="100%"
      gap={3}>
      <img alt="" src={quote} width={24} height={18} />
      <Grid container justifyContent="space-between" height="100%" gap={2}>
        <Typography
          sx={{ fontWeight: 800, fontFamily: 'Axia Black' }}
          variant="body1">
          {content}
        </Typography>
        <Person type="smaller" name={name} image={image} />
      </Grid>
    </Box>
  );
};

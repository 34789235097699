import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';
import { viewBoxes } from './CustomSvgIcons.types';

const SvgPath = ({
  iconType,
  shapeId,
  solidColor,
}: SvgPathProps): ReactJSXElement => {
  if (iconType === 'bold') {
    return (
      <path
        d="M8.27002 12.79L3.30002 7.82002C3.10791 7.62765 3 7.36689 3 7.09502C3 6.82315 3.10791 6.56239 3.30002 6.37002L6.37002 3.30002C6.56239 3.10791 6.82315 3 7.09502 3C7.36689 3 7.62765 3.10791 7.82002 3.30002L12.55 8.03002M15.97 11.45L20.7 16.18C20.8921 16.3724 21 16.6331 21 16.905C21 17.1769 20.8921 17.4376 20.7 17.63L17.63 20.7C17.4376 20.8921 17.1769 21 16.905 21C16.6331 21 16.3724 20.8921 16.18 20.7L11.21 15.73M6.78002 15.04C8.16702 12.7446 9.84782 10.64 11.7798 8.77979C15.8105 4.74904 19.8483 2.25165 20.7983 3.2017C21.7484 4.15175 19.251 8.1895 15.22 12.22C13.36 14.1522 11.2555 15.833 8.96002 17.22M6.96613 20.2882L4.24413 20.9687C4.07659 21.0106 3.90105 21.0084 3.73462 20.9623C3.56818 20.9162 3.41652 20.8278 3.2944 20.7056C3.17228 20.5835 3.08387 20.4319 3.03777 20.2654C2.99167 20.099 2.98946 19.9235 3.03134 19.7559L3.71351 17.0272C3.85854 16.4471 4.19364 15.9322 4.66537 15.5647C5.1371 15.1972 5.71829 14.9982 6.31628 14.9995H6.32323C7.03405 15.001 7.71522 15.2845 8.21719 15.7878C8.71916 16.291 9.00091 16.9729 9.00057 17.6837C9.0003 18.2825 8.79995 18.864 8.43135 19.3359C8.06275 19.8078 7.54703 20.143 6.96613 20.2882Z"
        id={shapeId}
        fill="none"
        strokeWidth="1.2"
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  } else if (iconType === 'normal') {
    return (
      <path
        d="M13.7834 21.3167L5.50003 13.0334C5.17984 12.7127 5 12.2781 5 11.825C5 11.3719 5.17984 10.9373 5.50003 10.6167L10.6167 5.50003C10.9373 5.17984 11.3719 5 11.825 5C12.2781 5 12.7127 5.17984 13.0334 5.50003L20.9167 13.3834M26.6167 19.0834L34.5 26.9667C34.8202 27.2873 35.0001 27.7219 35.0001 28.175C35.0001 28.6281 34.8202 29.0627 34.5 29.3834L29.3834 34.5C29.0627 34.8202 28.6281 35.0001 28.175 35.0001C27.7219 35.0001 27.2873 34.8202 26.9667 34.5L18.6834 26.2167M11.3 25.0667C13.6117 21.2409 16.413 17.7334 19.633 14.633C26.3509 7.91506 33.0805 3.75275 34.6639 5.33616C36.2473 6.91958 32.085 13.6492 25.3667 20.3667C22.2666 23.5869 18.7592 26.3884 14.9334 28.7M11.6102 33.8137L7.07356 34.9478C6.79431 35.0177 6.50175 35.014 6.22436 34.9371C5.94697 34.8603 5.6942 34.7129 5.49067 34.5094C5.28714 34.3059 5.13978 34.0531 5.06295 33.7757C4.98612 33.4983 4.98243 33.2058 5.05224 32.9265L6.18919 28.3787C6.4309 27.4118 6.9894 26.5537 7.77561 25.9412C8.56183 25.3286 9.53049 24.997 10.5271 24.9991H10.5387C11.7234 25.0016 12.8587 25.4741 13.6953 26.3129C14.5319 27.1517 15.0015 28.2882 15.001 29.4729C15.0005 30.4709 14.6666 31.4401 14.0522 32.2265C13.4379 33.013 12.5784 33.5716 11.6102 33.8137Z"
        id={shapeId}
        fill="none"
        strokeWidth="1.1"
        strokeLinecap="round"
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinejoin="round"
      />
    );
  } else {
    return (
      <path
        d="M27.5667 42.6333L11.0001 26.0667C10.3597 25.4254 10 24.5562 10 23.65C10 22.7438 10.3597 21.8746 11.0001 21.2333L21.2334 11C21.8746 10.3596 22.7438 9.99994 23.6501 9.99994C24.5563 9.99994 25.4255 10.3596 26.0667 11L41.8334 26.7667M53.2334 38.1667L69.0001 53.9333C69.6404 54.5746 70.0001 55.4438 70.0001 56.35C70.0001 57.2562 69.6404 58.1254 69.0001 58.7667L58.7667 69C58.1255 69.6404 57.2563 70.0001 56.3501 70.0001C55.4438 70.0001 54.5746 69.6404 53.9334 69L37.3667 52.4333M22.6001 50.1333C27.2234 42.4818 32.8261 35.4667 39.266 29.2659C52.7018 15.8301 66.1609 7.50543 69.3278 10.6723C72.4947 13.8391 64.1701 27.2983 50.7334 40.7333C44.5332 47.1738 37.5183 52.7767 29.8667 57.4M23.2204 67.6273L14.1471 69.8956C13.5886 70.0352 13.0035 70.0279 12.4487 69.8742C11.8939 69.7205 11.3884 69.4258 10.9813 69.0188C10.5743 68.6117 10.2796 68.1062 10.1259 67.5514C9.97223 66.9966 9.96485 66.4115 10.1045 65.853L12.3784 56.7573C12.8618 54.8236 13.9788 53.1073 15.5512 51.8823C17.1237 50.6572 19.061 49.9939 21.0543 49.9982H21.0774C23.4468 50.0032 25.7174 50.9482 27.3906 52.6258C29.0639 54.3034 30.003 56.5764 30.0019 58.9458C30.001 60.9417 29.3332 62.8801 28.1045 64.453C26.8758 66.0259 25.1568 67.1432 23.2204 67.6273Z"
        id={shapeId}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinejoin="round"
      />
    );
  }
};

export const DesignIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_designCurrentColorGradient`;
  const newColorId = `${prefix}_designNewColorGradient`;
  const shapeId = `${prefix}_designShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.aquamarineColors?.PLUS_2,
    },
  };
  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.aquamarineColors?.PLUS_3,
      stop: theme.palette.aquamarineColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox={viewBoxes[iconType]}>
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

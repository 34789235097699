import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { gaPageview, GoogleAnalytics } from './googleAnalytics';
import { GoogleTagManager, gTagPageview } from './googleTagManager';
import type {
  IGoogleTagManager,
  IMarketingScripts,
} from './MarketingScripts.types';
import { OnProd, __dev__ } from './onProd';

export const MarketingScripts = ({
  gtmId,
  gaTrackingId,
  prodSiteUrl,
}: IMarketingScripts) => {
  const router = useRouter();

  useEffect(() => {
    if (__dev__) return;
    const handleRouteChange = (url: URL) => {
      gaPageview(url, gaTrackingId);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeComplete', gTagPageview);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('routeChangeComplete', gTagPageview);
    };
  }, [router.events, gaTrackingId]);

  return (
    <OnProd prodSiteUrl={prodSiteUrl}>
      <GoogleTagManager gtmId={gtmId} />
      <GoogleAnalytics gaTrackingId={gaTrackingId} />
    </OnProd>
  );
};

export const GtmNoScript = ({ gtmId }: IGoogleTagManager) => {
  return (
    <noscript>
      <iframe
        title="gtm"
        src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
};

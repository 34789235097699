import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Headline from '@rumblefish/ui/src/components/RichText/global/Headline';
import React from 'react';
import linkedInIcon from '../../assets/svg/linkedIn.svg';
import type { GalleryTeamProps } from './GalleryTeam.types';

export const GalleryTeam = ({ teamData }: GalleryTeamProps) => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Headline headline="Meet the Team" />
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center">
          {teamData.map(({ img, name, position, linkedIn }) => (
            <Grid
              key={name}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              container
              direction="column"
              alignItems="center"
              gap={1}>
              <img src={img} alt={name} width="220" height="330" />
              <Typography component="div" variant="h4">
                {name}{' '}
              </Typography>
              <Typography component="div">{position}</Typography>
              <a href={linkedIn} aria-label="linkenIn profile">
                <img
                  src={linkedInIcon}
                  width="40"
                  height="40"
                  alt="linked icon"
                />
              </a>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React from 'react';
import { NavLink } from '../../components/NavLink';
import { colors } from '../../theme/default/color';
import type {
  DropdownMenuItems,
  PopperItemProps,
  PopperMenuProps,
} from './DropdownMenus.types';

const PREFIX = 'DropdownMenu';

export const classes = {
  webHeaderNavlinkText: `${PREFIX}-webHeaderNavlinkText`,
  webHeaderMenuItem: `${PREFIX}-webHeaderMenuItem`,
  paperContainer: `${PREFIX}-paperContainer`,
  webHeaderMenuItemSub: `${PREFIX}-webHeaderMenuItemSub`,
  nonClickable: `${PREFIX}-nonClickable`,
};

export const StyledPopper = styled(Popper)(() => ({
  [`& .${classes.webHeaderNavlinkText}`]: {
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    '&:hover h5, &:active h5, &:hover svg, &:active svg': {
      color: colors.blue,
    },
    '& h5': {
      fontWeight: 'normal',
    },
  },

  [`& .${classes.webHeaderMenuItem}`]: {
    height: '48px',
    width: '100%',
  },
  [`& .${classes.nonClickable}`]: {
    height: '48px',
    width: '100%',
    padding: '6px 16px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
  },

  [`& .${classes.webHeaderMenuItemSub}`]: {
    paddingLeft: '36px',
    background: colors.greyBackground,
    position: 'relative',

    '&:after': {
      position: 'absolute',
      content: "''",
      display: 'inline-block',
      left: '16px',
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      background: colors.blue,
    },
  },

  [`& .${classes.paperContainer}`]: {
    borderRadius: '8px',
  },
}));

export const PopperItem: React.FC<PopperItemProps> = ({
  url,
  label,
  isSubItem,
  setOpen,
  nonClickable,
}) => {
  return nonClickable ? (
    <Typography
      variant="h5"
      className={clsx({
        [classes.nonClickable]: nonClickable,
      })}>
      {label}
    </Typography>
  ) : (
    <NavLink
      exact
      to={url}
      onClick={() => setOpen(false)}
      className={classes.webHeaderNavlinkText}>
      <MenuItem
        className={clsx(classes.webHeaderMenuItem, {
          [classes.webHeaderMenuItemSub]: isSubItem,
        })}>
        <Typography variant="h5">{label}</Typography>
      </MenuItem>
    </NavLink>
  );
};

export const PopperMenu: React.FC<PopperMenuProps & DropdownMenuItems> = ({
  open,
  openMenuRef,
  setOpen,
  popperItems,
}) => {
  return (
    <StyledPopper
      open={open}
      anchorEl={openMenuRef.current}
      role={undefined}
      disablePortal
      translate="yes">
      <Paper className={classes.paperContainer} elevation={3}>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <MenuList>
            {popperItems.map((item) => (
              <PopperItem key={item.url} setOpen={setOpen} {...item} />
            ))}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </StyledPopper>
  );
};

const careersPopperItems: DropdownMenuItems['popperItems'] = [
  { url: '/careers/', label: 'Job offers' },
  // { url: '/blockchainhub/', label: 'Blockchain Training Hub' },
];

const resourcesPopperItems: DropdownMenuItems['popperItems'] = [
  { url: '/blog/', label: 'Blog' },
  {
    url: '/blog?category=code-stories',
    label: 'Code Stories',
    isSubItem: true,
  },
  {
    url: '/blog?category=business-stories',
    label: 'Business Stories',
    isSubItem: true,
  },
  { url: '', label: 'E-book', nonClickable: true },
  {
    url: '/resources/workshop',
    label: 'Workshop',
    isSubItem: true,
  },
  {
    url: '/resources/blockchain',
    label: 'Blockchain',
    isSubItem: true,
  },
];

export const CareersPopperMenu: React.FC<PopperMenuProps> = ({
  open,
  openMenuRef,
  setOpen,
}) => {
  return (
    <PopperMenu
      open={open}
      openMenuRef={openMenuRef}
      setOpen={setOpen}
      popperItems={careersPopperItems}
    />
  );
};

export const ResourcesPopperMenu: React.FC<PopperMenuProps> = ({
  open,
  openMenuRef,
  setOpen,
}) => {
  return (
    <PopperMenu
      open={open}
      openMenuRef={openMenuRef}
      setOpen={setOpen}
      popperItems={resourcesPopperItems}
    />
  );
};

import React from 'react';

export const LinkedinIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3333 0H0.666667C0.266667 0 0 0.266667 0 0.666667V15.3333C0 15.7333 0.266667 16 0.666667 16H15.3333C15.7333 16 16 15.7333 16 15.3333V0.666667C16 0.266667 15.7333 0 15.3333 0ZM4.73333 13.6667H2.4V6H4.8V13.6667H4.73333ZM3.53333 4.93333C2.8 4.93333 2.13333 4.33333 2.13333 3.53333C2.13333 2.8 2.73333 2.13333 3.53333 2.13333C4.26667 2.13333 4.93333 2.73333 4.93333 3.53333C4.93333 4.33333 4.33333 4.93333 3.53333 4.93333ZM13.6667 13.6667H11.2667V9.93333C11.2667 9.06667 11.2667 7.93333 10.0667 7.93333C8.8 7.93333 8.66667 8.86667 8.66667 9.86667V13.6667H6.26667V6H8.53333V7.06667C8.86667 6.46667 9.6 5.86667 10.8 5.86667C13.2 5.86667 13.6667 7.46667 13.6667 9.53333V13.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { Stack, styled } from '@mui/material';

export const StyledUserImage = styled(`img`)(({ theme }) => ({
  width: `64px`,
  height: `64px`,
  marginRight: theme.spacing(2),
}));

export const MobileSpan = styled(`span`)(({ theme }) => ({
  [theme.breakpoints.down(`sm`)]: {
    display: 'none',
  },
}));
export const StyledWrapper = styled(Stack)(({ theme }) => ({
  flexFlow: `row nowrap`,
  alignItems: 'center',
  justifyContent: 'flex-start',
  [theme.breakpoints.down(`sm`)]: {
    flexFlow: `column nowrap`,
    justifyContent: `center`,
    alignItems: 'flex-start',
  },
}));

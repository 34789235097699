import { Box, Stack, styled, Typography } from '@mui/material';

export const StyledStack = styled(Stack)(({ theme }) => ({
  maxWidth: '476px',
  minHeight: '254px',
  margin: theme.spacing(15, 0),
  [theme.breakpoints.down('md')]: {
    width: '324px',
  },
}));
export const ButtonWrapper = styled(Box)(() => ({
  transition: 'all 0.4s ease-in-out',
}));
export const StyledCompany = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  transition: 'all 0.4s ease-in-out',
}));
export const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  transition: 'all 0.4s ease-in-out',
}));

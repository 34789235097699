import { Typography } from '@mui/material';
import { Section } from '@rumblefish/ui/AlgeaTheme';
import React from 'react';
import cyc from '../../assets/svg/AlgeaTheme/clients/clients/cyc.svg';
import makerDao from '../../assets/svg/AlgeaTheme/clients/clients/dao.svg';
import solar from '../../assets/svg/AlgeaTheme/clients/clients/solar.svg';
import space from '../../assets/svg/AlgeaTheme/clients/clients/space.svg';
import verify from '../../assets/svg/AlgeaTheme/clients/clients/verify.svg';
import zbay from '../../assets/svg/AlgeaTheme/clients/clients/zbay.svg';
import type { OurClientsProps } from './OurClients.types';
import {
  StyledHeadline,
  StyledImage,
  StyledLogoWrapper,
  StyledStack,
  StyledWrapper,
} from './styles';

export const OurClients = ({ ...props }: OurClientsProps) => (
  <Section width="small">
    <StyledWrapper {...props} spacing={4}>
      <Typography variant="uppercase">Other Case Studies</Typography>
      <StyledStack>
        <StyledHeadline variant="heading3">
          We're trusted by global innovators and leaders. Join them!
        </StyledHeadline>
        <StyledLogoWrapper>
          <StyledImage src={cyc} alt="Connect Your Care" />
          <StyledImage src={makerDao} alt="Maker DAO" />
          <StyledImage src={solar} alt="Solar Generation" />
          <StyledImage src={space} alt="Space Inch" />
          <StyledImage src={verify} alt="Verify ID" />
          <StyledImage src={zbay} alt="Zbay" />
        </StyledLogoWrapper>
      </StyledStack>
    </StyledWrapper>
  </Section>
);

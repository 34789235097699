import { createSlice, Slice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { TErrorMessage } from './types';

const initialState: TErrorMessage = {
  messages: [],
};

export const errorSlice: Slice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    updateErrMsg: (
      state,
      action: PayloadAction<{ e: { message: string } }>
    ) => {
      state.messages = [
        ...state.messages,
        action.payload.e.message && 'Sending message failed',
      ];
    },
  },
});

export const { updateErrMsg } = errorSlice.actions;

export const selectErrorMsg = (state: { errorMsg: TErrorMessage }) =>
  state.errorMsg;
export default errorSlice.reducer;

import React from 'react';
import { Footer } from '../../Rumblefish23Theme/Footer';
import { Header } from '../../Rumblefish23Theme/Header';
import type { LayoutProps, RenderWithProviderProps } from './Layout.types';

import { themeDark, themeLight } from '../../../theme/rumblefish23Theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
const RenderWithProvider = ({
  finalTheme,
  children,
}: RenderWithProviderProps) => {
  return (
    <ThemeProvider theme={finalTheme}>
      <CssBaseline>{children}</CssBaseline>
    </ThemeProvider>
  );
};
export const Layout = ({ blogPosts, children, forceDark }: LayoutProps) => {
  let finalTheme = forceDark ? themeDark : themeLight;

  return (
    <>
      <RenderWithProvider finalTheme={finalTheme}>
        <Header blogPosts={blogPosts} />
      </RenderWithProvider>

      {children}
      <RenderWithProvider finalTheme={finalTheme}>
        <Footer />
      </RenderWithProvider>
    </>
  );
};

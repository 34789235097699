import type { StackProps } from '@mui/material';

export interface EbookFormProps extends StackProps {
  downloadSource?: string;
  callback?: () => void;
  btntext?: string;
  sx?: object;
}

const prefix = 'EbookFormStack';
export const ebookFormStackClasses = {
  navy: `${prefix}-navyMode`,
  light: `${prefix}-lightMode`,
  dark: `${prefix}-darkMode`,
};

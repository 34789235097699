import { default as MuiButton } from '@mui/material/Button';
import React from 'react';
import type { ButtonProps } from './Button.types';

export const Button: React.FC<ButtonProps> = ({
  variant,
  color,
  className,
  labelAdditional,
  to,
  onClick,
  ...props
}) => (
  <MuiButton
    role="button"
    variant={variant}
    color={color}
    aria-label={labelAdditional || ''}
    onClick={onClick}
    className={className}
    {...props}>
    {props.children || ''}
  </MuiButton>
);

/* eslint-disable @typescript-eslint/naming-convention*/
export enum SUBMENUS {
  SERVICES = 'services',
  RESOURCES = 'resources',
  CAREERS = 'careers',
  PRODUCTS = 'products',
}
/* eslint-enable @typescript-eslint/naming-convention*/

export const submenusWhichAction = {
  services: 'setServicesHover',
  resources: 'setResourcesHover',
  products: 'setProductsHover',
  careers: 'setCareersHover',
};

export const submenuActionStateValues = {
  setServicesHover: 'servicesHover',
  setResourcesHover: 'resourcesHover',
  setProductsHover: 'productsHover',
  setCareersHover: 'careersHover',
};

// primary colors taken to create palette

export const colors = {
  white: '#ffffff',
  black: '#101131',
  blackText: '#070D0D',
  gray: '#4A4A4A',
  red: '#ff0000',
  violetred: '#F8277D', // pink gradient color
  green: '#8fb605',
  violet: '#7F5BFD',
  textOnWhite: '#686868',
  blue: '#2B57F5',
  lightBlue: '#E1E6FC',
  fuchsiablue: '#7745C8', // purple gradient color
  darkblue: '#1E2234',
  lightblue: '#2F57F4',
  mediumgray: '#C4C4C4',
  mediumgray400: '#9D9D9D',
  mediumgray500: '#7C7C7C',
  lightgray: '#F6F6FB',
  lightgray100: '#F0F0F0',
  lightgray150: '#C7C7C7',
  lightgray200: '#969696',
  lightgray250: '#E8E8E8',
  lightgray300: '#D5D5D5',
  lightgray5A: '#5A5A5A',
  lightgray5F: '#5F5F5F',
  athensgray: '#EAEAEE',
  cometgray: '#5C5D72',
  // colors from new design
  greyBackground: '#F6F6FB',
  stepperBlue: '#345AEA',
  stepperLightBlue: '#C7D1F7',
  newButtonBlue: '#4E54DA',
  newButtonRed: '#C5448F',
  backgroundBlue: '#001A70',
  backgroundLightBlue: '#F2F3F8',
  footerGrey: '#F8F8F9',
  greyBoldText: '#5E5D6D',
  dividerGray: '#D1D1D5',
  lightGrayText: '#F6F6FB',
  lightViolet: '#6475B2',
  navyBlue: '#01196F',
  caseStudySliderGrey: '#F5F5F5',
  caseStudySliderLightGrey: '#FBFBFB',
  pink: '#F9277F',
  darkPink: '#C6339C',
  errorRed: '#f44336',
};

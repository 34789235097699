import { Stack, useTheme } from '@mui/material';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { sectionClasses } from './Section.types';
import type { SectionProps } from './Section.types';
import { StyledRoot } from './styles';
import { useInView } from 'framer-motion';
export const Section = ({
  width = 'full',
  mobilePadding = true,
  borderBottomOn,
  borderTopOn,
  sectionRef = null,
  forceLightBackground,
  shouldUseInView = false,
  children,
  ...props
}: SectionProps) => {
  const theme = useTheme();
  const inViewRef = useRef(null);
  const inView = useInView(inViewRef);
  return (
    <Stack
      {...props}
      ref={sectionRef}
      sx={{
        background: forceLightBackground ? '#FFF' : '',
        borderBottom: borderBottomOn
          ? `1px solid ${theme.palette.greyBackgroundColor?.SURFACE + '10'}`
          : '',
        borderTop: borderTopOn
          ? `1px solid ${theme.palette.greyBackgroundColor?.SURFACE + '10'}`
          : '',
        width: '100%',
        overflowX: 'clip',
      }}>
      <StyledRoot
        ref={shouldUseInView ? inViewRef : null}
        sx={
          shouldUseInView
            ? {
                '& .AnimateElement': {
                  animationPlayState: inView ? 'running' : 'paused',
                },
                visibility: inView ? 'visible' : 'hidden',
              }
            : {}
        }
        className={clsx(sectionClasses[width], {
          [sectionClasses.mobilePadding]: mobilePadding,
        })}
        maxWidth={false}
        disableGutters>
        {children}
      </StyledRoot>
    </Stack>
  );
};

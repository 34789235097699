import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { CustomSvgIconsProps, SvgPathProps } from '../../CustomSvgIcons.types';
import { useTheme } from '@mui/material';
import { SvgPath } from '../../SvgPath/SvgPath';

export const HandMoneyIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_handMoneyIconCurrentColorGradient`;
  const newColorId = `${prefix}_handMoneyIconNewColorGradient`;
  const shapeId = `${prefix}_handMoneyIconShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';

  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.purpleColors?.PLUS_3,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;

  return (
    <SvgIcon {...props} viewBox="0 0 61 60">
      {solidColor ? (
        <SvgPath
          d="M40.3333 42.5H45.3333C47.3224 42.5 49.23 41.7098 50.6366 40.3033C52.0431 38.8968 52.8333 36.9891 52.8333 35V17.5C52.8333 15.5109 52.0431 13.6032 50.6366 12.1967C49.23 10.7902 47.3224 10 45.3333 10H15.3333C13.3441 10 11.4365 10.7902 10.03 12.1967C8.62343 13.6032 7.83325 15.5109 7.83325 17.5V22.5M25.9133 23.08C26.4937 22.4996 27.1828 22.0391 27.9412 21.725C28.6995 21.4109 29.5124 21.2492 30.3333 21.2492C31.1541 21.2492 31.967 21.4109 32.7253 21.725C33.4837 22.0391 34.1728 22.4996 34.7533 23.08C35.3337 23.6604 35.7941 24.3495 36.1083 25.1079C36.4224 25.8663 36.5841 26.6791 36.5841 27.5C36.5841 28.3209 36.4224 29.1337 36.1083 29.8921C35.7941 30.6505 35.3337 31.3396 34.7533 31.92M7.83325 50L10.7233 51.445C12.1112 52.1387 13.6416 52.4999 15.1933 52.5H31.5833C32.5778 52.5 33.5316 52.1049 34.2349 51.4016C34.9382 50.6984 35.3333 49.7446 35.3333 48.75C35.3333 47.7554 34.9382 46.8016 34.2349 46.0984C33.5316 45.3951 32.5778 45 31.5833 45H21.5833M7.83325 32.5H15.1158C16.1976 32.5 17.2503 32.8509 18.1158 33.5L23.7108 37.695C24.1762 38.0438 24.5613 38.4885 24.8398 38.9991C25.1184 39.5097 25.2839 40.0742 25.3252 40.6544C25.3664 41.2346 25.2824 41.8169 25.079 42.3617C24.8755 42.9066 24.5572 43.4014 24.1458 43.8125C23.4548 44.5032 22.5378 44.9214 21.5633 44.9903C20.5887 45.0592 19.622 44.7742 18.8408 44.1875L15.3333 41.5575"
          thinStrokeWidth={1.2}
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              thinStrokeWidth={1.2}
              d="M40.3333 42.5H45.3333C47.3224 42.5 49.23 41.7098 50.6366 40.3033C52.0431 38.8968 52.8333 36.9891 52.8333 35V17.5C52.8333 15.5109 52.0431 13.6032 50.6366 12.1967C49.23 10.7902 47.3224 10 45.3333 10H15.3333C13.3441 10 11.4365 10.7902 10.03 12.1967C8.62343 13.6032 7.83325 15.5109 7.83325 17.5V22.5M25.9133 23.08C26.4937 22.4996 27.1828 22.0391 27.9412 21.725C28.6995 21.4109 29.5124 21.2492 30.3333 21.2492C31.1541 21.2492 31.967 21.4109 32.7253 21.725C33.4837 22.0391 34.1728 22.4996 34.7533 23.08C35.3337 23.6604 35.7941 24.3495 36.1083 25.1079C36.4224 25.8663 36.5841 26.6791 36.5841 27.5C36.5841 28.3209 36.4224 29.1337 36.1083 29.8921C35.7941 30.6505 35.3337 31.3396 34.7533 31.92M7.83325 50L10.7233 51.445C12.1112 52.1387 13.6416 52.4999 15.1933 52.5H31.5833C32.5778 52.5 33.5316 52.1049 34.2349 51.4016C34.9382 50.6984 35.3333 49.7446 35.3333 48.75C35.3333 47.7554 34.9382 46.8016 34.2349 46.0984C33.5316 45.3951 32.5778 45 31.5833 45H21.5833M7.83325 32.5H15.1158C16.1976 32.5 17.2503 32.8509 18.1158 33.5L23.7108 37.695C24.1762 38.0438 24.5613 38.4885 24.8398 38.9991C25.1184 39.5097 25.2839 40.0742 25.3252 40.6544C25.3664 41.2346 25.2824 41.8169 25.079 42.3617C24.8755 42.9066 24.5572 43.4014 24.1458 43.8125C23.4548 44.5032 22.5378 44.9214 21.5633 44.9903C20.5887 45.0592 19.622 44.7742 18.8408 44.1875L15.3333 41.5575"
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

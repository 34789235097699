import { Stack, styled, Link } from '@mui/material';
import type { LinkProps } from '@mui/material';
import ReCaptcha from 'react-google-recaptcha';
import { ebookFormStackClasses } from './EbookForm.types';

export const StyledStack = styled(Stack)(({ theme }) => ({
  height: `540px`,
  maxWidth: '368px',
  zIndex: 10,
  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
    width: '100%',
  },

  [`&.${ebookFormStackClasses.navy}`]: {
    height: '509px',
    width: '40%',
    maxWidth: 'none',
    padding: theme.spacing(4),
    borderRadius: '24px',
    [theme.breakpoints.down('lg')]: {
      width: '46%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down(700)]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(2),
    },
  },
}));

export const StyledRecaptcha = styled(ReCaptcha)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

export const StyledLink = styled(Link)(() => ({
  lineHeight: '150%',
  fontWeight: 650,
  fontStyle: 'normal',
  fontFamily: 'Inter',
  fontSize: '14px',
  background:
    'linear-gradient(152.46deg, #FFFFFF -22.85%, rgba(255, 255, 255, 0) 100%), #F47AFF',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  fontVariationSettings: 'slnt 0',
})) as React.FC<LinkProps>;

import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/system/Box';
import Headline from '@rumblefish/ui/src/components/RichText/global/Headline';
import React from 'react';
import checkImage from '../../assets/png/check.png';
import clutchImg from '../../assets/png/clutch_ref.png';
import ribbonImg from '../../assets/png/ribbon_left.png';
import { rumbleFishBullets } from './ReasonsToChooseRf.data';
import type { IReason } from './ReasonsToChooseRf.types';

const Reason = ({ heading, copy }: IReason) => {
  return (
    <Grid
      container
      gap={{ xs: 0, sm: 3 }}
      mt={{ xs: 5, sm: 0 }}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems="center"
      wrap="nowrap">
      <img src={checkImage} width="42" height="42" alt="" />
      <Grid my={4}>
        <Typography mb={1} variant="h4">
          {heading}
        </Typography>
        <Typography variant="body1">{copy}</Typography>
      </Grid>
    </Grid>
  );
};

const StyledRibbon = styled('img')(({ theme: { breakpoints } }) => ({
  position: 'absolute',
  left: '0',
  top: '400px',

  [breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const StyledClutch = styled('img')(({ theme: { breakpoints } }) => ({
  display: 'block',
  margin: '2rem auto 0',
  width: '50%',
  height: 'auto',

  [breakpoints.down('md')]: {
    width: '90%',
  },
}));

export const ReasonsToChooseRf = () => {
  return (
    <Box>
      <Headline headline="Reasons to choose Rumble Fish" />
      <Typography variant="h3">
        There’s a satisfied customer behind every project we take on. See what’s
        our secret sauce
      </Typography>
      <Box mx={[3, 6, 14]} my={7}>
        {rumbleFishBullets.map((bullet) => (
          <Reason key={bullet.heading} {...bullet} />
        ))}
      </Box>
      <StyledRibbon width="225" height="436" src={ribbonImg} alt="" />
      <a
        target="_blank"
        rel="noreferrer"
        href="https://clutch.co/profile/rumble-fish-software-development#summary">
        <StyledClutch
          width="1120"
          height="416"
          src={clutchImg}
          alt="award of clutch, top developers"
        />
      </a>
    </Box>
  );
};

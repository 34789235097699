import { Stack, Typography, styled } from '@mui/material';

export const StyledWrapper = styled(Stack)({
  height: 'min-content',
});

export const StyledStack = styled(Stack)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  flexDirection: 'row',
  gap: theme.spacing(1),
  cursor: 'pointer',
  transition: 'background .3s',
}));

export const IconStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  transition: 'color .2s linear',
  '& .SvgFilledPath': {
    transition: 'all .2s linear',
  },
  '&.Hovering': {
    '& .SvgFilledPath': {
      fill: theme.palette.blueColors?.PLUS_1,
    },
    color: theme.palette.blueColors?.PLUS_1,
  },
  '&.IconType': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export const ContentStack = styled(Stack)({});

export const StyledUnderlinedText = styled('span')({
  textDecoration: 'none',
  backgroundImage: 'linear-gradient(currentColor, currentColor)',
  backgroundPosition: '0% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '0% 1px',
  transition: 'background-size .2s',

  '&.Hovering': {
    backgroundSize: '100% 1px',
  },
});

export const StyledName = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  transition: 'color .2s linear',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
  // lineHeight: '22px !important',
  '*': {
    display: 'inline',
  },
  '&.Hovering': {
    color: theme.palette.blueColors?.PLUS_1,
  },
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.SECONDARY,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
  '*': {
    display: 'inline',
  },
  '&.IconType': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export const StyledImage = styled('img')(({ theme }) => ({
  objectFit: 'fill',
  height: '80px',
  width: '80px',
  borderRadius: '6px',

  [theme.breakpoints.down('lg')]: {
    height: '70px',
    width: '70px',
  },
}));

export const StyledLink = styled('a')({
  textDecoration: 'none',
});

import { Box, styled } from '@mui/material';

export const StyledWrapper = styled(Box)(({ theme }) => ({
  width: '586px',
  height: '600px',
  position: 'sticky',
  top: '300px',
  [theme.breakpoints.down('lg')]: {
    width: '464px',
  },
  [theme.breakpoints.down('md')]: {
    width: '346px',
    height: '450px',
  },
}));
export const StyledImage = styled(`img`)(({ theme }) => ({
  maxWidth: '586px',
  height: '586px',
  position: 'absolute',
  borderRadius: '24px',
  transition: 'all 0.4s ease-in-out',
  objectFit: 'cover',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '464px',
    height: '464px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '346px',
    height: '346px',
  },
}));

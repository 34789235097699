import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import quoteSrc from '../../assets/png/quoteGradient.png';
import { colors } from '../../theme/default/color';
import { useMobileDetect } from '../../theme/utils/useBreakpoints';
import { GradientText } from '../GradientText';
import type { SpeechBubbleGradientProps } from './SpeechBubble.types';

const PREFIX = 'SpeechBubbleGradient';

const classes = {
  content: `${PREFIX}-content`,
  gradientBackground: `${PREFIX}-gradientBackground`,
  gradientForeground: `${PREFIX}-gradientForeground`,
};

const StyledSpeechBubbleWrapper = styled('div', {
  shouldForwardProp: (props) =>
    props !== 'borderSize' &&
    props !== 'tailWidth' &&
    props !== 'tailHeight' &&
    props !== 'tailXPosition',
})<SpeechBubbleGradientProps>(
  ({ borderSize, tailHeight, tailWidth, tailXPosition }) => ({
    [`& .${classes.content}`]: { fontWeight: 700 },

    [`&.${classes.gradientBackground}`]: {
      padding: borderSize,
      paddingBottom: `calc(${tailHeight} + ${borderSize})`,
      background: `transparent linear-gradient(97deg, ${colors.newButtonBlue} 0%, ${colors.newButtonRed} 100%) 0% 0% no-repeat padding-box;`,
      position: 'relative',

      clipPath: `polygon(
        0px 0px,
        100% 0px,
        100% calc(100% - ${tailHeight}),
        calc(${tailXPosition} + ${tailWidth}) calc(100% - ${tailHeight}),
        ${tailXPosition} calc(100% - ${tailHeight} / 2),
        calc(${tailXPosition} - ${tailWidth}) calc(100% - ${tailHeight}),
        0 calc(100% - ${tailHeight}))`,
    },

    [`& .${classes.gradientForeground}`]: {
      background: colors.white,
      padding: '1.51rem',
      position: 'relative',

      display: 'grid',
      justifyItems: 'start',
      gap: '1.5rem',

      //white arrow
      '&:after': {
        content: "''",
        height: tailHeight,
        width: `calc(${tailWidth} * 2)`,
        bottom: `calc(-${tailHeight} + 2px)`,
        left:
          //TODO remove 1.03
          `calc((${tailXPosition} * 1.03) - ${tailWidth} - ${borderSize})`,
        background: colors.white,
        position: 'absolute',

        clipPath: `polygon(
          0px 0px,
          100% 0px,
          50% 50%)`,
      },
    },
  })
);

export const SpeechBubbleGradient: React.FC<SpeechBubbleGradientProps> = ({
  content,
  borderSize = '0.5rem',
  tailHeight = '3rem',
  tailWidth = '1.8rem',
  tailXPosition = '12%',
}) => {
  const isOnMobile = useMobileDetect();

  if (isOnMobile) {
    tailXPosition = '50%';
  }

  return (
    <StyledSpeechBubbleWrapper
      borderSize={borderSize}
      tailHeight={tailHeight}
      tailWidth={tailWidth}
      tailXPosition={tailXPosition}
      className={classes.gradientBackground}>
      <Box className={classes.gradientForeground}>
        <img alt="" src={quoteSrc} width={54} height={40} />
        <Typography variant="body1" className={classes.content}>
          <GradientText>{content}</GradientText>
        </Typography>
      </Box>
    </StyledSpeechBubbleWrapper>
  );
};

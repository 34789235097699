import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { SvgPathProps } from '../CustomSvgIcons.types';
import React from 'react';

export const SvgPath = ({
  iconType,
  shapeId,
  solidColor,
  thinStrokeWidth = 1,
  normalStrokeWidth = 2.1,
  boldStrokeWidth = 2.8,
  ...props
}: SvgPathProps): ReactJSXElement => {
  let strokeWidth = thinStrokeWidth;
  if (iconType === 'normal') {
    strokeWidth = normalStrokeWidth;
  }
  if (iconType === 'bold') {
    strokeWidth = boldStrokeWidth;
  }

  return (
    <path
      d={props.d}
      stroke={solidColor ? 'currentColor' : ''}
      stroke-width={strokeWidth}
      fill="none"
      id={shapeId}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  );
};

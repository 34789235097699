import { Grid, styled } from '@mui/material';

export const StyledGrid = styled(Grid)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column',
  [breakpoints.down('sm')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '12%',
    marginTop: '10%',
  },
}));

export const StyledCompanyTitle = styled(Grid)(
  ({ theme: { breakpoints } }) => ({
    [breakpoints.down('sm')]: {
      gridColumn: '1/3',
    },
  })
);

export const StyledAddressField = styled(Grid)(
  ({ theme: { palette, breakpoints } }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5%',
    marginBottom: '1%',
    ['& > :first-of-type']: {
      color: palette.colorBrand?.secondary,
      textTransform: 'uppercase',
      fontWeight: 600,
      letterSpacing: '0.02em',
    },
    [breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  })
);

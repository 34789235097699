import { styled, Typography } from '@mui/material';
import { MenuItem } from '../MenuItem';

export const StyledText = styled(Typography)(({ theme }) => ({
  paddingLeft: '10px !important',
  whiteSpace: 'pre-line',
  textAlign: 'center',
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(0),
    paddingLeft: '8px !important',
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingLeft: '10px !important',
  fontSize: '100%',
  cursor: 'pointer',
  '&:hover': theme.mixins.hoverTextLightBlue,
  [theme.breakpoints.down('md')]: {
    paddingLeft: '8px !important',
  },
}));

import { Stack, styled, Typography } from '@mui/material';
import ReCaptcha from 'react-google-recaptcha';
import { Button } from '../Button';

export const StyledStack = styled(Stack)(({ theme }) =>
  theme.palette.type === 'dark'
    ? {
        height: `640px`,
        maxWidth: '500px',
        width: '100%',
        background:
          'radial-gradient(47.45% 48.31% at -0.77% 0%, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, rgba(255, 255, 255, 0.01)',
        padding: '3%',
        borderRadius: '8px',
        backdropFilter: 'blur(12px)',
        [theme.breakpoints.down('md')]: {
          height: '564px',
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          justifyContent: 'unset',
        },
      }
    : {
        //100% not working for some reason
        height: `564px`,
        maxWidth: '500px',
        width: '100%',
        [theme.breakpoints.down('md')]: {
          height: '564px',
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          justifyContent: 'unset',
        },
      }
);
export const StyledRecaptcha = styled(ReCaptcha)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));
export const StyledLoadingIcon = styled(`img`)(() => ({
  animation: 'spin 4s linear infinite',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(360deg)',
    },
    '100%': {
      transform: 'rotate(deg0)',
    },
  },
}));

export const StyledEmailLinkA = styled(`a`)(({ theme }) => ({
  textDecoration: 'none',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));
export const StyledEmailLinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colorLink,
}));

export const StyledButtonWrapper = styled(Stack)(({ theme }) => ({
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(25),
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
  },
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
}));
export const StyledTextWrapper = styled(Stack)(({ theme }) => ({
  width: '260px',
  [theme.breakpoints.down('sm')]: {
    width: 'unset',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
  },
}));
export const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
}));

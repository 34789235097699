import type { Theme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

import { breakpoints } from '../default/breakpoints';
import { shape } from '../default/shape';
import { spacing } from '../default/spacing';
import { typography } from '../default/typography';
import { customMuiFields } from './customFields';
import { overrides as components } from './overrides';
import { palette } from './palette';

export const theme: Theme = createTheme({
  ...customMuiFields,
  breakpoints,
  components,
  palette,
  typography,
  shape,
  spacing,
});

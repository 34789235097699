import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React from 'react';
import type { DownloadButtonProps } from './DownloadButton.types';

export const DownloadButton: React.FC<DownloadButtonProps> = ({ model }) => {
  const download = React.useCallback(
    () => window.open(model.attributes?.target?.data?.attributes?.url),
    [model]
  );
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Button color="primary" variant="contained" onClick={download}>
          {model.attributes?.label}
        </Button>
      </Grid>
    </Grid>
  );
};

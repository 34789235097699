import { useScrollbarPosition } from '@rumblefish/ui/hooks/useScrollbarPosition';
import React from 'react';
import { CaseBox } from '../CaseBox';
import { data } from '../data';
import { FloatingImage } from '../FloatingImage';
import type { DesktopCaseStudiesProps } from './DesktopCaseStudies.types';
import { StyledStack } from './styles';

export const DesktopCaseStudies = ({ ...props }: DesktopCaseStudiesProps) => {
  const { containerRef, activeThreshold } = useScrollbarPosition();

  const activeCompany = activeThreshold([0, 25, 50, 75]);
  return (
    <>
      <StyledStack ref={containerRef} {...props}>
        {data.map((item, index) => (
          <CaseBox {...item} key={index} isActive={index === activeCompany} />
        ))}
      </StyledStack>
      <FloatingImage activeCompany={activeCompany} />
    </>
  );
};

import { Stack, styled } from '@mui/material';

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(4, 0),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(2.5, 0),
  },

  [theme.breakpoints.down('md')]: {
    borderBottom: `1px solid ${theme.palette.invertedBorder?.LIGHT}`,
    borderTop: `1px solid ${theme.palette.invertedBorder?.LIGHT}`,
    ...theme.mixins.mobilePadding(),
    paddingTop: `${theme.spacing(2)} !important`,
    paddingBottom: `${theme.spacing(2)} !important`,
  },
}));
export const Wrapper = styled(Stack)(({ theme }) => ({
  flexWrap: 'wrap',
  flexDirection: 'row',

  height: 'min-content',
  '>div': {
    width: '23%',
    [theme.breakpoints.down('xl')]: {
      width: '30%',
    },
  },
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    gap: theme.spacing(2),
    flexDirection: 'column',
    '>div': {
      width: '100%',
    },
  },
}));

import { Box, Stack, styled } from '@mui/material';

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(12, 0),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));
export const StyledTextWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'descMarginBottom',
})<{ descMarginBottom?: boolean }>(({ theme, descMarginBottom }) => ({
  maxWidth: '490px',
  position: 'sticky',
  height: '275px',
  top: 175,
  marginBottom: descMarginBottom ? '100px' : 0,
  [theme.breakpoints.down('md')]: {
    position: 'static',
    top: 'unset',
    width: '100%',
    maxWidth: 'unset',
  },
}));
export const StyledRightWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '50%',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
    width: '100%',
  },
}));
export const StyledLineWrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '1px',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    width: 'auto',
    marginRight: theme.spacing(4),
  },
}));
export const StyledDot = styled(`img`)(() => ({
  height: '17px',
  width: '17px',
  position: 'absolute',
  zIndex: 2,
  left: '-7.5px',
}));
export const StyledDottedLine = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  borderLeft: `2.5px dotted ${theme.palette.colorWhite}`,
  opacity: 0.15,
  index: 0,
}));
export const StyledLine = styled(Box)(({ theme }) => ({
  position: 'absolute',
  borderLeft: `2.5px solid ${theme.palette.colorWhite}`,
  index: 1,
}));
export const StyledOffersWrapper = styled(Stack)({
  maxWidth: '476px',
});

export const StyledOfferBoxIcon = styled(`img`)(({ theme }) => ({
  width: '56px',
  height: '56px',
  marginBottom: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledOneElement = styled(Stack)(({ theme }) => ({
  color: 'white',
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(22),
    paddingRight: theme.spacing(22),
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(16),
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
  },
}));

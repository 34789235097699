import { useTheme } from '@mui/material';
import React from 'react';
import {
  StyledCheckbox,
  StyledCheckboxIcon,
  StyledCheckboxIconChecked,
  StyledFormControlLabel,
} from './styles';
import type { CheckboxProps } from './types';
import { CheckboxClasses } from './types';

export const Checkbox = ({
  label,
  value,
  disabled = false,
  onChangeEvent,
  error,
  checkboxId,
}: CheckboxProps) => {
  const palleteType = useTheme().palette.type;
  return (
    <StyledFormControlLabel
      control={
        <StyledCheckbox
          icon={
            <StyledCheckboxIcon
              disabled={disabled}
              className={CheckboxClasses[palleteType]}
            />
          }
          checkedIcon={<StyledCheckboxIconChecked disabled={disabled} />}
          onChange={onChangeEvent}
          inputProps={{ role: 'checkbox', 'aria-label': checkboxId }}
          checked={value}
          disableRipple
        />
      }
      label={label}
      className={`${error ? CheckboxClasses.error : ''} ${
        CheckboxClasses[palleteType]
      }`}
    />
  );
};

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import type { ServiceInfoProps } from './ServiceInfo.types';

const PREFIX = 'ServiceInfo';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: { display: 'grid', gap: theme.spacing(0.2) },
}));

export const ServiceInfo: React.FC<ServiceInfoProps> = ({
  label,
  description,
}) => {
  return (
    <Root className={classes.root}>
      <Typography variant="caption" component="h3">
        {label}
      </Typography>
      <Typography variant="subtitle1" component="h4">
        {description}
      </Typography>
    </Root>
  );
};

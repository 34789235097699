import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import ribbonsImage from '../../assets/png/ribbons-background-logo_1x.png';
import { colors } from '../../theme/default/color';
import { Section } from '../Section';

const PREFIX = 'DiveIntoBlockchain';

const classes = {
  diveIntoBlockchainContainer: `${PREFIX}-diveIntoBlockchainContainer`,
  textContainer: `${PREFIX}-textContainer`,
  ribbonsImageContainer: `${PREFIX}-ribbonsImageContainer`,
};

const StyledDiveIntoWrapper = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    [`& .${classes.diveIntoBlockchainContainer}`]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      alignItems: 'center',
      gap: spacing(1),

      [breakpoints.down('sm')]: {
        backgroundImage: `url(${ribbonsImage})`,
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      },
    },

    [`& .${classes.textContainer}`]: {
      display: 'grid',
      gap: spacing(2),

      '& > *': {
        color: colors.white,
      },
      '& > h2': {
        marginBottom: '1rem',
        marginRight: '10rem',

        [breakpoints.down('md')]: {
          marginRight: '0',
        },
      },
      '& > p': {
        opacity: 0.7,
      },

      [breakpoints.down('sm')]: {
        gridColumn: '1 / -1',
      },
    },

    [`& .${classes.ribbonsImageContainer}`]: {
      display: 'grid',
      justifyContent: 'center',
      alignContent: 'center',

      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export const DiveIntoBlockchain: React.FC = () => {
  return (
    <Section theme="blue">
      <StyledDiveIntoWrapper>
        <div className={classes.diveIntoBlockchainContainer}>
          <div className={classes.textContainer}>
            <Typography variant="h2">
              Let’s dive into Blockchain with Rumble Fish
            </Typography>
            <Typography variant="h4" component="h3">
              Become a DeFi Developer in just 4 weeks!
            </Typography>
            <Typography variant="body1">
              Many engineers dream of changing the industry and exploring the
              world of DeFi, NFTs, cryptocurrencies, stablecoins, and smart
              contracts. With Rumble Fish, you can make that happen in as short
              as 4 weeks.
            </Typography>
            <Typography variant="h4" component="h3">
              Learn blockchain and get paid for it!
            </Typography>
            <Typography variant="body1">
              Complete the 4-week training and Rumble Fish will guarantee you
              stable employment and a place in one of the Blockchain projects we
              lead.
            </Typography>
          </div>
          <div className={classes.ribbonsImageContainer}>
            <img alt="" src={ribbonsImage} width={464} height={560} />
          </div>
        </div>
      </StyledDiveIntoWrapper>
    </Section>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import {
  getPercentageScrollPositionInContainer,
  getContainrHeightAndOffset,
} from '../utils/getScrollBarPosition';

export const useScrollbarPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const { containerHeight, containerPosition } =
    getContainrHeightAndOffset(containerRef);

  const handleSetScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  const scrollPercentagePosition = getPercentageScrollPositionInContainer({
    scrollPosition,
    containerPosition,
    containerHeight,
  });

  useEffect(() => {
    window.addEventListener('scroll', handleSetScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleSetScroll);
    };
  }, []);

  const activeThreshold = (thresholds: number[]) => {
    return thresholds.findIndex(
      (threshold, index) =>
        index === thresholds.length - 1 ||
        scrollPercentagePosition < thresholds[index + 1]
    );
  };

  return {
    scrollPercentagePosition,
    containerRef,
    activeThreshold,
  };
};

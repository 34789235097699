import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';
import { viewBoxes } from './CustomSvgIcons.types';

const SvgPath = ({
  iconType,
  shapeId,
  solidColor,
}: SvgPathProps): ReactJSXElement => {
  if (iconType === 'bold') {
    return (
      <path
        d="M6.5 20H13.5M16.75 20H20C20.552 20 21 19.552 21 19V4C21 3.448 20.552 3 20 3H13.5C12.948 3 12.5 3.448 12.5 4V6.187M10 17V20M16 17H4C3.448 17 3 16.552 3 16V8C3 7.448 3.448 7 4 7H16C16.552 7 17 7.448 17 8V16C17 16.552 16.552 17 16 17Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        strokeWidth="1.2"
        stroke={solidColor ? 'currentColor' : ''}
        id={shapeId}
      />
    );
  } else if (iconType === 'normal') {
    return (
      <path
        d="M10.8333 33.3333H22.5M27.9167 33.3333H33.3333C34.2533 33.3333 35 32.5867 35 31.6667V6.66667C35 5.74667 34.2533 5 33.3333 5H22.5C21.58 5 20.8333 5.74667 20.8333 6.66667V10.3117M16.6667 28.3333V33.3333M26.6667 28.3333H6.66667C5.74667 28.3333 5 27.5867 5 26.6667V13.3333C5 12.4133 5.74667 11.6667 6.66667 11.6667H26.6667C27.5867 11.6667 28.3333 12.4133 28.3333 13.3333V26.6667C28.3333 27.5867 27.5867 28.3333 26.6667 28.3333Z"
        strokeLinecap="round"
        strokeWidth="1.1"
        strokeLinejoin="round"
        stroke={solidColor ? 'currentColor' : ''}
        fill="none"
        id={shapeId}
      />
    );
  } else {
    return (
      <path
        d="M21.6667 66.6667H45M55.8333 66.6667H66.6667C68.5067 66.6667 70 65.1733 70 63.3333V13.3333C70 11.4933 68.5067 10 66.6667 10H45C43.16 10 41.6667 11.4933 41.6667 13.3333V20.6233M33.3333 56.6667V66.6667M53.3333 56.6667H13.3333C11.4933 56.6667 10 55.1733 10 53.3333V26.6667C10 24.8267 11.4933 23.3333 13.3333 23.3333H53.3333C55.1733 23.3333 56.6667 24.8267 56.6667 26.6667V53.3333C56.6667 55.1733 55.1733 56.6667 53.3333 56.6667Z"
        strokeLinecap="round"
        strokeWidth="1.4"
        strokeLinejoin="round"
        stroke={solidColor ? 'currentColor' : ''}
        fill="none"
        id={shapeId}
      />
    );
  }
};

export const WebIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_webCurrentColorGradient`;
  const newColorId = `${prefix}_webNewColorGradient`;
  const shapeId = `${prefix}_webShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.raspberryColors?.PLUS_2,
    },
  };
  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.raspberryColors?.PLUS_3,
      stop: theme.palette.raspberryColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox={viewBoxes[iconType]}>
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>

          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

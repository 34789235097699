import { ThemeProvider, CssBaseline } from '@mui/material';
import React from 'react';
import { theme } from '../theme/algaeTheme';
import { ThemeContext } from '../../context/themeContext/themeContext';

export const RenderWithAlgeaTheme: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ThemeContext.Provider
      value={{
        theme: {
          type: 'light',
        },
        changeTheme: () => true,
        changeScrollTarget: () => true,
        scrollTarget: null,
      }}>
      {' '}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

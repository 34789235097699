import { Hidden, Stack, Box, useTheme } from '@mui/material';
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useReducer,
} from 'react';
import CrossIcon from '../../../assets/svg/AlgeaTheme/cross.svg';
import HamburgerIcon from '../../../assets/svg/AlgeaTheme/hamburger.svg';
import { isDarkOrNavy } from '../../../utils/darkOrNavy';
import { Logo } from '../../Logo';
import {
  Button,
  CareersSubmenu,
  MenuItem,
  MenuItemWithCollapse,
  MenuItemIcon,
  ResourcesSubmenu,
  Section,
  ServicesSubmenu,
  SubmenuInsideContainer,
  Submenu,
  ProductsSubmenu,
} from '../index';
import { Link } from '../Link';
import { SUBMENUS, submenusWhichAction } from '../utils/SubmenusUtils';
import {
  reducer,
  initialState,
  doChangeCollapseUnmounted,
  doChangeHoverState,
  doSetCurrentSub,
  doSetIsUnwantedTouch,
  doOnSubmenuClose,
} from './DesktopHeaderReducer';
import type {
  AnimateIconProps,
  HeaderProps,
  IState,
  IView,
  TMenu,
} from './Header.types';
import {
  StyledHeader,
  StyledTextContainer,
  StyledWrapper,
  StyledImg,
  StyledCollapse,
  StyledMenuItem,
  StyledMenuItemIcon,
  StyledButtonWrapper,
  StyledButtonAnimationWrapper,
} from './styles';
const defaultState: IState = {
  services: false,
  resources: false,
  careers: false,
  products: false,
};

const AnimatedIcon = ({
  displayMobile,
  mobileDisplayHandler,
}: AnimateIconProps) => {
  return (
    <StyledButtonAnimationWrapper>
      <StyledImg
        src={CrossIcon}
        onClick={mobileDisplayHandler}
        sx={displayMobile ? { opacity: 1, transform: 'scale(1)' } : {}}
      />
      <StyledImg
        src={HamburgerIcon}
        onClick={mobileDisplayHandler}
        sx={displayMobile ? {} : { opacity: 1, transform: 'scale(1)' }}
      />
    </StyledButtonAnimationWrapper>
  );
};

const MobileView = ({
  displayHandler,
  display,
  closeAll,
  blogs,
  background,
}: IView) => {
  const [displayMobile, setDisplayMobile] = useState(false);
  const [collapseHeight, setCollapseHeight] = useState(0);
  const mainNavbarRef = useRef(null);
  const handleResize = useCallback(() => {
    if (mainNavbarRef.current !== null)
      setCollapseHeight(
        window.innerHeight - mainNavbarRef.current['clientHeight']
      );
  }, []);

  const mobileDisplayHandler = () => {
    if (displayMobile) {
      setDisplayMobile(false);
      window.removeEventListener('resize', handleResize);
      closeAll();
      document.body.style.overflow = 'auto';
    } else {
      setDisplayMobile(true);
      if (mainNavbarRef.current !== null)
        setCollapseHeight(
          window.innerHeight - mainNavbarRef.current['clientHeight']
        );
      window.addEventListener('resize', handleResize);
      document.body.style.overflow = 'hidden';
    }
  };

  const theme = useTheme();
  const useDarkOrNavy = isDarkOrNavy(theme);
  const isNavyMode: boolean = theme.palette.type === 'navy';

  return (
    <>
      <Box height="80px" />
      <StyledHeader
        background={background}
        sx={displayMobile ? { height: 'auto' } : {}}>
        <Section
          width="normal"
          backgroundColor={background ?? '#fff'}
          heightRef={mainNavbarRef}>
          <StyledWrapper
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Logo algeaTheme />
            <Stack direction="row" spacing={2}>
              <Link
                to="/contact"
                sx={{
                  display: !useDarkOrNavy
                    ? {
                        xs: 'none',
                        md: 'block',
                      }
                    : 'block',
                }}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ maxWidth: '288px' }}>
                  Contact
                </Button>
              </Link>

              <AnimatedIcon
                displayMobile={displayMobile}
                mobileDisplayHandler={mobileDisplayHandler}
              />
            </Stack>
          </StyledWrapper>
        </Section>
        <Submenu
          isOpen={displayMobile}
          closeMenu={mobileDisplayHandler}
          backDropFilter={isNavyMode ? true : false}>
          <Stack
            sx={{ height: `${collapseHeight}px` }}
            justifyContent="space-between">
            <StyledTextContainer>
              <StyledMenuItemIcon
                onClick={() => displayHandler && displayHandler('services')}
                open={display?.services}>
                Services
              </StyledMenuItemIcon>
              <StyledCollapse
                in={display?.services}
                timeout={500}
                unmountOnExit>
                <ServicesSubmenu />
              </StyledCollapse>
              <StyledMenuItemIcon
                onClick={() => displayHandler && displayHandler('products')}
                open={display?.products}>
                Products
              </StyledMenuItemIcon>
              <StyledCollapse
                in={display?.products}
                timeout={500}
                unmountOnExit>
                <ProductsSubmenu />
              </StyledCollapse>
              <StyledMenuItem
                to="/case-studies"
                linkProps={{ sx: { ...theme.mixins.mobilePadding('16px') } }}>
                Case Studies
              </StyledMenuItem>
              <StyledMenuItemIcon
                onClick={() => displayHandler && displayHandler('careers')}
                open={display?.careers}>
                Careers
              </StyledMenuItemIcon>
              <StyledCollapse in={display?.careers} timeout={500} unmountOnExit>
                <CareersSubmenu />
              </StyledCollapse>
              <StyledMenuItemIcon
                onClick={() => displayHandler && displayHandler('resources')}
                open={display?.resources}>
                Resources
              </StyledMenuItemIcon>
              <StyledCollapse
                in={display?.resources}
                timeout={500}
                unmountOnExit>
                <ResourcesSubmenu blogs={blogs} />
              </StyledCollapse>
              <StyledMenuItem
                to="/team"
                linkProps={{ sx: { ...theme.mixins.mobilePadding('16px') } }}>
                About us
              </StyledMenuItem>
            </StyledTextContainer>

            <StyledButtonWrapper
              sx={{
                display: useDarkOrNavy
                  ? {
                      xs: 'none',
                      md: 'flex',
                    }
                  : 'flex',
              }}>
              <Link
                to="/contact"
                sx={{
                  width: '100%',
                  maxWidth: '288px',
                  marginBottom: '24px',
                }}>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ width: '100%', maxWidth: '288px' }}>
                  Contact
                </Button>
              </Link>
            </StyledButtonWrapper>
          </Stack>
        </Submenu>
      </StyledHeader>
    </>
  );
};

const DesktopView = ({ closeAll, blogs, background }: IView) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleOnSubmenuClose = useCallback(
    (sub: SUBMENUS | null) => {
      dispatch(doOnSubmenuClose(sub));
    },
    [dispatch]
  );
  const handleCollapseUnmounted = useCallback(
    (value: boolean) => {
      dispatch(doChangeCollapseUnmounted(value));
    },
    [dispatch]
  );

  const handleUnwantedTouch = useCallback(
    (value: boolean) => {
      dispatch(doSetIsUnwantedTouch(value));
    },
    [dispatch]
  );
  const handleHoverStateChange = useCallback(
    (type: string, value: boolean) => {
      dispatch(doChangeHoverState(type, value));
    },
    [dispatch]
  );

  const handleCurrentSubChange = useCallback(
    (sub: SUBMENUS | null) => {
      dispatch(doSetCurrentSub(sub));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!state.isCollapseUnmounted || !state.currentSubmenu) return;
    if (state.isUnwantedTouch) {
      handleUnwantedTouch(false);

      if (state.prevSubmenu === state.currentSubmenu)
        handleCurrentSubChange(null);
      return;
    }
    handleHoverStateChange(submenusWhichAction[state.currentSubmenu], true);
  }, [
    handleCurrentSubChange,
    handleHoverStateChange,
    handleUnwantedTouch,
    state,
  ]);

  return (
    <>
      <Box height="100px"></Box>
      <StyledHeader background={background}>
        <Section width="normal" backgroundColor={background ?? '#fff'}>
          <StyledWrapper
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Logo algeaTheme />
            <StyledTextContainer
              direction="row"
              justifyContent="space-around"
              alignItems="center">
              <MenuItemWithCollapse
                onSubmenuClose={handleOnSubmenuClose}
                onSubmenuChange={handleCurrentSubChange}
                state={state}
                submenu={SUBMENUS.SERVICES}>
                <MenuItemIcon open={state.servicesHover}>Services</MenuItemIcon>
              </MenuItemWithCollapse>
              <MenuItemWithCollapse
                onSubmenuClose={handleOnSubmenuClose}
                onSubmenuChange={handleCurrentSubChange}
                state={state}
                submenu={SUBMENUS.PRODUCTS}>
                <MenuItemIcon open={state.productsHover}>Products</MenuItemIcon>
              </MenuItemWithCollapse>

              <MenuItem to="/case-studies">Case Studies</MenuItem>
              <MenuItemWithCollapse
                onSubmenuClose={handleOnSubmenuClose}
                onSubmenuChange={handleCurrentSubChange}
                state={state}
                submenu={SUBMENUS.CAREERS}>
                <MenuItemIcon open={state.careersHover}>Careers</MenuItemIcon>
              </MenuItemWithCollapse>
              <MenuItemWithCollapse
                onSubmenuClose={handleOnSubmenuClose}
                onSubmenuChange={handleCurrentSubChange}
                state={state}
                submenu={SUBMENUS.RESOURCES}>
                <MenuItemIcon open={state.resourceHover}>
                  Resources
                </MenuItemIcon>
              </MenuItemWithCollapse>
              <MenuItem to="/team">About us</MenuItem>
            </StyledTextContainer>
            <Link to="/contact">
              <Button variant="contained" size="medium">
                Contact
              </Button>
            </Link>
          </StyledWrapper>
        </Section>
        <Submenu
          isOpen={state.servicesHover}
          closeMenu={closeAll}
          noPadding
          setUnmounted={handleCollapseUnmounted}>
          <SubmenuInsideContainer
            onUnwantedTouch={handleUnwantedTouch}
            onHoverStateChange={handleHoverStateChange}
            submenu={SUBMENUS.SERVICES}>
            <ServicesSubmenu />
          </SubmenuInsideContainer>
        </Submenu>
        <Submenu
          isOpen={state.productsHover}
          closeMenu={closeAll}
          noPadding
          setUnmounted={handleCollapseUnmounted}>
          <SubmenuInsideContainer
            onUnwantedTouch={handleUnwantedTouch}
            onHoverStateChange={handleHoverStateChange}
            submenu={SUBMENUS.PRODUCTS}>
            <ProductsSubmenu />
          </SubmenuInsideContainer>
        </Submenu>
        <Submenu
          isOpen={state.resourceHover}
          closeMenu={closeAll}
          noPadding
          setUnmounted={handleCollapseUnmounted}>
          <SubmenuInsideContainer
            onUnwantedTouch={handleUnwantedTouch}
            onHoverStateChange={handleHoverStateChange}
            submenu={SUBMENUS.RESOURCES}>
            <ResourcesSubmenu blogs={blogs} />
          </SubmenuInsideContainer>
        </Submenu>
        <Submenu
          isOpen={state.careersHover}
          closeMenu={closeAll}
          noPadding
          setUnmounted={handleCollapseUnmounted}>
          <SubmenuInsideContainer
            onUnwantedTouch={handleUnwantedTouch}
            onHoverStateChange={handleHoverStateChange}
            submenu={SUBMENUS.CAREERS}>
            <CareersSubmenu />
          </SubmenuInsideContainer>
        </Submenu>
      </StyledHeader>
    </>
  );
};

export const Header = ({ blogs, background }: HeaderProps) => {
  const theme = useTheme();
  const [display, setDisplay] = useState(defaultState);
  const [isMobile, setMobile] = useState<boolean>(false);

  useEffect(() => {
    setMobile(window.innerWidth <= theme.breakpoints.values.md);
    const handleWindowResize = () =>
      setMobile(window.innerWidth <= theme.breakpoints.values.md);
    window.addEventListener('resize', handleWindowResize);
  }, [theme.breakpoints.values.md]);

  const displayHandler = (menu: TMenu) => {
    setDisplay({
      ...defaultState,
      [menu]: !display[menu],
    });
  };

  const closeAll = () => {
    setDisplay(defaultState);
  };

  return (
    <>
      {isMobile ? (
        <MobileView
          displayHandler={displayHandler}
          closeAll={closeAll}
          display={display}
          blogs={blogs}
          background={background}
        />
      ) : (
        <DesktopView
          closeAll={closeAll}
          blogs={blogs}
          display={display}
          displayHandler={displayHandler}
          background={background}
        />
      )}
    </>
  );
};

import type { ContainerProps, StackProps } from '@mui/material';
import type { RefObject } from 'react';

export interface SectionProps extends StackProps {
  width?: 'full' | 'normal' | 'small';
  mobilePadding?: boolean;
  borderBottomOn?: boolean;
  borderTopOn?: boolean;
  shouldUseInView?: boolean;
  forceLightBackground?: boolean;
  sectionRef?: RefObject<HTMLDivElement> | null;
}

const prefix = 'Section';

export const sectionClasses = {
  full: `${prefix}-full`,
  normal: `${prefix}-normal`,
  small: `${prefix}-small`,
  mobilePadding: `${prefix}-mobilePadding`,
};

import type { CourseCardProps } from '../CourseCard/CourseCard.types';

export const courseTimelineContent: CourseCardProps[] = [
  {
    heading: 'WEEK 1.',
    subheading: 'Blockchain Basics',
    description: 'Project: Simple on-chain token exchange',
  },
  {
    heading: 'WEEK 2.',
    subheading: 'Smart Contracts, Tokens',
    description:
      'Project: Flash Loans, Liquidity Pools, Distribution of Rewards',
  },
  {
    heading: 'WEEK 3.',
    subheading: 'Advanced Tools',
    description: 'Project: Creating and testing subgraphs',
  },
  {
    heading: 'WEEK 4.',
    subheading: 'Dapp Development',
    description: 'Project: Frontend integration in React app',
  },
  {
    heading: 'You’re ready!',
    description: 'Start your first commercial project with Rumble Fish',
  },
];

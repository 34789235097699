import { Stack, styled, Typography } from '@mui/material';

export const StyledWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  justifyContent: 'center',
  alignItems: 'center',
}));
export const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.SECONDARY,
  whiteSpace: 'nowrap',
}));

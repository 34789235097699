import { useTheme } from '@mui/material';
import React from 'react';
import AlgeaThemeLogo from '../../assets/png/AlgeaTheme/logo.png';
import logoWhiteDarkMode from '../../assets/png/logo-white.png';
import logo from '../../assets/png/logo.png';
import { isDarkOrNavy } from '../../utils/darkOrNavy';
import type { LogoProps } from './Logo.types';
import { NextLink } from '../NextLink';

export const Logo = ({ algeaTheme }: LogoProps) => {
  const theme = useTheme();
  const useIsDarkOrNavyMode = isDarkOrNavy(theme);
  return (
    <NextLink href="/">
      <img
        src={
          useIsDarkOrNavyMode
            ? logoWhiteDarkMode
            : algeaTheme
            ? AlgeaThemeLogo
            : logo
        }
        width={algeaTheme ? '156px' : '208px'}
        height={algeaTheme ? '48px' : '64px'}
        alt="rumble fish logo"
        draggable="false"
        style={{ userSelect: 'none', cursor: 'pointer' }}
      />
    </NextLink>
  );
};

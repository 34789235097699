import type { StyledComponentProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';
import { colors } from '../../theme/default/color';
import type { SectionProps, TSpacingType } from './Section.types';

const PREFIX = 'Section';

const classes = {
  root: `${PREFIX}-root`,
  narrowStyle: `${PREFIX}-narrowStyle`,
  topOverlay: `${PREFIX}-topOverlay`,
  grayBg: `${PREFIX}-grayBg`,
  blueBg: `${PREFIX}-blueBg`,
  darkBlueBg: `${PREFIX}-darkBlueBg`,
  noneBg: `${PREFIX}-noneBg`,
  relative: `${PREFIX}-relative`,
  content: `${PREFIX}-content`,
  spacingSmall: `${PREFIX}-spacingSmall`,
};

const Root = styled('section')(
  ({
    theme: {
      spacing,
      breakpoints,
      template: { normalSectionWidth, narrowSectionWidth },
    },
  }) => ({
    [`&.${classes.root}`]: {
      display: 'grid',
      backgroundColor: colors.white,
      gridTemplateColumns: `1fr minmax(0, ${normalSectionWidth}) 1fr`,
      padding: spacing(10, 5),
      [breakpoints.only('xs')]: {
        padding: spacing(10, 2),
      },
    },

    [`&.${classes.narrowStyle}`]: {
      gridTemplateColumns: `1fr minmax(0, ${narrowSectionWidth}) 1fr`,
    },

    [`&.${classes.topOverlay}`]: {
      marginTop: `-${spacing(3)}`,
      padding: spacing(0, 5, 10),

      [breakpoints.down('md')]: {
        marginTop: 0,
      },
      [breakpoints.only('xs')]: {
        padding: spacing(10, 2),
      },
    },

    [`&.${classes.grayBg}`]: {
      backgroundColor: colors.footerGrey,
    },

    [`&.${classes.relative}`]: {
      position: 'relative',
    },

    [`&.${classes.blueBg}`]: {
      backgroundColor: colors.navyBlue,
    },

    [`&.${classes.darkBlueBg}`]: {
      backgroundColor: colors.darkblue,
    },
    [`&.${classes.noneBg}`]: {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    [`&.${classes.spacingSmall}`]: {
      padding: spacing(2, 5),
      [breakpoints.only('xs')]: {
        padding: spacing(2, 2),
      },
    },

    [`& .${classes.content}`]: {
      gridColumn: '2 / span 1',
    },
  })
);

export const Section: React.FC<SectionProps> = ({
  type = 'normal',
  theme = 'white',
  topOverlay = false,
  relative = false,
  children,
  spacingType = 'large',
}) => {
  return (
    <Root
      className={clsx(classes.root, {
        [classes.narrowStyle]: type === 'narrow',
        [classes.grayBg]: theme === 'gray',
        [classes.blueBg]: theme === 'blue',
        [classes.noneBg]: theme === 'none',
        [classes.darkBlueBg]: theme === 'darkBlue',
        [classes.relative]: relative === true,
        [classes.topOverlay]: topOverlay,
        [classes.spacingSmall]: spacingType === 'small',
      })}>
      <div className={classes.content}>{children}</div>
    </Root>
  );
};

import { Hidden } from '@mui/material';
import React from 'react';
import type { HeadlineWithDashProps } from './HeadlineWithDash.types';
import { StyledHeader, StyledHeaderBox, StyledHeaderDash } from './styles';

export const HeadlineWithDash = ({
  children,
  ...props
}: HeadlineWithDashProps) => (
  <StyledHeaderBox {...props}>
    <StyledHeaderDash
      sx={{
        display: {
          xs: 'none',
          md: 'block',
        },
      }}
    />

    <StyledHeader variant="h2">{children}</StyledHeader>
  </StyledHeaderBox>
);

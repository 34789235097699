import { CSSProperties } from 'react';

export interface IGradientsMixins {
  redGradient: CSSProperties;
  redGradientTransparent: CSSProperties;
  raspberryGradient: CSSProperties;
  raspberryGradientTransparent: CSSProperties;
  magentaGradient: CSSProperties;
  magentaGradientTransparent: CSSProperties;
  purpleGradient: CSSProperties;
  purpleGradientTransparent: CSSProperties;
  grapeGradient: CSSProperties;
  grapeGradientTransparent: CSSProperties;
  violetGradient: CSSProperties;
  violetGradientTransparent: CSSProperties;
  blueGradient: CSSProperties;
  blueGradientTransparent: CSSProperties;
  cyanGradient: CSSProperties;
  cyanGradientTransparent: CSSProperties;
  tealGradient: CSSProperties;
  tealGradientTransparent: CSSProperties;
  aquamarineGradient: CSSProperties;
  aquamarineGradientTransparent: CSSProperties;
  greenGradient: CSSProperties;
  greenGradientTransparent: CSSProperties;
  emeraldGradient: CSSProperties;
  emeraldGradientTransparent: CSSProperties;
  orangeGradient: CSSProperties;
  orangeGradientTransparent: CSSProperties;
  neutralGradient: CSSProperties;
  neutralGradientTransparent: CSSProperties;
}
export const gradientsDark: IGradientsMixins = {
  redGradient: {
    background: 'linear-gradient(275deg, #F49090 0%, #FFF 100%)',
  },
  redGradientTransparent: {
    background: '#070E15',
    boxShadow:
      '0px -4px 8px 0px rgba(242, 100, 100, 0.15) inset, -16px -24px 16px 0px rgba(242, 100, 100, 0.15) inset, -8px -4px 12px 0px rgba(244, 144, 144, 0.45) inset, -2px -2px 4px 0px rgba(252, 207, 207, 0.35) inset',
  },
  raspberryGradient: {
    background: 'linear-gradient(275deg, #EC8DAD 0%, #FFF 100%)',
  },
  raspberryGradientTransparent: {
    background: '#070E15',
    boxShadow:
      '0px -4px 8px 0px rgba(228, 98, 141, 0.15) inset, -16px -24px 16px 0px rgba(228, 98, 141, 0.15) inset, -8px -4px 12px 0px rgba(236, 141, 173, 0.45) inset, -2px -2px 4px 0px rgba(248, 206, 220, 0.35) inset',
  },
  magentaGradient: {
    background: 'linear-gradient(275deg, #EC89CB 0%, #FFF 100%)',
  },
  magentaGradientTransparent: {
    background: '#070E15',
    boxShadow:
      '0px -4px 8px 0px rgba(222, 94, 179, 0.15) inset, -16px -24px 16px 0px rgba(222, 94, 179, 0.10) inset, -8px -4px 12px 0px rgba(236, 137, 203, 0.45) inset, -2px -2px 4px 0px rgba(247, 202, 232, 0.35) inset',
  },
  purpleGradient: {
    background: 'linear-gradient(275deg, #D98DEC 0%, #FFF 100%)',
  },
  purpleGradientTransparent: {
    background: '#070E15',
    boxShadow:
      '0px -4px 8px 0px rgba(153, 57, 172, 0.15) inset, -16px -24px 16px 0px rgba(196, 102, 219, 0.10) inset, -8px -4px 12px 0px rgba(217, 141, 236, 0.45) inset, -2px -2px 4px 0px rgba(244, 202, 247, 0.35) inset',
  },
  grapeGradient: {
    background: 'linear-gradient(275deg, #C494F4 0%, #FFF 100%)',
  },
  grapeGradientTransparent: {
    background: '#070E15',
    boxShadow:
      '0px -4px 8px 0px rgba(99, 78, 202, 0.10) inset, -16px -24px 16px 0px rgba(173, 113, 234, 0.10) inset, -8px -4px 12px 0px rgba(196, 148, 244, 0.45) inset, -2px -2px 4px 0px rgba(229, 208, 251, 0.35) inset',
  },
  violetGradient: {
    background: 'linear-gradient(275deg, #ABA2FB 0%, #FFF 100%)',
  },
  violetGradientTransparent: {
    background: '#070E15',
    boxShadow:
      '0px -4px 8px 0px rgba(99, 78, 202, 0.15) inset, -16px -24px 16px 0px rgba(145, 126, 241, 0.10) inset, -8px -4px 12px 0px rgba(171, 162, 251, 0.45) inset, -2px -2px 4px 0px rgba(220, 214, 255, 0.35) inset',
  },
  blueGradient: {
    background: 'linear-gradient(275deg, #8DB0FB 0%, #FFF 100%)',
  },
  blueGradientTransparent: {
    background: '#070E15',
    boxShadow:
      '0px -4px 8px 0px rgba(48, 98, 212, 0.15) inset, -16px -24px 16px 0px rgba(103, 146, 244, 0.10) inset, -8px -4px 12px 0px rgba(141, 176, 251, 0.45) inset, -2px -2px 4px 0px rgba(205, 221, 255, 0.35) inset',
  },
  cyanGradient: {
    background: 'linear-gradient(275deg, #79BCF6 0%, #FFF 100%)',
  },
  cyanGradientTransparent: {
    background: '#070E15',
    boxShadow:
      '0px -4px 8px 0px rgba(8, 112, 186, 0.15) inset, -16px -24px 16px 0px rgba(86, 153, 220, 0.10) inset, -8px -4px 12px 0px rgba(121, 188, 246, 0.45) inset, -2px -2px 4px 0px rgba(194, 227, 255, 0.35) inset',
  },
  tealGradient: {
    background: 'linear-gradient(275deg, #7AC0DC 0%, #FFF 100%)',
  },
  tealGradientTransparent: {
    background: '#070E15',
    boxShadow:
      '0px -4px 8px 0px rgba(7, 117, 151, 0.15) inset, -16px -24px 16px 0px rgba(79, 158, 186, 0.10) inset, -8px -4px 12px 0px rgba(122, 192, 220, 0.45) inset, -2px -2px 4px 0px rgba(184, 232, 245, 0.35) inset',
  },
  aquamarineGradient: {
    background: 'linear-gradient(275deg, #6EC9BF 0%, #FFF 100%)',
  },
  aquamarineGradientTransparent: {
    background: '#070E15',
    boxShadow:
      '0px -4px 8px 0px rgba(9, 124, 105, 0.15) inset, -16px -24px 16px 0px rgba(61, 164, 151, 0.10) inset, -8px -4px 12px 0px rgba(110, 201, 191, 0.45) inset, -2px -2px 4px 0px rgba(190, 233, 228, 0.35) inset',
  },
  greenGradient: {
    background: 'linear-gradient(275deg, #75CC9E 0%, #FFF 100%)',
  },
  greenGradientTransparent: {
    background: '#070E15',
    boxShadow:
      '0px -4px 8px 0px rgba(29, 124, 77, 0.15) inset, -16px -24px 16px 0px rgba(74, 165, 120, 0.10) inset, -8px -4px 12px 0px rgba(117, 204, 158, 0.45) inset, -2px -2px 4px 0px rgba(194, 235, 213, 0.35) inset',
  },
  emeraldGradient: {
    background: 'linear-gradient(275deg, #87C987 0%, #FFF 100%)',
  },
  emeraldGradientTransparent: {
    background: '#070E15',
    boxShadow:
      '0px -4px 8px 0px rgba(52, 116, 52, 0.15) inset, -16px -24px 16px 0px rgba(85, 165, 85, 0.10) inset, -8px -4px 12px 0px rgba(135, 201, 135, 0.45) inset, -2px -2px 4px 0px rgba(198, 236, 198, 0.35) inset',
  },
  orangeGradient: {
    background: 'linear-gradient(275deg, #FEB872 0%, #FFF 100%)',
  },
  orangeGradientTransparent: {
    background: '#070E15',
    boxShadow:
      '0px -4px 8px 0px rgba(245, 150, 56, 0.15) inset, -16px -24px 16px 0px rgba(246, 163, 81, 0.10) inset, -8px -4px 12px 0px rgba(254, 184, 114, 0.45) inset, -2px -2px 4px 0px rgba(255, 212, 168, 0.35) inset',
  },
  neutralGradient: {
    background: 'linear-gradient(275deg, #7E8B99 0%, #FFF 100%)',
  },
  neutralGradientTransparent: {
    background: '#070E15',
    boxShadow:
      '0px -4px 8px 0px rgba(74, 84, 94, 0.15) inset, -16px -24px 16px 0px rgba(85, 95, 109, 0.10) inset, -8px -4px 12px 0px rgba(126, 139, 153, 0.45) inset, -2px -2px 4px 0px rgba(158, 168, 179, 0.35) inset',
  },
};
export const gradientsLight: IGradientsMixins = {
  redGradient: {
    background: 'linear-gradient(275deg, #F49090 0%, #C53434 100%)',
  },
  redGradientTransparent: {
    background: '#FFF',
    boxShadow:
      '0px -4px 8px 0px rgba(242, 100, 100, 0.15) inset, -16px -24px 16px 0px rgba(242, 100, 100, 0.15) inset, -8px -4px 12px 0px rgba(244, 144, 144, 0.45) inset, -2px -2px 4px 0px rgba(252, 207, 207, 0.35) inset',
  },

  raspberryGradient: {
    background: 'linear-gradient(275deg, #EC8DAD 0%, #C03060 100%)',
  },
  raspberryGradientTransparent: {
    background: '#FFF',
    boxShadow:
      '0px -4px 8px 0px rgba(228, 98, 141, 0.15) inset, -16px -24px 16px 0px rgba(228, 98, 141, 0.15) inset, -8px -4px 12px 0px rgba(236, 141, 173, 0.45) inset, -2px -2px 4px 0px rgba(248, 206, 220, 0.35) inset',
  },

  magentaGradient: {
    background: 'linear-gradient(275deg, #EC89CB 0%, #B12F86 100%)',
  },
  magentaGradientTransparent: {
    background: '#FFF',
    boxShadow:
      '0px -4px 8px 0px rgba(222, 94, 179, 0.15) inset, -16px -24px 16px 0px rgba(222, 94, 179, 0.10) inset, -8px -4px 12px 0px rgba(236, 137, 203, 0.45) inset, -2px -2px 4px 0px rgba(247, 202, 232, 0.35) inset',
  },
  purpleGradient: {
    background: 'linear-gradient(275deg, #D98DEC 0%, #9939AC 100%)',
  },
  purpleGradientTransparent: {
    background: '#FFF',
    boxShadow:
      '0px -4px 8px 0px rgba(153, 57, 172, 0.15) inset, -16px -24px 16px 0px rgba(196, 102, 219, 0.10) inset, -8px -4px 12px 0px rgba(217, 141, 236, 0.45) inset, -2px -2px 4px 0px rgba(244, 202, 247, 0.35) inset',
  },
  grapeGradient: {
    background: 'linear-gradient(275deg, #C494F4 0%, #634ECA 100%)',
  },
  grapeGradientTransparent: {
    background: '#FFF',
    boxShadow:
      '0px -4px 8px 0px rgba(99, 78, 202, 0.10) inset, -16px -24px 16px 0px rgba(173, 113, 234, 0.10) inset, -8px -4px 12px 0px rgba(196, 148, 244, 0.45) inset, -2px -2px 4px 0px rgba(229, 208, 251, 0.35) inset',
  },
  violetGradient: {
    background: 'linear-gradient(275deg, #ABA2FB 0%, #634ECA 100%)',
  },
  violetGradientTransparent: {
    background: '#FFF',
    boxShadow:
      '0px -4px 8px 0px rgba(99, 78, 202, 0.15) inset, -16px -24px 16px 0px rgba(145, 126, 241, 0.10) inset, -8px -4px 12px 0px rgba(171, 162, 251, 0.45) inset, -2px -2px 4px 0px rgba(220, 214, 255, 0.35) inset',
  },
  blueGradient: {
    background: 'linear-gradient(275deg, #8DB0FB 0%, #3062D4 100%)',
  },
  blueGradientTransparent: {
    background: '#FFF',
    boxShadow:
      '0px -4px 8px 0px rgba(48, 98, 212, 0.15) inset, -16px -24px 16px 0px rgba(103, 146, 244, 0.10) inset, -8px -4px 12px 0px rgba(141, 176, 251, 0.45) inset, -2px -2px 4px 0px rgba(205, 221, 255, 0.35) inset',
  },
  cyanGradient: {
    background: 'linear-gradient(275deg, #79BCF6 0%, #0870BA 100%)',
  },
  cyanGradientTransparent: {
    background: '#FFF',
    boxShadow:
      '0px -4px 8px 0px rgba(8, 112, 186, 0.15) inset, -16px -24px 16px 0px rgba(86, 153, 220, 0.10) inset, -8px -4px 12px 0px rgba(121, 188, 246, 0.45) inset, -2px -2px 4px 0px rgba(194, 227, 255, 0.35) inset',
  },
  tealGradient: {
    background: 'linear-gradient(275deg, #7AC0DC 0%, #077597 100%)',
  },
  tealGradientTransparent: {
    background: '#FFF',
    boxShadow:
      '0px -4px 8px 0px rgba(7, 117, 151, 0.15) inset, -16px -24px 16px 0px rgba(79, 158, 186, 0.10) inset, -8px -4px 12px 0px rgba(122, 192, 220, 0.45) inset, -2px -2px 4px 0px rgba(184, 232, 245, 0.35) inset',
  },
  aquamarineGradient: {
    background: 'linear-gradient(275deg, #6EC9BF 0%, #097C69 100%)',
  },
  aquamarineGradientTransparent: {
    background: '#FFF',
    boxShadow:
      '0px -4px 8px 0px rgba(9, 124, 105, 0.15) inset, -16px -24px 16px 0px rgba(61, 164, 151, 0.10) inset, -8px -4px 12px 0px rgba(110, 201, 191, 0.45) inset, -2px -2px 4px 0px rgba(190, 233, 228, 0.35) inset',
  },
  greenGradient: {
    background: 'linear-gradient(275deg, #75CC9E 0%, #1D7C4D 100%)',
  },
  greenGradientTransparent: {
    background: '#FFF',
    boxShadow:
      '0px -4px 8px 0px rgba(29, 124, 77, 0.15) inset, -16px -24px 16px 0px rgba(74, 165, 120, 0.10) inset, -8px -4px 12px 0px rgba(117, 204, 158, 0.45) inset, -2px -2px 4px 0px rgba(194, 235, 213, 0.35) inset',
  },
  emeraldGradient: {
    background: 'linear-gradient(275deg, #87C987 0%, #347434 100%)',
  },
  emeraldGradientTransparent: {
    background: '#FFF',
    boxShadow:
      '0px -4px 8px 0px rgba(52, 116, 52, 0.15) inset, -16px -24px 16px 0px rgba(85, 165, 85, 0.10) inset, -8px -4px 12px 0px rgba(135, 201, 135, 0.45) inset, -2px -2px 4px 0px rgba(198, 236, 198, 0.35) inset',
  },
  orangeGradient: {
    background: 'linear-gradient(275deg, #FEB872 0%, #F59638 100%)',
  },
  orangeGradientTransparent: {
    background: '#FFF',
    boxShadow:
      '0px -4px 8px 0px rgba(245, 150, 56, 0.15) inset, -16px -24px 16px 0px rgba(246, 163, 81, 0.10) inset, -8px -4px 12px 0px rgba(254, 184, 114, 0.45) inset, -2px -2px 4px 0px rgba(255, 212, 168, 0.35) inset',
  },
  neutralGradient: {
    background: 'linear-gradient(275deg, #7E8B99 0%, #4A545E 100%)',
  },
  neutralGradientTransparent: {
    background: '#FFF',
    boxShadow:
      '0px -4px 8px 0px rgba(74, 84, 94, 0.15) inset, -16px -24px 16px 0px rgba(85, 95, 109, 0.10) inset, -8px -4px 12px 0px rgba(126, 139, 153, 0.45) inset, -2px -2px 4px 0px rgba(158, 168, 179, 0.35) inset',
  },
};

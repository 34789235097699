import { type StackProps } from '@mui/material';
import { Button, Section } from '@rumblefish/ui/AlgeaTheme';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import {
  StyledStack,
  StyledBlocksStack,
  StyledHeading,
  Block,
  StyledBlocksText,
  Chequered,
  Line,
  BottomText,
} from './styles';

export const FintechWhatInsideSection = ({ ...props }: StackProps) => {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const mainTextRef = useRef<HTMLDivElement>(null);
  const sectionRef = useRef<HTMLDivElement>(null);
  const [animationTo, setAnimationTo] = useState(0);
  const boxVariant = {
    visible: (custom: number) => ({
      backgroundSize: `${custom}% 100%`,
    }),
  };
  const setAnimation = useCallback(() => {
    if (mainTextRef.current === null) return;
    const occupiedArrea = Math.abs(
      mainTextRef.current.getBoundingClientRect().top - window.innerHeight + 104
    );
    const percentage = Math.trunc(
      (occupiedArrea / mainTextRef.current.clientHeight) * 100
    );
    if (percentage > 100) setAnimationTo(100);
    else setAnimationTo(Math.abs(percentage));
    control.start('visible');
  }, [control]);
  const initial = useCallback(() => {
    if (mainTextRef.current === null) return;
    if (inView) {
      setAnimation();
    } else {
      const top = mainTextRef.current.getBoundingClientRect().top;
      if (animationTo <= 0 && animationTo >= 100) return;
      if (top > 0) setAnimationTo(0);
      else setAnimationTo(100);
    }
  }, [animationTo, inView, setAnimation]);

  const onScroll = useCallback(() => {
    initial();
  }, [initial]);

  useEffect(() => {
    initial();
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [initial, onScroll]);

  const blocks: Array<string> = [
    'how to prepare your fintech product development roadmap and what tools to use in the process',
    'how to select the right development team',
    'what tech stack would be the right fit for your fintech product',
    'what are the best UI practices to keep in mind while designing fintech products',
    'what launch strategy to use for your fintech MVP',
    'what are the fintech trends to watch in the coming years',
  ];
  const handleDownloadCopyBtn = () => {
    window.open('https://www.rumblefish.dev/pdf/your-fintech-mvp.pdf');
  };
  return (
    <Section
      width="small"
      backgroundColor="transparent"
      positionRelativeOn
      heightRef={sectionRef}>
      <Chequered />
      <StyledStack {...props}>
        <StyledHeading>
          Take a sneak peek <br /> at what’s inside:
        </StyledHeading>
        <StyledBlocksStack>
          {blocks.map((blockName, index) => (
            <Block key={index}>
              <StyledBlocksText>{blockName}</StyledBlocksText>
            </Block>
          ))}
        </StyledBlocksStack>
        <BottomText ref={mainTextRef}>
          <motion.span
            ref={ref}
            variants={boxVariant}
            initial="visible"
            animate={control}
            transition={{ duration: 0.3 }}
            custom={animationTo}
            style={{
              background:
                'linear-gradient(#fff,#fff)left no-repeat rgba(255,255,255,0.15)',
              backgroundSize: `0% 100%`,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              color: 'transparent',
            }}>
            Rumble Fish Software Development has been helping companies of all
            sizes design, build, and scale their businesses through custom
            end-to-end software development and IT mentoring. We are
            blockchain-fluent and fintech-oriented. We’ve successfully worked
            with multiple Web3 and fintech companies to help them achieve their
            goals. Download our e-book to learn all you need to know before
            building your fintech MVP.
          </motion.span>
        </BottomText>
        <Button
          sx={{
            marginTop: '3rem',
            borderRadius: '16px',
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '100%',
          }}
          onClick={handleDownloadCopyBtn}
          variant="contained"
          size="large">
          Download a free copy
        </Button>
        <Line />
      </StyledStack>
    </Section>
  );
};

import { useTheme } from '@mui/material';
import React, { useState, useCallback } from 'react';
import { Link } from '../Link';
import type { ServiceBoxProps } from './ServiceBox.types';
import {
  StyledStack,
  StyledHeadline,
  StyledIcon,
  StyledText,
  StyledTextWrapper,
  StyledButton,
} from './styles';

export const ServiceBox = ({
  headline,
  text,
  icon,
  to,
  ...props
}: ServiceBoxProps) => {
  const theme = useTheme();

  const [display, setDisplay] = useState(
    useCallback(() => theme.utils.isMobile(), [theme.utils])
  );

  const displayHandler = (display: boolean) => {
    if (theme.utils.isMobile()) {
      return;
    }
    setDisplay(display);
  };

  return (
    <StyledStack
      {...props}
      onMouseEnter={() => displayHandler(true)}
      onMouseLeave={() => displayHandler(false)}>
      <StyledTextWrapper spacing={1.5}>
        <StyledIcon>
          <img src={icon} alt="" />
        </StyledIcon>
        <StyledHeadline variant="h6">{headline}</StyledHeadline>
        <StyledText variant="bodySmall">{text}</StyledText>
      </StyledTextWrapper>
      <Link to={to}>
        <StyledButton
          variant="text"
          icon
          sx={display ? { opacity: 1, transform: 'translateX(0)' } : {}}>
          Find out more
        </StyledButton>
      </Link>
    </StyledStack>
  );
};

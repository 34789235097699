import React from 'react';

import activeDot from '../../../../assets/svg/AlgeaTheme/offer_dot_active.svg';
import inactiveDot from '../../../../assets/svg/AlgeaTheme/offer_dot_inactive.svg';
import startDot from '../../../../assets/svg/AlgeaTheme/offer_dot_start.svg';

import type { LineProps } from './Line.types';
import {
  StyledDot,
  StyledDottedLine,
  StyledLine,
  StyledLineWrapper,
} from './styles';

export const Line = ({
  percentageThreshold,
  scrollPercentagePosition,
  activeCompany,
  ...props
}: LineProps) => (
  <StyledLineWrapper {...props}>
    {percentageThreshold.map((dotPosition, index) => {
      const position = { top: `${dotPosition}%` };
      if (index === 0) {
        return <StyledDot src={startDot} key={index} sx={position} />;
      }
      return (
        <StyledDot
          src={index <= activeCompany ? activeDot : inactiveDot}
          key={index}
          sx={position}
        />
      );
    })}
    <StyledDottedLine />
    <StyledLine
      sx={{
        maxHeight: '100%',
        height: scrollPercentagePosition + '%',
      }}
    />
  </StyledLineWrapper>
);

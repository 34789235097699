import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { CustomSvgIconsProps, SvgPathProps } from '../../CustomSvgIcons.types';
import { useTheme } from '@mui/material';
import { SvgPath } from '../../SvgPath/SvgPath';

export const WalletConnectIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_walletConnectIconCurrentColorGradient`;
  const newColorId = `${prefix}_walletConnectIconNewColorGradient`;
  const shapeId = `${prefix}_walletConnectIconShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';

  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.purpleColors?.PLUS_3,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;

  return (
    <SvgIcon {...props} viewBox="0 0 61 60">
      {solidColor ? (
        <SvgPath
          d="M43.1667 32.0833L36.9167 38.3333L30.6667 32.0833L24.4167 38.3333L18.1667 32.0833M21.823 25.3229C22.9843 24.1576 24.3642 23.233 25.8836 22.6021C27.403 21.9712 29.032 21.6464 30.6772 21.6464C32.3223 21.6464 33.9513 21.9712 35.4707 22.6021C36.9901 23.233 38.37 24.1576 39.5313 25.3229M55.6667 30C55.6667 43.8071 44.4739 55 30.6667 55C16.8596 55 5.66675 43.8071 5.66675 30C5.66675 16.1929 16.8596 5 30.6667 5C44.4739 5 55.6667 16.1929 55.6667 30Z"
          thinStrokeWidth={1.2}
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              d="M43.1667 32.0833L36.9167 38.3333L30.6667 32.0833L24.4167 38.3333L18.1667 32.0833M21.823 25.3229C22.9843 24.1576 24.3642 23.233 25.8836 22.6021C27.403 21.9712 29.032 21.6464 30.6772 21.6464C32.3223 21.6464 33.9513 21.9712 35.4707 22.6021C36.9901 23.233 38.37 24.1576 39.5313 25.3229M55.6667 30C55.6667 43.8071 44.4739 55 30.6667 55C16.8596 55 5.66675 43.8071 5.66675 30C5.66675 16.1929 16.8596 5 30.6667 5C44.4739 5 55.6667 16.1929 55.6667 30Z"
              thinStrokeWidth={1.2}
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

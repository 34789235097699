import { EbookForm } from '@rumblefish/ui/AlgeaTheme';
import React, { useState } from 'react';
import { DownloadView } from './downloadView';
import type { FintechEbookFormProps } from './FintechEbookForm.types';
export const FintechEbookForm = ({
  ebookBtnText = 'Download free e-book',
  ebookSx = {},
}: FintechEbookFormProps) => {
  const [downloadView, setView] = useState<boolean>(false);
  const downloadSource = 'Fintech mvp guide form';

  if (downloadView) return <DownloadView sx={ebookSx} />;
  else
    return (
      <EbookForm
        btntext={ebookBtnText}
        sx={ebookSx}
        callback={() => setView(true)}
        downloadSource={downloadSource}
      />
    );
};

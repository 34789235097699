import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import React from 'react';
import type { BackgroudProps } from './Background.types';

const PREFIX = 'Background';

const classes = {
  root: `${PREFIX}-root`,
  color: `${PREFIX}-color`,
  background: `${PREFIX}-background`,
};

const Root = styled('div')<BackgroudProps>(({ theme, image }) => ({
  [`&.${classes.root}`]: {
    position: 'relative',
  },

  [`& .${classes.color}`]: {
    background: image?.attributes?.color,
    position: 'absolute',
    width: '100%',
    height: '520px',
    zIndex: -2,
  },

  [`& .${classes.background}`]: {
    background: `url(${image?.attributes?.image?.data?.attributes?.url})`,
    position: 'absolute',
    backgroundSize: '300%',
    width: '100%',
    height: '330px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'top',
    mixBlendMode: 'multiply',
    zIndex: -1,
    [theme.breakpoints.up('sm')]: {
      backgroundSize: '125%',
    },
    [theme.breakpoints.up('md')]: {
      backgroundSize: '100%',
    },
  },
}));

export const Background: React.FC<BackgroudProps> = ({ image }) => {
  return (
    <Root image={image} className={classes.root}>
      <Grid container>
        <div className={classes.color} />
        <Grid item className={classes.background} xs={12} />
      </Grid>
    </Root>
  );
};

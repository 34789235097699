import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import type { AvatarProps } from './ListComponent.types';

export const StyledUpTitle = styled(Typography)(
  ({ theme: { palette, breakpoints } }) => ({
    width: '100%',
    color: palette.colorBrand?.secondary,
    letterSpacing: '0.25rem',
    textTransform: 'uppercase',
    marginTop: '5%',
    fontSize: '14px',
    marginBottom: '24px',
    [breakpoints.down('sm')]: {
      marginBottom: '10px',
      paddingTop: '10%',
    },
  })
);

export const StyledTitleGrid = styled(Grid)(({ theme: { breakpoints } }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  marginBottom: '5%',
  gap: '5%',
  alignItems: 'center',
  ['& h3']: {
    width: '80%',
    [breakpoints.down('md')]: {
      width: '90% ',
    },
  },
  ['& .description']: {
    fontSize: '1.12rem',
    [breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '10px',
    },
  },
  [breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
  [breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

export const StyledList = styled(List)(({ theme: { breakpoints } }) => ({
  width: '100%',
  display: 'grid',
  gap: '5%',
  [breakpoints.up('lg')]: {
    marginBottom: '10%',
  },
  [breakpoints.down('md')]: {
    gap: '0%',
  },
  [breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: '2%',
    paddingBottom: '30%',
  },
}));

export const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'noIconBorder',
})<AvatarProps>(({ noIconBorder, theme: { palette } }) => ({
  border: noIconBorder ? 'none' : `1px solid ${palette.colorBrand?.secondary}`,
  background: 'none',
}));

export const StyledStyledListItemAvatar = styled(ListItemAvatar)(() => ({
  marginTop: 0,
}));

export const StyledListItem = styled(ListItem)(() => ({
  paddingRight: '5%',
  paddingLeft: 0,
}));

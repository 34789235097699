import { useTheme } from '@mui/material';
import React, { useState } from 'react';
import arrowDownHover from '../../../assets/svg/AlgeaTheme/arrowDownHover.svg';
import arrowDownIdle from '../../../assets/svg/AlgeaTheme/arrowDownIdle.svg';
import arrowDownWhite from '../../../assets/svg/AlgeaTheme/arrowDownWhite.svg';
import type { MenuItemIconProps } from './MenuItemIcon.types';
import {
  StyledIcon,
  StyledIconWrapper,
  StyledStack,
  StyledTypography,
} from './styles';

export const MenuItemIcon = ({
  children,
  open,
  ...props
}: MenuItemIconProps) => {
  const [hover, setHover] = useState(false);
  const theme = useTheme();
  const isMobile = theme.utils.isMobile();

  return (
    <StyledStack
      {...props}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      open={open}>
      <StyledTypography open={open} variant="buttonSmall">
        {children}
      </StyledTypography>
      <StyledIconWrapper>
        <StyledIcon
          src={arrowDownIdle}
          alt=""
          sx={!hover || isMobile ? { opacity: 1 } : {}}
          open={open}
        />
        <StyledIcon
          src={arrowDownHover}
          alt=""
          sx={hover && !isMobile ? { opacity: 1 } : {}}
          open={open}
        />
        <StyledIcon
          src={arrowDownWhite}
          alt=""
          sx={open && isMobile ? { opacity: 1 } : {}}
          open={open}
        />
      </StyledIconWrapper>
    </StyledStack>
  );
};

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { CustomSvgIconsProps } from '../../CustomSvgIcons.types';
import { useTheme } from '@mui/material';
import { SvgPath } from '../../SvgPath/SvgPath';

export const MobileDeviceMenuIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_mobileDeviceMenuIconCurrentColorGradient`;
  const newColorId = `${prefix}_mobileDeviceMenuIconNewColorGradient`;
  const shapeId = `${prefix}_mobileDeviceMenuIconShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';

  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.purpleColors?.PLUS_3,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;

  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      {solidColor ? (
        <SvgPath
          thinStrokeWidth={0.9}
          d="M24.1667 5V8.33333C24.1667 8.55435 24.0789 8.76631 23.9226 8.92259C23.7663 9.07887 23.5543 9.16667 23.3333 9.16667H16.6667C16.4457 9.16667 16.2337 9.07887 16.0774 8.92259C15.9211 8.76631 15.8333 8.55435 15.8333 8.33333V5M30 8.33333V31.6667C30 32.5507 29.6488 33.3986 29.0237 34.0237C28.3986 34.6488 27.5507 35 26.6667 35H13.3333C12.4493 35 11.6014 34.6488 10.9763 34.0237C10.3512 33.3986 10 32.5507 10 31.6667V8.33333C10 7.44928 10.3512 6.60143 10.9763 5.97631C11.6014 5.35119 12.4493 5 13.3333 5H26.6667C27.5507 5 28.3986 5.35119 29.0237 5.97631C29.6488 6.60143 30 7.44928 30 8.33333ZM20 29.9917L20.0083 30L20 30.0083L19.9917 30L20 29.9917ZM25 29.9917L25.0083 30L25 30.0083L24.9917 30L25 29.9917ZM15 29.9917L15.0083 30L15 30.0083L14.9917 30L15 29.9917Z"
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              thinStrokeWidth={0.9}
              d="M24.1667 5V8.33333C24.1667 8.55435 24.0789 8.76631 23.9226 8.92259C23.7663 9.07887 23.5543 9.16667 23.3333 9.16667H16.6667C16.4457 9.16667 16.2337 9.07887 16.0774 8.92259C15.9211 8.76631 15.8333 8.55435 15.8333 8.33333V5M30 8.33333V31.6667C30 32.5507 29.6488 33.3986 29.0237 34.0237C28.3986 34.6488 27.5507 35 26.6667 35H13.3333C12.4493 35 11.6014 34.6488 10.9763 34.0237C10.3512 33.3986 10 32.5507 10 31.6667V8.33333C10 7.44928 10.3512 6.60143 10.9763 5.97631C11.6014 5.35119 12.4493 5 13.3333 5H26.6667C27.5507 5 28.3986 5.35119 29.0237 5.97631C29.6488 6.60143 30 7.44928 30 8.33333ZM20 29.9917L20.0083 30L20 30.0083L19.9917 30L20 29.9917ZM25 29.9917L25.0083 30L25 30.0083L24.9917 30L25 29.9917ZM15 29.9917L15.0083 30L15 30.0083L14.9917 30L15 29.9917Z"
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

import Script from 'next/script';
import React from 'react';
import type { IGoogleAnalytics } from './MarketingScripts.types';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const gaPageview = (url: URL, gaTrackingId?: string) => {
  window.gtag('config', gaTrackingId as string, {
    page_path: url,
  });
};

export const GoogleAnalytics = ({ gaTrackingId }: IGoogleAnalytics) => {
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gaTrackingId}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  );
};

import { styled } from '@mui/material/styles';
import React from 'react';
import { colors } from '../../theme/default/color';
import type { HeadlineLinkProps } from './HeadlineLink.types';

const StyledHeadline = styled('span')({
  fontWeight: 'bold',
  fontFamily: 'Axia Black',
  color: colors.lightblue,
  textDecoration: 'none',
});

export const HeadlineLink: React.FC<HeadlineLinkProps> = ({ linkText }) => {
  return <StyledHeadline>{linkText}</StyledHeadline>;
};

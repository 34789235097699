import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';
import { viewBoxes } from './CustomSvgIcons.types';

const SvgPath = ({ shapeId }: SvgPathProps): ReactJSXElement => {
  return (
    <g id={shapeId}>
      <path
        opacity="0.75"
        d="M11.876 16.0112L14.9302 7H12.469L10.0235 14.3836H11.1615L8.80612 7H6.41108L9.36188 16.0112H11.876Z"
      />
      <path d="M12.4357 7.00006L9.48486 16.0112H11.9461L14.2882 10.2817H13.1502L15.5055 16.0112H17.9006L14.9498 7.00006H12.4357Z" />
      <path d="M18.5348 7.00006L15.584 16.0112H18.0452L20.3873 10.2817H19.2493L21.6047 16.0112H23.9997L21.0489 7.00006H18.5348Z" />
      <path d="M1.46878 12.6502V10.361H5.22674V12.6502H1.46878ZM1.42908 9.28918L2.39504 8.2306V14.9526L1.36292 13.722H5.92805V16.0112H0V7H5.92805V9.28918H1.42908Z" />
    </g>
  );
};

export const EvmDebuggerIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_evmDebuggerCurrentColorGradient`;
  const newColorId = `${prefix}_evmDebuggerNewColorGradient`;
  const shapeId = `${prefix}_evmDebuggerShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.redColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.redColors?.PLUS_3,
      stop: theme.palette.redColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox={viewBoxes[iconType]}>
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

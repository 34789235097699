import React, { useContext } from 'react';
import { ComponentsType } from '../../context/nextComponentsContext/nextComponents.Context.types';
import { NextComponentsContext } from '../../context/nextComponentsContext/nextComponentsContext';

interface IShouldUseNextPropsOutput {
  Image: ComponentsType['NextImage'] | 'img';
  Link: ComponentsType['NextLink'] | 'a';
  Script: ComponentsType['Script'] | 'script';
  isUsingNext: boolean;
}

export const useUniversalComponents = (): IShouldUseNextPropsOutput => {
  const context = useContext(NextComponentsContext);

  return {
    Script: context?.components?.Script || 'script',
    Link: context?.components?.NextLink || 'a',
    Image: context?.components?.NextImage || 'img',
    isUsingNext: !!context,
  };
};

import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';

const SvgPath = ({ shapeId, solidColor }: SvgPathProps): ReactJSXElement => {
  return (
    <g id={shapeId}>
      <path
        d="M7.01392 20L10.8334 14.6527L16.1806 21.5277L23.0556 10.8333"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.845 4.15492C34.9855 3.29539 33.8197 2.8125 32.6042 2.8125H7.39583C6.18025 2.8125 5.01447 3.29539 4.15492 4.15492C3.29539 5.01447 2.8125 6.18025 2.8125 7.39583V25.7292C2.8125 26.9447 3.29539 28.1105 4.15492 28.97C5.01447 29.8297 6.18025 30.3125 7.39583 30.3125H32.6042C33.8197 30.3125 34.9855 29.8297 35.845 28.97C36.7047 28.1105 37.1875 26.9447 37.1875 25.7292V7.39583C37.1875 6.18025 36.7047 5.01447 35.845 4.15492Z"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9277 37.2042H15.0729C16.4941 35.2948 17.5789 32.5972 17.7219 30.3125H22.2788C22.4217 32.5972 23.5066 35.2948 24.9277 37.2042Z"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 10.91666L30.01 10.15832"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.8221 20.4622L29.7421 21.9568"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.4783 13.7365C33.4783 14.3311 33.242 14.9013 32.8217 15.3217C32.4013 15.7422 31.8309 15.9784 31.2364 15.9784H27.5V11.4946H31.2364C31.8309 11.4946 32.4013 11.7308 32.8217 12.1512C33.242 12.5717 33.4783 13.1419 33.4783 13.7365Z"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.4783 18.2203C33.4783 18.8149 33.242 19.3851 32.8217 19.8055C32.4013 20.226 31.8309 20.4622 31.2364 20.4622H27.5V15.9784H31.2364C31.8309 15.9784 32.4013 16.2146 32.8217 16.635C33.242 17.0555 33.4783 17.6257 33.4783 18.2203Z"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 37.2083L28.3334 37.2082"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  );
};

export const DexDevelopmentIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_dexDevelopmentCurrentColorGradient`;
  const newColorId = `${prefix}_dexDevelopmentNewColorGradient`;
  const shapeId = `${prefix}_dexDevelopmentShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.purpleColors?.PLUS_3,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { CustomSvgIconsProps, SvgPathProps } from '../../CustomSvgIcons.types';
import { useTheme } from '@mui/material';
import { SvgPath } from '../../SvgPath/SvgPath';

export const MobileCashSendIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_mobileCashSendIconCurrentColorGradient`;
  const newColorId = `${prefix}_mobileCashSendIconNewColorGradient`;
  const shapeId = `${prefix}_mobileCashSendIconShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';

  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.purpleColors?.PLUS_3,
      stop: theme.palette.purpleColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;

  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      {solidColor ? (
        <SvgPath
          d="M13.3334 30H20.0001M26.6667 23.3333V30.8333C26.6667 31.9384 26.2278 32.9982 25.4464 33.7796C24.665 34.561 23.6052 35 22.5001 35H10.8334C9.72835 35 8.66854 34.561 7.88714 33.7796C7.10573 32.9982 6.66675 31.9384 6.66675 30.8333V9.16667C6.66675 8.0616 7.10573 7.00179 7.88714 6.22039C8.66854 5.43899 9.72835 5 10.8334 5H13.3334M16.6667 11.6667H20.0001M20.0001 16.6667H23.3334M31.6667 5V6.34667M31.6667 6.34667C30.8694 6.27107 30.0741 6.51058 29.4511 7.01395C28.8282 7.51732 28.427 8.24455 28.3334 9.04C28.3335 9.59296 28.5252 10.1288 28.8758 10.5563C29.2265 10.9839 29.7145 11.2767 30.2567 11.385L33.0768 11.9483C33.619 12.0566 34.107 12.3494 34.4576 12.777C34.8083 13.2045 35 13.7404 35.0001 14.2933C34.9065 15.0888 34.5053 15.816 33.8824 16.3194C33.2594 16.8228 32.4641 17.0623 31.6667 16.9867M31.6667 6.34667C32.3626 6.30249 33.0542 6.48327 33.6395 6.86232C34.2247 7.24137 34.6725 7.79859 34.9168 8.45167M31.6667 18.3333V16.9867M31.6667 16.9867C30.9709 17.0308 30.2793 16.8501 29.694 16.471C29.1088 16.092 28.661 15.5347 28.4167 14.8817M20.0001 6.61167H23.3334"
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              d="M13.3334 30H20.0001M26.6667 23.3333V30.8333C26.6667 31.9384 26.2278 32.9982 25.4464 33.7796C24.665 34.561 23.6052 35 22.5001 35H10.8334C9.72835 35 8.66854 34.561 7.88714 33.7796C7.10573 32.9982 6.66675 31.9384 6.66675 30.8333V9.16667C6.66675 8.0616 7.10573 7.00179 7.88714 6.22039C8.66854 5.43899 9.72835 5 10.8334 5H13.3334M16.6667 11.6667H20.0001M20.0001 16.6667H23.3334M31.6667 5V6.34667M31.6667 6.34667C30.8694 6.27107 30.0741 6.51058 29.4511 7.01395C28.8282 7.51732 28.427 8.24455 28.3334 9.04C28.3335 9.59296 28.5252 10.1288 28.8758 10.5563C29.2265 10.9839 29.7145 11.2767 30.2567 11.385L33.0768 11.9483C33.619 12.0566 34.107 12.3494 34.4576 12.777C34.8083 13.2045 35 13.7404 35.0001 14.2933C34.9065 15.0888 34.5053 15.816 33.8824 16.3194C33.2594 16.8228 32.4641 17.0623 31.6667 16.9867M31.6667 6.34667C32.3626 6.30249 33.0542 6.48327 33.6395 6.86232C34.2247 7.24137 34.6725 7.79859 34.9168 8.45167M31.6667 18.3333V16.9867M31.6667 16.9867C30.9709 17.0308 30.2793 16.8501 29.694 16.471C29.1088 16.092 28.661 15.5347 28.4167 14.8817M20.0001 6.61167H23.3334"
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

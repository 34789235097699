import type { Mixins } from '@mui/material/styles/createMixins';
import { createBreakpoints } from '@mui/system';
import { breakpoints as breakpointsValues } from '../default/breakpoints';

const breakpoints = createBreakpoints(breakpointsValues);

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    hoverTextLightBlue: CSSProperties;
    mobilePadding: (paddingY?: string) => CSSProperties;
    flexColumnStartStart: CSSProperties;
    defaultTransition: CSSProperties;
  }
}

const applyMobilePadding = (paddingY = '0px') => {
  return {
    [breakpoints.down('md')]: {
      padding: `${paddingY} 48px`,
    },
    [breakpoints.down('sm')]: {
      padding: `${paddingY} 24px`,
    },
  };
};

export const mixins: Mixins = {
  defaultTransition: {
    transition: 'all 0.3s ease-in-out',
  },
  hoverTextLightBlue: {
    transition: 'all 0.3s ease-in-out',
    color: '#2F57F4',
  },
  mobilePadding: applyMobilePadding,
  flexColumnStartStart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
} as Mixins;

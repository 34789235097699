import React, { useCallback } from 'react';
import { isTouchScreen } from '../../../utils/touchScreenDetect';
import type { HeaderMenuItemWithCollapseProps } from './HeaderMenuItemWithCollapseProps.types';
import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
  cursor: 'pointer',
}));

export const HeaderMenuItemWithCollapse: React.FC<
  HeaderMenuItemWithCollapseProps
> = ({
  onSubmenuNameLeave,
  onSubmenuNameEnter,
  children,
  onSubmenuChange,
  onSubmenuClose,
  submenu,
  state,
}) => {
  const handleTouchStart = useCallback(() => {
    if (!isTouchScreen()) return;
    if (!state.currentSubmenu) {
      onSubmenuChange(submenu);
    } else {
      onSubmenuClose(submenu);
    }
  }, [onSubmenuChange, onSubmenuClose, submenu, state.currentSubmenu]);

  const handleMouseEnter = useCallback(() => {
    onSubmenuNameEnter({ sub: submenu, subHover: true });
  }, [onSubmenuNameEnter, submenu]);

  const handleMouseLeave = useCallback(() => {
    onSubmenuNameLeave({ sub: submenu, subHover: false });
  }, [onSubmenuNameLeave, submenu]);

  return (
    <Wrapper
      onTouchStart={handleTouchStart}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {children}
    </Wrapper>
  );
};

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';
import type { TextBlockGridProps } from './TextBlockGrid.types';

const PREFIX = 'TextBlockGrid';

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  item: `${PREFIX}-item`,
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {
    marginBottom: '24px',
  },

  [`& .${classes.header}`]: {
    fontWeight: 'bold',
    lineHeight: '32px',
  },

  [`& .${classes.item}`]: {
    padding: '16px 16px 16px 0px',
  },
}));

export const TextBlockGrid: React.FC<TextBlockGridProps> = ({ model }) => {
  return (
    <StyledGrid
      container
      className={clsx(classes.root, 'blockPadding')}
      justifyContent="flex-start">
      {model?.attributes?.blocks?.data.map((block) => (
        <Grid
          className={classes.item}
          item
          key={block?.attributes?.url_path_id}
          md={6}
          sm={10}>
          <div className={classes.header}>{block?.attributes?.header}</div>
          <div>{block?.attributes?.content}</div>
        </Grid>
      ))}
    </StyledGrid>
  );
};

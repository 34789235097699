import { Box, styled } from '@mui/material';

export const ArrowWrapper = styled(Box)(({ theme }) => ({
  width: '36px',
  height: '36px',
  borderRadius: '24px',
  border: `1px solid ${theme.palette.colorLink}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'relative',
}));
export const StyledIcon = styled(`img`)(() => ({
  width: '10px',
  height: '10px',
  transform: 'rotate(90deg)',
  position: 'absolute',
  transition: 'all 0.4s ease-in-out',
}));

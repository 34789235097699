import { Stack, styled } from '@mui/material';

export const StyledStack = styled(Stack)(() => ({
  borderRadius: '24px',
  background: '#F5F6F8',
  alignItems: 'center',
}));
export const StyledTextWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
}));
export const StyledImage = styled(`img`)(() => ({
  borderRadius: '24px',
  height: 'auto',
  width: '100%',
}));

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { RenderWithAlgeaTheme } from '../../utils/RenderWithAlgeaTheme';
import { StyledAddressField, StyledCompanyTitle, StyledGrid } from './styles';

export const CompanyData: React.FC = () => {
  return (
    <Grid>
      <RenderWithAlgeaTheme>
        <Grid item xs={12} lg={10} xl={8}>
          <StyledGrid>
            <StyledCompanyTitle item xs={12}>
              <Typography variant="heading6">
                Rumble Fish Poland sp. z o.o.
              </Typography>
            </StyledCompanyTitle>
            <Grid item xs={12} sm={5} lg={2}>
              <StyledAddressField>
                <span>Address:</span>
                <Typography variant="body2Small">
                  Filipa Eisenberga 11/3,
                </Typography>
                <Typography variant="body2Small">
                  31-523 Kraków, Poland
                </Typography>
              </StyledAddressField>
            </Grid>
            <Grid item xs={12} sm={5} lg={2}>
              <StyledAddressField>
                <span>Phone:</span>
                <Typography variant="body2Small">+48 601 265 364</Typography>
              </StyledAddressField>
            </Grid>
            <Grid item xs={12} sm={5} lg={2}>
              <StyledAddressField>
                <span>Email:</span>
                <Typography variant="body2Small">
                  hello@rumblefish.dev
                </Typography>
              </StyledAddressField>
            </Grid>
            <Grid item xs={12} sm={5} lg={2}>
              <StyledAddressField>
                <span>NIP:</span>
                <Typography variant="body2Small">PL6772425725</Typography>
              </StyledAddressField>
            </Grid>
          </StyledGrid>
        </Grid>
      </RenderWithAlgeaTheme>
    </Grid>
  );
};

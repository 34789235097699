import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';

const SvgPath = ({ shapeId, solidColor }: SvgPathProps): ReactJSXElement => {
  return (
    <g id={shapeId}>
      <path
        d="M4.13831 29.8117H9.80384V32.0782H13.5813"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.1261 18.4798H9.04835V15.4578H12.0703"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.1261 25.2811H11.3148M11.3148 25.2811V20.7481H14.3368M11.3148 25.2811H18.8697"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1142 21.5037C17.697 21.5037 17.3588 21.1654 17.3588 20.7482C17.3588 20.3309 17.697 19.9928 18.1142 19.9928"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1143 21.5037C18.5316 21.5037 18.8697 21.1654 18.8697 20.7482C18.8697 20.3309 18.5316 19.9928 18.1143 19.9928"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6448 16.2131C22.2275 16.2131 21.8892 15.8749 21.8892 15.4576C21.8892 15.0404 22.2275 14.7021 22.6448 14.7021"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6449 16.2131C23.0621 16.2131 23.4004 15.8749 23.4004 15.4576C23.4004 15.0404 23.0621 14.7021 22.6449 14.7021"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3586 32.8318C16.9413 32.8318 16.6031 32.4935 16.6031 32.0764C16.6031 31.659 16.9413 31.3209 17.3586 31.3209"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3586 32.8318C17.7758 32.8318 18.1141 32.4935 18.1141 32.0764C18.1141 31.659 17.7758 31.3209 17.3586 31.3209"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9577 19.9907V15.4578H26.4247"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.7132 29.0567V32.0786H25.6693"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.8685 29.0565V32.0784C35.8685 33.2808 35.3908 34.4337 34.5407 35.2837C33.6907 36.1339 32.5377 36.6114 31.3355 36.6114H8.6706C7.46838 36.6114 6.3154 36.1339 5.46531 35.2837C4.61521 34.4337 4.13763 33.2808 4.13763 32.0784V16.2131C4.13763 15.0109 4.61521 13.8579 5.46531 13.0078C6.3154 12.1577 7.46838 11.6801 8.6706 11.6801H31.3355C32.5377 11.6801 33.6907 12.1577 34.5407 13.0078C35.3908 13.8579 35.8685 15.0109 35.8685 16.2131V19.9906"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3355 11.6801L27.8165 4.6419C27.6833 4.37556 27.4991 4.13806 27.2741 3.94295C27.0493 3.74784 26.7882 3.59895 26.5057 3.50479C26.2232 3.41064 25.9249 3.37306 25.628 3.39422C25.331 3.41536 25.041 3.49481 24.7747 3.62803L8.67065 11.6801"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.1202 29.0565C36.6546 29.0565 37.1669 28.8656 37.5447 28.5254C37.9227 28.1854 38.1349 27.7242 38.1349 27.2434V21.8039C38.1349 21.3229 37.9227 20.8617 37.5447 20.5217C37.1669 20.1817 36.6546 19.9906 36.1202 19.9906H31.0837C29.7479 19.9906 28.4668 20.4682 27.5222 21.3182C26.5776 22.1684 26.0469 23.3214 26.0469 24.5235C26.0469 25.7257 26.5776 26.8787 27.5222 27.7289C28.4668 28.579 29.7479 29.0565 31.0837 29.0565H36.1202Z"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9582 25.0901C30.6454 25.0901 30.3916 24.8365 30.3916 24.5236C30.3916 24.2106 30.6454 23.957 30.9582 23.957"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9583 25.0901C31.2711 25.0901 31.5249 24.8365 31.5249 24.5236C31.5249 24.2106 31.2711 23.957 30.9583 23.957"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  );
};

export const WalletIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_walletCurrentColorGradient`;
  const newColorId = `${prefix}_walletNewColorGradient`;
  const shapeId = `${prefix}_walletShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.raspberryColors?.PLUS_2,
    },
  };
  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.raspberryColors?.PLUS_3,
      stop: theme.palette.raspberryColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

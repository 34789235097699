import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import type { ImageProps, LinkImageProps } from './LinkImage.types';
import { NextLink } from '../../../NextLink';
const StyledImage = styled('img')(() => ({
  objectFit: 'contain',
  cursor: 'pointer',
  width: '100%',
  maxWidth: '75vw',
  height: 'auto',
}));

const StyledLink = styled('a')({
  display: 'inline-block',
});

const Image = ({ src, title }: ImageProps) => {
  return (
    <StyledImage width="281" height="187" src={src ? src : ''} title={title} />
  );
};

export const LinkImage: React.FC<LinkImageProps> = ({ model }) => {
  const type = model?.attributes?.type;
  const url = model?.attributes?.url || '';
  const src = model?.attributes?.image?.data?.attributes?.url;
  const title = model?.attributes?.title || '';
  const width = !model?.attributes?.width ? 100 : model?.attributes?.width;
  const imagePlacement = !model?.attributes?.imagePlacement
    ? 'center'
    : model?.attributes?.imagePlacement;

  const centerLinkStyle = {
    display: 'flex',
    justifyContent: 'center',
    img: {
      width: `${width}%`,
    },
  };
  const sideLinkStyle = {
    width: `${width}%`,
    float: imagePlacement,
    padding: '15px !important',
    margin: '0px !important',
    img: {
      margin: '0px !important',
    },
  };
  const ultimateLinkStyle =
    imagePlacement === 'center' ? centerLinkStyle : sideLinkStyle;
  if (type === 'internal')
    return (
      <NextLink href={url}>
        <StyledLink sx={ultimateLinkStyle}>
          <Image src={src} title={title} />
        </StyledLink>
      </NextLink>
    );
  if (type === 'external')
    return (
      <StyledLink href={url} sx={ultimateLinkStyle}>
        <Image src={src} title={title} />
      </StyledLink>
    );
  return (
    <Stack sx={ultimateLinkStyle}>
      <Image src={src} title={title} />
    </Stack>
  );
};

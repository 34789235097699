import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';
import { viewBoxes } from './CustomSvgIcons.types';

const SvgPath = ({
  iconType,
  shapeId,
  solidColor,
}: SvgPathProps): ReactJSXElement => {
  if (iconType === 'bold') {
    return (
      <path
        d="M20.976 11.619L20.301 5.662C20.13 4.146 18.847 3 17.321 3H6.679C5.153 3 3.87 4.146 3.698 5.662L3.023 11.619M18 15H6M18 15C19.657 15 21 16.343 21 18C21 19.657 19.657 21 18 21H6C4.343 21 3 19.657 3 18C3 16.343 4.343 15 6 15M18 15C19.657 15 21 13.657 21 12C21 10.343 19.657 9 18 9H6C4.343 9 3 10.343 3 12C3 13.657 4.343 15 6 15M11 12H18M6 12H7M6 18H7M11.07 18H18.07"
        fill="none"
        id={shapeId}
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
    );
  } else if (iconType === 'normal') {
    return (
      <path
        d="M34.96 19.365L33.835 9.43667C33.55 6.91 31.4117 5 28.8683 5H11.1317C8.58833 5 6.45 6.91 6.16333 9.43667L5.03833 19.365M30 25H10M30 25C32.7617 25 35 27.2383 35 30C35 32.7617 32.7617 35 30 35H10C7.23833 35 5 32.7617 5 30C5 27.2383 7.23833 25 10 25M30 25C32.7617 25 35 22.7617 35 20C35 17.2383 32.7617 15 30 15H10C7.23833 15 5 17.2383 5 20C5 22.7617 7.23833 25 10 25M18.3333 20H30M10 20H11.6667M10 30H11.6667M18.45 30H30.1167"
        fill="none"
        id={shapeId}
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.1"
      />
    );
  } else {
    return (
      <path
        d="M69.92 38.73L67.67 18.8733C67.1 13.82 62.8233 10 57.7367 10H22.2633C17.1767 10 12.9 13.82 12.3267 18.8733L10.0767 38.73M60 50H20M60 50C65.5233 50 70 54.4767 70 60C70 65.5233 65.5233 70 60 70H20C14.4767 70 10 65.5233 10 60C10 54.4767 14.4767 50 20 50M60 50C65.5233 50 70 45.5233 70 40C70 34.4767 65.5233 30 60 30H20C14.4767 30 10 34.4767 10 40C10 45.5233 14.4767 50 20 50M36.6667 40H60M20 40H23.3333M20 60H23.3333M36.9 60H60.2333"
        fill="none"
        id={shapeId}
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
    );
  }
};

export const AwsIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_awsCurrentColorGradient`;
  const newColorId = `${prefix}_awsNewColorGradient`;
  const shapeId = `${prefix}_awsShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';

  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.redColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.redColors?.PLUS_3,
      stop: theme.palette.redColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;

  return (
    <SvgIcon {...props} viewBox={viewBoxes[iconType]}>
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

import type { Action } from '@reduxjs/toolkit';
import { all, put, takeEvery } from 'redux-saga/effects';
interface ErrorPayloadAction extends Partial<Action> {
  type: string;
  payload: { e: { message: string } };
}

export function* setError(payload: ErrorPayloadAction) {
  yield put({
    type: 'error/updateErrMsg',
    payload: payload,
  });
}

export function* setErrorAsync() {
  yield takeEvery('SET_ERROR_ASYNC', setError);
}

export default function* rootSaga() {
  yield all([setErrorAsync()]);
}

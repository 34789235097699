import React from 'react';
import { InstagramPostsProps } from './InstagramPosts.types';

export const InstagramPosts: React.FC<InstagramPostsProps> = ({ model }) => {
  return (
    <div>
      {/* {model.fields.posts.map((post) => (
      ))} */}
    </div>
  );
};

import { Stack, styled, Box } from '@mui/material';
import { MenuBoxIcon } from '../MenuBoxIcon';

export const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  display: 'flex',
  padding: theme.spacing(5, 0),
  overflow: 'hidden',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(2, 0),
  },
  [theme.breakpoints.down('md')]: {
    overflow: 'visible',
    padding: theme.spacing(0, 0),
    ...theme.mixins.flexColumnStartStart,
  },
}));
export const StyledWrapper = styled(Stack)(({ theme }) => ({
  width: '81%',
  flexFlow: 'row wrap',
  alignItems: 'flex-start',
  [theme.breakpoints.down(1040)]: {
    width: '87%',
  },
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    ...theme.mixins.flexColumnStartStart,
  },
}));
export const StyledDivider = styled(Stack)(({ theme }) => ({
  background: theme.palette.colorLines50,
  width: '1px',
  height: '400px',
  opacity: 0.15,
  margin: theme.spacing(1, 0, 0, 8),
  [theme.breakpoints.down('lg')]: {
    margin: theme.spacing(0),
  },
  [theme.breakpoints.down('md')]: {
    height: '1px',
    width: '100%',
    margin: '0 0 10px 0',
  },
}));

export const StyledLinkWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0, 0, 4),
  [theme.breakpoints.down(1040)]: {
    margin: theme.spacing(2, 0, 0, 0),
  },
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(3, 0, 2, 0),
  },
}));
export const StyledMenuBoxIcon = styled(MenuBoxIcon)(({ theme }) => ({
  margin: theme.spacing(0, 0, 1, 3),

  ['&:hover']: {
    background:
      theme.palette.type === 'dark'
        ? '#000000b8'
        : theme.palette.type === 'navy' && 'rgba(255, 255, 255, 0.1);',
  },
  [theme.breakpoints.down('lg')]: {
    margin: theme.spacing(0),
  },
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(2, 0),
  },
}));

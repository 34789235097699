import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';

const SvgPath = ({ shapeId, solidColor }: SvgPathProps): ReactJSXElement => {
  return (
    <g id={shapeId}>
      <path
        d="M27.1333 6.24988V7.77766"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1394 16.9444L27.1333 18.4722"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9525 10.0694C30.9525 10.6772 30.7111 11.2601 30.2812 11.6898C29.8514 12.1196 29.2686 12.3611 28.6607 12.3611H24.8414V7.77771H28.6607C29.2686 7.77771 29.8514 8.01916 30.2812 8.44893C30.7111 8.87869 30.9525 9.4616 30.9525 10.0694Z"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9525 14.6528C30.9525 15.2605 30.7111 15.8434 30.2812 16.2732C29.8514 16.703 29.2686 16.9444 28.6607 16.9444H24.8414V12.3611H28.6607C29.2686 12.3611 29.8514 12.6025 30.2812 13.0323C30.7111 13.4621 30.9525 14.045 30.9525 14.6528Z"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3621 22.6736C32.8467 22.6736 37.2928 18.2275 37.2928 12.7431C37.2928 7.25856 32.8467 2.8125 27.3621 2.8125C21.8778 2.8125 17.4317 7.25856 17.4317 12.7431C17.4317 14.2447 17.765 15.6685 18.3615 16.9444L15.5219 23.0556L22.397 21.3452C23.8576 22.19 25.5534 22.6736 27.3621 22.6736Z"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinejoin="round"
      />
      <path
        d="M36 29V24.625C36 24.4592 35.9853 24.5 35.9853 24"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.20944 30.3125V14.2709C5.20944 13.6631 5.4509 13.0802 5.88066 12.6504C6.31043 12.2206 6.89333 11.9792 7.50111 11.9792H13.2303"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0531 30.3126C23.6554 31.0086 23.0808 31.5872 22.3875 31.9895C21.6942 32.3918 20.9069 32.6039 20.1053 32.6039C19.3037 32.6039 18.5164 32.3918 17.8231 31.9895C17.1298 31.5872 16.5552 31.0086 16.1575 30.3126H2.91779V32.6043C2.91779 33.8198 3.40068 34.9856 4.26021 35.8451C5.11975 36.7048 6.28554 37.1876 7.50111 37.1876H32.7095C33.925 37.1876 35.0908 36.7048 35.9503 35.8451C36.81 34.9856 37.2928 33.8198 37.2928 32.6043V30.3126H24.0531Z"
        fill="none"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  );
};

export const ContractsIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_contractsCurrentColorGradient`;
  const newColorId = `${prefix}_contractsNewColorGradient`;
  const shapeId = `${prefix}_contractsShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.orangeColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.orangeColors?.PLUS_3,
      stop: theme.palette.orangeColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};

import React from 'react';
import { getCmsOptimizedUrl } from '../../../utils/getCmsOptimizedUrl';
import { truncateText, removeDash } from '../../../utils/stringUtilis';
import type { MenuBoxImageProps } from './MenuBoxImage.types';
import {
  StyledStack,
  StyledHeadline,
  StyledTextWrapper,
  StyledImage,
  StyledText,
  StyledLink,
} from './styles';

export const MenuBoxImage = ({
  headline,
  text,
  img,
  link,
  ...props
}: MenuBoxImageProps) => {
  const truncatedText = truncateText(text, 80);
  const adjustedHeadline = removeDash(headline || '');
  const adjustedImage = getCmsOptimizedUrl({
    url: img,
    width: 128,
    quality: 100,
  });
  const websiteUrl = process.env.NEXT_PUBLIC_PATH as string;
  return (
    <StyledLink
      href={link}
      target={`${
        link.startsWith(websiteUrl) || link.startsWith('/') ? '_self' : '_blank'
      }`}
      rel={`${link.startsWith(websiteUrl) ? '' : 'noopener noreferrer'}`}>
      <StyledStack direction="row" spacing={2} {...props}>
        <StyledImage
          src={adjustedImage}
          alt="menu box"
          style={{ filter: 'none' }}
        />
        <StyledTextWrapper>
          <StyledHeadline variant={'buttonSmall'}>
            {adjustedHeadline}
          </StyledHeadline>
          <StyledText variant={'caption'}>{truncatedText}</StyledText>
        </StyledTextWrapper>
      </StyledStack>
    </StyledLink>
  );
};

import { useTheme } from '@mui/material';
import { CallToAction, Link } from '@rumblefish/ui/AlgeaTheme';
import React from 'react';
import type { CaseBoxProps } from './CaseBox.types';
import {
  ButtonWrapper,
  StyledCompany,
  StyledStack,
  StyledTitle,
} from './styles';

export const CaseBox = ({ company, isActive, link, title }: CaseBoxProps) => {
  const theme = useTheme();

  const companyColor = isActive
    ? theme.palette.colorBrand?.secondary
    : '#A2A9B0';
  const titleColor = isActive ? theme.palette.colorBlack : '#A2A9B0';

  return (
    <StyledStack>
      <StyledCompany variant="buttonRegular" color={companyColor}>
        {company}
      </StyledCompany>
      <StyledTitle variant="heading4" color={titleColor}>
        {title}
      </StyledTitle>
      <ButtonWrapper sx={isActive ? { opacity: 1 } : { opacity: 0 }}>
        <Link to={link}>
          <CallToAction
            text="Check it!"
            arrowDirection="right"
            sx={{ maxWidth: '320px' }}
          />
        </Link>
      </ButtonWrapper>
    </StyledStack>
  );
};
